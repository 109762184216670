import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import PartsModalSteps from "./PartsModalSteps";


type Props = {
  activeStep: number;
}

/**
 * 基本プラン選択モーダルのステップ
 * 
 * @param props 
 * @returns 
 */
const PartsComprehensiveOptionModalSteps: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const steps = [
    {
      text: t("ComprehensiveOptionDetailsModal.詳細確認"),
      active: props.activeStep === 1 ? " active" : ""
    },
    {
      text: t("ComprehensiveOptionDetailsModal.日程選択"),
      active: props.activeStep === 2 ? " active" : ""
    },
  ];

  return <PartsModalSteps steps={steps} activeStep={props.activeStep}/>
};

export default PartsComprehensiveOptionModalSteps;
