import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


type Props = {
  totalPrice: number;
}

const PartsTotalPrice: React.FC<Props> = ({
  totalPrice,
}) => {

  const { t } = useTranslation();

  return (
    <div className="parts-bottom-fixed">
      <div className="wrap-content-900-no_padding ">
        <div className="box-white fixed-padding">
          <div className="d-f ai-c jc-sb jc-fe-md">
            <p className="fw-b pr-24">{t("PlanSelectConfirm.現在の旅行代金合計")}</p>
            <p className="fz-24 fz-28-md c-red fw-b">
              {totalPrice > 0 ? totalPrice.toLocaleString() : '--'}<span className="fz-14 fz-16-md">{t("Common.円")}(税込)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsTotalPrice;
