import React from "react";
import { useTranslation } from "react-i18next";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

const ServiceStop: React.FC = () => {

  const { t } = useTranslation();

  return (
    <>
      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                alt=""
              />
            </p>
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("ServiceStop.サービス停止中です")}</p>
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">服務停止中。</p> */}
            <p className="fz-16 ta-c-md c-blue_gray">
              {t("ServiceStop.一時的にサービスを停止しております。")}<br />
              {t("ServiceStop.サービス再開日時については現時点で未定となっております。")}<br />
              {t("ServiceStop.大変ご迷惑をお掛けしますが、")}<br className="d-n d-b-md" />
              {t("ServiceStop.何卒ご理解いただけますようお願い申し上げます。")}
              {/* 暫時停止服務。<br />
              服務再開時間仍未定。<br />
              造成了您的不便，在此致上十二萬分的歉意，<br className="d-n d-b-md" />
              並希望獲得您的諒解，謝謝您的合作。 */}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceStop;
