import React, {useEffect, useState} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form4Slice";
import { RootState } from "../redux/store";
import { InputsForm4 } from "../types/InputsForm4";
import { useTravelPrice } from "../utils/useTravelPrice";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionPayment
} from "./section/Form4"
import SectionTravelPrice from "./section/SectionTravelPrice";
import {PlanSelectFormData} from "../types/planSelectForm";
import {getPriceRequestParams} from "../utils/price";
import {creditAPI, getCreditRequestPrams} from "../utils/credit";
// import {insuranceQuoteRequest} from "../types/insurance";
import {getBackSearchURL} from "../utils/search";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";


// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const Form4: React.FC = () => {
  const classesForLoading = useStyles();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [planSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  // reduxに保存されているデータをデバッグ
  // for debug
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜3", {
    ...savedState.form1,
    ...savedState.form2,
    ...savedState.form3,
  });

  const savedForm1 = savedState.form1.form1;
  const savedForm3 = savedState.form3.form3;
  devLog("savedForm3", savedForm3)

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<InputsForm4>({
  });

  const [creditLoading, setCreditLoading] = useState(false)
  const [isCreditError,setIsCreditError] = useState(false);
  const onSubmit: SubmitHandler<any> = async (data) => {
    
    setCreditLoading(true);
    const creditRequestPrams = getCreditRequestPrams(planSelectFormData.data, savedForm1, savedForm3, data, i18n.language);
    const resultCredit = await creditAPI(creditRequestPrams);
    setCreditLoading(false);
    if (resultCredit.status !== 200) {
      // 与信エラー
      setIsCreditError(true)
      window.scrollTo(0, 0)
    } else if (resultCredit.data !== null)  {
      dispatch(save({ form4: {
        payment: {
          cashcard_number: data.payment.cashcard_number,
          cashcard_month: data.payment.cashcard_month,
          cashcard_year: data.payment.cashcard_year,
          cashcard_name: data.payment.cashcard_name,
          cashcard_number_of_payments: data.payment.cashcard_number_of_payments,
          cashcard_cvc: data.payment.cashcard_cvc,
          token: resultCredit.responce.paymentId,
        }
        }}));
      planSelectFormData.data.priceData = resultCredit.responce.resultPlanPrice;
      
      navigate(ROUTES.Form5.replace(":lang", i18n.language),{ state: {
          data: planSelectFormData.data
        } });
    }
    
    
  };

  // 料金確認APIリクエストパラメータ
  const priceParams = getPriceRequestParams(planSelectFormData.data, savedForm1, savedForm3, i18n.language);

  // 料金確認
  const [ priceData, { isLoading, isError, error } ] = useTravelPrice({data: priceParams});

  // todo: API呼び出しエラー時の処理
  if (isError) {
    console.error(error);
  }

  useEffect(() => {
    if(priceData?.status === 200) {
      planSelectFormData.data.travelPrice = priceData.result.insurance.insuranceCancelAmount;
    } else if (priceData && isError) {
      alert(t("Form4.システムエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。"));
      // alert("系統發生錯誤，請過段時間再行設定您的行程方案。");
      window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id, i18n.language);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData])
  // todo フォームデータチェック（ない場合はstep１に戻る、プランセレクトデータがない場合は404）


  // let buttonIconLabel = "5";
  // if (!isOption(planSelectFormData.data)) {
  //   buttonIconLabel = "4";
  // }
  let buttonIconLabel = "4";
  if (!isOption(planSelectFormData.data)) {
    buttonIconLabel = "3";
  }

  return (
      <MainTemplate 
        title={t("Form4.決済情報の入力")} 
        logo={planSelectFormData.data.logo} 
        sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId}
        supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial}
        planSelectFormData={planSelectFormData.data}
      >
        
        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading || creditLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              {t("Form4.決済情報の入力")}
              {/* 請輸入支付方式 */}
            </h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
           {/* <FormStep activeStep={4} isOption={isOption(planSelectFormData.data)}></FormStep> */}
           <FormStep activeStep={3} isOption={isOption(planSelectFormData.data)} />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--アラート--> */}
              {isCreditError ?
                <>
                  <div className="wrap-content-900-no_padding">
                    <div className="box-alert-red-small icon-alert">
                      <p className="fz-14 fz-16-md c-red">
                        {t("Form4.クレジットカード確認エラー")}
                        {/* 信用卡出現錯誤 */}
                      </p>
                      <p className="fz-14">
                        {t("Form4.クレジットカードの与信チェックでエラーが発生しました。")}<br />
                        {t("Form4.このカードでは取引することはできません。")}
                        {/* 您的信用卡出現交易失敗<br />
                        此信用卡無法進行交易 */}
                      </p>
                    </div>
                  </div>
                </>:
                <>
                  <div className="box-border-blue small icon-alert">
                    <p className="fz-16 c-blue">
                      {t("Form4.旅行代金をご確認のうえ、決済情報を入力してください。")}
                      {/* 請先行確認訂單金額是否有誤，再行輸入支付資訊。 */}
                    </p>
                  </div>
                </>
              }

              {/* 旅行代金 */}
              <SectionTravelPrice
                priceData={priceData}
              />

              {/*お支払い方法 */}
              <SectionPayment
                register={register}
                getValues={getValues}
                control={control} 
                errors={errors}          
              />

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-md">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    {/* <a href="/Form2/" className="button-large-red">
                      <span className="label-number-orange mr-5">5</span>
                      予約の最終確認をする
                    </a> */}
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">{buttonIconLabel}</span>
                      {t("Form4.予約の最終確認へ進む")}
                      {/* 前往下一頁・預約最終確認 */}
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      {t("Form4.オプション情報入力に戻る")}
                      {/* 返回上一頁・輸入追加項目 */}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form4;
