import {apiClient} from "./apiClient";
import {
  carRentalAnshinOptionItemsType,
  carRentalAnshinOptionType,
  carRentalCHildeSeatOptionItems,
  carRentalChildSeatOptionNamesType,
  carRentalChildSeatOptionType,
  carRentalChildSheetData,
  carRentalCountOption,
  carRentalDetail,
  carRentalOptionList,
  carRentalPlanData,
  carRentalPlansResponse,
  // carRentalPlanParamsType,
  carRentalSaveData,
  carRentalSearchResponse,
  carRentalSearchResponsePlanList
} from "../types/carRental";
import {dateFormatWithLocale} from "./convert";
import {
  carRentalAnshinOptions,
  carRentalChildSeatOptionNames,
  carRentalChildSeatOptions,
  carRentalOfficeCodes
} from "../config/carRental";
import {PlanSearch} from "../types/Search";
import {reserveRentalCar} from "../types/reserve";
import { TFunction } from "i18next";


export const carRentalSearch = async (input: { data: any }): Promise<carRentalSearchResponse | undefined> => {
  try {
    const response = await apiClient.post("/carRental/v2/carRental-search", input.data);
    return response.data as carRentalSearchResponse;
  } catch (error) {
    return undefined;
  }

}

export const carRentalPlans = async (input: { data: any }): Promise<carRentalPlansResponse | undefined> => {
  try {
    const response = await apiClient.post("/carRental/v2/plans", input.data);
    return response.data as carRentalPlansResponse;
  } catch (error) {
    return undefined;
  }
}


/**
 * レンタカープラン取得（レンタカーAPI使用）
 * @param input
 */
export const carRentalPlanApi = async (input: { data: any }, i18nextTranslate: TFunction) => {
  const carRentalRowsData: carRentalPlanData[] = [];
  const childSeatRowsData: carRentalChildSheetData[] = [];
  const childSheetPrices = 1100;
  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_01.png").default,
    heading: i18nextTranslate("CarRental.乳幼児ベビーシート"),
    // heading: "嬰兒座椅",
    target_age: i18nextTranslate("CarRental.新生児から生後1歳位まで"),
    weight: i18nextTranslate("CarRental.10kg位まで"),
    height: i18nextTranslate("CarRental.75cm位まで"),
    // target_age: "新生兒-1歲",
    // weight: "至10kg為止",
    // height: "至75cm為止",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'baby_sheet',
    price: childSheetPrices,
  });

  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_02.png").default,
    heading: i18nextTranslate("CarRental.幼児用チャイルドシート"),
    // heading: "幼兒座椅",
    target_age: i18nextTranslate("CarRental.生後6ヶ月から4歳位まで"),
    weight: i18nextTranslate("CarRental.18kg位まで"),
    height: i18nextTranslate("CarRental.100cm位まで"),
    // target_age: "6個月-4歲",
    // weight: "至18kg為止",
    // height: "至100cm為止",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'child_sheet',
    price: childSheetPrices,
  });

  childSeatRowsData.push({
    img: require("../img/share/rentacar/rentacar_img_Seat_03.png").default,
    heading: i18nextTranslate("CarRental.学童用ジュニアシート"),
    // heading: "兒童用座椅",
    target_age: i18nextTranslate("CarRental.4歳から6歳位まで"),
    weight: i18nextTranslate("CarRental.32kg位まで"),
    height: i18nextTranslate("CarRental.135cm位まで"),
    // target_age: "4歲-6歲",
    // weight: "至32kg為止", 
    // height: "至135cm為止",
    additional_fee: "+" + childSheetPrices.toLocaleString(),
    code: 'junior_sheet',
    price: childSheetPrices,
  });

  try {
    const response = await apiClient.post("/carRental/plans", input.data);
    const carRentalData = await response.data;

    carRentalData.result.forEach((carDental: { [key: string]: any }) => {
      if (carDental.success) {
        const tagRows: string[] = []
        carDental.response.plan_info.default_option_list.forEach((defaultOptionList: any) => {
          tagRows.push(defaultOptionList.option_name);
        });
        const fee = Number(carDental.response.plan_info.basic_price) + Number(carDental.response.plan_info.indemnity_price);
        carRentalRowsData.push({
          data: carDental.response,
          plan_code: carDental.response.plan_info.plan_code,
          img: carDental.response.plan_info.class_image_url,
          status: true,
          grade: carDental.response.plan_info.class_name,
          car_model: carDental.response.plan_info.class_model,
          engine_size: carDental.response.plan_info.class_cc,
          tag_rows: tagRows,
          max_member: carDental.response.plan_info.max_member,
          price: carDental.response.plan_info.total_price,
          basic_price: carDental.response.plan_info.basic_price,
          indemnity_price: carDental.response.plan_info.indemnity_price,
          additional_fee: "+" + fee.toLocaleString()
        });
      }
    });

    return {error: false, carRentalRowsData: carRentalRowsData, childSeatRowsData: childSeatRowsData};
  } catch (error) {
    return {error: true, carRentalRowsData: carRentalRowsData, childSeatRowsData: childSeatRowsData};
  }

}

/**
 * レンタカー在庫確認API
 * @param input
 */
export const carRentalStockAPI = async (input: { data: reserveRentalCar }) => {
  try {
    const response = await apiClient.post("/carRental/stock", input.data);
    const carRentalData = response.data;
    if (response.status === 200 && carRentalData.success) {
      return {status: 200, result: true};
    }
    return {status: 200, result: false};
  } catch (e) {
    return {status: 500, result: false};
  }
}

// /**
//  * チャイルドシートの料金取得
//  * @param carRentalPlanOptionList
//  */
// const getChildSheetPrices = (carRentalPlanOptionList: { [key: string]: any }[]) => {
//   const result = {
//     babySheet: 0,
//     childSheet: 0,
//     juniorSheet: 0
//   };
//   carRentalPlanOptionList.forEach((option) => {
//     if (option["option_name"] === "ベビーシート") {
//       result.babySheet = option.option_vaule_list[1]["price"] ?? 0;
//     } else if (option["option_name"] === "チャイルドシート") {
//       result.childSheet = option.option_vaule_list[1]["price"] ?? 0;
//     } else if (option["option_name"] === "ジュニアシート") {
//       result.juniorSheet = option.option_vaule_list[1]["price"] ?? 0;
//     }
//   });

//   return result;
// };

/**
 * 選択したレンタカー情報の保存
 * @param carRentalSaveData
 * @param carRentalPlanData
 * @param childSeatRows
 * @param type
 * @param plan_code
 * @param count
 */
export const updateCarRentalSaveData = (carRentalSaveData: carRentalSaveData, carRentalPlanData: carRentalSearchResponsePlanList[], type: string, plan_code: string, count: number) => {
  if (type === "car") {
    const saveDataIndex = carRentalSaveDataIndex(carRentalSaveData, plan_code);
    const saveData = carRentalSaveData.detail.filter((carRentalPlan) => { return carRentalPlan.plan_code === plan_code });
    const plan = carRentalPlanData.find((carRentalPlan) => {
      return carRentalPlan.plan_code === plan_code
    })

    if (carRentalSaveData.detail.length > 0) {
      if (saveData.length > count) {
        carRentalSaveData.detail.splice(saveDataIndex, 1);
      } else if (saveData.length < count && plan) {
        carRentalSaveData.detail.push({
          plan_code: plan.plan_code,
          img: "",
          grade: "",
          car_model: "",
          engine_size: "",
          tag_rows: [],
          additional_fee: "",
          price: 0,
          basic_price: 0,
          indemnity_price: 0,
          max_member: 0,
          status: true,
          num: 1,
          detail: plan,
          total: Number(plan.price),
          count_option: {
            "baby_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "child_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "junior_seat": {
              num: 0,
              option_code: "",
              price: 0
            },
            "anshin": {
              used: false,
              option_name: "",
              price: 0
            },
            "anshin_premium": {
              used: false,
              option_name: "",
              price: 0
            }
          }
        });
      }
    } else if (count > 0 && plan) {
      carRentalSaveData.detail.push({
        plan_code: plan.plan_code,
        img: "",
        grade: "",
        car_model: "",
        engine_size: "",
        tag_rows: [],
        additional_fee: "",
        price: 0,
        basic_price: 0,
        indemnity_price: 0,
        max_member: 0,
        status: true,
        num: 1,
        detail: plan,
        total: Number(plan.price),
        count_option: {
          "baby_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "child_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "junior_seat": {
            num: 0,
            option_code: "",
            price: 0
          },
          "anshin": {
            used: false,
            option_name: "",
            price: 0
          },
          "anshin_premium": {
            used: false,
            option_name: "",
            price: 0
          }
        }
      });
    }
  }

  carRentalSaveData.total = calculationCarRentalTotalPrice(carRentalSaveData);
  return {...carRentalSaveData} as carRentalSaveData;
}

/**
 * 保存するレンタカー情報を追加
 * @param carRentalPlanData
 * @param plan_code
 * @param count
 */
const addCarRentalDetailData = (carRentalPlanData: carRentalPlanData[], plan_code: string, count: number) => {
  const planData = getSelectCarRentalPlan(carRentalPlanData, plan_code);
  if (planData.length > 0) {
    return {
      plan_code: planData[0]["plan_code"],
      img: planData[0]["img"],
      status: planData[0]["status"],
      grade: planData[0]["grade"],
      car_model: planData[0]["car_model"],
      engine_size: planData[0]["engine_size"],
      tag_rows: planData[0]["tag_rows"],
      additional_fee: planData[0]["additional_fee"],
      max_member: planData[0]["max_member"],
      price: planData[0]["price"],
      basic_price: planData[0]["basic_price"],
      indemnity_price: planData[0]["indemnity_price"],
      num: count,
      option: planData[0]["data"]["plan_info"]["option_list"]
    };
  }
  return false;
}

/**
 * 取得したレンタカー情報から指定したプランコードのデータを取得
 * @param carRentalPlanData
 * @param plan_code
 */
const getSelectCarRentalPlan = (carRentalPlanData: carRentalPlanData[], plan_code: string) => {
  return carRentalPlanData.filter((carRentalPlan) => {
    return carRentalPlan.plan_code === plan_code
  });
}

/**
 指定したプランコードのプランがすでに設定されているか確認
 * @param carRentalSaveData
 * @param plan_code
 */
const carRentalSaveDataIndex = (carRentalSaveData: carRentalSaveData, plan_code: string) => {
  return carRentalSaveData.detail.findIndex((carRentalPlan) => {
    return carRentalPlan.plan_code === plan_code
  });
}


/**
 * 設定したレンタカープランの合計金額を計算
 * @param carRentalSaveData
 */
const calculationCarRentalTotalPrice = (carRentalSaveData: carRentalSaveData) => {
  let total = 0;
  carRentalSaveData.detail.forEach((carRental) => {
    total += carRental.total;
  });

  return total;
}

export const getDateLabel = (date: string, time: string, languageCode: string) => {
  const jpDate = dateFormatWithLocale(date, "YYYY年MM月DD日（ddd）", languageCode);
  return jpDate + " " + time;
}

export const getCarRentalOfficeLabel = (office_code: string) => {
  const officeCodes = carRentalOfficeCodes.filter((office) => {
    return office.code === office_code
  });

  if (officeCodes.length > 0) {
    return officeCodes[0].label
  }
  return "";
}

export const getCarRentalCountNum = (saveData: carRentalSaveData, plan_code: string) => {

  if (saveData !== undefined && saveData.detail !== undefined && saveData.detail.length > 0) {
    const carRentalDetail = saveData.detail.filter((detail) => {
      return detail.plan_code === plan_code
    });
    if (carRentalDetail.length > 0) {
      return carRentalDetail[0].num;
    }
  }

  return 0;
}

export const getCarRentalSheetCountNum = (saveData: carRentalSaveData, code: string) => {
  // if (saveData !== undefined) {
  //   switch (code) {
  //     case "baby_sheet":
  //       return saveData.count_option.baby_seat;
  //     case "child_sheet":
  //       return saveData.count_option.child_seat;
  //     case "junior_sheet":
  //       return saveData.count_option.junior_seat;
  //     default:
  //       break;
  //   }
  // }

  return 0;
}

export const getCarRentalReservePeople = (searchParams: PlanSearch) => {
  const people = {
    "joshaadt": 0,
    "joshainf": 0,
  }
  
  searchParams.roomDetail.forEach((roomDetail) => {
    people.joshaadt += roomDetail.adult;
    if (roomDetail.child.length > 0) {
      roomDetail.child.forEach((child) => {
        if (child.age > 1) {
          people.joshaadt++;
        } else {
          people.joshainf++;
        }
      })
    }
  })
  
  return people;
}

export const getCarRentalOptionCode = (type: string, optionDetail:carRentalDetail, i18nextTranslate: TFunction) => {
  
  if (type === 'baby_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === i18nextTranslate("CarRental.ベビーシート");
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
  } else if (type === 'child_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === i18nextTranslate("CarRental.チャイルドシート");
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
    
  } else if (type === 'junior_seat'){
    const optionIndex = optionDetail.option.findIndex((option) => {
      return option.option_name === i18nextTranslate("CarRental.ジュニアシート");
    })
    if (optionIndex >= 0) {
      return optionDetail.option[optionIndex];
    }
  }
  
  return undefined;
}

export const getCarRentalChildOptionItem = (name: string, key: keyof carRentalCHildeSeatOptionItems, lang: string) => {
  if (carRentalChildSeatOptions[lang]) {
    const findOption = carRentalChildSeatOptions[lang].find((option) => {
      return option.title === name;
    });

    return findOption ? findOption[key] : "";
  }
  
  return "";
}

export const getCarRentalAnshinOptionItem = (name: string, key: keyof carRentalAnshinOptionItemsType, lang: string) => {
  if (carRentalAnshinOptions[lang]) {
    const findOption = carRentalAnshinOptions[lang].find((option) => {
      return option.name === name;
    });

    return findOption ? findOption[key] : "";
  }
  return "";
}

/**
 * レンタカーのオプションの有無
 * @param countOption
 */
export const isCarRentalOptions = (countOption: carRentalCountOption) => {
  return countOption.baby_seat.num > 0 || countOption.child_seat.num > 0 || countOption.junior_seat.num > 0 || countOption.anshin.used || countOption.anshin_premium.used;
}

/**
 * 指定したレンタカーのオプションがチャイルドシートのオプションか確認
 * @param optionName
 * @param lang
 */
export const checkCarRentalChildSeatOption = (optionName: string , lang: keyof carRentalChildSeatOptionNamesType) => {
  if (carRentalChildSeatOptionNames.findIndex((name) => name[lang] === optionName) >= 0) {
    return true;
  }

  return false;
}