import React, {useEffect} from "react";

// // for google map
// import GoogleMapTemplate from "../google/Maps";

// for styles
import {
  // Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
// import {
//   ComprehensiveOptionDetailsModal
// } from "./"
import { IActivity } from "../../types/Activity";
import {dateFormatWithLocale, toDisplayTime} from "../../utils/convert";
import {devLog} from "../../utils/errors";
import { useTranslation } from "react-i18next";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  activity: IActivity | undefined
}

const ScheduleDetailModal: React.FC<Props> = ({
  isShow,
  callback,
  activity
}) => {

  const { t, i18n } = useTranslation();

  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  // const [step, setStep] = React.useState(1);
  // const stepHandle = (step: number) => {
  //   setStep(step)
  // }

  // モーダルのステータスを追加する
  const [status, setStatus] = React.useState(false);
  
  // モーダルのhandleを追加する
  const selectedCallback = (data:IActivity | undefined) => {
    // setStep(2)
    setStatus(!status)
  }
  
  const [selectActivity, setSelectActivity] = React.useState<IActivity>()
  useEffect(() => {
    if (activity !== undefined) {
      devLog("activity", activity);
      setSelectActivity({...activity});
    }
  },[activity])

  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content pt-0 pb-0">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>
          <div className="box-separate">

            <div className="box-separate-item">
              <div className="box-separate-item-block">
              {/* <p className="fz-16 fw-b c-navy mb-4">ご利用日</p> */}
              {/* <p className="fz-16 fw-b c-navy mb-4">利用日期</p> */}
              <p className="fz-16 fw-b c-navy mb-4">{t("ScheduleDetailModal.ご利用日")}</p>
              {/* <p className="fz-14">{dateFormat(selectActivity?.date, "YYYY年MM月DD日(ddd)")} {toDisplayTime(selectActivity?.time ?? "")}</p> */}
              <p className="fz-14">{dateFormatWithLocale(selectActivity?.date, "YYYY年MM月DD日(ddd)", i18n.language)} {toDisplayTime(selectActivity?.time ?? "")}</p>
              </div>
            </div>

            <div className="box-separate-item">
              <div className="box-separate-item-block small">
                {/* <p className="mb-16 fz-12 c-red">※基本プランの料金は、<br />宿泊費用に含まれています。</p> */}
                {/* <p className="mb-16 fz-12 c-red">※基本方案費用裡<br />已含有住宿費用。</p> */}
                <p className="mb-16 fz-12 c-red">※{t("ScheduleDetailModal.基本プランの料金は、")}<br />{t("ScheduleDetailModal.宿泊費用に含まれています。")}</p>
                <div className="d-f jc-sb ai-c">
                  <p>
                    <button onClick={callback} className="button-medium-light_blue w-60">
                    OK
                    </button>
                  </p>

                  <p>
                    {/* 仮遷移先：遷移先のモーダル作成後に変更 */}
                    <button onClick={ () => selectedCallback(selectActivity) } className="button-border-medium button-clear-blue-underline p-0 w-100per">
                      {/* 変更する<i className="icon-right-arrow-simple ml-5"></i> */}
                      {/* 變更此內容<i className="icon-right-arrow-simple ml-5"></i> */}
                      {t("ScheduleDetailModal.変更する")}<i className="icon-right-arrow-simple ml-5"></i>
                    </button>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
        {/* ここに作成したモーダルを追加する */}
        {/* STEP1 */}
        {/* <ComprehensiveOptionDetailsModal activity={activity} stepHandler={stepHandle} defaultStep={step} isShow={status} callback={selectedCallback} /> */}
    </div>
    </>
  );
};

export default ScheduleDetailModal;
