import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useReserveInfo } from "../utils/useReserveInfo";
import { SubmitHandler, useForm } from "react-hook-form";
import { save, clear as clearReservationCancel } from "../redux/reservationCancelSlice";
import ReCAPTCHA from "react-google-recaptcha"
import { useApi } from "../utils/useApi";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import { LANGUAGES, RECAPTCHA_LANGUAGES } from "../constants/languages";

import Button from "@material-ui/core/Button";
import {
  Card,
  CardContent,
  FormControl,
  CardActions,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import { InputText } from "./input/InputText";

import { makeStyles } from '@material-ui/core/styles';

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";



// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReservationCancelLogin: React.FC = () => {
  const classesForLoading = useStyles();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<{ bookingId: string, bookingEmail: string }>({
  });

  // 予約情報
  const [ fetchReserveInfo, reserveInfo, { isLoading, isError, error } ] = useReserveInfo();

  const [ isLoadingForRecaptcha, setIsLoadingForRecaptcha ] = React.useState(false);

  const captchaRef = useRef<ReCAPTCHA>(null);

  // todo: API呼び出しエラー時の処理
  if (isError) {
    console.log("error", error);
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    const bookingId = data.bookingId;

    const bookingEmail = data.bookingEmail;

    // note: もし何かしらのチェックを入れるならここでどうぞ

    // reCAPTCHA
    try {
      setIsLoadingForRecaptcha(true);

      const token = captchaRef.current?.getValue();

      if (!token) {
        setIsLoadingForRecaptcha(false);
        alert(t("ReservationCancelLogin.「私はロボットではありません」にチェックを入れてください。"));
        // alert("請勾選「我不是機器人」。");
        return false;
      }

      captchaRef.current?.reset();

      const { data: recaptchaResponse } = await useApi.post({
        url: "/checkRecaptcha",
        data: {
          token
        }
      });

      if (!recaptchaResponse.success) {
        throw new Error("しばらくしてから再度お試しください。");
      }

      setIsLoadingForRecaptcha(false);

    } catch (error) {
      setIsLoadingForRecaptcha(false);

      console.error(error);
      alert(t("ReservationCancelLogin.しばらくしてから再度お試しください。"));
      // alert("請過段時間再行測試。");
      return false;
    }


    // 予約情報取得
    await fetchReserveInfo({
      bookingId,
      bookingEmail,
    });

    return false;
  };

  useEffect(() => {
    if (isLoading) return;

    if (!reserveInfo ||  (reserveInfo && reserveInfo.data.message == null)) {
      return;
    }

    if (reserveInfo.status !== 200) {
      alert(t("ReservationCancelLogin.ログインに失敗しました。"));
      // alert("登入失敗。");
      return;
    }

    // データ取得できたら保存して次の画面へ
    dispatch(save({ reservation: reserveInfo }));

    navigate(ROUTES.ReservationCancelDetail.replace(":lang", i18n.language));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveInfo, isLoading, dispatch, navigate]);


  // 初回マウント時にredux内のデータを初期化する
  useEffect(() => {
    dispatch(clearReservationCancel());
    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    
    <MainTemplate 
      title={t("ReservationCancelLogin.予約詳細確認ページへのログイン")}
      logo={undefined} 
      supportDial={undefined} 
      sellerId={undefined}
      planSelectFormData={undefined}
    >

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading || isLoadingForRecaptcha}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("ReservationCancelLogin.予約詳細確認ページへのログイン")}</h1>
            {/* <h1 className="l-page-content-upper_navi-heading">登入至可查看您預約內容的頁面</h1> */}
          </div>
        </div>

        <div className="l-page-content">
          <div className="pt-24 pt-80-md">
            <div className="wrap-content-530">

              <h2 className="heading-2 mt-0 mb-16 mb-24-md ta-c-md">{t("ReservationCancelLogin.予約の確認・キャンセル")}</h2>
              {/* <h2 className="heading-2 mt-0 mb-16 mb-24-md ta-c-md">預約的確認・取消</h2> */}
              <form action="" onSubmit={handleSubmit(onSubmit)}>

              <Card className="login-form-card">
                <CardContent>
                  <div className="login-form-card-inner">
                    <p className="mb-8">{t("ReservationCancelLogin.このたびは、お申し込みいただき、ありがとうございます。")}</p>
                    {/* <p className="mb-8">此次非常高興能接受到您的預約。</p> */}
                    <p className="mb-24 fz-16 fw-b">{t("ReservationCancelLogin.以下より旅行予約番号とメールアドレスをご入力いただくと、ご予約の確認・キャンセルが行えます。")}</p>
                    {/* <p className="mb-24 fz-16 fw-b">從下方輸入您的行程的預約號碼及電子郵件，即可對您的預約進行確認或取消。</p> */}

                    <div className="login-form-card-inner-item">
                      {/*ユーザー名*/}
                      <FormControl variant="standard" fullWidth>
                        <div className="LoginPage-form-field">
                          <label className="login-form-card-inner-item-label">
                            {t("ReservationCancelLogin.旅行予約番号")}
                            {/* 行程預約號碼 */}
                          </label>
                          <InputText
                            name="bookingId"
                            placeholder=""
                            register={register}
                            options={{
                              required: true,
                              maxLength: 50,
                              pattern: /^[0-9a-zA-Z-]+$/, // 半角英数 + ハイフン
                            }}
                            error={errors.bookingId}
                          />
                        </div>
                      </FormControl>
                      {errors.bookingId &&
                        errors.bookingId.type === "required" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.旅行予約番号をご入力ください。")}
                            {/* 請填入行程預約號碼。 */}
                          </p>
                        )}
                      {errors.bookingId &&
                        errors.bookingId.type === "maxLength" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.旅行予約番号は50文字以内でご入力ください。")}
                            {/* 行程預約號碼請填入50以內的文字。 */}
                          </p>
                        )}
                      {errors.bookingId &&
                        errors.bookingId.type === "pattern" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.旅行予約番号を正しくご入力ください。")}
                            {/* 請填入正確的行程預約號碼。 */}
                          </p>
                        )}
                    </div>

                    <div className="login-form-card-inner-item">
                      {/*メールアドレス*/}
                      <FormControl variant="standard" fullWidth>
                        <div className="LoginPage-form-field">
                          <label className="login-form-card-inner-item-label">
                            {t("ReservationCancelLogin.メールアドレス")}
                            {/* 請輸入您的E-mail */}
                          </label>
                          <InputText
                            name="bookingEmail"
                            placeholder="例：mail@example.com"
                            register={register}
                            options={{
                              required: true,
                              maxLength: 200,
                              pattern: /^\S+@\S+$/i,
                            }}
                            error={errors.bookingEmail}
                          />
                        </div>
                      </FormControl>
                      {errors.bookingEmail &&
                        errors.bookingEmail.type === "required" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.メールアドレスをご入力ください。")}
                            {/* 請填入E-mail。 */}
                          </p>
                        )}
                      {errors.bookingEmail &&
                        errors.bookingEmail.type === "maxLength" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.メールアドレスは200文字以内でご入力ください。")}
                            {/* E-mail請填入200以內的文字。 */}
                          </p>
                        )}
                      {errors.bookingEmail &&
                        errors.bookingEmail.type === "pattern" && (
                          <p className="form-error_text c-red">
                            {t("ReservationCancelLogin.メールアドレスを正しくご入力ください。")}
                            {/* 請填入正確的E-mail。 */}
                          </p>
                        )}
                    </div>

                    <div className="login-form-card-inner-item">
                      <ReCAPTCHA
                        style={{ display: "flex", justifyContent: "center" }}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
                        ref={captchaRef}
                        size={"normal"}
                        hl={i18n.language === LANGUAGES.JA ? RECAPTCHA_LANGUAGES.JA : RECAPTCHA_LANGUAGES.TW}
                      />
                    </div>
                  </div>
                </CardContent>

                {/*ログインボタン*/}
                <CardActions className="login-form-card-button">
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className="login-form-card-button-link w-100per"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("ReservationCancelLogin.予約の確認・キャンセルボタン")}
                    {/* 確認/取消 您的預約 */}
                  </Button>
                </CardActions>
              </Card>
              </form>
            </div>
          </div>
        </div>


    </MainTemplate>
  );
};

export default ReservationCancelLogin;
