import React from "react";
import { useTranslation } from "react-i18next";


//scss
import "../../scss/common.scss";
import "../../scss/component.scss";

const Error500: React.FC = () => {

  const { t } = useTranslation();
 
  return (
      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                alt=""
              />
            </p>
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">通信エラーが発生しました</p> */}
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">發生通訊錯誤</p> */}
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("Error500.通信エラーが発生しました")}</p>
            <p className="fz-16 ta-c-md c-blue_gray">
              {/* 通信エラーのため情報を取得できませんでした。<br className="d-n d-b-md" />
              しばらくしてから再度お試しください。 */}
              {/* 由於通信錯誤，無法獲取信息。<br className="d-n d-b-md" />
              請過段時間再行測試。 */}
              {t("Error500.通信エラーのため情報を取得できませんでした。")}<br className="d-n d-b-md" />
              {t("Error500.しばらくしてから再度お試しください。")}
            </p>
          </div>
        </div>
      </section>
  );
};

export default Error500;
