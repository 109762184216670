import React, {useCallback, useEffect} from "react";
import reducer, { decrementType, incrementType } from "./reducer";
import {useTranslation} from "react-i18next";
type Props = {
  defaultCount: number,
  type: string,
  plan_code: string,
  updateCounter(count:number, plan_code:string, type:string): void
}

const CounterNumberOfUnitsCarRental: React.FC<Props> = ({type, defaultCount, plan_code, updateCounter}) => {
  const { t, i18n } = useTranslation();
  const initialState = { count: defaultCount };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const decrement = useCallback(() => dispatch({ type: decrementType }), []);
  const increment = useCallback(() => dispatch({ type: incrementType }), []);
  const disabled = state.count <= 0 ;
  let addButtonDisabled = false
  if (type === "car") {
    addButtonDisabled = state.count > 4;
  }
  const clickDecrement = () => {
    decrement();
  }
  const clickIncrement = () => {
    increment();
  }
  useEffect(() => {
    updateCounter(state.count, plan_code, type)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state])
  return (
    <div className="counter">
      <div className="counter-minus">
        <button
          className="counter-minus-icon"
          onClick={clickDecrement}
          disabled={disabled}
        ></button>
      </div>
      <div className="counter-value">{state.count}<span className="d-ib ml-4 fz-12 fw-n c-blue_gray">{t('CarRental.台')}</span></div>
      <div className="counter-plus">
        <button
          className="counter-plus-icon"
          onClick={clickIncrement}
          disabled={addButtonDisabled}
        ></button>
      </div>
    </div>
  );
};

export default CounterNumberOfUnitsCarRental;
