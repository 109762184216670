import React, {ChangeEvent, Fragment, useEffect} from "react";

//swiper
import { Navigation, Pagination, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import LoadingOverlay from 'react-loading-overlay-ts';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// for styles
import {
  // Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
// import CounterNumberOfPeaple from "./../counter/counterNumberOfPeaple";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {IActivity, planStocks} from "../../types/Activity";
import {
  activityMinPrice, activityPeopleMaxData, activityPeopleMinData,
  checkPlanStockListType,
  // getActivityPriceCount,
  getActivityTotalPrice,
  getPlanStocks, getSelectActivityTimePrices, hasActivityStock
} from "../../utils/plan";
import DOMPurify from "dompurify";
// import CounterNumberOfOptionPlan from "../counter/counterNumberOfOptionPlan";
import {dateFormat, dateFormatWithLocale} from "../../utils/convert";
import Map from "../google/Maps";
import {devLog} from "../../utils/errors";
import { useTranslation } from "react-i18next";
import { PartsIconDescription, PartsModalHeader, PartsModalHeaderWithBackButton, PartsOptionModalSteps } from "../parts";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  callback: () => void,
  defaultStep: number,
  stepHandler: ( step: number ) => void,
  planSelectFormData: PlanSelectFormData,
  selectActivity: IActivity[],
  activity: IActivity| undefined,
  updateSelectFormData(updatePlanData: PlanSelectFormData):void,
}

const OptionSelectModal: React.FC<Props> = ({
  isShow,
  callback,
  defaultStep,
  stepHandler,
  planSelectFormData,
  selectActivity,
  activity,
  updateSelectFormData,
}) => {
  
  const { t, i18n } = useTranslation();

  const [useActivity, setUseActivity] = React.useState(activity);
  // const [saveActivity, setSaveActivity] = React.useState(activity);
  useEffect(() => {
    if (activity !== undefined) {
      setUseActivity({...activity})
    }
  }, [activity])

  // const [options, setOptions] = React.useState(planSelectFormData.planData?.options);

  //モーダルステップカウンター
  const onPageHandle = ( step: number ) => {
    stepHandler(step)
  }

  const onClickStep3 = (  ) => {
    if (!useActivity?.date) {
      alert(t("OptionSelectModal.日程選択してください。"));
      // alert("請選擇日期");
    } else if(!useActivity?.time_code || !useActivity.time ) {
      alert(t("OptionSelectModal.時間を設定してください。"));
      // alert("請設定時間");
    } else {
      useActivity.price_list = getSelectActivityTimePrices(useActivity, useActivity.time_code, useActivity.date);
      stepHandler(4)
    }
  }
  
  const onClickStep1 = ( activityData: IActivity ) => {
    setUseActivity({...activityData})
    stepHandler(2)
  }

  const createMarkup = (data: string | undefined) => {
    if(data) {
      const content = data.replace(/\n/g, '<br />');
      return  {
        __html: DOMPurify.sanitize(content)
      }
    }
  }

  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (defaultStep === 3 && useActivity !== undefined) {
      setLoading(true);
      ( async () => {
        const setActivity = useActivity;
        const planCode = useActivity.plan_info.plan_code;
        const planStocks = await getPlanStocks(planSelectFormData.searchParams, planCode, i18n.language);
        setActivity.stocks = planStocks.detail;
        setActivity.stock = planStocks.stock;
        setActivity.timeType = planStocks.timeType;
        setActivity.price_list = useActivity.plan_info.price_type_list;
        setUseActivity({...setActivity});
        setLoading(false);
      })();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[defaultStep])

  const checkedDate = (stockData: planStocks) => {
    return useActivity?.date === stockData.use_date;
  }

  const setDefaultTimeSelect = (stockData: planStocks) => {
    if (useActivity?.date !== undefined && useActivity?.date === stockData.use_date) {
      return useActivity.time_code;
    }
    return "";
  }


  const selectedTime = (stockData: planStocks, time_code: string) => {
    if (useActivity?.date === stockData.use_date && useActivity?.time_code === time_code) {
      return true;
    } else if (time_code === "") {
      return true;
    }
    return false;
  }


  // 日程決定handle
  const setUseDate = (event: ChangeEvent<HTMLInputElement>, stockData: planStocks) => {
    let setActivity = useActivity;
    if (stockData.timeType === "no_settime") {
      const timeList = stockData.time_stock_list.filter((time) => {
        return time.time === stockData.timeType;
      })
      if (timeList.length > 0) {
        if (setActivity !== undefined) {
          setActivity.time_code = timeList[0].time_code;
          setActivity.time = timeList[0].time;
          setActivity.time_stock = timeList[0].stock;
          setActivity.date = stockData.use_date;
        }
      }
    } else if (setActivity !== undefined) {
      setActivity.time_code = "";
      setActivity.time = "";
      setActivity.time_stock = 0;
    }

    if (setActivity !== undefined) {
      setActivity.date = event.target.value;
      setUseActivity({...setActivity});
    }
  }

  // 日程決定handle
  const setUseTime = (event: React.ChangeEvent<HTMLSelectElement>, stockData: planStocks) => {
      let setActivity = useActivity;
      const timeList = stockData.time_stock_list.filter((time) => {
        return time.time_code === event.target.value;
      })
      if (timeList.length > 0) {
        if (setActivity !== undefined) {
          setActivity.time_code = timeList[0].time_code;
          setActivity.time = timeList[0].time;
          setActivity.time_stock = timeList[0].stock;
          setActivity.date = stockData.use_date;
        }
      }

      if (setActivity !== undefined) {
        setUseActivity({...setActivity});
      }
  }
  
  const countDisabled = (priceNum: number | undefined) => {
    if (priceNum !== undefined) {
      return priceNum < 1
    }
    return true
  }

  const updateActivityPrice = (count: string, priceCode: string) => {
    if (useActivity !== undefined) {
      const setActivity = useActivity;
      const index = setActivity.price_list?.findIndex((price) => {
        return price.type_code === priceCode;
      })
      if (setActivity.price_list && index !== undefined &&  index >= 0) {
          let num = setActivity.price_list[index].num ?? 0;
          if (count === "add") {
            num = num + 1;
          } else {
            num = num - 1;
          }
          if (num <= useActivity.time_stock) {
            const priceList = setActivity.price_list[index];
            setActivity.price_list[index] = {
              type_code: priceList.type_code,
              name: priceList.name,
              unit: priceList.unit,
              max_people: priceList.max_people,
              min_people: priceList.min_people,
              undiscounted_price: priceList.undiscounted_price,
              price: priceList.price,
              num:num
            }
            devLog("setActivity", setActivity);
            setUseActivity({...setActivity})
          }
      }
    }
  }
  
  const updateActivity = () => {
    if (useActivity !== undefined) {
      const priceCheck = useActivity?.price_list?.filter((price) => {
        const priceNum = price.num ?? 0;
        return priceNum > 0
      });
      const peopleMinData = activityPeopleMinData(useActivity);
      const peopleMaxData = activityPeopleMaxData(useActivity);

      if (priceCheck === undefined || priceCheck.length <= 0) {
        alert(t("OptionSelectModal.参加人数を設定してください。"));
        // alert("請設定參加人數");
      } else if (!useActivity?.date) {
        alert(t("OptionSelectModal.日程選択してください。"));
        // alert("請選擇日期");
      } else if(!useActivity?.time_code || !useActivity.time ) {
        alert(t("OptionSelectModal.時間を設定してください。"));
        // alert("請設定時間");
      } else if (peopleMinData && peopleMinData.length > 0) {
        let text = "";
        peopleMinData.forEach((min) => {
          // text += min.name + "の参加人数は"+min.min_people+"以上を設定してください。\n";
          // text += min.name + "的參加人數請設定成"+min.min_people+"以上。\n";
          text += t("OptionSelectModal.nameの参加人数はn以上を設定してください。", { name: min.name, people: min.min_people }) + "\n";
        });
        alert(text);
      } else if (peopleMaxData && peopleMaxData.length > 0) {
        let text = "";
        peopleMaxData.forEach((max) => {
          // text += max.name + "の参加人数は"+max.max_people+"以下を設定してください。\n";
          // text += max.name + "的參加人數請設定成"+max.max_people+"以下。\n";
          text += t("OptionSelectModal.nameの参加人数はn以下を設定してください。", { name: max.name, people: max.max_people }) + "\n";
        });
        alert(text);
      } else {
        const activityIndex = planSelectFormData.options.findIndex((option) => {
          return option.plan_info.plan_code === useActivity?.plan_info.plan_code;
        });
        if (activityIndex >= 0 && planSelectFormData.options[activityIndex] !== undefined) {
          useActivity.active = true;
          planSelectFormData.options[activityIndex] = useActivity;
          updateSelectFormData(planSelectFormData);
        }
        setUseActivity(undefined)
      }
    }
  }

  const timeStockText = (stock: number) => {
    if (stock >= 10) {
      return "（" + t("Common.空き") + " 10以上）";
    }
    return "（" + t("Common.空き") + stock + "）";
  }

  return (
    <>
    <Modal
      open={isShow}
      onClose={callback}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div>
        <LoadingOverlay
          active={loading}
          spinner
        >
        {/* 1 */}
        <div className={"modal-fixed bgc-gray" + (defaultStep === 1? " d-b":" d-n")}>
          {/* <div className="modal-fixed-heading"> */}
            {/* <p className="modal-fixed-heading-text">選擇活動方案</p> */}
            {/* <p className="modal-fixed-heading-text">{t("OptionSelectModal.オプションの選択")}</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>{t("Common.閉じる")}
              </Button>
            </p>
          </div> */}
          <PartsModalHeader
            headingText={t("OptionSelectModal.オプションの選択")}
            close={callback}
          />

          <div className="modal-fixed-content">
            {/* STEP */}
            <PartsOptionModalSteps activeStep={1} />

            <div className="wrap-content-900-large wrap-padding-md">
              {/* <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">若欲追加其它體驗活動，請從下方選擇</p> */}
              <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">{t("OptionSelectModal.オプションを追加される方は下記よりお選びください")}</p>
              <div className="d-f ai-c">
                <div className="w-95 w-130-md fx-sh">
                  <img src={require("../../img/share/logo_ots_activities.png").default} alt="OTS RENT-A-CAR" />
                </div>
                {/* <p className="fz-12 fz-16-md lh-1_5 ml-12 ml-20-md">沖繩的各種體驗活動預約就在OTS！在這裡您可以查詢及預約沖繩縣內的各項體驗活動。不論是從高人氣的水上活動，還是最新的活動方案，您都可在這裡預約的到，就讓您有個充實快樂的沖繩之旅吧！</p> */}
                <p className="fz-12 fz-16-md lh-1_5 ml-12 ml-20-md">
                  {t("OptionSelectModal.沖縄のアクティビティ予約はOTSで！県内のさまざまなアクティビティの予約が検索・予約可能です。人気のマリンアクティビティや最新のオプショナルツアーを予約して、めいいっぱい沖縄を楽しもう！")}
                </p>
              </div>
            </div>
            <div className="wrap-content-900 wrap-padding-md">
              <div className="box-plan-bt_gray mt-24 mt-40-md">
                <div className="box-plan-bt_gray-list">
                  {selectActivity?.map((option, oIndex) => (
                    <div key={oIndex} className="box-plan-bt_gray-list-item large">
                      <div className="box-stay-list">
                        <div className="box-stay-item pb-none">
                            <div className="box-stay-item-thumbnail middle">
                                <img
                                src={option.plan_info.image_list[0].image_url}
                                alt=""
                                />
                            </div>
                            <div className="box-stay-item-content">
                              <div className="box-stay-item-content-heading small mb-xlarge">
                                {option.plan_info.plan_name}
                              </div>

                              <div className="box-stay-item-content-label">
                                  <div className="list-stay-label md-large">
                                      <ul className="list-stay-label-list mb-20-md">
                                        {/*{row.tag_rows.map((tagRow) => (*/}
                                        {/*  <li className="list-stay-label-list-item">{tagRow}</li>*/}
                                        {/*))}*/}
                                      </ul>
                                  </div>
                              </div>
                            </div>
                            <div className="box-stay-item-detail small mt-8 mt-0-md">
                                <div className="w-100per">
                                    <div className="d-f ai-c jc-sb d-ib-md w-100per-md">
                                      <p className="mb-5-md fz-13 fw-b ta-c-md c-navy">
                                        {/* お一人様<span className="d-ib d-b-md c-red fz-20 ml-8 ml-0-md mt-4-md">{activityMinPrice(option).toLocaleString()}<span className="fz-12">日圓〜</span></span> */}
                                        {t("SectionOption.お一人様")}<span className="d-ib d-b-md c-red fz-20 ml-8 ml-0-md mt-4-md">{activityMinPrice(option).toLocaleString()}<span className="fz-12">{t("Common.円")}〜</span></span>
                                      </p>
                                      <div className="ta-c-md mt-12-md">
                                        <button type="button" onClick={()=> onClickStep1(option)} className="button-border-large">
                                          {/* 查看詳細內容 */}
                                          {t("SectionOption.詳細を見る")}
                                        </button>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className={"modal-fixed" + (defaultStep === 2? " d-b":" d-n")}>
          {/* <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-back">
              <button className="button-clear" onClick={()=> onPageHandle(defaultStep-1)}><i className="icon-left-arrow-blue fz-20"></i></button>
            </p>
            <p className="modal-fixed-heading-text">{t("OptionSelectModal.オプションの選択")}</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>{t("Common.閉じる")}
              </Button>
              </p>
          </div> */}
          <PartsModalHeaderWithBackButton
            headingText={t("OptionSelectModal.オプションの選択")}
            toPrevious={() => onPageHandle(defaultStep-1)}
            close={callback}
          />

          <div className="modal-fixed-content">

            {/* STEP */}
            <PartsOptionModalSteps activeStep={2} />

            <div className="modal-fixed-content-item">
              <div className="modal-fixed-content-item-carousel">              
              <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, A11y, EffectFade]}
                  loop={true}
                  effect="fade"
                  pagination={{ clickable: true }}
                >
                {useActivity?.plan_info.image_list.map((image, iIndex) => (
                  <SwiperSlide key={iIndex}><img src={image.image_url} alt="" /></SwiperSlide>
                ))}
                </Swiper>
              </div>
              <div className="modal-fixed-content-item-heading">
                {useActivity?.plan_info.plan_name}
              </div>
            </div>

            <div className="wrap-content-900-large wrap-padding-md pt-24 pt-16-md">
              <div className="box-separator_bottom-medium lh-1_75 lh-1_5-md">
                <div className="box-separator_bottom-medium-item">
                  <div className="box-light-gray medium mb-8">
                    <p className="lh-1_75 lh-1_5-md" dangerouslySetInnerHTML={createMarkup(activity?.plan_info.description)}>
                    </p>
                  </div>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">行程・内容</h3>
                  <p dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.contents)}></p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">適合年齡</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.対象年齢")}</h3>
                  <p>
                    {useActivity?.plan_info.age_min && useActivity?.plan_info.age_max ?
                      <>
                        {useActivity?.plan_info.age_min}{t("Common.歳")} ～ {useActivity?.plan_info.age_max}{t("Common.歳")}
                      </>
                    : useActivity?.plan_info.age_min && !useActivity?.plan_info.age_max ?
                      <>
                        {useActivity?.plan_info.age_min}{t("Common.歳")} ～
                      </>
                    : !useActivity?.plan_info.age_min && useActivity?.plan_info.age_max ?
                      <>
                        ～ {useActivity?.plan_info.age_max}{t("Common.歳")}
                      </>
                    :<></>
                    }
                    </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">成團人數</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.最少催行人数")}</h3>
                  <p>{useActivity?.plan_info.least_number}人</p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">費用包含</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.料金に含まれるもの")}</h3>
                  <p dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.include)}>
                  </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合時間</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">集合場所</h3>
                  <p>
                    <>
                      〒{useActivity?.plan_info.gather_place_zip}
                      <br/>
                      {useActivity?.plan_info.gather_place_address}
                      <br/>
                      {useActivity?.plan_info.gather_place_map_lat && useActivity?.plan_info.gather_place_map_lng?
                        <>
                          <Map address={useActivity?.plan_info.gather_place_address} lat={useActivity?.plan_info.gather_place_map_lat} lng={useActivity?.plan_info.gather_place_map_lng} label={useActivity?.plan_info.supplier_name}></Map>
                        </>:<></>
                      }
                      {/* マップコード：{useActivity?.plan_info.gather_place_mapcode} */}
                      {t("ComprehensiveOptionDetailsModal.マップコード")}：{useActivity?.plan_info.gather_place_mapcode}
                    </>
                  </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">注意事項</h3>
                  <p dangerouslySetInnerHTML={createMarkup(activity?.plan_info.notes)}>
                  </p>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">キャンセルポリシー</h3> */}
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">取消規定</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.キャンセルポリシー")}</h3>
                  <>
                    <table className="mb-16 table-xsmall">
                      <tbody>
                        <tr>
                          <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">{t("OptionCancelPolicy.契約の解除日")}</th>
                          <th className="table-bgc-light_navy fw-b ta-c">{t("OptionCancelPolicy.取消料")}</th>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日の4日前まで")}</th>
                          <td>{t("OptionCancelPolicy.無料")}</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日の3日前～2日前まで")}</th>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日前日")}</th>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日当日")}</th>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始後又は無連絡不参加")}</th>
                          <td>100%</td>
                        </tr>
                        {/* <tr>
                          <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">契約解除日</th>
                          <th className="table-bgc-light_navy fw-b ta-c">取消費</th>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日的4天前</th>
                          <td>無取消費</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日的3天前-2天前</th>
                          <td>20%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日的前1天</th>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始日當天</th>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <th className="fz-13 fz-16-md fw-b">利用開始後或無聯絡不參加</th>
                          <td>100%</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </>
                </div>
                <div className="box-separator_bottom-medium-item">
                  {/* <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">主辦公司</h3> */}
                  <h3 className="fz-18 fz-20-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.主催会社")}</h3>
                  <p>
                    <span className="" dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.supplier_name)}></span>
                    <span className="" dangerouslySetInnerHTML={createMarkup(useActivity?.plan_info.time_remarks)}></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-fixed-bottom">
            <button onClick={()=> onPageHandle(defaultStep+1)} className="button-medium-light_blue padding-large w-100per">
              {/* 選擇日期 */}
              {t("ComprehensiveOptionDetailsModal.日程を選択する")}
            </button>
          </div>
        </div>

        {/* 3 */}
        <div className={"modal-fixed" + (defaultStep === 3? " d-b":" d-n")}>
          {/* <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-back">
              <button className="button-clear" onClick={()=> onPageHandle(defaultStep-1)}><i className="icon-left-arrow-blue fz-20"></i></button>
            </p>
            <p className="modal-fixed-heading-text">選擇活動方案</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>關閉此頁
              </Button>
            </p>
          </div> */}
          <PartsModalHeaderWithBackButton
            headingText={t("OptionSelectModal.オプションの選択")}
            toPrevious={() => onPageHandle(defaultStep-1)}
            close={callback}
          />

          <div className="modal-fixed-content">
            {/* STEP */}
            {/* <div className="step-option no-padding">
              <ul className="step-option-list">
                <li key="step-option-list3-1" className="step-option-list-item">請選擇活動方案</li>
                <li key="step-option-list3-2" className="step-option-list-item">確認詳細內容</li>
                <li key="step-option-list3-3" className="step-option-list-item active">選擇日期</li>
                <li key="step-option-list3-4" className="step-option-list-item">請選擇人數</li>
              </ul>
            </div> */}
            <PartsOptionModalSteps activeStep={3} />
          
            <div>
              <div className="wrap-content-900 wrap-padding-md">
                {/* <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">請選擇日期</p> */}
                <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">{t("OptionSelectModal.日程を選択してください")}</p>
                <div>
                  {/* <p className="fz-12 fz-14-md fw-b c-navy mb-5 mb-8-md">您正選擇的活動方案</p> */}
                  <p className="fz-12 fz-14-md fw-b c-navy mb-5 mb-8-md">{t("OptionSelectModal.選択中のプラン")}</p>
                  <div className="box-light-gray xsmall mb-24">
                    <p className="fz-10 fz-14-md lh-1_75 lh-1_5-md">
                      {useActivity?.plan_info.plan_name}
                    </p>
                  </div>
                </div>
              </div>
              <form action="">
                <div className="box_select-hotel pl-40-md pr-40-md">
                  <div className="box_select-hotel-schedule">
                    <ul className="box_select-hotel-schedule-list">
                      {useActivity?.stocks?.map((planStock, psindex) => (
                        <li key={"hotel-schedule-list-item-" + psindex} className="box_select-hotel-schedule-list-item">
                          <div className="box_select-hotel-schedule-list-item-date">
                            <div className={planStock.stock ? 'box_select-hotel-schedule-list-item-date-content' : 'box_select-hotel-schedule-list-item-date-content invalid'}>
                              <ul className="box_select-hotel-schedule-list-item-date-content-list">
                                <li key={"hotel-schedule-list-item-label" + psindex} className="box_select-hotel-schedule-list-item-date-content-list-label">
                                  <input
                                    type="radio"
                                    name={useActivity?.plan_info.plan_code + "_schedule"}
                                    id={useActivity?.plan_info.plan_code + "_schedule_" + psindex}
                                    value={planStock.use_date}
                                    onChange={(event) => setUseDate(event, planStock)}
                                    checked={checkedDate(planStock)}
                                    disabled={!planStock.stock}
                                  />
                                  <label
                                    htmlFor={useActivity?.plan_info.plan_code + "_schedule_" + psindex}
                                    className=""
                                  >
                                    <span>
                                      {dateFormat(planStock.use_date, "YYYY年MM月DD日")}
                                      <span className="d-b d-ib-md ml-24-md fz-16 fz-20-md fw-b price">{activityMinPrice(activity).toLocaleString()}<span className="fz-11 fz-14-md">{t("Common.円")}〜</span></span>
                                    </span>
                                  </label>
                                </li>
                                <li key={"hotel-schedule-list-item-status-" + psindex} className="box_select-hotel-schedule-list-item-date-content-list-status">
                                  <label
                                    id={useActivity?.plan_info.plan_code + "_schedule_" + psindex}
                                    className="box_select-hotel-schedule-list-item-date-content-list-status-label"
                                  >
                                    <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">{t("Common.空き")}</span>
                                    {/* <span className="box_select-hotel-schedule-list-item-date-content-list-status-label-text">空位</span> */}
                                    {planStock.totalStock > 9 && hasActivityStock(useActivity) ?
                                      <i className="icon-possible fz-14"></i>
                                    : planStock.totalStock < 10 && planStock.totalStock > 0 && hasActivityStock(useActivity) && planStock.timeType === "no_settime" ?
                                      <span className="c-orange fz-14">{planStock.totalStock}</span>
                                    : planStock.totalStock > 0 && hasActivityStock(useActivity) && planStock.timeType !== "no_settime" ?
                                      <i className="icon-possible fz-14"></i>
                                    :
                                      <i className="icon-impossible fz-14"></i>
                                    }
                                  </label>
                                </li>
                                <li  key={"hotel-schedule-list-item-result-" + psindex} className="box_select-hotel-schedule-list-item-date-content-list-result">
                                  <div className="w-90 w-100per-md d-f jc-c ai-c">
                                    {!planStock.stock ?
                                      <>
                                        <div className="ta-c w-100 w-180-md">{t("ComprehensiveOptionDetailsModal.選択できません")}</div>
                                        {/* <div className="ta-c w-100 w-180-md">無法選擇</div> */}
                                      </>
                                      : checkPlanStockListType(planStock.time_stock_list) === "list" ?
                                        <>
                                          <div className="select_box-default fw-b c-navy w-100 w-170-md">
                                            <select
                                              defaultValue={setDefaultTimeSelect(planStock)}
                                              onChange={(event) => setUseTime(event, planStock)}
                                            >
                                              <option selected={selectedTime(planStock, "")} value="">{t("ComprehensiveOptionDetailsModal.時間")}</option>
                                              {planStock.time_stock_list.map((timeStock,timeStockListIndex) => (
                                                <Fragment key={timeStockListIndex}>
                                                  {timeStock.reservation_type !== 3?
                                                    <option selected={selectedTime(planStock, timeStock.time_code)} value={timeStock.time_code}>
                                                      {timeStock.time + timeStockText(timeStock.stock)}
                                                    </option>
                                                    :
                                                    <></>
                                                  }
                                                </Fragment>
                                              ))
                                              }
                                            </select>
                                          </div>
                                        </>
                                        :
                                        <i className="icon-bar fz-18"></i>
                                      
                                    }
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </form>

              <PartsIconDescription selectActivity={useActivity} />

            </div>
          </div>
          <div className="modal-fixed-bottom">
            <button 
              onClick={onClickStep3} 
              className="button-medium-light_blue padding-large w-100per"
            >
              {t("ComprehensiveOptionDetailsModal.日程を選択する")}
            </button>
          </div>
        </div>
      
        {/* 4 */}
        <div className={"modal-fixed" + (defaultStep === 4? " d-b":" d-n")}>
          {/* <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-back">
              <button className="button-clear" onClick={()=> onPageHandle(defaultStep-1)}><i className="icon-left-arrow-blue fz-20"></i></button>
            </p>
            <p className="modal-fixed-heading-text">選擇活動方案</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>關閉此頁
              </Button>
            </p>
          </div> */}
          <PartsModalHeaderWithBackButton
            headingText={t("OptionSelectModal.オプションの選択")}
            toPrevious={() => onPageHandle(defaultStep-1)}
            close={callback}
          />


          <div className="modal-fixed-content">
            {/* STEP */}
            {/* <div className="step-option no-padding">
              <ul className="step-option-list">
                <li className="step-option-list-item">請選擇活動方案</li>
                <li className="step-option-list-item">確認詳細內容</li>
                <li className="step-option-list-item">選擇日期</li>
                <li className="step-option-list-item active">請選擇人數</li>
              </ul>
            </div> */}
            <PartsOptionModalSteps activeStep={4} />

            <div>
              <div className="wrap-content-900 wrap-padding-md">
                {/* <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">請填入參加者人數</p> */}
                <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">{t("OptionSelectModal.参加人数を入力してください")}</p>
                <div>
                  {/* <p className="fz-12 fz-14-md fw-b c-navy mb-5 mb-8-md">您正選擇的活動方案</p> */}
                  <p className="fz-12 fz-14-md fw-b c-navy mb-5 mb-8-md">{t("OptionSelectModal.選択中のプラン")}</p>
                  <div className="box-light-gray medium_small mb-16 mb-24-md">
                    <p className="fz-13 fz-16-md lh-1_5 fw-b c-navy">
                      {/* 利用日期：{dateFormat(useActivity?.date, "YYYY年MM月DD日 (ddd)")}  */}
                      {t("ScheduleDetailModal.ご利用日")}：{dateFormatWithLocale(useActivity?.date, "YYYY年MM月DD日 (ddd)", i18n.language)} 
                      {useActivity?.time !== "no_settime" && (<>{useActivity?.time}</>)}
                    </p>
                    <p className="fz-10 fz-14-md lh-1_5 c-blue_gray mt-8">{useActivity?.plan_info.plan_name}</p>
                  </div>
                </div>
              </div>

              <div className="wrap-content-900-large wrap-padding-md pt-6">
                  <ul className="list-counter">
                    {useActivity?.price_list?.map((price_type, pIndex) => (
                      <li key={pIndex} className="list-counter-item">
                        <div className="list-counter-item-title">
                          <span className="d-b d-ib-md">{price_type.name}</span>
                          <span className="d-b d-ib-md ml-16-md c-red">{Number(price_type.price).toLocaleString()}<span className="fz-11 fz-12-md">{t("Common.円")}（税込）</span></span>
                        </div>
                        <div className="list-conter-item-contents">

                          <div className="counter">
                            <div className="counter-minus">
                              <button
                                className="counter-minus-icon"
                                onClick={() => updateActivityPrice( "down", price_type.type_code)}
                                disabled={countDisabled(price_type.num)}
                              ></button>
                            </div>
                            <div className="counter-value">{price_type.num?? 0}<span className="fz-12 fw-n c-blue_gray">{price_type.unit}</span></div>
                            <div className="counter-plus">
                              <button
                                className="counter-plus-icon"
                                onClick={() => updateActivityPrice("add", price_type.type_code)}
                              ></button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
              </div>
            </div>
          </div>
          <div className="modal-fixed-bottom modal-fixed-bottom-2column-wrap">
            <div className="modal-fixed-bottom-2column jc-sb">
              <dl className="d-f fd-c fd-r-md ai-s ai-c-md">
                {/* <dt className="fz-13 fz-14-md fw-b c-navy mr-20">您的使用費用</dt> */}
                <dt className="fz-13 fz-14-md fw-b c-navy mr-20">{t("OptionSelectModal.ご利用料金")}</dt>
                <dd className="c-red fz-20 fz-24-md fw-b">
                  + {getActivityTotalPrice(useActivity).toLocaleString()}
                  <span className="fz-12">{t("Common.円")}（税込）</span>
                </dd>
              </dl>
              <button
                onClick={updateActivity}
                className="button-medium-light_blue padding-large fz-n w-190-md"
              >
                {/* 確定 */}
                {t("SearchCounter.確定する")}
              </button>
            </div>
          </div>
        </div>
        </LoadingOverlay>
      </div>
    </Modal>
    </>
  );
};

export default OptionSelectModal;
