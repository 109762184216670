import React, {useEffect} from "react";

// // toggle button
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


//モーダルインポート
import HotelHandModal from "../modal/HotelHandModal";
import {Hotel, hotelList} from "../../types/Hotel";

import LoadingOverlay from 'react-loading-overlay-ts';
import {
  diffHotelPrice, 
  getHotelTypeLabel, 
  // hotelTagImageName
} from "../../utils/hotel";
import {defaultHotelDetail} from "../../defaults/hotel";
import {
  PlanSelectFormData, 
  // PlanSelectFromHotelType
} from "../../types/planSelectForm";
// import {PlanSearch} from "../../types/Search";
import PartsHotelTagList from "../parts/PartsHotelTagList";
import SearchNotFound from "../SearchNotFound";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/languages";

type Props = {
  staySearchPageView: number
  setStaySearchPageView: ( index: number ) => void,
  hotel: Hotel | undefined,
  planSelectFormData: PlanSelectFormData | undefined,
  isLoading: boolean,
  selectHotel: (hotelCode:string) => void,
  setRoomTypeFilterState: any,
  setMealFilterState: any,
  // setRoomTypeFilter: any,
  // setMealFilter: any,
}

/**
 * ホテル一覧 表示
 * @param staySearchPageView
 * @param setStaySearchPageView
 * @param hotel
 * @param planSelectFormData
 * @param isLoading
 * @param selectHotel
 * @constructor
 */
const StaySearchResult: React.VFC<Props> = ({
    staySearchPageView,
    setStaySearchPageView,
    hotel,
    planSelectFormData,
    isLoading,
    selectHotel,
    setRoomTypeFilterState,
    setMealFilterState,
    // setRoomTypeFilter,
    // setMealFilter,
}) => {

    const { t, i18n } = useTranslation();
    
    const checkIn = planSelectFormData?.searchParams.checkin ?? '';
    const checkOut = planSelectFormData?.searchParams.checkout ?? '';
    //モーダル：ホテル詳細
    const [HotelHandModalState, setHotelHandModalState] = React.useState(false);
    const handlHotelHandModal = () => {
        setHotelHandModalState(!HotelHandModalState);
    };
    const [hotelDetail, setHotelDetail] = React.useState<hotelList>(defaultHotelDetail);
    // const [reserveHotel, setReserveHotel] = React.useState<PlanSelectFromHotelType>();
    const handlHotelDetailModal  = (hotelDetailData: hotelList) => {
      setHotelDetail(hotelDetailData);
      setHotelHandModalState(!HotelHandModalState);
    }
    
    // useEffect(() => {
    //   if (planSelectFormData !== undefined && planSelectFormData?.hotel !== undefined && planSelectFormData?.hotel.length > 0) {
    //     setReserveHotel(planSelectFormData.hotel[0]);
    //   }
    // }, [planSelectFormData])

    useEffect(() => {
      // ホテルが１店舗の場合は部屋詳細にを表示する
      if (staySearchPageView === 1 && hotel !== undefined && planSelectFormData?.hotelData !== undefined && planSelectFormData?.hotelData.length === 1) {
        setRoomTypeFilterState([]); // 部屋タイプ
        setMealFilterState([]); // 食事
        selectHotel(hotel?.hotelList[0].hotel_code);
        setStaySearchPageView(staySearchPageView+1)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel]);

    
    const handleSelectHotel = (hotelCode:string) => {
      // 選択済みの絞り込み条件を選択解除する
      setRoomTypeFilterState([]); // 部屋タイプ
      setMealFilterState([]); // 食事
      
      selectHotel(hotelCode);
      setStaySearchPageView(staySearchPageView+1)
    }
    
    const handleSelectHotelDetail = (hotelCode:string) => {
         selectHotel(hotelCode);
         setHotelHandModalState(!HotelHandModalState);
        setStaySearchPageView(staySearchPageView+1)
    }

    return (
        <>
            <div className={(staySearchPageView === 1? " animation-fade active":" animation-fade")}>
                <div className="box-plan-bt_gray">
                    <div className="box-plan-bt_gray-list">
                      {isLoading ?
                        <LoadingOverlay
                          active={isLoading}
                          spinner
                        >
                          <div className="pb-100"></div>
                        </LoadingOverlay>
                        :
                        <>
                          {/* ここから回す */}
                          {hotel == null ? <></> :
                            hotel.hotelList && hotel.hotelList.length > 0 ? 
                              <> 
                              {hotel.hotelList.map((data, index) => (
                              <div key={index} className="box-plan-bt_gray-list-item">
                                <div className="box-stay">
                                  <div className="box-stay-item">
                                    <div className="box-stay-item-thumbnail large">
                                      <img src={data.image_m_url} alt=""/>
                                    </div>
                                    <div className="box-stay-item-content">
                                      <div className="box-stay-item-content-area">
                                        <div>
                                          {data.sub_area_name !== undefined ? (<ul className="list-stay-area-list">
                                            <li className="list-stay-area-list-item">{data.sub_area_name}</li>
                                          </ul>) : (<></>)}
                                        </div>
                                      </div>
                                      <div className="box-stay-item-content-heading mb-medium">
                                        {data.hotel_name}
                                      </div>
                                      <div className="mb-16-md">
                                        <button
                                          onClick={() => handlHotelDetailModal(data)}
                                          className="button-border-small-blue w-100per w-a-md"
                                        >
                                          {/* 查看飯店介紹 */}
                                          {t("StaySearchResult.ホテルの詳細を見る")}
                                        </button>
                                      </div>

                                      {/* 以下PCのみ */}
                                      <div className="box-stay-item-content-label d-n d-b-md">
                                        <div className="list-stay-label mb-small">
                                          <ul className="list-stay-label-list mb-0-md">
                                            {data.hotel_type_list?.map((list, index) => (
                                              <li key={index} className="list-stay-label-list-item">
                                                {getHotelTypeLabel(list.hotel_type_code, i18n.language)}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        {/* 一旦APIで出力できないため非表示 */}
                                        <div className="d-n d-b-md">
                                            <PartsHotelTagList
                                              hotel_facility_list={data.hotel_facility_list}
                                              ulClass="parts-hotel_tag-list d-f fw-w"
                                              languageCode={i18n.language}
                                            />
                                        </div>
                                      </div>
                                    </div>

                                    {/* sp時 */}
                                    <div className="box-stay-item-content-sp mt-20 mb-16">
                                      <div className="d-n-md">
                                        <div className="list-stay-label mb-small">
                                          <ul className="list-stay-label-list mb-20-md">
                                            {data.hotel_type_list?.map((list, index) => (
                                              <li key={index} className="list-stay-label-list-item">
                                                {getHotelTypeLabel(list.hotel_type_code, i18n.language)}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div className="mt-4">
                                          <PartsHotelTagList
                                            hotel_facility_list={data.hotel_facility_list}
                                            ulClass="parts-hotel_tag-list d-f fw-w"
                                            languageCode={i18n.language}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="box-stay-item-detail">
                                      <div className="w-100per">
                                        <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                          <p className="mb-5-md fz-13 fw-b ta-c-md">
                                            {t("HotelPlanSearchResult.追加料金")}<br/>
                                            {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                            <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                          </p>
                                          <p className="mb-10-md c-red fz-24 fw-b ta-c-md">
                                            {diffHotelPrice(planSelectFormData, {
                                              isUpdate: false,
                                              step: 'hotel',
                                              updateHotelIndex: 0,
                                              updatePlan: undefined,
                                              updateHotel: data,
                                              checkIn: checkIn,
                                              checkOut: checkOut
                                            })}<span className="fz-12">{t("Common.円")}〜</span>
                                          </p>
                                        </div>
                                        <p className="mb-10-md">
                                          <button
                                            onClick={() => handleSelectHotel(data.hotel_code)}
                                            className="button-medium-light_blue button-variable sp-large w-100per"
                                          >
                                            {/* この宿泊施設を<br className="d-n d-b-md"/>
                                            選択する */}
                                            {/* 選擇此住宿設施 */}
                                            {
                                              i18n.language === LANGUAGES.JA ? (
                                                <>この宿泊施設を<br className="d-n d-b-md"/>選択する</>
                                              ) : (
                                                <>選擇此住宿設施</>
                                              )
                                            }
                                          </button>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              ))}
                              </>:
                              <SearchNotFound></SearchNotFound>
                          }
                        </>
                      }
                    </div>
                </div>
            </div>

            {/* モーダル：ホテル詳細 */}
            <HotelHandModal
              selectHotel={handleSelectHotelDetail} 
              isUpdate={true} 
              updateIndex={0} 
              planSelectFormData={planSelectFormData} 
              isShow={HotelHandModalState} 
              hotelList={hotelDetail} 
              callback={handlHotelHandModal}
            />
        </>
    );
}
export default StaySearchResult;