import React from "react";
import { useTranslation } from "react-i18next";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

const SearchNotFound: React.FC = () => {

  const { t } = useTranslation();

  return (
    <>
      <section className="pt-40 pt-56-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                alt=""
              />
            </p>
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">ご希望の条件での検索結果は0件です</p> */}
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">依條件設定搜尋結果為0件</p> */}
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("SearchNotFound.ご希望の条件での検索結果は0件です")}</p>
            <p className="fz-16 ta-c-md c-blue_gray">
              {/* 誠に申し訳ございませんが、指定した条件で見つかりませんでした。<br className="d-n d-b-md" />
              条件を変更して、再度検索を行ってください。 */}
              {/* 對不起，您所指定的條件無法搜尋到任何結果。<br className="d-n d-b-md" />
              請變更條件後再次搜尋。 */}
              {t("SearchNotFound.誠に申し訳ございませんが、指定した条件で見つかりませんでした。")}<br className="d-n d-b-md" />
              {t("SearchNotFound.条件を変更して、再度検索を行ってください。")}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchNotFound;
