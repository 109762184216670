import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "../constants/languages";

import ja from "./ja.json";
import tw from "./tw.json";

const resources = {
  ja: {
    translation: ja,
  },
  tw: {
    translation: tw,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGES.TW,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
