import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ROUTES } from "../../../constants/routes";
import { useTranslation } from "react-i18next";

//input
import { InputCheckbox } from "./../../input/InputCheckbox";
import { InputsForm1 } from "./../../../types/InputsForm1";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";


type Props = {
  errors: FieldErrors;
  register: UseFormRegister<InputsForm1>;
  languageCode: string;
}

const SectionPersonalInformation: React.FC<Props> = (props) => {
  
  const { t } = useTranslation();

  return (
    <>
      {/* <!--同行者情報の入力--> */}
      <section>
       <div className="box-border-red">
          <p className="c-red fz-16 fz-18-md mb-16 ta-c fw-b">
            {t("SectionPersonalInformation.個人情報の取り扱いについて")}
            {/* 有關於個人資料的處理及利用 */}
          </p>
          <p className="fz-13 fz-16-md mb-16">
            {t("SectionPersonalInformation.入力いただくお客様の個人情報は、お客様との連絡に利用させていただくほか、お客様がお申込みいただいた旅行においてサービスの手続きに必要な範囲内で利用させていただきます。")}
            {/* 您所輸入的旅客的個人資料，本公司會在需要跟您聯繫，或是在處理您訂單裡所需使用到的服務的必要範圍內才會使用。 */}
          </p>
          <p className="mb-16 ta-c">
            <a href={ROUTES.Privacypolicy.replace(":lang", props.languageCode)} target="_blank" className="fz-13" rel="noreferrer">
              {t("SectionPersonalInformation.プライバシーポリシーはこちらをご覧ください。")}
              {/* 有關於隱私權保護政策請參照此處 */}
              <i className="icon-blank ml-5"></i>
            </a>
          </p>
          <p className="fz-13 fz-16-md mb-16 fw-b ta-c-md">
            {t("SectionPersonalInformation.上記にご同意頂ける場合は「同意します」にチェックをいれてください。")}
            {/* 如同意上述內容，敬請點選『我同意』。 */}
          </p>
          <div className="box-light-red small">
            <div className="form-input-checkbox">
              <InputCheckbox
                name="agree"
                register={props.register}
                checkboxes={[
                  { id: "agree", label: t("SectionPersonalInformation.同意します"), value: "yes" },
                ]}
                options={{ required: true }}
                className={"form-input-checkbox-icon"}
              ></InputCheckbox>
              <span className="form-input-checkbox-text fw-b">
                <label htmlFor="agree.0">{t("SectionPersonalInformation.同意します")}</label>
              </span>
            </div>
            {props.errors.agree && (
              <p className="form-error_text c-red ta-c">
                {t("SectionPersonalInformation.「同意します」にチェックをいれてください。")}
                {/* 請勾選『我同意』 */}
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionPersonalInformation;
