import React, { useEffect, useState } from "react";

//MainTemplate
import MainTemplate from "./MainTemplate";

import { LANGUAGES } from "../constants/languages";
import { useTranslation } from "react-i18next";


//scss
import "../scss/common.scss";
import "../scss/component.scss";

const Privacypolicy: React.FC = () => {

  const [ languageCode, setLanguageCode ] = useState<string>("tw");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);
  
  return (
    <MainTemplate title={t("Privacypolicy.プライバシーポリシー")} logo={undefined} supportDial={undefined} sellerId={undefined} planSelectFormData={undefined}>
        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("Privacypolicy.プライバシーポリシー")}</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="pt-24 pt-80-md">
            <div className="wrap-content-900">
              <section id="sec_01" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.基本的考え方")}</h2>
                <p>{t("Privacypolicy.沖縄ツーリスト株式会社（以下、「当社」という）は「地域に根ざし世界にはばたく」を企業理念としており、事業をとおして共感価値にもとづく安全で快適な「感動の旅」を提供するとともに、顧客と地域の間に立ち、地域が主役となれる持続可能な観光振興の実現を目指します。")}</p>
                <p>{t("Privacypolicy.当社は、これらを実践するに当たり、お客様に安心して、当社が提供する商品・サービスを利用していただけるよう、個人情報保護の重要性を強く認識し、お客様をはじめとする取引関係者および当社の従業員のプライバシーを尊重するとともに、個人情報を保護すべく、個人情報保護に関する法令およびその他の規範を遵守します。")}</p>
              </section>

              <section id="sec_02" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.事業者の名称・住所・代表者の氏名")}</h2>
                <p>{t("Privacypolicy.沖縄ツーリスト株式会社")}</p>
                <p>{t("Privacypolicy.〒900-8602　沖縄県那覇市松尾1丁目2番3号")}</p>
                <p>{t("Privacypolicy.代表取締役会長　東　良和")}</p>
              </section>

              <section id="sec_03" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.収集する情報の範囲及び利用目的")}</h2>
                <p className="mb-8">{t("Privacypolicy.当社では、個人情報を、お客様へ提供するサービスや業務上の手続きにより収集・利用することを目的としており、収集されるお客様の個人情報は、その目的をお客様が許諾し、当社が提供する以下の業務上のサービスや手続きにおいて利用いたします。")}</p>
                <ul className="list-dot">
                  <li>{t("Privacypolicy.お客様との間の連絡のため")}</li>
                  <li>{t("Privacypolicy.運送・宿泊機関等が提供する旅行サービスの手配及びそれらのサービスの受領のため")}</li>
                  <li>{t("Privacypolicy.旅行に際しての諸手続き等のため")}</li>
                  <li>{t("Privacypolicy.旅行代金の支払いのため")}</li>
                  <li>{t("Privacypolicy.当社および当社の提携する企業の商品やサービス等のご案内のため")}</li>
                  <li>{t("Privacypolicy.お客様からの各種お問い合わせの対応のため")}</li>
                  <li>{t("Privacypolicy.その他当社が業務遂行上に付帯するサービスのため")}</li>
                </ul>
              </section>

              <section id="sec_04" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.個人情報の共同利用について")}</h2>
                <p>{t("Privacypolicy.当社は、当社が保有するお客様の個人情報を商品開発や商品提案などの販売促進活動、お客様へのご連絡や対応のために、以下当社グループ企業と共同利用させていただきます。")}</p>
                <p>{t("Privacypolicy.当社グループ企業が共同利用する個人情報は以下の通りです。")}</p>
                <p>{t("Privacypolicy.住所、氏名、電話番号、性別、年齢、生年月日、メールアドレス、商品購入履歴等")}</p>
                <p className="mb-8">{t("Privacypolicy.＊共同利用する当社グループ企業（2022年4月1日現在）")}</p>

                <ul className="list-wrap-number">
                  <li>{t("Privacypolicy.OTS交通株式会社")}</li>
                  <li>{t("Privacypolicy.北海道ツアーズ株式会社")}</li>
                  <li>{t("Privacypolicy.OTS MICE MANAGEMENT株式会社")}</li>
                  <li>{t("Privacypolicy.株式会社OTSサービス経営研究所")}</li>
                  <li>{t("Privacypolicy.美ら海ハウジング株式会社")}</li>
                  <li>{t("Privacypolicy.株式会社エコ")}</li>
                  <li>{t("Privacypolicy.その他、当社グループ企業")}</li>
                </ul>
              </section>

              <section id="sec_05" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.お客様個人情報の第三者への提供")}</h2>
                <p>{t("Privacypolicy.当社は、お客様との契約目的を達成するために運輸･宿泊機関等および手配代行者、土産品店等、商品サプライヤー・外部委託業者へ、住所、氏名、電話番号、性別、年齢、生年月日、旅券番号、メールアドレス、搭乗される航空便名等、いずれも必要な範囲内の個人情報を電子的方法等で送付することにより提供いたします｡お申込みいただく際には、これらの個人データの提供についてお客様に同意いただくものとします。")}</p>
                <p>{t("Privacypolicy.また、当社は、お客様があらかじめ同意した場合、その他法令により認められる場合を除いては、個人情報を第三者に提供することはありません。")}</p>
              </section>

              <section id="sec_06" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.外部サービスとの連携と個人情報の利用")}</h2>
                <p>{t("Privacypolicy.当社が提供するサイトでは、お客様が承諾することにより、第三者が運営する外部サービス（以下、「外部サービス」といいます）のアカウントと接続、連携し、当社がお客様から取得した個人情報を外部サービスへ提供できるものといたします。お客様の承諾を受け、外部サービスに提供した情報については、当社の管理下にあるものではなく、外部サービスのプライバシーポリシーがそれぞれ別に適用されることにご注意ください。")}</p>
                <p>{t("Privacypolicy.なお、当社は、以下の提供先に対して、当社の所管システム、電子媒体の手段・方法によって個人情報を提供いたします。")}</p>
              </section>

              <section id="sec_08" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.安全確保の措置")}</h2>
                <p>{t("Privacypolicy.当社は、お客様の個人情報を常に正確かつ最新の状態で管理するよう努めると共に、以下の安全管理のための措置を講じます。")}</p>
                <ul className="list-dot">
                  <li>{t("Privacypolicy.お客様の個人情報に関する不正アクセス、漏えい、滅失、き損等に対する予防措置及び是正措置を実施いたします。")}</li>
                  <li>{t("Privacypolicy.お客様の個人情報の保護と適切な取扱いに関して、社員等に対する必要な教育及び監督を行います。")}</li>
                  <li>{t("Privacypolicy.業務を外部委託する場合は、委託業者と個人情報保護に関する契約を締結したうえ、お客様の個人情報が安全に管理されているかの確認を行う等、委託業者に対する必要かつ適切な監督を行います。")}</li>
                  <li>{t("Privacypolicy.お客様の個人情報の取扱状況等について定期的な監査を実施し、個人情報保護マネジメントシステム（個人情報保護に関する当社の取り組み）の検証及び必要に応じた安全措置の改善を行います。")}</li>
                </ul>
              </section>

              <section id="sec_09" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.事故時の措置")}</h2>
                <p>{t("Privacypolicy.当社は、個人情報の漏えい事故等が発生した場合には、事実関係と原因の調査、二次被害の防止策及び再発防止策等を実施し、適切に対応いたします。")}</p>
              </section>

              <section id="sec_10" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.情報個人データの開示、訂正、削除")}</h2>
                <p>{t("Privacypolicy.個人データの開示、その内容の訂正、追加もしくは削除、またはその利用の停止もしくは消去もしくは第三者への提供の停止等をご希望の方は、お問い合わせ窓口までお申し出ください。その際、お客さまご本人であることを確認した上で、法令に従い、合理的な期間内に必要な措置を取らせていただきます。また、ご希望の全部または一部に応じられない場合はその理由をご説明いたします。")}</p>
              </section>

              <section id="sec_11" className="mt-48-md mt-24 pt-16">
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.クッキー（Cookie）等の利用について")}</h2>
                <p className="mb-8">{t("Privacypolicy.当社が提供するサイトでは、以下の目的のためにクッキー、Webビーコン（Web Beacon）その他の類似技術を用いることがあります。")}</p>
                <ul className="list-dot mb-8">
                  <li>{t("Privacypolicy.お客様が認証サービスにログインされるとき、保存されているお客様の登録情報を参照して、お客様ごとにカスタマイズされたサービスを提供できるようにするため。")}</li>
                  <li>{t("Privacypolicy.お客様が興味を持っている内容や当ウェブサイト上での利用状況をもとに、最も適切な広告を他社ウェブサイト上で表現するため。")}</li>
                  <li>{t("Privacypolicy.当ウェブサイトの利用者数やトラフィックを調査するため。")}</li>
                  <li>{t("Privacypolicy.弊社のサービスを改善するため。")}</li>
                  <li>
                    {t("Privacypolicy.セキュリティー保持のため、ご利用から一定の時間が経過したお客様に対してパスワードの再入力（再認証）を促すため。")} <br />
                    {t("Privacypolicy.※保存される内容にはお名前や電話番号など個人を特定できる情報は含まれておりません。")}
                  </li>
                </ul>
                <p className="mb-8">{t("Privacypolicy.Cookieを無効化されたいお客様は、ウェブブラウザの設定を変更することによりCookieを無効化してください。ただし、Cookieを無効化すると、本サイトの一部の機能をご利用いただけなくなる場合があります。")}</p>
                <p className="mb-8">{t("Privacypolicy.た当社サイトでは、Webサイトやアプリの利用動向を把握するためにGoogle の解析ツールであるGoogle Analyticsを使用しています。クッキーを使用して匿名で情報を収集し、Webサイトやアプリの利用動向をレポートします。Google Analyticsが主に使用するのは「_ga」という名前の クッキー です。ウェブサイトの利用動向をレポートするだけでなく、Google サービス（Google 検索など）やウェブでより関連性の高い広告が表示されるようにしたり、Google が表示する広告とのインタラクションを測定したりするために、上記の広告 クッキー の一部が、Google Analyticsによって収集された Google サービスに関するデータとともに使用されることもあります。")}</p>
                <p hidden={languageCode !== LANGUAGES.JA}>＊Google Analyticsのクッキーポリシーについては<a href="https://policies.google.com/technologies/cookies?hl=ja" target="_blank" rel="noreferrer">こちら</a>をご覧ください。</p>
                <p hidden={languageCode !== LANGUAGES.TW}>＊有關於Google Analytics的Cookie政策，請參照<a href="https://policies.google.com/technologies/cookies?hl=tw" target="_blank" rel="noreferrer">此處</a>。</p>

                <p className="mb-8" hidden={languageCode !== LANGUAGES.JA}>＊Google Analytics JavaScript（analytics.js、gtag.js）によるデータの使用を無効化するにはGoogle Analytics オプトアウトアドオン（<a href="https://tools.google.com/dlpage/gaoptout?hl=ja" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout?hl=ja</a>）をダウンロードしてご利用のブラウザにインストールしてください。</p>
                <p className="mb-8" hidden={languageCode !== LANGUAGES.TW}>＊若欲藉由Google Analytics JavaScript（analytics.js、gtag.js）所收集的資料無效化，請下載Google Analytics (分析) 不透露資訊瀏覽器外掛程式（<a href="https://tools.google.com/dlpage/gaoptout?hl=zh-TW" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout?hl=zh-TW</a>)，並將其安裝在您所使用的網路瀏覽器中。</p>
              </section>

              <section id="sec_12" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.継続的な改善")}</h2>
                <p>{t("Privacypolicy.当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。 当社は、本プライバシーポリシーに基づくお客様の権利をお客様の明示的な許可なく縮減することはありません。また、本プライバシーポリシーの変更に際してはこのページに掲載し、大幅な変更の場合はより明確な方法で告知するよう努めます。")}</p>
              </section>

              <section id="sec_13" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Privacypolicy.その他")}</h2>
                <p>{t("Privacypolicy.当社では、本保護方針を改定することがあります。改定する場合は、当ウェブサイトでお知らせします。")}</p>
              </section>

              <section id="sec_14" className="mt-48-md mt-24 pt-16">
                <div className="box-blue">
                  <p className="ta-c fz-18 fw-b mb-16 mb-24-md">{t("Privacypolicy.個人情報に関するお問い合わせ")}</p>
                  <p className="ta-c mb-24">{t("Privacypolicy.個人情報の取り扱いに関するお問い合わせは、下記宛にお願いいたします。")}</p>
                  <p className="fw-b ta-c mb-8">{t("Privacypolicy.個人情報保護管理部署")}</p>
                  <p className="ta-c fw-b">{t("Privacypolicy.沖縄ツーリスト株式会社　本社総務部")}</p>
                  <p className="ta-c fw-b">{t("Privacypolicy.住所：沖縄県那覇市松尾1丁目2番3号")}</p>
                  <p className="ta-c fw-b" hidden={languageCode !== LANGUAGES.JA}>電話：<a href="tel:098-917-2546">098-917-2546</a></p>
                  <p className="ta-c fw-b" hidden={languageCode !== LANGUAGES.TW}>電話：<a href="tel:098-917-2546">81-（0）98-917-2546</a></p>
                </div>
              </section>

            </div>
          </div>
        </div>
    </MainTemplate>
  );
};

export default Privacypolicy;
