import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


type Props = {
  headingText: string;
  appendClass?: string;
  close: () => void;
};

/**
 * モーダルのヘッダー部
 * 
 * @param param0 
 * @returns 
 */
const PartsModalHeader: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const appendClass = props.appendClass ?? "";

  return (
    <div className={`modal-fixed-heading ${appendClass}`}>
      <p className="modal-fixed-heading-text">{props.headingText}</p>
      <p className="modal-fixed-close">
        <Button onClick={props.close} className="modal-fixed-close-button">
          <i className="modal-fixed-close-button-icon"></i>{t("Common.閉じる")}
        </Button>
      </p>
    </div>
  );
};

export default PartsModalHeader;
