import dayjs from "dayjs";
import "dayjs/locale/ja";
import "dayjs/locale/zh-tw";
import isBetween from "dayjs/plugin/isBetween";
import { DAYJS_LOCALES, LANGUAGES } from "../constants/languages";

dayjs.locale("zh-tw");


/**
 * 数値を丸付きに変換（50まで）
 * @param num
 */
export const toCircled = (num: number): string => {
  if (num <= 20) {
    const base = "①".charCodeAt(0);
    return String.fromCharCode(base + num - 1);
  }
  if (num <= 35) {
    const base = "㉑".charCodeAt(0);
    return String.fromCharCode(base + num - 21);
  }
  if (num <= 50) {
    const base = "㊱".charCodeAt(0);
    return String.fromCharCode(base + num - 36);
  }
  return num.toString();
};

/**
 * 日付の形式を変更する
 * @param date
 * @param format
 */
export const dateFormat = (date: string|null|undefined, format: string): string => {
  return dayjs(date).format(format);
};

/**
 * locale設定するパターンの日付フォーマット関数
 * 
 * @param date 
 * @param format 
 * @param languageCode 
 * @returns 
 */
export const dateFormatWithLocale = (date: string|null|undefined, format: string, languageCode: string): string => {
  const locale = languageCode === LANGUAGES.JA ? DAYJS_LOCALES.JA : DAYJS_LOCALES.TW;

  return dayjs(date).locale(locale).format(format);
};

/**
 * 指定日付から年齢を取得
 * @param birthday
 */
export const getAge = (birthday: string) => {
  const today = dayjs();
  return today.diff(birthday, "year");
};

/**
 * 指定した日が現在の時刻から何日離れているか取得
 * @param date
 */
export const diffDate = (date: string) => {
  const today = dayjs();
  return today.diff(date, "day", false);
};

/**
 * 指定した日付の範囲が何日あるか取得
 * @param stateDate
 * @param endData
 */
export const rageDiffDate = (stateDate: string, endData:string) => {
  const end = dayjs(endData);
  return end.diff(stateDate, "day", false);
};

/**
 * 指定した日付の範囲の日付を返す
 * @param useStartDate
 * @param useEndDate
 */
export const rangeDate = (useStartDate: string, useEndDate: string): string[] => {
  const resultDateRange = [];
  const startDate = dayjs(useStartDate);
  const endDate = dayjs(useEndDate);
  for (let date=startDate; date<=endDate; date=date.add(1, "day") ) {
    resultDateRange.push(date.format("YYYY-MM-DD"));
  }

  return resultDateRange;
};

/**
 * 指定日付分減算する
 * @param date
 * @param day
 * @param format
 */
export const dayAgo = (date:string, day:number, format:string) => {
  return dayjs(date).subtract(day, 'day').format(format);
}

export const nextDate = (date:string, day:number, format:string) => {
  return dayjs(date).add(day, 'day').format(format);
};

/**
 * 時刻が「no_settime」なら空文字を返し、それ以外なら引数をそのまま返す
 * 
 * @param time 
 * @returns 
 */
export const toDisplayTime = (time: string) => {
  return time === "no_settime" ? "" : time;
}

/**
 * 指定した時刻がstart-endの時刻内にあるかどうかを判定
 * 
 * @param targetTime 
 * @param baseDate 
 * @param startTime 
 * @param endTime 
 * @returns 
 */
export const inDateTimeRange = (targetTime: string, baseDate: string, startTime: string, endTime: string) => {
  dayjs.extend(isBetween);
  
  const targetDateTime = `${baseDate} ${targetTime}`

  const start = `${baseDate} ${startTime}`

  const end = `${baseDate} ${endTime}`

  return dayjs(targetDateTime).isBetween(start, end, 'minute', "[]");
}

/**
   * 生年月日から年齢を計算する
   * @param year 
   * @param month 
   * @param day 
   * @returns 
   */
export const getAgeFromBirthday = (year: number|string, month: number|string, day: number|string, tripStartDate: string) => {
  const birthday = dayjs(year+"-"+month+"-"+day);
  const today = dayjs();
  return today.diff(birthday, "year");
}

/**
 * 正しい日付ならtrueを返す
 * 
 * @param targetDate 
 * @returns 
 */
export const isValidDate = (targetDate: string) => {
  return dayjs(targetDate).isValid();
}

/**
 * 導入事業者ID・販売者ID 表示用に変換する(000-000形式)
 * @param sellerId
 */
export const convertSellerIdForDisplay = (sellerId: number): string => {
  const paddingZero = sellerId.toString().padStart(6, "0");

  return `${paddingZero.slice(0, 3)}-${paddingZero.slice(3, 6)}`;
}