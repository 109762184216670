import {hotelTagImageForJp, hotelTagImageForTw, hotelTypeCodeForJp, hotelTypeCodeForTw, okinawaAreaCodeLabel, roomTypeCodeForJp, roomTypeCodeForTw} from "../config/hotel";
// import {sellerInfo} from "../types/sller";
import {PlanSearch, roomDetailChild} from "../types/Search";
import {
  hotelList,
  roomData,
  searchHotelParams,
  searchHotelRoomParams,
  stayDetailType,
  hotelData,
  // HotelPlan, 
  Hotel, 
  roomSearchDetail, 
  // hotelPlanDetail, 
  updateHotel, 
  stayDetail
} from "../types/Hotel";
import {apiClient} from "./apiClient";
import {rageDiffDate, toCircled} from "./convert";
import {
  // planData, 
  PlanSelectFormData,
  PlanSelectFromHotelType
} from "../types/planSelectForm";
import {diffBasicPrice, getBasicTotalPrice} from "./price";
import _ from "lodash";
import { LANGUAGES } from "../constants/languages";
import { TFunction } from "i18next";
import {getHotelSettings} from "./sellerProduct";
// import {roomTypeCode as roomTypeCodeList} from "../config/hotel";

export const checkHotel = async (areaCode: string, target:string, code: string, searchParams: PlanSearch, languageCode: string) => {
  try {
    const params = getHotelSearchParms(areaCode, target, code, searchParams, languageCode);
    params.params.get_limit = 1;
    const response = await apiClient.post("/hotel/hotel-search", params);
    const result = await response.data;
    if (result.success) {
      return true;
    }
    
  } catch (e) {
    console.error(e);
  }
  
  return false;
}

/**
 * ホテル検索
 * @param areaCode
 * @param target
 * @param code
 * @param searchParams
 * @param limit
 */
export const hotelSearch = async (areaCode: string, target:string, code: string, searchParams: PlanSearch, limit: number, languageCode: string):Promise<hotelList[]> => {
  try {
    const params = await getHotelSearchParms(areaCode, target, code, searchParams, languageCode);
    params.params.get_limit = limit;
    const response = await apiClient.post("/hotel/hotel-search", JSON.stringify(params));
    const result = await response.data;
    if (result.success && response.data.response.hotel_list) {
      return response.data.response.hotel_list as hotelList[];
    }
    
  } catch (e) {
    console.error(e);
  }
  
  return [] as hotelList[];
}

/**
 * ホテル全検索
 * @param areaCode
 * @param target
 * @param code
 * @param searchParams
 */
export const hotelsAllSearch = async (areaCode: string, target:string|undefined, code: string|undefined, searchParams: PlanSearch, languageCode: string): Promise<hotelList[]> => {
  try {
    if (target && code) {
      const params = getHotelSearchParms(areaCode, target, code, searchParams, languageCode);
      const response = await apiClient.post("/hotel/hotel-all-search", params);
      const result = await response.data;
      if (result.success) {
        const hotels:Hotel = response.data;
        return hotels.hotelList as hotelList[];
      }
    }

  } catch (e) {
    console.error(e);
  }
  
  return [] as hotelList[];
}

/**
 * ホテル詳細検索
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 */
export const hotelsDetailSearch = async (areaCode: string, searchParams: PlanSearch, hotelCode: string|undefined, languageCode: string): Promise<hotelData|undefined> => {
  try {
    if (hotelCode) {
      const params = getHotelDetailSearchParms(areaCode, hotelCode, searchParams, languageCode);
      const response = await apiClient.post("/hotel/hotel-detail", params);
      const result = await response.data;
      if (result.success) {
        return result.response as hotelData;
      }
    }
  } catch (e) {
    console.error(e);
  }
  
  return undefined;
}

/**
 * ホテル部屋一括検索
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 */
export const hotelRoomsSearch = async (areaCode: string, searchParams: PlanSearch, hotelCode: string, languageCode: string): Promise<roomData[]|undefined> => {
  try {
      const params = getHotelRoomsSearchParms(areaCode, hotelCode, searchParams, languageCode);
      const response = await apiClient.post("/hotel/hotel-all-room", params);
      const result = await response.data;
      
      if (result.status === 200) {
        return response.data.roomData as roomData[];
      }
  } catch (e) {
    console.error(e);
  }
  
  return undefined;
}

/**
 * ルーム詳細取得
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 * @param roomCode
 * @param planCode
 */
export const hotelRoomSearch = async (areaCode: string, searchParams: PlanSearch, hotelCode: string, roomCode: string, planCode: string | undefined, languageCode: string): Promise<roomSearchDetail|undefined> => {
  try {
    if (planCode) {
      const params = getHotelRoomsSearchParms(areaCode, hotelCode, searchParams, languageCode);
      params.hotel_code = hotelCode;
      params.room_code = roomCode;
      params.plan_code = planCode
      const response = await apiClient.post("/hotel/hotel-room", {params: params});
      return  response.data as roomSearchDetail;
    }
  } catch (e) {
    console.error(e);
  }
  
  return undefined;
}

/**
 * ホテルタイプのコードからラベルを取得
 * @param code
 * @param languageCode
 */
export const getHotelTypeLabel = (code: string, languageCode: string) => {
  // 言語ごとで出し分ける
  const hotelTypeCode = getHotelTypeCodeConfigByLanguageCode(languageCode);

  return hotelTypeCode[code] != null ? hotelTypeCode[code] : "";
}

/**
 * ホテルエリアラベル
 * @param code
 */
export const hotelAreaCodeLabel = (code: string) => {
  if (okinawaAreaCodeLabel[code]) {
    return okinawaAreaCodeLabel[code];
  }
  return code;
}

/**
 * ホテル部屋タイプコードからラベルを取得
 * @param code
 * @param languageCode
 */
export const getRoomTypeLabel = (code: string, languageCode: string) => {
  // 言語ごとで出し分ける
  const roomTypeCode = getRoomTypeCodeConfigByLanguageCode(languageCode);

  return roomTypeCode[code] != null ? roomTypeCode[code] : "";
}

/**
 * ホテルタグアイコンイメージURL取得
 * @param tagName
 */
export const hotelTagImageName = (tagName:string, languageCode: string) => {
  // 言語ごとで出し分ける
  const hotelTagImage = getHotelTagImageConfigByLanguageCode(languageCode);

  return hotelTagImage[tagName] != null ? hotelTagImage[tagName] : undefined;
}

/**
 * ホテル予約人数ラベル
 * @param roomDetail
 * @param rooms
 */
export const getHotelRoomPeopleLabel = (roomDetail:any, rooms: number|undefined, i18nextTranslate: TFunction) => {
  let room = Number(rooms)?? 0;
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  const total = adult + child;
  
  
  // return `${room}室・${total}名（大人${adult}/子供${child}）`;
  // return `${room}間・${total}名（成人${adult}/兒童${child}）`;
  return `${room}${i18nextTranslate("Hotel.室")}・${total}名（${i18nextTranslate("Common.大人")}${adult}/${i18nextTranslate("Common.子供")}${child}）`;
}

/**
 * ホテル検索セクション人数ラベル
 * @param roomDetail
 */
export const getHotelSearchRoomPeopleLabel = (roomDetail:any, i18nextTranslate: TFunction) => {
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  const total = adult + child;
  
  
  // return `${total}名（大人${adult}/子供${child}）`;
  // return `${total}名（成人${adult}/兒童${child}）`;
  return `${total}名（${i18nextTranslate("Common.大人")}${adult}/${i18nextTranslate("Common.子供")}${child}）`;
}

/**
 * 部屋ごとの人数の割り当てコンテンツ表示取得
 * @param roomDetail
 */
export const getHotelSearchRoomPeopleConfirmLabel = (roomDetail:any, i18nextTranslate: TFunction) => {
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  // const total = adult + child;
  
  // return `大人${adult}名/子供${child}名`;
  // return `成人${adult}名/兒童${child}名`;
  return `${i18nextTranslate("Common.大人")}${adult}名/${i18nextTranslate("Common.子供")}${child}名`;
}

/**
 * ホテル一覧検索用パラメータデータ取得
 * @param areaCode
 * @param target
 * @param code
 * @param query
 */
export const getHotelSearchParms = (areaCode: string, target:string, code: string, query: PlanSearch, languageCode: string) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  
  const params:searchHotelParams = {
    "params": {
      "area_code": areaCode,
      "check_in": query?.checkin,
      "check_out": query?.checkout,
      "count_adults": peopleNum[countIndex].adult,
      "count_rooms": query.rooms,
      "language_code": languageCode,
    }
  };
    
  if (peopleNum[countIndex].children_a > 0) {
    params.params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.params.count_children_f = peopleNum[countIndex].children_f
  }
  
  // NOTE: ここでの条件にしか使っていないので日本語でOK
  if (target === "エリア指定") {
    const codes = code.split(',');
    params.params.search_keyword_type = "area";
    params.params.search_keyword_mode = 2;
    if (codes.length > 1) {
      params.params.search_keyword_mode = 2;
    }
    params.params.search_keyword_code = code;
    
  } else if (target === "ホテル指定") {
    params.params.hotel_code = code;
  }
  
  return params;
}

/**
 * ホテル詳細検索用パラメータデータ取得
 * @param areaCode
 * @param hotelCode
 * @param query
 */
export const getHotelDetailSearchParms = (areaCode: string, hotelCode:string, query: PlanSearch, languageCode: string) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  
  const params:searchHotelParams = {
    "params": {
      "area_code": "okinawa",
      "hotel_code": hotelCode,
      "check_in": query?.checkin,
      "check_out": query?.checkout,
      "count_adults": peopleNum[countIndex].adult,
      "count_rooms": query.rooms,
      "language_code": languageCode,
    }
  };
    
  if (peopleNum[countIndex].children_a > 0) {
    params.params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.params.count_children_f = peopleNum[countIndex].children_f
  }
  
  return params;
}

/**
 * ホテル部屋検索用パラメータ取得
 * @param areaCode
 * @param hotelCode
 * @param query
 */
export const getHotelRoomsSearchParms = (areaCode: string, hotelCode:string, query: PlanSearch, languageCode: string) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  const stayDetail:stayDetailType[] = [];
  
  const params:searchHotelRoomParams = {
    "area_code": areaCode,
    "hotel_code": hotelCode,
    "check_in": query?.checkin,
    "check_out": query?.checkout,
    "count_adults": peopleNum[countIndex].adult,
    "count_rooms": query.rooms,
    "stay_detail": [],
    "language_code": languageCode,
  };

  if (peopleNum[countIndex].children_a > 0) {
    params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.count_children_f = peopleNum[countIndex].children_f
  }
  
  query.roomDetail.forEach((room) => {
    let adult = 0;
    let children_a = 0;
    let children_b = 0;
    let children_c = 0;
    let children_d = 0;
    let children_e = 0;
    let children_f = 0;
    
    adult = room.adult;
    room.child.forEach((child) => {
      const age = Number(child.age);
      if (age >= 10) {
        children_a++;
      } else if(age < 10 && age >= 6) {
        children_b++;
      } else if(age < 6){
        if (child.meal && child.futon) {
          children_c++
        } else if (child.meal) {
          children_d++
        } else if (child.futon) {
          children_e++;
        } else {
          children_f++
        }
      }
    });
    const stay_detailParam:stayDetailType  = {
      "count_adults": adult,
    }

    if (children_a > 0) {
      stay_detailParam.count_children_a = children_a
    }

    if (children_b > 0) {
      stay_detailParam.count_children_b = children_b
    }

    if (children_c > 0) {
      stay_detailParam.count_children_c = children_c
    }

    if (children_d > 0) {
      stay_detailParam.count_children_d = children_d
    }

    if (children_e > 0) {
      stay_detailParam.count_children_e = children_e
    }

    if (children_f > 0) {
      stay_detailParam.count_children_f = children_f
    }

    stayDetail.push(stay_detailParam);
  });
  
  // todo stay detail sort（最大人数の値を一番にもってくる）
  if (stayDetail.length > 0) {
    params.stay_detail = sortHotelStayDetail(stayDetail);
  }
  
  return params;
}

/**
 * ホテルの検索のパラメータ用データ取得
 * @param query
 */
export const getHotelPeopleNum = (query: PlanSearch) => {
  const stayParams: stayDetail[] = [];

  query.roomDetail.forEach((room) => {
    let adult = 0;
    let children_a = 0;
    let children_b = 0;
    let children_c = 0;
    let children_d = 0;
    let children_e = 0;
    let children_f = 0;
    adult += Number(room.adult);
    if (room.child.length > 0) {
      const childNums = childrenRoomNum(room.child);
      if (childNums.children_a > 0) {
        children_a += childNums.children_a;
      }
      if (childNums.children_b > 0) {
        children_b += childNums.children_b;
      }
      if (childNums.children_c) {
        children_c += childNums.children_c;
      }
      if (childNums.children_d) {
        children_d += childNums.children_d;
      }
      if (childNums.children_e) {
        children_e += childNums.children_e;
      }
      if (childNums.children_f) {
        children_f += childNums.children_f;
      }
    }
    stayParams.push({
      adult: adult,
      children_a: children_a,
      children_b: children_b,
      children_c: children_c,
      children_d: children_d,
      children_e: children_e,
      children_f: children_f,
    });
  });
  
  return stayParams;
}

/**
 * ホテル子供パラメータ取得
 * @param children
 */
export const childrenRoomNum = (children: roomDetailChild[]) => {
  let children_a = 0;
  let children_b = 0;
  let children_c = 0;
  let children_d = 0;
  let children_e = 0;
  let children_f = 0;

  children.forEach((child) => {
    const age = Number(child.age);
    if (age >= 10) {
      children_a++;
    } else if(age < 10 && age >= 6) {
      children_b++;
    } else if(age < 6){
      if (child.meal && child.futon) {
        children_c++
      } else if (child.meal) {
        children_d++
      } else if (child.futon) {
        children_e++;
      } else {
        children_f++
      }
    }
  })

  return {
    children_a: children_a,
    children_b: children_b,
    children_c: children_c,
    children_d: children_d,
    children_e: children_e,
    children_f: children_f,
  }
}

export const findMaxPeopleParamIndex = (stayDetail: stayDetail[]) => {
  let peopleMaxNum = 0;
  let findIndex = 0;
  
  stayDetail.forEach((detail, sIndex) => {
    let peopleNum = 0;
    peopleNum += detail.adult;
    peopleNum += detail.children_a;
    peopleNum += detail.children_b;
    peopleNum += detail.children_c;
    peopleNum += detail.children_e;
    if (peopleNum > peopleMaxNum) {
      peopleMaxNum = peopleNum;
      findIndex = sIndex;
    }
  })
  
  return findIndex;
}

/**
 * ホテル一覧時の料金差分
 * @param planSelectFormData
 * @param updateData
 */
export const diffHotelPrice = (planSelectFormData:PlanSelectFormData|undefined, updateData: updateHotel) => {
  if (planSelectFormData !== undefined && (updateData.updatePlan || updateData.updateHotel)) {
    const people = getHotelTotalPeople(planSelectFormData);
    // 現在の基本料金
    const basicTotal = getBasicTotalPrice( {
      isUpdate: false,
      step: 'total',
      updateHotelIndex: undefined,
      updatePlan: undefined,
      updateHotel: undefined,
      checkIn: planSelectFormData.searchParams.checkin,
      checkOut: planSelectFormData.searchParams.checkout
    }, planSelectFormData);
    const currentTotalPricePerPerson = basicTotal / people;
    const updatePricePerson = getBasicTotalPrice(updateData, planSelectFormData) / people;
    return diffBasicPrice(currentTotalPricePerPerson, updatePricePerson);
  }

  return diffBasicPrice(0, 0);
}

/**
 * ホテル追加・更新時の料金差分
 * @param planSelectFormData
 * @param updateData
 */
export const diffUpdateHotelPrice = (planSelectFormData:PlanSelectFormData|undefined, updateData: updateHotel) => {
    return diffHotelPrice(planSelectFormData, updateData);
}

/**
 * プラン一覧時の表示用にプランデータを分割
 * @param hotelData
 * @param roomData
 */
export const mergeHotelRoomPlans = (hotelData:hotelData|undefined, roomData: roomData[]) => {
  if (roomData === undefined) {
    return [];
  }
  return roomData?.map((room) => {
    
    if (room.planList[0]) {
      room.first_plan = room.planList[0];
    }
    if (room.planList.length > 1) {
      room.plan_list = room.planList;
      room.plan_list.slice();
    }
    
    return room;
  })
  
}

/**
 * 部屋絞り込み
 * @param hotelListData
 * @param params
 */
export const filterRooms = (hotelListData: hotelList | undefined, params: {
  roomTypes: string[],
  mealTypes: string[],
}) => {
  const roomTypes = params.roomTypes;
  const mealTypes = params.mealTypes;
  if (hotelListData && hotelListData.roomData && (roomTypes.length > 0 || mealTypes.length > 0)) {
    const updateHotelData = _.cloneDeep(hotelListData);
    let updateRoomList = updateHotelData.roomData;
    if (updateRoomList && updateRoomList.length > 0) {
      // `ルームタイプ`と`お食事`のAND検索
      const filterRoomList = updateRoomList.map((roomData) => {
        const filterRoomData = _.cloneDeep(roomData);

        // プランがない場合
        if (filterRoomData.planList.length === 0) {
          return false;
        }

        // 部屋を`ルームタイプ`で絞り込む(OR検索)
        if (roomTypes.length > 0) {
          let searchRoomTypes = _.cloneDeep(roomTypes);
          if (searchRoomTypes.includes("western_style")) {
            searchRoomTypes = [...searchRoomTypes, "1", "2", "3", "4", "5", "6"];
          }
          if (!searchRoomTypes.includes(filterRoomData.detail.room_info.room_type_code)) {
            return false;
          }
        }

        // プランを`お食事`で絞り込む(OR検索)
        if (mealTypes.length > 0) {
          filterRoomData.planList = filterRoomData.planList.filter((plan) => {
            // 食事なし
            if (mealTypes.includes("meal_nothing") && plan.room_plan_detail.meal_breakfast === "0" && plan.room_plan_detail.meal_lunch === "0" && plan.room_plan_detail.meal_dinner === "0") {
              return true;
            }
            // 朝食あり
            if (mealTypes.includes("meal_breakfast") && plan.room_plan_detail.meal_breakfast === "1") {
              return true;
            }
            // 昼食あり
            if (mealTypes.includes("meal_lunch") && plan.room_plan_detail.meal_lunch === "1") {
              return true;
            }
            // 夕食あり
            if (mealTypes.includes("meal_dinner") && plan.room_plan_detail.meal_dinner === "1") {
              return true;
            }
            return false;
          })
        }

        // 部屋の最初のプランと2つ目以降のプランを割り当て直す
        if (filterRoomData.planList[0]) {
          filterRoomData.first_plan = filterRoomData.planList[0];
        }
        if (filterRoomData.planList.length > 1) {
          filterRoomData.plan_list = filterRoomData.planList;
          filterRoomData.plan_list.slice();
        }
        return filterRoomData;
      }).filter((result) => result);

      updateHotelData.roomData = filterRoomList as roomData[];
    }
    return updateHotelData;
  }
  return hotelListData;
}

/**
 * ホテルの変更ができる日程日かチェック
 * @param date
 * @param checkIn
 */
export const hotelChangeDisabled = (date:string, checkIn: string) => {
  const diffDate = rageDiffDate(checkIn, date);
  return diffDate >= 1;
}

/**
 * ホテルのキャンセルができる日程日かチェック
 * @param date
 * @param checkIn
 * @param hotelList
 */
export const hotelCancelDisabled = (date: string, checkIn: string, hotelList: PlanSelectFromHotelType[]) => {
  const diffDate = rageDiffDate(checkIn, date);
  const hotelIndex = hotelList.findIndex((hotel) => {
    return hotel.checkIn === checkIn;
  });
  
  return !(hotelIndex === 0 && diffDate < 1);
}

export const selectHotelIndex = (hotelData: PlanSelectFromHotelType[], checkIn:string) => {
  return hotelData.findIndex((hotel) => {
    return hotel.checkIn === checkIn;
  })
}

/**
 * ホテル追加・更新時のホテル合計金額
 * @param planSelectData
 * @param isUpdate
 * @param updateHotelIndex
 * @param price
 */
export const updateHotelPrice = (planSelectData: PlanSelectFormData, isUpdate:boolean, updateHotelIndex:number|undefined, price:number) => {
  let hotelTotal = planSelectData.hotelTotal;
  
  if (isUpdate && updateHotelIndex !== undefined) {
    return planSelectData.hotel[updateHotelIndex].plan;
  }
  hotelTotal += price
  return hotelTotal;
}

export const updateHotelTotal = (planSelectData: PlanSelectFormData) => {
  let hotelTotal = 0;
  if (planSelectData.hotel.length > 0) {
    planSelectData.hotel.forEach((hotel) => {
      hotelTotal += hotel.plan?.room_plan_detail.total_price ?? 0;
    })
  }

  return hotelTotal;
}

export const getHotelGenderCode = (gender: string) => {
  if (gender === "male") {
    return "1";
  }
  
  return "2";
}

export const getHotelTotalPeople = (planSelectFormData: PlanSelectFormData) => {
  let people = 0;
  const peopleDetail = getHotelPeopleNum(planSelectFormData.searchParams);
  peopleDetail.forEach((detail) => {
    people += detail.adult;
    people += detail.children_a;
    people += detail.children_b;
    people += detail.children_c;
    people += detail.children_d;
    people += detail.children_e;
    people += detail.children_f;
  })
  return people;
}

export const showMealLabel = (meal_breakfast: string, meal_lunch: string, meal_dinner: string, i18nextTranslate: TFunction) => {
  let text = "";
  if (meal_breakfast === "0" && meal_lunch === "0" && meal_dinner === "0") {
    return i18nextTranslate("Hotel.食事なし");
    // return "無附餐";
  }
  if (meal_breakfast === "1") {
    text += i18nextTranslate("Hotel.朝");
    // text += "早";
  }
  if (meal_lunch === "1") {
    text += i18nextTranslate("Hotel.昼");
    // text += "午";
  }
  if (meal_dinner === "1") {
    text += i18nextTranslate("Hotel.夕");
    // text += "晚";
  }
  
  // return text + "食付き";
  // return `附${text}餐`;
  return i18nextTranslate("Hotel.mealType食付き", { mealType: text });
}

export const showRoomText = (hotelPlan: any, i18nextTranslate: TFunction) => {
  const room = {
    people: 0,
    room: 0,
  };
  let roomText = "";

  if (hotelPlan.stay_detail_list && hotelPlan.stay_detail_list.length > 0) {
    hotelPlan.stay_detail_list.forEach((stayDetail: any, index: number) => {
      room.room++;
      const peoples = {
        adult: 0,
        child: 0,
      };
      const countAdults = Number(stayDetail.count_adults) ?? 0;
      if (countAdults > 0) {
        peoples.adult = countAdults;
      }
      const countChildrenA = Number(stayDetail.count_children_a) ?? 0;
      if (countChildrenA > 0) {
        peoples.child += countChildrenA;
      }
      const countChildrenB = Number(stayDetail.count_children_b) ?? 0;
      if (countChildrenB > 0) {
        peoples.child += countChildrenB;
      }
      const countChildrenC = Number(stayDetail.count_children_c) ?? 0;
      if (countChildrenC > 0) {
        peoples.child += countChildrenC;
      }
      const countChildrenD = Number(stayDetail.count_children_d) ?? 0;
      if (countChildrenD > 0) {
        peoples.child += countChildrenD;
      }
      const countChildrenE = Number(stayDetail.count_children_e) ?? 0;
      if (countChildrenE > 0) {
        peoples.child += countChildrenE;
      }
      const countChildrenF = Number(stayDetail.count_children_f) ?? 0;
      if (countChildrenF > 0) {
        peoples.child += countChildrenF;
      }
      roomText += i18nextTranslate("Hotel.客室") + toCircled(index+1);
      // roomText += "客房" + toCircled(index+1);
      // if (peoples.adult > 0) {
      //   // roomText += "大人×"+ peoples.adult;
      //   roomText += "成人×"+ peoples.adult;
      // }
      // if (peoples.child > 0) {
      //   // roomText += "子供×"+ peoples.child;
      //   roomText += "兒童×"+ peoples.child;
      // }

      // refs. #128 年齢が任意なので、大人と子供は分けずに合算して表示する
      roomText += `${(peoples.adult + peoples.child)}名`;

      roomText += "、";
    });
  }
  const roomCountText = room.room + i18nextTranslate("Hotel.室");
  // const roomCountText = room.room + "間";
  return roomCountText + "(" + roomText.slice( 0, -1) + ")";
};

export const sortHotelStayDetail = (stayDetail: stayDetailType[]) => {
  stayDetail.sort((first, second) => {
    let firstCount = 0;
    let secondCount = 0;
    firstCount += first.count_adults;
    firstCount += first.count_children_a ?? 0;
    firstCount += first.count_children_b ?? 0;
    firstCount += first.count_children_c ?? 0;
    firstCount += first.count_children_f ?? 0;

    secondCount += second.count_adults;
    secondCount += second.count_children_a ?? 0;
    secondCount += second.count_children_b ?? 0;
    secondCount += second.count_children_c ?? 0;
    secondCount += second.count_children_f ?? 0;

    if (firstCount < secondCount) {
      return  1
    } else if (firstCount > secondCount) {
      return -1;
    }

    return 0
  });
  
  return stayDetail;
}

/**
 * ホテル大エリア取得
 * @param planSelectFormData
 */
export const getHotelAreaCode = (planSelectFormData: PlanSelectFormData|undefined) => {
  if (planSelectFormData) {
    const hotelSettings = getHotelSettings(planSelectFormData);
    return hotelSettings?.bigArea ?? "okinawa";
  }

  return 'okinawa';
}


/**
 * ホテル種類のconfigを言語で出し分ける
 * 
 * @param languageCode 
 * @returns 
 */
const getHotelTypeCodeConfigByLanguageCode = (languageCode: string) => {
  return languageCode === LANGUAGES.JA ? hotelTypeCodeForJp : hotelTypeCodeForTw;
}

/**
 * 部屋種類のconfigを言語で出し分ける
 * 
 * @param languageCode 
 * @returns 
 */
export const getRoomTypeCodeConfigByLanguageCode = (languageCode: string) => {
  return languageCode === LANGUAGES.JA ? roomTypeCodeForJp : roomTypeCodeForTw;
}

/**
 * タグのconfigを言語で出し分ける
 * 
 * @param languageCode 
 * @returns 
 */
const getHotelTagImageConfigByLanguageCode = (languageCode: string) => {
  return languageCode === LANGUAGES.JA ? hotelTagImageForJp : hotelTagImageForTw;
}


export const getHotelTarget = (planSelectFormData: PlanSelectFormData) => {
  const hotelSettings = getHotelSettings(planSelectFormData);
  return hotelSettings?.target ?? "エリア指定";
}


export const getHotelCode = (planSelectFormData: PlanSelectFormData) => {
  const hotelSettings = getHotelSettings(planSelectFormData);
  return hotelSettings?.hotelCode ?? "";
}