import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  // useRoutes,
} from "react-router-dom";

import { ROUTES } from "./constants/routes";

import {
  Form1,
  Form2,
  Form4,
  Form5,
  Form6,
  PlanSelectTop,
  PlanSelectConfirm,
  PlanSelectComplete,
  ReservationCancelLogin,
  ReservationCancelDetail,
  ReservationCancelFee,
  Page404,
  PageServiceStop,
  Conditions,
  Agreement,
  Privacypolicy,
  Faq
} from "./pages/";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

// interface AppProps {
// }
// interface AppState {
//     authed: boolean;
//     loading: boolean;
// }

import { useTranslation } from "react-i18next";
import { LANGUAGES } from "./constants/languages";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const InitializeGTM = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

    if (gtmId == null || gtmId === "") {
      return;
    }
    
    TagManager.initialize({
      gtmId
    });
  }, []);

  return null;
}

const App = () => {
  const { i18n } = useTranslation();
  const [, setLanguageCode ] = useState<string>("");

  // i18nの初期化
  // twとja以外は404エラーに飛ばす
  useLayoutEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith(`/${LANGUAGES.TW}/`)) {
      i18n.changeLanguage(LANGUAGES.TW);
      setLanguageCode(LANGUAGES.TW);
      return;
    }

    if (path.startsWith(`/${LANGUAGES.JA}/`)) {
      i18n.changeLanguage(LANGUAGES.JA);
      setLanguageCode(LANGUAGES.JA);
      return;
    } 
    
    window.location.href = ROUTES.Page404.replace(":lang", LANGUAGES.TW);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        {/* Private Route */}
        <Route path={ROUTES.Default} element={<Page404 />} />
        <Route path={ROUTES.Form1} element={<Form1 />} />
        <Route path={ROUTES.Form2} element={<Form2 />} />
        <Route path={ROUTES.Form4} element={<Form4 />} />
        <Route path={ROUTES.Form5} element={<Form5 />} />
        <Route path={ROUTES.Form6} element={<Form6 />} />
        <Route path={ROUTES.Default} element={<PlanSelectTop />} />
        <Route path={ROUTES.PlanSelectTop} element={<PlanSelectTop />} />
        <Route path={ROUTES.PlanSelectConfirm} element={<PlanSelectConfirm />} />
        <Route path={ROUTES.PlanSelectComplete} element={<PlanSelectComplete />}/>
        <Route path={ROUTES.ReservationCancelLogin} element={<ReservationCancelLogin />} />
        <Route path={ROUTES.ReservationCancelDetail} element={<ReservationCancelDetail />} />
        <Route path={ROUTES.ReservationCancelFee} element={<ReservationCancelFee />} />
        <Route path={ROUTES.Page404} element={<Page404 />} />
        <Route path={ROUTES.PageServiceStop} element={<PageServiceStop />} />
        <Route path={ROUTES.Conditions} element={<Conditions />} />
        <Route path={ROUTES.Agreement} element={<Agreement />} />
        <Route path={ROUTES.Privacypolicy} element={<Privacypolicy />} />
        {/*<Route path={ROUTES.Faq} element={<Faq />} />*/}

        {/* pathにmatchしないものは404 */}
        <Route path={"*"} element={<Page404 />} />
      </Routes>
    </Provider>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <InitializeGTM />
      <ScrollToTop />
      <App />
    </Router>
  );
};

export default AppWrapper;
