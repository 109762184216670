import React, {useEffect, useState} from "react";

// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// for styles
import {
  // Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import "../../scss/component/form.scss";
import "../../scss/component/icon.scss";

import Modal from "@material-ui/core/Modal";

// import CounterNumberOfUnits from "./../counter/counterNumberOfUnits";
import CounterNumberOfUnitsCarRental from "./../counter/carRentalCounterNumberOfUnits";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import {
  carRentalPlanApi,
  getCarRentalCountNum,
  getCarRentalOfficeLabel,
  getDateLabel,
  updateCarRentalSaveData,
  getCarRentalSheetCountNum,
  getCarRentalOptionCode,
  carRentalStockAPI,
  carRentalSearch,
  carRentalPlans,
  getCarRentalChildOptionItem
} from "../../utils/carRental";
import {
  carRentalChildSheetData, carRentalCountOption,
  carRentalPlanData, carRentalPlanDetail,
  carRentalPlanParamsType,
  carRentalSaveData, carRentalSearchResponsePlanList
} from "../../types/carRental";
import {
  carRentalAccessType,
  carRentalBorrowingTimeOptions, carRentalFlights,
  carRentalOfficeCodes,
  carRentalReturnTimeOptions,
  perSheetMaxNum
} from "../../config/carRental";
// import {apiClient} from "../../utils/apiClient";
import {SearchNotFound} from "../index";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {dateFormat} from "../../utils/convert";
import {reserveCarRentalDetail, reserveRentalCar} from "../../types/reserve";
import DatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
// import {convertReserveFlightData} from "../../utils/flight";
import ja from 'date-fns/locale/ja';
import zhTW from 'date-fns/locale/zh-TW';
import { useTranslation } from "react-i18next";
import { DATEPICKER_LOCALES, LANGUAGES } from "../../constants/languages";
import { PartsModalHeader } from "../parts";
import {InputText} from "../input/InputText";
import _ from "lodash";
import RentalModalStep2 from "./RentalModal/RentalModalStep2";
import RentalModalStep1 from "./RentalModal/RentalModalStep1";
import Button from "@material-ui/core/Button";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  planSelectFormData: PlanSelectFormData,
  isUpdate: boolean,
  updateIndex: number,
  isShow: boolean,
  callback: () => void,
  updateCarRental(planSelectFormData: PlanSelectFormData): void,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const DEFAULT_TIME_INDEX = 6;
const DEFAULT_TIME = '12:00';

const defaultCarRentalPlanParams: carRentalSaveData = {
  "access_type": 'airport',
  "departure_office_code": "toyosaki",
  "return_office_code": "toyosaki",
  "departure_date": "",
  "departure_time": DEFAULT_TIME,
  "return_date": "",
  "return_time": DEFAULT_TIME,
  "departure_date_label": "",
  "departure_office_label": getCarRentalOfficeLabel("toyosaki"),
  "return_date_label": "",
  "return_office_label": getCarRentalOfficeLabel("toyosaki"),
  "flight_arrival_code": "ANA",
  "flight_arrival_no": "",
  "flight_departure_code": "ANA",
  "flight_departure_no": "",
  "total": 0,
  "detail": []
};

const RentalModal: React.FC<Props> = ({
  planSelectFormData,
  isShow,
  isUpdate,
  updateIndex,
  callback,
  updateCarRental
}) => {
  
  /**
   *  Use State
   */
  const [step, setStep] = useState(1);
  const classesForLoading = useStyles();

  const { t, i18n } = useTranslation();

  const [departureDate, setDepartureDate] =  React.useState<string>(planSelectFormData.searchParams.start);
  const [returnDate, setReturnDate] =  React.useState<string>(planSelectFormData.searchParams.end);
  const [borrowingTimeAnchorEl, setBorrowingTimeAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedBorrowingTimeIndex, setSelectedBorrowingTimeIndex] = React.useState(0);
  const [borrowingPlaceAnchorEl, setBorrowingPlaceAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedBorrowingPlaceIndex, setSelectedBorrowingPlaceIndex] = React.useState(0);
  const [returnTimeAnchorEl, setReturnTimeAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedReturnTimeIndex, setSelectedReturnTimeIndex] = React.useState(0);
  const [returnPlaceAnchorEl, setReturnPlaceAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedReturnPlaceIndex, setSelectedReturnPlaceIndex] = React.useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingStep2, setLoadingStep2] = useState(false);
  // const [error, setError] = useState("");
  const [rentalRows, setRentalRows] = useState<carRentalSearchResponsePlanList[]>([]);
  const [childSeatRows, setChildSeatRows] = useState<carRentalChildSheetData[]>([]);
  const [carRentalPlanParams , setCarRentalPlanParams] = useState<carRentalSaveData>(defaultCarRentalPlanParams);
  const [rentalParams, setRentalParams] = useState<carRentalPlanParamsType>({
    "departure_office_code": "",
    "return_office_code": "",
    "departure_date":  "",
    "departure_time":  "",
    "return_date":  "",
    "return_time": ""
  });
  const [departureFlightNumber, setDepartureFlightNumber] = useState<string>()
  const [returnFlightNumber, setReturnFlightNumber] = useState<string>()
  const [selectAccessTypeIndex, setSelectAccessTypeIndex] = useState<number>(0)
  const [accessTypeAnchorEl, setAccessTypeAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectDepartureFlightsIndex, setSelectDepartureFlightsIndex] = useState<number>(0)
  const [departureFlightsAnchorEl, setDepartureFlightsAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectReturnFlightsIndex, setSelectReturnFlightsIndex] = useState<number>(0)
  const [returnFlightsAnchorEl, setReturnFlightsAnchorEll] = React.useState<null | HTMLElement>(null);

  const accessTypes = carRentalAccessType;
  const flights = carRentalFlights;
  const accessTypeOpen = Boolean(accessTypeAnchorEl);
  const departureFlightsOpen = Boolean(departureFlightsAnchorEl);
  const returnFlightsOpen = Boolean(returnFlightsAnchorEl);
  
  /**
   * 貸出日変更
   * @param newValue
   */
  const changeDepartureDate = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    setDepartureDate(_date);
    setCarRentalPlanParams((prevState) => ({...prevState,
      departure_date: _date,
      departure_date_label: getDateLabel(_date, carRentalPlanParams.departure_time, i18n.language),
    }));
    setRentalParams((prevState) => ({...prevState,
      departure_date: _date
    }));
  }

  /**
   * 返却日変更
   * @param newValue
   */
  const changeReturnDate = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    setReturnDate(_date)
    setCarRentalPlanParams((prevState) => ({...prevState,
      return_date: _date,
      return_date_label: getDateLabel(_date, carRentalPlanParams.return_time, i18n.language),
    }));
    setRentalParams((prevState) => ({...prevState,
      return_date: _date
    }));
  }

  //貸出時間セレクトボックス
  const borrowingTimeOpen = Boolean(borrowingTimeAnchorEl);
  const handleBorrowingTimeClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setBorrowingTimeAnchorEl(event.currentTarget);
  };

  const handleBorrowingTimeClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    if (carRentalBorrowingTimeOptions[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        departure_time: carRentalBorrowingTimeOptions[index],
        departure_date_label: getDateLabel(carRentalPlanParams.departure_date, carRentalBorrowingTimeOptions[index], i18n.language),
      }));
    }
    setSelectedBorrowingTimeIndex(index);
    setBorrowingTimeAnchorEl(null);
    setRentalParams((prevState) => ({...prevState,
      departure_time: carRentalBorrowingTimeOptions[index]
    }));
  };

  const borrowingTimeHandleClose = () => {
    setBorrowingTimeAnchorEl(null);
  };

  const borrowingTimeOptions = carRentalBorrowingTimeOptions;

  //貸出場所セレクトボックス
  const borrowingPlaceOpen = Boolean(borrowingPlaceAnchorEl);
  const handleBorrowingPlaceClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setBorrowingPlaceAnchorEl(event.currentTarget);
  };

  const handleBorrowingPlaceClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    if (carRentalOfficeCodes[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        departure_office_code: carRentalOfficeCodes[index]["code"],
        departure_office_label: getCarRentalOfficeLabel(carRentalOfficeCodes[index]["code"]),
      }));
    }
    setSelectedBorrowingPlaceIndex(index);
    setBorrowingPlaceAnchorEl(null);
    setRentalParams((prevState) => ({...prevState,
      departure_office_code: carRentalOfficeCodes[index]["code"]
    }));
  };

  const borrowingPlaceHandleClose = () => {
    setBorrowingPlaceAnchorEl(null);
  };

  const borrowingPlaceOptions = carRentalOfficeCodes;

  //貸出時間セレクトボックス
  const returnTimeOpen = Boolean(returnTimeAnchorEl);
  const handleReturnTimeClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setReturnTimeAnchorEl(event.currentTarget);
  };

  const handleReturnTimeClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    if (carRentalReturnTimeOptions[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        return_time: carRentalReturnTimeOptions[index],
        return_date_label: getDateLabel(carRentalPlanParams.return_date, carRentalReturnTimeOptions[index], i18n.language),
      }));
    }
    setSelectedReturnTimeIndex(index);
    setReturnTimeAnchorEl(null);
    setRentalParams((prevState) => ({...prevState,
      return_time:carRentalReturnTimeOptions[index]
    }));
  };

  const returnTimeHandleClose = () => {
    setReturnTimeAnchorEl(null);
  };

  const returnTimeOptions = carRentalReturnTimeOptions;

  //貸出場所セレクトボックス
  const returnPlaceOpen = Boolean(returnPlaceAnchorEl);
  const handleReturnPlaceClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setReturnPlaceAnchorEl(event.currentTarget);
  };

  const handleReturnPlaceClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    if (carRentalOfficeCodes[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        return_office_code:carRentalOfficeCodes[index]["code"],
        return_office_label: getCarRentalOfficeLabel(carRentalOfficeCodes[index]["code"]),
      }));
    }
    setSelectedReturnPlaceIndex(index);
    setReturnPlaceAnchorEl(null);
    setRentalParams((prevState) => ({...prevState,
      return_office_code:carRentalOfficeCodes[index]["code"]
    }));
  };

  const returnPlaceHandleClose = () => {
    setReturnPlaceAnchorEl(null);
  };

  const returnPlaceOptions = carRentalOfficeCodes;


  /**
   * 営業所までの来店手段セレクトハンドル
   * @param event
   */
  const handleAccessTypes = (event: React.MouseEvent<HTMLElement>) => {
    setAccessTypeAnchorEl(event.currentTarget);
  };

  const handleAccessTypesClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
  ) => {
    if (accessTypes[index] !== undefined) {
      const accessType = accessTypes[index]["code"];
      const flightDepartureCode = carRentalFlights[selectReturnFlightsIndex].code ?? '';
      const flightArrivalCode = carRentalFlights[selectDepartureFlightsIndex].code ?? '';
      setCarRentalPlanParams((prevState) => ({...prevState,
        access_type: accessType,
        flight_departure_code: accessType === 'airport' ? flightDepartureCode : '',
        flight_departure_no: accessType === 'airport' ? carRentalPlanParams.flight_departure_no : '',
        flight_arrival_code: accessType === 'airport' ? flightArrivalCode : '',
        flight_arrival_no: accessType === 'airport' ? carRentalPlanParams.flight_arrival_no : '',
      }));
    }
    setSelectAccessTypeIndex(index);
    setAccessTypeAnchorEl(null);
  };
  
  const handleAccessTypesClose = () => {
    setAccessTypeAnchorEl(null);
  };

  /**
   * 到着便校区会社セレクトハンドル
   * @param event
   */
  const handleDepartureFlights = (event: React.MouseEvent<HTMLElement>) => {
    setDepartureFlightsAnchorEl(event.currentTarget);
  };

  const handleDepartureFlightsClose = () => {
    setDepartureFlightsAnchorEl(null);
  };

  const handleDepartureFlightsClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
  ) => {
    if (flights[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        flight_arrival_code:flights[index]["code"],
      }));
    }
    setSelectDepartureFlightsIndex(index);
    setDepartureFlightsAnchorEl(null);
  };

  /**
   * 出発便校区会社セレクトハンドル
   * @param event
   */
  const handleReturnFlights = (event: React.MouseEvent<HTMLElement>) => {
    setReturnFlightsAnchorEll(event.currentTarget);
  };

  const handleReturnFlightsClose = () => {
    setReturnFlightsAnchorEll(null);
  };

  const handleReturnFlightsClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
  ) => {
    if (flights[index] !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        flight_departure_code: flights[index]["code"],
      }));
    }
    setSelectReturnFlightsIndex(index);
    setReturnFlightsAnchorEll(null);
  };



  const handleDepartureFlightNumber = (
      event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        flight_arrival_no: event.target.value,
      }));
    }
    setDepartureFlightNumber(event.target.value);
  };


  const handleReturnFlightNumber = (
      event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value !== undefined) {
      setCarRentalPlanParams((prevState) => ({...prevState,
        flight_departure_no: event.target.value,
      }));
    }
    setReturnFlightNumber(event.target.value);
  };

  const searchCarRental = () => {
    fetchCarRentalPlanData({ data:rentalParams});
  }
  
  /**
   * レンタカー情報取得
   * @param input
   */
  const fetchCarRentalPlanData = async (input: { data: any }) => {
    setLoading(true);

    try {
      const response = await carRentalSearch(input);
      if (response && response.success) {
        setRentalRows([...response.response.plan_list]);
      }

    } catch (error) {
      // setError("プランの取得に失敗しました。");
      // setError("取得方案失敗。");
      console.error("プランの取得に失敗しました。");
    }

    setLoading(false);

    return;
  }

  registerLocale('ja', ja);
  registerLocale('zhTW', zhTW);

  useEffect(() => {
    if (isShow) {
      setStep(1)
      if (isUpdate && planSelectFormData.carRental[updateIndex]) {
        const selectCarRental = _.cloneDeep(planSelectFormData.carRental[updateIndex]);

        const selectCarRentalParams = {
          "access_type": selectCarRental.access_type,
          "departure_office_code": selectCarRental.departure_office_code,
          "return_office_code": selectCarRental.return_office_code,
          "departure_date": selectCarRental.departure_date,
          "departure_time":selectCarRental.departure_time,
          "return_date": selectCarRental.return_date,
          "return_time": selectCarRental.return_time,
          "departure_date_label": selectCarRental.departure_date_label,
          "departure_office_label": selectCarRental.departure_office_label,
          "return_date_label": selectCarRental.return_date_label,
          "return_office_label": selectCarRental.departure_office_label,
          "flight_arrival_code": selectCarRental.flight_arrival_code,
          "flight_arrival_no": selectCarRental.flight_arrival_no,
          "flight_departure_code": selectCarRental.flight_departure_code,
          "flight_departure_no": selectCarRental.flight_departure_no,
          "total": selectCarRental.total,
          "detail": selectCarRental.detail,
          language_code: i18n.language,
        };
        setCarRentalPlanParams({...selectCarRentalParams});

        const departureTimeIndex = carRentalBorrowingTimeOptions.findIndex((borrowingTime) => {
          return borrowingTime === selectCarRentalParams.departure_time;
        })
        const returnTimeIndex = carRentalReturnTimeOptions.findIndex((borrowingTime) => {
          return borrowingTime === selectCarRentalParams.return_time;
        })
        setSelectedBorrowingTimeIndex(departureTimeIndex)
        setSelectedReturnTimeIndex(returnTimeIndex)
        setDepartureDate(selectCarRentalParams.departure_date)
        setReturnDate(selectCarRentalParams.return_date)

        const accessTypeIndex = carRentalAccessType.findIndex((accessType) => {
          return accessType.code === selectCarRentalParams.access_type;
        })
        setSelectAccessTypeIndex(accessTypeIndex)

        const departureFlightIndex = carRentalFlights.findIndex((flight) => {
          return flight.code === selectCarRentalParams.flight_arrival_code;
        })

        const returnFlightIndex = carRentalFlights.findIndex((flight) => {
          return flight.code === selectCarRentalParams.flight_departure_code;
        })

        setSelectDepartureFlightsIndex(departureFlightIndex >= 0 ? departureFlightIndex : 0)
        setDepartureFlightNumber(selectCarRental.flight_arrival_no)
        setSelectReturnFlightsIndex(returnFlightIndex >= 0 ? returnFlightIndex : 0)
        setReturnFlightNumber(selectCarRental.flight_departure_no)
        
        const updateRentalParams = {...rentalParams,
          departure_date: selectCarRentalParams.departure_date,
          departure_time: selectCarRentalParams.departure_time,
          return_time: selectCarRentalParams.return_time,
          return_date: selectCarRentalParams.return_date,
          return_office_code: selectCarRentalParams.return_office_code,
          departure_office_code: selectCarRentalParams.departure_office_code,
          language_code: i18n.language
        }
        setRentalParams({...updateRentalParams});
        fetchCarRentalPlanData({ data:updateRentalParams});
      } else {
        const selectCarRentalParams = {
          "access_type": "airport",
          "departure_office_code": "toyosaki",
          "return_office_code": "toyosaki",
          "departure_date": planSelectFormData.searchParams.start,
          "departure_time": DEFAULT_TIME,
          "return_date": planSelectFormData.searchParams.end,
          "return_time": DEFAULT_TIME,
          "departure_date_label": getDateLabel(planSelectFormData.searchParams.start, DEFAULT_TIME, i18n.language),
          "departure_office_label": getCarRentalOfficeLabel("toyosaki"),
          "return_date_label": getDateLabel(planSelectFormData.searchParams.end, DEFAULT_TIME, i18n.language),
          "return_office_label": getCarRentalOfficeLabel("toyosaki"),
          "flight_arrival_code": "ANA",
          "flight_arrival_no": "",
          "flight_departure_code": "ANA",
          "flight_departure_no": "",
          "total": 0,
          "detail": [],
          language_code: i18n.language,
        };
        setSelectedBorrowingTimeIndex(DEFAULT_TIME_INDEX)
        setSelectedReturnTimeIndex(DEFAULT_TIME_INDEX)
        setCarRentalPlanParams({...selectCarRentalParams});
        setSelectAccessTypeIndex(0)
        setSelectDepartureFlightsIndex(0)
        setDepartureFlightNumber("")
        setSelectReturnFlightsIndex(0)
        setReturnFlightNumber("")
        const updateRentalParams = {...rentalParams,
          departure_office_code: 'toyosaki',
          departure_date: planSelectFormData.searchParams.start,
          departure_time: DEFAULT_TIME,
          return_time: DEFAULT_TIME,
          return_date: planSelectFormData.searchParams.end,
          return_office_code: 'toyosaki',
          language_code: i18n.language,}
        setRentalParams({...updateRentalParams});
        fetchCarRentalPlanData({ data:updateRentalParams});
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const addSaveDataByStep1 = (count:number, plan_code:string, type: string) => {
    const updateData = updateCarRentalSaveData({...carRentalPlanParams}, rentalRows, type, plan_code, count);
    setCarRentalPlanParams({...updateData})
  }

  const nextStep = (step: number) => {
    
    if (carRentalPlanParams.access_type === 'airport' && (returnFlightNumber === "" || departureFlightNumber === "")) {
      const departureFlightNumberMessage = departureFlightNumber !== "" ? "" : t("RentalModal.到着便の便名を入力してください。")
      const returnFlightNumberMessage = returnFlightNumber !== "" ? "" : t("RentalModal.出発便の便名を入力してください。")
      const br = (returnFlightNumber === "" && departureFlightNumber === "") ? "\n" : "";
      alert(departureFlightNumberMessage + br + returnFlightNumberMessage);
      return
    }
    
    if (carRentalPlanParams.detail.length > 0) {
      setStep(step);
      fetchCarRentalPlanDetailData();
      window.scrollTo(0, 0);
    } else {
      alert(t("CarRental.レンタカープランを選択してください。"));
    }
  }


  /**
   * レンタカープラン詳細取得
   */
  const fetchCarRentalPlanDetailData = async () => {
    if (carRentalPlanParams.detail.length > 0) {
      setLoadingStep2(true);
      const defaultPlanCodes: {plan_code: string}[] = [];
      const planCodes = carRentalPlanParams.detail.reduce((accumulator, currentItem: any) => {
        if (!accumulator.some((item) => item.plan_code === currentItem.plan_code)){
          accumulator.push({plan_code: currentItem.plan_code});
        }
        return accumulator;
      }, defaultPlanCodes)

      const requestParams = {
        departure_office_code: carRentalPlanParams.departure_office_code,
        departure_date: carRentalPlanParams.departure_date,
        departure_time: carRentalPlanParams.departure_time,
        return_time: carRentalPlanParams.return_time,
        return_date: carRentalPlanParams.return_date,
        return_office_code: carRentalPlanParams.return_office_code,
        plans: planCodes,
        language_code: i18n.language,
      }
      const response = await carRentalPlans({data: requestParams});
      if (response && response.status === 200) {
        const updatePlanData = _.cloneDeep(carRentalPlanParams);
        updatePlanData.detail = updatePlanData.detail.map((detailData) => {
          const findPlanData = response?.result?.find((planData) => planData.response.plan_info.plan_code === detailData.plan_code);
          if (findPlanData) {
            const planInfo = findPlanData.response.plan_info as carRentalPlanDetail;
            return {
              ...detailData,
              planDetail: planInfo,
              grade: planInfo.class_name,
              car_model: planInfo.class_model,
              engine_size: planInfo.class_cc,
              tag_rows: planInfo.default_option_list,
              price: Number(planInfo.total_price),
              basic_price: Number(planInfo.basic_price),
              indemnity_price: Number(planInfo.indemnity_price),
              max_member: Number(planInfo.max_member),
              access_type: carRentalPlanParams.access_type,
            }
          }
          return detailData;
        });
        setCarRentalPlanParams({...updatePlanData});
      }
    }
    setLoadingStep2(false);
  }


  /**
   * チャイルドシート更新
   */
  const updateChildSeatCount = (count: number, option_code: string, type: string, option_name: string, detailIndex: number) => {
    const updateCarRentalData = _.cloneDeep(carRentalPlanParams);
    const updateDetail = updateCarRentalData.detail.map((detailData, index) => {
      if (index === detailIndex) {
        const option_vaule_list = detailData.planDetail?.option_list.find((option) => option.option_name === option_name)?.option_vaule_list ?? [];
        const price = option_vaule_list.find((option) => option.num === count)?.price ?? 0;
        const updateCountOption = _.cloneDeep(detailData.count_option);
        switch (getCarRentalChildOptionItem(option_name, 'code', i18n.language)){
          case "baby_seat":
            updateCountOption.baby_seat.num = getCarRentalChildOptionItem(option_name, 'code', i18n.language) === "baby_seat" ? count : updateCountOption.baby_seat.num;
            updateCountOption.baby_seat.option_code = option_code;
            updateCountOption.baby_seat.price = price;
            break;
          case "child_seat":
            updateCountOption.child_seat.num = getCarRentalChildOptionItem(option_name, 'code', i18n.language) === "child_seat" ? count : updateCountOption.baby_seat.num;
            updateCountOption.child_seat.option_code = option_code;
            updateCountOption.child_seat.price = price;
            break
          case "junior_seat":
            updateCountOption.junior_seat.num = getCarRentalChildOptionItem(option_name, 'code', i18n.language) === "junior_seat" ? count : updateCountOption.baby_seat.num;
            updateCountOption.junior_seat.option_code = option_code;
            updateCountOption.junior_seat.price = price;
            break
          default:
            break
        }

        // 合計金額更新
        const total = updateDetailTotalPrice(detailData.planDetail?.total_price ?? 0, updateCountOption);
        return {...detailData, count_option: updateCountOption, total: total};
      }

      return detailData;
    });
    updateCarRentalData.detail = updateDetail;
    setCarRentalPlanParams({...updateCarRentalData});
  }

  const updateAnshinPack = (anshin_pack: string, detailIndex: number, price: number) => {
    const updateCarRentalData = _.cloneDeep(carRentalPlanParams);
    const updateDetail = updateCarRentalData.detail.map((detailData, index) => {
      if (index === detailIndex) {
        const updateCountOption = _.cloneDeep(detailData.count_option);
        updateCountOption.anshin.used = false;
        updateCountOption.anshin_premium.used = false;
        switch (anshin_pack){
          case '安心パック':
          case t('CarRental.安心パックValue'):
            updateCountOption.anshin.used = true;
            updateCountOption.anshin.option_name = anshin_pack;
            updateCountOption.anshin.price = price
            break;
          case 'プレミアム安心パック':
          case t('CarRental.プレミアム安心パックValue'):
            updateCountOption.anshin_premium.used = true;
            updateCountOption.anshin_premium.option_name = anshin_pack;
            updateCountOption.anshin_premium.price = price;
            break;
          default:
            break
        }
        const total = updateDetailTotalPrice(detailData.planDetail?.total_price ?? 0, updateCountOption);
        return {...detailData, count_option: updateCountOption, total: total};
      }
      return detailData;
    });
    updateCarRentalData.detail = updateDetail;
    setCarRentalPlanParams({...updateCarRentalData});
  }

  const cancelCarRental = (index: number) => {
    const updateCarRentalData = _.cloneDeep(carRentalPlanParams);
    const updateDetail = updateCarRentalData.detail.filter((detail, i) => i !== index);
    updateCarRentalData.detail = updateDetail;
    setCarRentalPlanParams({...updateCarRentalData});
    if (updateCarRentalData.detail.length === 0) {
      setStep(1);
    }
  }

  const updateDetailTotalPrice = (total: number, countOption: carRentalCountOption) => {
    return total + countOption.baby_seat.price + countOption.child_seat.price + countOption.junior_seat.price + countOption.anshin.price + countOption.anshin_premium.price;
  }
  
  const callBackModal = () => {
    
    if (carRentalPlanParams.access_type === 'airport' && (returnFlightNumber === "" || departureFlightNumber === "")) {
      const departureFlightNumberMessage = departureFlightNumber !== "" ? "" : t("RentalModal.到着便の便名を入力してください。")
      const returnFlightNumberMessage = returnFlightNumber !== "" ? "" : t("RentalModal.出発便の便名を入力してください。")
      const br = (returnFlightNumber === "" && departureFlightNumber === "") ? "\n" : "";
      alert(departureFlightNumberMessage + br + returnFlightNumberMessage);
      return
    }
    
    if (carRentalPlanParams.detail.length > 0) {
      const carDetail = carRentalPlanParams.detail;
      const carNums = carDetail.length;
      const maxChildSheet = carNums * perSheetMaxNum;
      const carOption = {
        "baby_seat": carRentalPlanParams.detail.map((detail) => detail.count_option.baby_seat.num).reduce((a, b) => a + b, 0),
        "child_seat": carRentalPlanParams.detail.map((detail) => detail.count_option.child_seat.num).reduce((a, b) => a + b, 0),
        "junior_seat": carRentalPlanParams.detail.map((detail) => detail.count_option.junior_seat.num).reduce((a, b) => a + b, 0),
      };
      const childSheet = carOption.baby_seat + carOption.child_seat + carOption.junior_seat;
      if (maxChildSheet >= childSheet) {
        // 在庫チェック
        (async () => {
            const selectCarRental = {...planSelectFormData.carRental[updateIndex]};

            setLoading(true);

            const carRentalField:reserveRentalCar = {
              id: "carRental_" + carRentalPlanParams.departure_date,
              "reservation_name1": i18n.language === LANGUAGES.JA ? "オキナワ" : "WANG",      // 日本語はカタカナ、多言語は英字
              "reservation_name2": i18n.language === LANGUAGES.JA ? "タロウ" : "TAILANG",     // 日本語はカタカナ、多言語は英字
              "reservation_tel": "0801111111",
              "reservation_email": "noreply@example.com",
              "departure_date": carRentalPlanParams.departure_date,
              "departure_time": carRentalPlanParams.departure_time,
              "return_date": carRentalPlanParams.return_date,
              "return_time": carRentalPlanParams.return_time,
              "access_type": carRentalPlanParams.access_type,
              departure_date_label: carRentalPlanParams.departure_date_label,
              departure_office_label: carRentalPlanParams.departure_office_label,
              return_date_label: carRentalPlanParams.return_date_label,
              return_office_label: carRentalPlanParams.return_office_label,
              count_option: carOption,
              car_detail: carRentalPlanParams.detail,
              "total_price": carRentalPlanParams.total.toString(),
              "detail": [],
              "order": 0,
              language_code: i18n.language,
            }

            for (let c = 0; c < carRentalPlanParams.detail.length; c++) {
              const detail = carRentalPlanParams.detail[c];
              let total = 0;
              const carRentalData: reserveCarRentalDetail = {
                "grade": detail.planDetail?.class_name ?? "",
                "car_model": detail.planDetail?.class_model ?? "",
                "class": detail.planDetail?.class_name ?? "",
                "classModel": detail.planDetail?.class_model ?? "",
                "plan_code": detail.plan_code,
                "departure_office_code": carRentalPlanParams.departure_office_code,
                "return_office_code": carRentalPlanParams.return_office_code,
                "departure_date": carRentalPlanParams.departure_date,
                "departure_time": carRentalPlanParams.departure_time,
                "return_date": carRentalPlanParams.return_date,
                "return_time": carRentalPlanParams.return_time,
                "access_type": carRentalPlanParams.access_type,
                flight_arrival_no: carRentalPlanParams.flight_arrival_no,
                flight_arrival_code: carRentalPlanParams.flight_arrival_code,
                flight_departure_no: carRentalPlanParams.flight_departure_no,
                flight_departure_code: carRentalPlanParams.flight_departure_code,
                "joshaadt": "1",
                "joshainf": "",
                "basic_price": detail.planDetail?.basic_price.toString() ?? "",
                "indemnity_price": detail.planDetail?.indemnity_price?.toString() ?? "",
                "anshin_price": "",
                "anshinpremium_price": "",
                "total_price": 0,
                "option_list": []
              };
              total += detail.planDetail?.total_price ?? 0;

              // 安心パック設定
              if (detail.count_option.anshin.used) {
                const anshinName = detail.count_option.anshin.option_name
                const anshinData = detail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
                if (anshinData) {
                  const anshinPrice = anshinData.anshin_price;
                  total += anshinPrice;
                  carRentalData.anshin_price = anshinPrice.toString()
                }
              }

              if (detail.count_option.anshin_premium.used) {
                const anshinName = detail.count_option.anshin_premium.option_name
                const anshinData = detail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
                if (anshinData) {
                  const anshinPrice = anshinData.anshin_price;
                  total += anshinPrice;
                  carRentalData.anshinpremium_price = anshinPrice.toString()
                }
              }

              // オプション設定
              if (detail.count_option.baby_seat.num > 0) {
                const optionCode = detail.count_option.baby_seat.option_code;
                const babySeatData = detail.planDetail?.option_list.find((option) => option.option_code === optionCode);
                if (babySeatData) {
                  const num = detail.count_option.baby_seat.num;
                  const babySeatPrice = babySeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
                  total += babySeatPrice;
                  carRentalData.option_list.push({
                    "option_code": babySeatData.option_code,
                    "option_name": babySeatData.option_name,
                    "num": num.toString(),
                    "price": babySeatPrice.toString(),
                  });
                }
              }

              if (detail.count_option.child_seat.num > 0) {
                const optionCode = detail.count_option.child_seat.option_code;
                const childSeatData = detail.planDetail?.option_list.find((option) => option.option_code === optionCode);
                if (childSeatData) {
                  const num = detail.count_option.child_seat.num;
                  const babySeatPrice = childSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
                  total += babySeatPrice;
                  carRentalData.option_list.push({
                    "option_code": childSeatData.option_code,
                    "option_name": childSeatData.option_name,
                    "num": num.toString(),
                    "price": babySeatPrice.toString(),
                  });
                }
              }

              if (detail.count_option.junior_seat.num > 0) {
                const optionCode = detail.count_option.junior_seat.option_code;
                const juniorSeatData = detail.planDetail?.option_list.find((option) => option.option_code === optionCode);
                if (juniorSeatData) {
                  const num = detail.count_option.junior_seat.num;
                  const babySeatPrice = juniorSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
                  total += babySeatPrice;
                  carRentalData.option_list.push({
                    "option_code": juniorSeatData.option_code,
                    "option_name": juniorSeatData.option_name,
                    "num": num.toString(),
                    "price": babySeatPrice.toString(),
                  });
                }
              }
              carRentalData.total_price = total;
              carRentalField.detail.push(carRentalData);
            }
            const resultStock = await carRentalStockAPI({data: carRentalField});
            setLoading(false);
            if (resultStock.status === 200 && resultStock.result) {
              const updateCarRentalParams = _.cloneDeep(carRentalPlanParams);
              if (isUpdate && carRentalPlanParams.detail.length === 1) {
                const planDetail = updateCarRentalParams.detail[0];
                const optionTotal = planDetail.count_option.baby_seat.price + planDetail.count_option.child_seat.price + planDetail.count_option.junior_seat.price + planDetail.count_option.anshin.price + planDetail.count_option.anshin_premium.price;
                const planTotal = planDetail.planDetail?.total_price ?? 0
                updateCarRentalParams.total = planTotal + optionTotal;
                planSelectFormData.carRental[updateIndex] = {...updateCarRentalParams};
              } else {
                carRentalPlanParams.detail.forEach((detail, index) => {
                  updateCarRentalParams.detail = [detail];
                  const optionTotal = detail.count_option.baby_seat.price + detail.count_option.child_seat.price + detail.count_option.junior_seat.price + detail.count_option.anshin.price + detail.count_option.anshin_premium.price;
                  const planTotal = detail.planDetail?.total_price ?? 0
                  updateCarRentalParams.total = planTotal + optionTotal;
                  if (isUpdate && index === 0) {
                    planSelectFormData.carRental[updateIndex] = {...updateCarRentalParams};
                  } else {
                    planSelectFormData.carRental.push({...updateCarRentalParams});
                  }
                });
              }
              updateCarRental(planSelectFormData)
            } else {
              alert(t("RentalModal.選択したレンタカーの設定の中に在庫切れのものがあります。再度選択をお願いします。"));
              // alert("您所設定的期間裡有已無空位日期，請再次選擇您所希望的日期。");
            }
          
          }
        )()
      } else {
        alert(t("RentalModal.チャイルドシートはレンタカー１台に付き３台までの設定となります。"))
        // alert("一台出租車中最多可設定3個幼兒安全座椅。")
      }
      
    } else {
      alert(t("RentalModal.レンタカーを選択してください。"));
      // alert("請選擇出租車。");
    }
  }
  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));

  const closeModal = () => {
    setStep(1);
    callback();
  };

  return (
    <>
    {/* モーダル：フライト選択 */}
    <div>
      <Modal
        open={isShow}
        onClose={closeModal}
        className="modal"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div id="modal-section-rental-car" className="modal-fixed bgc-gray">
          {/* ローディングの表示(APIのレスポンス待ち) */}
          <Backdrop className={classesForLoading.backdrop} open={isLoading}>
            <CircularProgress color="inherit"/>
          </Backdrop>
          <Backdrop className={classesForLoading.backdrop} open={isLoadingStep2}>
            <CircularProgress color="inherit"/>
          </Backdrop>
          {/* <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-text">請選擇租車</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>關閉此頁
              </Button>
              </p>
          </div> */}
          <div className="modal-fixed-heading">
            <p className="modal-fixed-heading-back">
              <button className={"button-clear" + (step === 2 ? " d-b" : " d-n")} onClick={() => setStep(1)}><i
                className="icon-left-arrow-blue fz-20"></i></button>
            </p>
            <p className="modal-fixed-heading-text">{t("RentalModal.レンタカー選択")}</p>
            <p className="modal-fixed-close">
              <Button onClick={callback} className="modal-fixed-close-button">
                <i className="modal-fixed-close-button-icon"></i>{t('Common.閉じる')}
              </Button>
            </p>
          </div>

          <div className="modal-fixed-content">

            {/* STEP */}
            <div className="step-option no-padding">
              <ul className="step-option-list">
                <li key={"car_rental_step1"}
                    className={step === 1 ? "step-option-list-item active" : "step-option-list-item"}>{t('CarRental.車種・台数選択')}
                </li>
                <li key={"car_rental_step2"}
                    className={step === 2 ? "step-option-list-item active" : "step-option-list-item"}>{t('CarRental.オプション選択')}
                </li>
              </ul>
            </div>

            <RentalModalStep1
              step={step}
              departureDate={departureDate}
              changeDepartureDate={changeDepartureDate}
              planSelectFormData={planSelectFormData}
              isLoading={isLoading}
              borrowingTimeOpen={borrowingTimeOpen}
              handleBorrowingTimeClickListItem={handleBorrowingTimeClickListItem}
              borrowingTimeOptions={borrowingTimeOptions}
              selectedBorrowingTimeIndex={selectedBorrowingTimeIndex}
              borrowingTimeAnchorEl={borrowingTimeAnchorEl}
              borrowingTimeHandleClose={borrowingTimeHandleClose}
              handleBorrowingTimeClick={handleBorrowingTimeClick}
              borrowingPlaceOpen={borrowingPlaceOpen}
              handleBorrowingPlaceClickListItem={handleBorrowingPlaceClickListItem}
              selectedBorrowingPlaceIndex={selectedBorrowingPlaceIndex}
              borrowingPlaceAnchorEl={borrowingPlaceAnchorEl}
              borrowingPlaceHandleClose={borrowingPlaceHandleClose}
              handleBorrowingPlaceClick={handleBorrowingPlaceClick}
              returnDate={returnDate}
              changeReturnDate={changeReturnDate}
              returnTimeOpen={returnTimeOpen}
              handleReturnTimeClickListItem={handleReturnTimeClickListItem}
              returnTimeOptions={returnTimeOptions}
              selectedReturnTimeIndex={selectedReturnTimeIndex}
              returnTimeAnchorEl={returnTimeAnchorEl}
              returnTimeHandleClose={returnTimeHandleClose}
              handleReturnTimeClick={handleReturnTimeClick}
              returnPlaceOpen={returnPlaceOpen}
              handleReturnPlaceClickListItem={handleReturnPlaceClickListItem}
              selectedReturnPlaceIndex={selectedReturnPlaceIndex}
              returnPlaceAnchorEl={returnPlaceAnchorEl}
              returnPlaceHandleClose={returnPlaceHandleClose}
              handleReturnPlaceClick={handleReturnPlaceClick}
              searchCarRental={searchCarRental}
              rentalRows={rentalRows}
              carRentalPlanParams={carRentalPlanParams}
              addSaveDataByStep1={addSaveDataByStep1}
              callBackModal={() => {
                nextStep(2)
              }}
              flights={flights}
              borrowingPlaceOptions={borrowingPlaceOptions}
              returnPlaceOptions={returnPlaceOptions}
              accessTypes={accessTypes}
              accessTypeOpen={accessTypeOpen}
              handleAccessTypes={handleAccessTypes}
              accessTypeAnchorEl={accessTypeAnchorEl}
              selectAccessTypeIndex={selectAccessTypeIndex}
              handleAccessTypesClose={handleAccessTypesClose}
              handleAccessTypesClick={handleAccessTypesClick}
              departureFlightsOpen={departureFlightsOpen}
              handleDepartureFlights={handleDepartureFlights}
              selectDepartureFlightsIndex={selectDepartureFlightsIndex}
              departureFlightsAnchorEl={departureFlightsAnchorEl}
              handleDepartureFlightsClose={handleDepartureFlightsClose}
              handleDepartureFlightsClick={handleDepartureFlightsClick}
              departureFlightNumber={departureFlightNumber}
              handleDepartureFlightNumber={handleDepartureFlightNumber}
              returnFlightsOpen={returnFlightsOpen}
              handleReturnFlights={handleReturnFlights}
              selectReturnFlightsIndex={selectReturnFlightsIndex}
              returnFlightsAnchorEl={returnFlightsAnchorEl}
              handleReturnFlightsClose={handleReturnFlightsClose}
              handleReturnFlightsClick={handleReturnFlightsClick}
              returnFlightNumber={returnFlightNumber}
              handleReturnFlightNumber={handleReturnFlightNumber}
            ></RentalModalStep1>

            <RentalModalStep2
              step={step}
              carRentalSaveData={carRentalPlanParams}
              updateChildSeatCount={updateChildSeatCount}
              updateAnshinPack={updateAnshinPack}
              addData={callBackModal}
              prevStep={() => {
                setStep(1)
              }}
              cancel={cancelCarRental}
            ></RentalModalStep2>
          </div>

        </div>
      </Modal>
    </div>
      {/* モーダルレンタカー変更 */}
    </>
  );
};

export default RentalModal;
