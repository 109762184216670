import React, { useEffect, useState } from "react";

import { Link as Scroll } from 'react-scroll';

//MainTemplate
import MainTemplate from "./MainTemplate";

import { LANGUAGES } from "../constants/languages";
import { useTranslation } from "react-i18next";


//scss
import "../scss/common.scss";
import "../scss/component.scss";

const Agreement: React.FC = () => {

  const [ languageCode, setLanguageCode ] = useState<string>("tw");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);
  
  return (
    <MainTemplate title={t("Agreement.旅行業約款")} logo={undefined} supportDial={undefined} sellerId={undefined} planSelectFormData={undefined}>
        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("Agreement.旅行業約款")}</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="pt-24 pt-80-md">
            <div className="wrap-content-900">

              <p hidden={languageCode !== LANGUAGES.JA}>{t("Agreement.当社はお客様との旅行契約につき以下の当社旅行業約款に基づき、お申込みを受け付けます。")}</p>

              {/* ローカルナビ（ここから） */}
              <section className="parts-loaclnavi mt-32-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（募集型企画旅行契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_01" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第一章　総則")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_02" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第二章　契約の締結")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_03" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第三章　契約の変更")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_04" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第四章　契約の解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_05" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第五章　団体・グループ契約")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_06" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第六章　旅程管理")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_07" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第七章　責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_08" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第八章　弁済業務保証金")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="parts-loaclnavi mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（受注型企画旅行契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_09" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第一章　総則")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_10" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第二章　契約の締結")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_11" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第三章　契約の変更")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_12" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第四章　契約の解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_13" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第五章　団体・グループ契約")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_14" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第六章　旅程管理")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_15" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第七章　責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_16" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第八章　弁済業務保証金")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="parts-loaclnavi mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（事業者を相手方とする受注型企画旅行契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_17" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第一章　総則")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_18" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第二章　契約の締結")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_19" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第三章　契約の変更")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_20" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第四章　契約の解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_21" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第五章　旅程管理")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_22" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第六章　責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_23" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第七章　弁済業務保証金")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="parts-loaclnavi mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（特別補償規程）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_24" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第一章　補償金等の支払い")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_25" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第二章　補償金等を支払わない場合")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_26" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第三章　補償金等の種類及び支払額")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_27" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第四章　事故の発生及び補償金等の請求の手続")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_28" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第五章　携帯品損害補償")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="parts-loaclnavi mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（手配旅行契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_29" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第一章　総則")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_30" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第二章　契約の成立")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_31" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第三章　契約の変更及び解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_32" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第四章　旅行代金")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_33" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第五章　団体・グループ手配")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_34" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第六章　責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_35" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.第七章　弁済業務保証金")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="parts-loaclnavi mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（渡航手続代行契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_36" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.渡航手続代行契約")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>
              
              <section className="parts-loaclnavi mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（旅行相談契約の部）")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_37" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Agreement.旅行相談契約")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>
              {/* ローカルナビ（ここまで） */}


              {/* 旅行業約款（募集型企画旅行契約の部）ここから */}

              {/* section: 第一章　総則 */}
              <section id="sec_01" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（募集型企画旅行契約の部）")}</h2>
                <h3 className="mb-12">{t("Agreement.第一章　総則")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.適用範囲")}</p>
                  <p className="mb-4">{t("Agreement.第一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社が旅行者との間で締結する募集型企画旅行に関する契約（以下「募集型企画旅行契約」といいます。）は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。")}</li>
                    <li>{t("Agreement.当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。")}</li>
                  </ul>
                
                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.用語の定義")}</p>
                  <p className="mb-4">{t("Agreement.第二条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.この約款で「募集型企画旅行」とは、当社が、旅行者の募集のためにあらかじめ、旅行の目的地及び日程、旅行者が提供を受けることができる運送又は宿泊のサービスの内容並びに旅行者が当社に支払うべき旅行代金の額を定めた旅行に関する計画を作成し、これにより実施する旅行をいいます。")}</li>
                    <li>{t("Agreement.この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。")}</li>
                    <li>{t("Agreement.この部で「通信契約」とは、当社が、当社又は当社の募集型企画旅行を当社を代理して販売する会社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員との間で電話、郵便、ファクシミリその他の通信手段による申込みを受けて締結する募集型企画旅行契約であって、当社が旅行者に対して有する募集型企画旅行契約に基づく旅行代金等に係る債権又は債務を、当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、旅行者があらかじめ承諾し、かつ当該募集型企画旅行契約の旅行代金等を第十二条第二項、第十六条第一項後段、第十九条第二項に定める方法により支払うことを内容とする募集型企画旅行契約をいいます。")}</li>
                    <li>{t("Agreement.この部で「電子承諾通知」とは、契約の申込みに対する承諾の通知であって、情報通信の技術を利用する方法のうち当社又は当社の募集型企画旅行を当社を代理して販売する会社が使用する電子計算機、ファクシミリ装置、テレックス又は電話機（以下「電子計算機等」といいます。）と旅行者が使用する電子計算機等とを接続する電気通信回線を通じて送信する方法により行うものをいいます。")}</li>
                    <li>{t("Agreement.この約款で「カード利用日」とは、旅行者又は当社が募集型企画旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます。")}</li>
                  </ul>
                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.旅行契約の内容")}</p>
                  <p className="mb-4">{t("Agreement.第三条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、募集型企画旅行契約において、旅行者が当社の定める旅行日程に従って、運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように、手配し、旅程を管理することを引き受けます。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.手配代行者")}</p>
                  <p className="mb-4">{t("Agreement.第四条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社は、募集型企画旅行契約の履行に当たって、手配の全部又は一部を本邦内又は本邦外の他の旅行業者、手配を業として行う者その他の補助者に代行させることがあります。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第二章　契約の締結 */}
              <section id="sec_02" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第二章　契約の締結")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.契約の申込み")}</p>
                  <p className="mb-4">{t("Agreement.第五条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社に募集型企画旅行契約の申込みをしようとする旅行者は、当社所定の申込書（以下「申込書」といいます。）に所定の事項を記入の上、当社が別に定める金額の申込金とともに、当社に提出しなければなりません。")}</li>
                    <li>{t("Agreement.当社に通信契約の申込みをしようとする旅行者は、前項の規定にかかわらず、申込みをしようとする募集型企画旅行の名称、旅行開始日、会員番号その他の事項（以下次条において「会員番号等」といいます。）を当社に通知しなければなりません。")}</li>
                    <li>{t("Agreement.第一項の申込金は、旅行代金又は取消料若しくは違約料の一部として取り扱います。")}</li>
                    <li>{t("Agreement.募集型企画旅行の参加に際し、特別な配慮を必要とする旅行者は、契約の申込時に申し出てください。このとき、当社は可能な範囲内でこれに応じます。")}</li>
                    <li>{t("Agreement.前項の申出に基づき、当社が旅行者のために講じた特別な措置に要する費用は、旅行者の負担とします。")}</li>
                  </ul>
                
                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.電話等による予約")}</p>
                  <p className="mb-4">{t("Agreement.第六条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、電話、郵便、ファクシミリその他の通信手段による募集型企画旅行契約の予約を受け付けます。この場合、予約の時点では契約は成立しておらず、旅行者は、当社が予約の承諾の旨を通知した後、当社が定める期間内に、前条第一項又は第二項の定めるところにより、当社に申込書と申込金を提出又は会員番号等を通知しなければなりません。")}</li>
                    <li>{t("Agreement.前項の定めるところにより申込書と申込金の提出があったとき又は会員番号等の通知があったときは、募集型企画旅行契約の締結の順位は、当該予約の受付の順位によることとなります。")}</li>
                    <li>{t("Agreement.旅行者が第一項の期間内に申込金を提出しない場合又は会員番号等を通知しない場合は、当社は、予約がなかったものとして取り扱います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.契約締結の拒否")}</p>
                  <p className="mb-4">{t("Agreement.第七条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      {t("Agreement.当社は、次に掲げる場合において、募集型企画旅行契約の締結に応じないことがあります。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.当社があらかじめ明示した性別、年齢、資格、技能その他の参加旅行者の条件を満たしていないとき。")}</li>
                        <li>{t("Agreement.応募旅行者数が募集予定数に達したとき。")}</li>
                        <li>{t("Agreement.旅行者が他の旅行者に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあるとき。")}</li>
                        <li>{t("Agreement.通信契約を締結しようとする場合であって、旅行者の有するクレジットカードが無効である等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できないとき。")}</li>
                        <li>{t("Agreement.旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。")}</li>
                        <li>{t("Agreement.旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。")}</li>
                        <li>{t("Agreement.旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。")}</li>
                        <li>{t("Agreement.その他当社の業務上の都合があるとき。")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.契約の成立時期")}</p>
                  <p className="mb-4">{t("Agreement.第八条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.募集型企画旅行契約は、当社が契約の締結を承諾し、第五条第一項の申込金を受理した時に成立するものとします。")}</li>
                    <li>{t("Agreement.通信契約は、前項の規定にかかわらず、当社が契約の締結を承諾する旨の通知を発した時に成立するものとします。ただし、当該契約において電子承諾通知を発する場合は、当該通知が旅行者に到達した時に成立するものとします。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.契約書面の交付")}</p>
                  <p className="mb-4">{t("Agreement.第九条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、前条の定める契約の成立後速やかに、旅行者に、旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面（以下「契約書面」といいます。）を交付します。")}</li>
                    <li>{t("Agreement.当社が募集型企画旅行契約により手配し旅程を管理する義務を負う旅行サービスの範囲は、前項の契約書面に記載するところによります。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.確定書面")}</p>
                  <p className="mb-4">{t("Agreement.第十条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.前条第一項の契約書面において、確定された旅行日程、運送若しくは宿泊機関の名称を記載できない場合には、当該契約書面において利用予定の宿泊機関及び表示上重要な運送機関の名称を限定して列挙した上で、当該契約書面交付後、旅行開始日の前日（旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に募集型企画旅行契約の申込みがなされた場合にあっては、旅行開始日）までの当該契約書面に定める日までに、これらの確定状況を記載した書面（以下「確定書面」といいます。）を交付します。")}</li>
                    <li>{t("Agreement.前項の場合において、手配状況の確認を希望する旅行者から問い合わせがあったときは、確定書面の交付前であっても、当社は迅速かつ適切にこれに回答します。")}</li>
                    <li>{t("Agreement.第一項の確定書面を交付した場合には、前条第二項の規定により当社が手配し旅程を管理する義務を負う旅行サービスの範囲は、当該確定書面に記載するところに特定されます。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.情報通信の技術を利用する方法")}</p>
                  <p className="mb-4">{t("Agreement.第十一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、あらかじめ旅行者の承諾を得て、募集型企画旅行契約を締結しようとするときに旅行者に交付する旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面、契約書面又は確定書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項（以下この条において「記載事項」といいます。）を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。")}</li>
                    <li>{t("Agreement.前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル（専ら当該旅行者の用に供するものに限ります。）に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.旅行代金")}</p>
                  <p className="mb-4">{t("Agreement.第十二条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.旅行者は、旅行開始日までの契約書面に記載する期日までに、当社に対し、契約書面に記載する金額の旅行代金を支払わなければなりません。")}</li>
                    <li>{t("Agreement.通信契約を締結したときは、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして契約書面に記載する金額の旅行代金の支払いを受けます。また、カード利用日は旅行契約成立日とします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第三章　契約の変更 */}           
              <section id="sec_03" className="mt-48-md mt-24 pt-16">
              <h3 className="mb-12">{t("Agreement.第三章　契約の変更")}</h3>
              <div className="card large-side-medium">
                <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.契約内容の変更")}</p>
                <p className="mb-4">{t("Agreement.第十三条")}</p>
                <ul className="list-number-dot c-blue_gray">
                  <li>{t("Agreement.当社は、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供その他の当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、旅行者にあらかじめ速やかに当該事由が関与し得ないものである理由及び当該事由との因果関係を説明して、旅行日程、旅行サービスの内容その他の募集型企画旅行契約の内容（以下「契約内容」といいます。）を変更することがあります。ただし、緊急の場合において、やむを得ないときは、変更後に説明します。")}</li>
                </ul>
              
                <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">{t("Agreement.旅行代金の額の変更")}</p>
                <p className="mb-4">{t("Agreement.第十四条")}</p>
                <ul className="list-number-dot c-blue_gray">
                  <li>{t("Agreement.募集型企画旅行を実施するに当たり利用する運送機関について適用を受ける運賃・料金（以下この条において「適用運賃・料金」といいます。）が、著しい経済情勢の変化等により、募集型企画旅行の募集の際に明示した時点において有効なものとして公示されている適用運賃・料金に比べて、通常想定される程度を大幅に超えて増額又は減額される場合においては、当社は、その増額又は減額される金額の範囲内で旅行代金の額を増加し、又は減少することができます。")}</li>
                  <li>{t("Agreement.当社は、前項の定めるところにより旅行代金を増額するときは、旅行開始日の前日から起算してさかのぼって十五日目に当たる日より前に旅行者にその旨を通知します。")}</li>
                  <li>{t("Agreement.当社は、第一項の定める適用運賃・料金の減額がなされるときは、同項の定めるところにより、その減少額だけ旅行代金を減額します。")}</li>
                  <li>{t("Agreement.当社は、前条の規定に基づく契約内容の変更により旅行の実施に要する費用（当該契約内容の変更のためにその提供を受けなかった旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を含みます。）の減少又は増加が生じる場合（費用の増加が、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによる場合を除きます。）には、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することがあります。")}</li>
                  <li>{t("Agreement.当社は、運送・宿泊機関等の利用人員により旅行代金が異なる旨を契約書面に記載した場合において、募集型企画旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人員が変更になったときは、契約書面に記載したところにより旅行代金の額を変更することがあります。")}</li>
                </ul>

                <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅行者の交替")}</p>
                <p className="mb-4">{t("Agreement.第十五条")}</p>
                <ul className="list-number-dot c-blue_gray mb-0">
                  <li>{t("Agreement.当社と募集型企画旅行契約を締結した旅行者は、当社の承諾を得て、契約上の地位を第三者に譲り渡すことができます。")}</li>
                  <li>{t("Agreement.旅行者は、前項に定める当社の承諾を求めようとするときは、当社所定の用紙に所定の事項を記入の上、所定の金額の手数料とともに、当社に提出しなければなりません。")}</li>
                  <li>{t("Agreement.第一項の契約上の地位の譲渡は、当社の承諾があった時に効力を生ずるものとし、以後、旅行契約上の地位を譲り受けた第三者は、旅行者の当該募集型企画旅行契約に関する一切の権利及び義務を承継するものとします。")}</li>
                </ul>
              </div>
            </section>

              {/* section: 第四章　契約の解除 */}           
              <section id="sec_04" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第四章　契約の解除")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅行者の解除権")}</p>
                  <p className="mb-4">{t("Agreement.第十六条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者は、いつでも別表第一に定める取消料を当社に支払って募集型企画旅行契約を解除することができます。通信契約を解除する場合にあっては、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして取消料の支払いを受けます。")}</li>
                    <li>
                      {t("Agreement.旅行者は、次に掲げる場合において、前項の規定にかかわらず、旅行開始前に取消料を支払うことなく募集型企画旅行契約を解除することができます。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.当社によって契約内容が変更されたとき。ただし、その変更が別表第二上欄に掲げるものその他の重要なものであるときに限ります。")}</li>
                        <li>{t("Agreement.第十四条第一項の規定に基づいて旅行代金が増額されたとき。")}</li>
                        <li>{t("Agreement.天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。")}</li>
                        <li>{t("Agreement.当社が旅行者に対し、第十条第一項の期日までに、確定書面を交付しなかったとき。")}</li>
                        <li>{t("Agreement.当社の責に帰すべき事由により、契約書面に記載した旅行日程に従った旅行の実施が不可能となったとき。")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.旅行者は、旅行開始後において、当該旅行者の責に帰すべき事由によらず契約書面に記載した旅行サービスを受領することができなくなったとき又は当社がその旨を告げたときは、第一項の規定にかかわらず、取消料を支払うことなく、旅行サービスの当該受領することができなくなった部分の契約を解除することができます。")}</li>
                    <li>{t("Agreement.前項の場合において、当社は、旅行代金のうち旅行サービスの当該受領することができなくなった部分に係る金額を旅行者に払い戻します。ただし、前項の場合が当社の責に帰すべき事由によらない場合においては、当該金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の解除権等－旅行開始前の解除")}</p>
                  <p className="mb-4">{t("Agreement.第十七条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      {t("Agreement.当社は、次に掲げる場合において、旅行者に理由を説明して、旅行開始前に募集型企画旅行契約を解除することがあります。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者が当社があらかじめ明示した性別、年齢、資格、技能その他の参加旅行者の条件を満たしていないことが判明したとき。")}</li>
                        <li>{t("Agreement.旅行者が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められるとき。")}</li>
                        <li>{t("Agreement.旅行者が他の旅行者に迷惑を及ぼし、又は団体旅行の円滑な実施を妨げるおそれがあると認められるとき。")}</li>
                        <li>{t("Agreement.旅行者が、契約内容に関し合理的な範囲を超える負担を求めたとき。")}</li>
                        <li>{t("Agreement.旅行者の数が契約書面に記載した最少催行人員に達しなかったとき。")}</li>
                        <li>{t("Agreement.スキーを目的とする旅行における必要な降雪量等の旅行実施条件であって契約の締結の際に明示したものが成就しないおそれが極めて大きいとき。")}</li>
                        <li>{t("Agreement.天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、契約書面に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。")}</li>
                        <li>{t("Agreement.通信契約を締結した場合であって、旅行者の有するクレジットカードが無効になる等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約にしたがって決済できなくなったとき。")}</li>
                        <li>{t("Agreement.旅行者が第七条第五号から第七号までのいずれかに該当することが判明したとき。")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.旅行者が第十二条第一項の契約書面に記載する期日までに旅行代金を支払わないときは、当該期日の翌日において旅行者が募集型企画旅行契約を解除したものとします。この場合において、旅行者は、当社に対し、前条第一項に定める取消料に相当する額の違約料を支払わなければなりません。")}</li>
                    <li>{t("Agreement.当社は、第一項第五号に掲げる事由により募集型企画旅行契約を解除しようとするときは、旅行開始日の前日から起算してさかのぼって、国内旅行にあっては十三日目（日帰り旅行については、三日目）に当たる日より前に、海外旅行にあっては二十三日目（別表第一に規定するピーク時に旅行を開始するものについては三十三日目）に当たる日より前に、旅行を中止する旨を旅行者に通知します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の解除権－旅行開始後の解除")}</p>
                  <p className="mb-4">{t("Agreement.第十八条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      {t("Agreement.当社は、次に掲げる場合において、旅行開始後であっても、旅行者に理由を説明して、募集型企画旅行契約の一部を解除することがあります。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者が病気、必要な介助者の不在その他の事由により旅行の継続に耐えられないとき。")}</li>
                        <li>{t("Agreement.旅行者が旅行を安全かつ円滑に実施するための添乗員その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等により団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。")}</li>
                        <li>{t("Agreement.旅行者が第七条第五号から第七号までのいずれかに該当することが判明したとき。")}</li>
                        <li>{t("Agreement.天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社が前項の規定に基づいて募集型企画旅行契約を解除したときは、当社と旅行者との間の契約関係は、将来に向かってのみ消滅します。この場合において、旅行者が既に提供を受けた旅行サービスに関する当社の債務については、有効な弁済がなされたものとします。")}</li>
                    <li>{t("Agreement.前項の場合において、当社は、旅行代金のうち旅行者がいまだその提供を受けていない旅行サービスに係る部分に係る金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅行代金の払戻し")}</p>
                  <p className="mb-4">{t("Agreement.第十九条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により募集型企画旅行契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し当該金額を払い戻します。")}</li>
                    <li>{t("Agreement.当社は、旅行者と通信契約を締結した場合であって、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により通信契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、提携会社のカード会員規約に従って、旅行者に対し当該金額を払い戻します。この場合において、当社は、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し払い戻すべき額を通知するものとし、旅行者に当該通知を行った日をカード利用日とします。")}</li>
                    <li>{t("Agreement.前二項の規定は第二十七条又は第三十条第一項に規定するところにより旅行者又は当社が損害賠償請求権を行使することを妨げるものではありません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.契約解除後の帰路手配")}</p>
                  <p className="mb-4">{t("Agreement.第二十条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社は、第十八条第一項第一号又は第四号の規定によって旅行開始後に募集型企画旅行契約を解除したときは、旅行者の求めに応じて、旅行者が当該旅行の出発地に戻るために必要な旅行サービスの手配を引き受けます。")}</li>
                    <li>{t("Agreement.前項の場合において、出発地に戻るための旅行に要する一切の費用は、旅行者の負担とします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第五章　団体・グループ契約 */}           
              <section id="sec_05" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第五章　団体・グループ契約")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.団体・グループ契約")}</p>
                  <p className="mb-4">{t("Agreement.第二十一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、同じ行程を同時に旅行する複数の旅行者がその責任ある代表者（以下「契約責任者」といいます。）を定めて申し込んだ募集型企画旅行契約の締結については、本章の規定を適用します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.契約責任者")}</p>
                  <p className="mb-4">{t("Agreement.第二十二条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社は、特約を結んだ場合を除き、契約責任者はその団体・グループを構成する旅行者（以下「構成者」といいます。）の募集型企画旅行契約の締結に関する一切の代理権を有しているものとみなし、当該団体・グループに係る旅行業務に関する取引は、当該契約責任者との間で行います。")}</li>
                    <li>{t("Agreement.契約責任者は、当社が定める日までに、構成者の名簿を当社に提出しなければなりません。")}</li>
                    <li>{t("Agreement.当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。")}</li>
                    <li>{t("Agreement.当社は、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第六章　旅程管理 */}           
              <section id="sec_06" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第六章　旅程管理")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅程管理")}</p>
                  <p className="mb-4">{t("Agreement.第二十三条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      {t("Agreement.当社は、旅行者の安全かつ円滑な旅行の実施を確保することに努力し、旅行者に対し次に掲げる業務を行います。ただし、当社が旅行者とこれと異なる特約を結んだ場合には、この限りではありません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者が旅行中旅行サービスを受けることができないおそれがあると認められるときは、募集型企画旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講ずること。")}</li>
                        <li>{t("Agreement.前号の措置を講じたにもかかわらず、契約内容を変更せざるを得ないときは、代替サービスの手配を行うこと。この際、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努めること、また、旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努めること等、契約内容の変更を最小限にとどめるよう努力すること。")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の指示")}</p>
                  <p className="mb-4">{t("Agreement.第二十四条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者は、旅行開始後旅行終了までの間において、団体で行動するときは、旅行を安全かつ円滑に実施するための当社の指示に従わなければなりません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.添乗員等の業務")}</p>
                  <p className="mb-4">{t("Agreement.第二十五条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行の内容により添乗員その他の者を同行させて第二十三条各号に掲げる業務その他当該募集型企画旅行に付随して当社が必要と認める業務の全部又は一部を行わせることがあります。")}</li>
                    <li>{t("Agreement.前項の添乗員その他の者が同項の業務に従事する時間帯は、原則として八時から二十時までとします。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.保護措置")}</p>
                  <p className="mb-4">{t("Agreement.第二十六条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社は、旅行中の旅行者が、疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用は旅行者の負担とし、旅行者は当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第七章　責任 */}           
              <section id="sec_07" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第七章　責任")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の責任")}</p>
                  <p className="mb-4">{t("Agreement.第二十七条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、募集型企画旅行契約の履行に当たって、当社又は当社が第四条の規定に基づいて手配を代行させた者（以下「手配代行者」といいます。）が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して二年以内に当社に対して通知があったときに限ります。")}</li>
                    <li>{t("Agreement.旅行者が天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社又は当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は、前項の場合を除き、その損害を賠償する責任を負うものではありません。")}</li>
                    <li>{t("Agreement.当社は、手荷物について生じた第一項の損害については、同項の規定にかかわらず、損害発生の翌日から起算して、国内旅行にあっては十四日以内に、海外旅行にあっては二十一日以内に当社に対して通知があったときに限り、旅行者一名につき十五万円を限度（当社に故意又は重大な過失がある場合を除きます。）として賠償します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.特別補償")}</p>
                  <p className="mb-4">{t("Agreement.第二十八条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、前条第一項の規定に基づく当社の責任が生ずるか否かを問わず、別紙特別補償規程で定めるところにより、旅行者が募集型企画旅行参加中にその生命、身体又は手荷物の上に被った一定の損害について、あらかじめ定める額の補償金及び見舞金を支払います。")}</li>
                    <li>{t("Agreement.前項の損害について当社が前条第一項の規定に基づく責任を負うときは、その責任に基づいて支払うべき損害賠償金の額の限度において、当社が支払うべき前項の補償金は、当該損害賠償金とみなします。")}</li>
                    <li>{t("Agreement.前項に規定する場合において、第一項の規定に基づく当社の補償金支払義務は、当社が前条第一項の規定に基づいて支払うべき損害賠償金（前項の規定により損害賠償金とみなされる補償金を含みます。）に相当する額だけ縮減するものとします。")}</li>
                    <li>{t("Agreement.当社の募集型企画旅行参加中の旅行者を対象として、別途の旅行代金を収受して当社が実施する募集型企画旅行については、主たる募集型企画旅行契約の内容の一部として取り扱います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅程保証")}</p>
                  <p className="mb-4">{t("Agreement.第二十九条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、別表第二上欄に掲げる契約内容の重要な変更（次の各号に掲げる変更（運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによるものを除きます。）を除きます。）が生じた場合は、旅行代金に同表下欄に記載する率を乗じた額以上の変更補償金を旅行終了日の翌日から起算して三十日以内に支払います。ただし、当該変更について当社に第二十七条第一項の規定に基づく責任が発生することが明らかである場合には、この限りではありません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.次に掲げる事由による変更")}
                          <ul className="list-iroha" hidden={languageCode !== LANGUAGES.JA}>
                            <li>{t("Agreement.天災地変")}</li>
                            <li>{t("Agreement.戦乱")}</li>
                            <li>{t("Agreement.暴動")}</li>
                            <li>{t("Agreement.官公署の命令")}</li>
                            <li>{t("Agreement.運送・宿泊機関等の旅行サービス提供の中止")}</li>
                            <li>{t("Agreement.当初の運行計画によらない運送サービスの提供")}</li>
                            <li>{t("Agreement.旅行参加者の生命又は身体の安全確保のため必要な措置")}</li>
                          </ul>

                          <ul className="list-number-dot" hidden={languageCode !== LANGUAGES.TW}>
                            <li>{t("Agreement.天災地変")}</li>
                            <li>{t("Agreement.戦乱")}</li>
                            <li>{t("Agreement.暴動")}</li>
                            <li>{t("Agreement.官公署の命令")}</li>
                            <li>{t("Agreement.運送・宿泊機関等の旅行サービス提供の中止")}</li>
                            <li>{t("Agreement.当初の運行計画によらない運送サービスの提供")}</li>
                            <li>{t("Agreement.旅行参加者の生命又は身体の安全確保のため必要な措置")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.第十六条から第十八条までの規定に基づいて募集型企画旅行契約が解除されたときの当該解除された部分に係る変更")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社が支払うべき変更補償金の額は、旅行者一名に対して一募集型企画旅行につき旅行代金に十五％以上の当社が定める率を乗じた額をもって限度とします。また、旅行者一名に対して一募集型企画旅行につき支払うべき変更補償金の額が千円未満であるときは、当社は、変更補償金を支払いません。")}</li>
                    <li>{t("Agreement.当社が第一項の規定に基づき変更補償金を支払った後に、当該変更について当社に第二十七条第一項の規定に基づく責任が発生することが明らかになった場合には、旅行者は当該変更に係る変更補償金を当社に返還しなければなりません。この場合、当社は、同項の規定に基づき当社が支払うべき損害賠償金の額と旅行者が返還すべき変更補償金の額とを相殺した残額を支払います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.旅行者の責任")}</p>
                  <p className="mb-4">{t("Agreement.第三十条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.旅行者の故意又は過失により当社が損害を被ったときは、当該旅行者は、損害を賠償しなければなりません。")}</li>
                    <li>{t("Agreement.旅行者は、募集型企画旅行契約を締結するに際しては、当社から提供された情報を活用し、旅行者の権利義務その他の募集型企画旅行契約の内容について理解するよう努めなければなりません。")}</li>
                    <li>{t("Agreement.旅行者は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者に申し出なければなりません。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第八章　弁済業務保証金 */}           
              <section id="sec_08" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第八章　弁済業務保証金")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.弁済業務保証金")}</p>
                  <p className="mb-4">{t("Agreement.第三十一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、一般社団法人日本旅行業協会（東京都千代田区霞が関三丁目3番3号）の保証社員になっております。")}</li>
                    <li>{t("Agreement.当社と募集型企画旅行契約を締結した旅行者又は構成者は、その取引によって生じた債権に関し、前項の一般社団法人日本旅行業協会が供託している弁済業務保証金から7千万円に達するまで弁済を受けることができます。")}</li>
                    <li>{t("Agreement.当社は、旅行業法第四十九条第一項の規定に基づき、一般社団法人日本旅行業協会に弁済業務保証金分担金を納付しておりますので、同法第七条第一項に基づく営業保証金は供託しておりません。")}</li>
                  </ul>

                  <p><span className="fw-b">{t("Agreement.別表第一")}</span>　{t("Agreement.取消料（第十六条第一項関係）")}</p>
                  <p className="mb-8">{t("Agreement.一 国内旅行に係る取消料")}</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tr>
                      <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c">{t("Agreement.区分")}</td>
                      <td className="table-bgc-light_navy fw-b ta-c">{t("Agreement.取消料")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（一）次項、第三項及び第四項以外の募集型企画旅行契約")}</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.イ 旅行開始日の前日から起算してさかのぼって二十日目（日帰り旅行にあっては十日目）に当たる日以降に解除する場合（ロからホまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ 旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に解除する場合（ハからホまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の30％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ 旅行開始日の前日に解除する場合")}</td>
                      <td>{t("Agreement.旅行代金の40％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ 旅行開始当日に解除する場合（ホに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ホ 旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>

                    <tr hidden={languageCode !== LANGUAGES.TW}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.（二）搭乘包租船舶的募集型策劃旅遊契約")}</td>
                      <td>{t("Agreement.依據與該船舶相關的取消費用規定。")}</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2} hidden={languageCode !== LANGUAGES.JA}>
                      （二） 航空会社がウェブサイト等により広く消費者向けに販売する航空券と同一の取引条件による航空券を利用する募集型企画旅行契約であって、契約書面において、当該航空券が利用されること、航空会社の名称、並びに当該航空券に関して航空会社が定める取消手数料、違約料、払戻手数料その他の航空運送契約の解除に要する費用（以下、総称して「航空券取消料等」といいます。）の条件（以下「航空券取消条件」といいます。）及び金額を明示したもの"
                      </td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2} hidden={languageCode !== LANGUAGES.TW}>
                      備註<br />
                      （一）在契約書面文件上明示取消費用的金額。 <br />
                      （二）在適用本表之際，「旅遊開始後」是指附錄特別補償規程第二條第三項規定的「開始接受服務提供之時」以後。
                      </td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.イ 旅行契約締結後に解除する場合（ロからヘに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行契約を解除した時点において航空券取消条件を適用した場合の航空券取消料等の額（以下「旅行契約解除時の航空券取消料等」といいます。）以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始日の前日から起算してさかのぼって二十日目（日帰り旅行にあっては十日目）に当たる日以降に解除する場合（ハからヘまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ　旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に解除する場合（ニからヘまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の30％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ　旅行開始日の前日に解除する場合")}</td>
                      <td>{t("Agreement.旅行代金の40％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ホ　旅行開始当日に解除する場合（ヘに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ヘ　旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>

                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        {t("Agreement.（三）航空会社が設定する航空券（募集型企画旅行のために旅行の目的地における宿泊費その他の費用を合算した旅行代金の額のみを表示することができ、運賃・料金を単独では表示することができない航空券（１名から利用できる「個人包括旅行運賃」に限る。））を利用する募集型企画旅行契約であって、契約書面において、当該航空券が利用されること、航空会社の名称、並びに当該航空券に関して航空会社が定める取消手数料、違約料、払戻手数料その他の航空運送契約の解除に要する費用（以下、総称して「航空券取消料等」といいます。）の条件（以下「航空券取消条件」といい、当該航空会社のウェブサイト等でご確認いただけます。）及び金額を明示したもの")}
                      </td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.イ　旅行契約締結後に解除する場合（ロからヘに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行契約解除時の航空券取消料等の額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始日の前日から起算してさかのぼって二十日目に当たる日以降に解除する場合（ハからヘまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ　旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に解除する場合（ニからヘまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の30％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ　旅行開始日の前日に解除する場合")}</td>
                      <td>{t("Agreement.旅行代金の40％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ホ　旅行開始当日に解除する場合（ヘに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.へ　旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.（四）貸切船舶を利用する募集型企画旅行契約")}</td>
                      <td>{t("Agreement.当該船舶に係る取消料の規定によります。")}</td>
                    </tr>
                    <tr hidden={languageCode !== LANGUAGES.JA}>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        {t("Agreement.備考")}<br />
                        {t("Agreement.（一）取消料の金額は、契約書面に明示します。")}<br />
                        {t("Agreement.（二）本表の適用にあたって「旅行開始後」とは、別紙特別補償規程第二条第三項に規定する「サービスの提供を受けることを開始した時」以降をいいます。")}<br />
                        {t("Agreement.（三）第二項及び第三項の場合において、当該航空券に関して、当社が航空会社に対して支払うべき航空券取消料等が生じなかったときは、旅行契約解除時の航空券取消料等の額は無料として取り扱い、航空会社により航空券取消料等が減額されたときは、当該減額後の航空券取消料等の額を旅行契約解除時の航空券取消料等の額として取り扱います。")}
                      </td>
                    </tr>
                  </table>

                  <p className="mt-16 mt-24-md mb-8" hidden={languageCode !== LANGUAGES.JA}>{t("Agreement.二　海外旅行に係る取消料")}</p>
                  <table className="mb-24 mb-32-md table-xsmall" hidden={languageCode !== LANGUAGES.JA}>
                    <tr>
                      <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c">{t("Agreement.区分")}</td>
                      <td className="table-bgc-light_navy fw-b ta-c">{t("Agreement.取消料")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（一） 本邦出国時又は帰国時に航空機を利用する募集型企画旅行契約並びに本邦外を出発地及び到着地とする募集型企画旅行契約（次項から第四項に掲げる旅行契約を除く。）")}</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.イ　旅行開始日がピーク時の旅行である場合であって、旅行開始日の前日から起算してさかのぼって四十日目に当たる日以降に解除するとき（ロからニまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の10％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合（ハ及びニに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ　旅行開始日の前々日以降に解除する場合（ニに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ　旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（二）本邦出国時又は帰国時に、航空会社がウェブサイト等により広く消費者向けに販売する航空券と同一の取引条件による航空券を利用する募集型企画旅行契約であって、契約書面において、当該航空券が利用されること、航空会社の名称並びに航空券取消条件及び航空券取消料等の金額を明示したもの（次項から第四項に掲げる旅行契約を除く。）")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.イ　旅行契約締結後に解除する場合（ロからホに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行契約解除時の航空券取消料等の額以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始日がピーク時の旅行である場合であって、旅行開始日の前日から起算してさかのぼって四十日目に当たる日以降に解除するとき（ハからホまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の10％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ　旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合（ニ及びホに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ　旅行開始日の前々日以降に解除する場合（ホに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％又は旅行契約解除時の航空券取消料等とのいずれか大きい額以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ホ　旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（三）貸切航空機を利用する募集型企画旅行契約")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.イ　旅行開始日の前日から起算してさかのぼって九十日目に当たる日以降に解除する場合（ロからニまでに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の20％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合（ハ及びニに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の50％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ハ　旅行開始日の前日から起算してさかのぼって二十日目に当たる日以降に解除する場合（ニに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.旅行代金の80％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ニ　旅行開始日の前日から起算してさかのぼって三日目に当たる日以降の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（四）旅行日程中に3泊以上のクルーズ日程を含む募集型企画旅行契約（次項に掲げる旅行契約を除く）")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.イ　日程に含まれるクルーズに係る取消料規定の取消料収受期間の起算日であるクルーズ開始日を旅行開始日と読み替えた期間内に解除する場合（ロに掲げる場合を除く。）")}</td>
                      <td>{t("Agreement.①クルーズ中の泊数が当該募集型企画旅行の日程中の宿泊数（航空機内のものを除く。②において同じ。）の50％以上のもの当該期間に対応するクルーズの取消料収受期間の区分に適用される取消料率の2分の1に相当する率以内")}<br />
                        {t("Agreement.②クルーズ中の泊数が当該募集型企画旅行の日程中の宿泊数の50%未満のもの")}<br />
                        {t("Agreement.当該期間に対応するクルーズの取消料収受期間の区分に適用される取消料率の4分の1に相当する率以内")}
                      </td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.ロ　旅行開始後の解除又は無連絡不参加の場合")}</td>
                      <td>{t("Agreement.旅行代金の100％以内")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md fw-b">{t("Agreement.（五）本邦出国時及び帰国時に船舶を利用する募集型企画旅行契約")}</td>
                      <td>{t("Agreement.当該船舶に係る取消料の規定によります。")}</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.注　「ピーク時」とは、十二月二十日から一月七日まで、四月二十七日から五月六日まで及び七月二十日から八月三十一日までをいいます。")}</td>
                    </tr>
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        {t("Agreement.備考")}<br />
                        {t("Agreement.（一）取消料の金額は、契約書面に明示します。")}<br />
                        {t("Agreement.（二）本表の適用に当たって「旅行開始後」とは、別紙特別補償規程第二条第三項に規定する「サービスの提供を受けることを開始した時」以降をいいます。")}<br />
                        {t("Agreement.（三）第二項の場合において、当該航空券に関して、当社が航空会社に対して支払うべき航空券取消料等が生じなかったときは、旅行契約解除時の航空券取消料等の額は無料として取り扱い、航空会社により航空券取消料等が減額されたときは、当該減額後の航空券取消料等の額を旅行契約解除時の航空券取消料等の額として取り扱います。")}
                      </td>
                    </tr>
                  </table>

                  <p className="mb-8"><span className="fw-b">{t("Agreement.別表第二")}</span>　{t("Agreement.変更補償金（第二十九条第一項関係）")}</p>
                  <table className="table-xsmall">
                    <tr>
                      <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c" rowSpan={2}>{t("Agreement.変更補償金の支払いが必要となる変更")}</td>
                      <td className="table-bgc-light_navy fw-b ta-c" colSpan={2}>{t("Agreement.一件あたりの率（％）")}</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_navy fz-13 fz-16-md fw-b ta-c w-25per">{t("Agreement.旅行開始前")}</td>
                      <td className="table-bgc-light_navy fz-13 fz-16-md fw-b ta-c w-25per">{t("Agreement.旅行開始後")}</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.一　契約書面に記載した旅行開始日又は旅行終了日の変更")}</td>
                      <td className="fz-13 fz-16-md">1.5</td>
                      <td className="fz-13 fz-16-md">3.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.二　契約書面に記載した入場する観光地又は観光施設（レストランを含みます。）その他の旅行の目的地の変更")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.三　契約書面に記載した運送機関の等級又は設備のより低い料金のものへの変更（変更後の等級及び設備の料金の合計額が契約書面に記載した等級及び設備のそれを下回った場合に限ります。）")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.四　契約書面に記載した運送機関の種類又は会社名の変更")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.五　契約書面に記載した本邦内の旅行開始地たる空港又は旅行終了地たる空港の異なる便への変更")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.六　契約書面に記載した本邦内と本邦外との間における直行便の乗継便又は経由便への変更")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.七　契約書面に記載した宿泊機関の種類又は名称の変更（当社が宿泊機関の等級を定めている場合であって、変更後の宿泊機関の等級が契約書面に記載した宿泊機関の等級を上回った場合を除きます。）")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.八　契約書面に記載した宿泊機関の客室の種類、設備、景観その他の客室の条件の変更")}</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">{t("Agreement.九　前各号に掲げる変更のうち契約書面のツアー・タイトル中に記載があった事項の変更")}</td>
                      <td className="fz-13 fz-16-md">2.5</td>
                      <td className="fz-13 fz-16-md">5.0</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={3}>
                        {t("Agreement.注一　「旅行開始前」とは、当該変更について旅行開始日の前日までに旅行者に通知した場合をいい、「旅行開始後」とは、当該変更について旅行開始当日以降に旅行者に通知した場合をいいます。")}<br />
                        {t("Agreement.注二　確定書面が交付された場合には、「契約書面」とあるのを「確定書面」と読み替えた上で、この表を適用します。この場合において、契約書面の記載内容と確定書面の記載内容との間又は確定書面の記載内容と実際に提供された旅行サービスの内容との間に変更が生じたときは、それぞれの変更につき一件として取り扱います。")}<br />
                        {t("Agreement.注三　第三号又は第四号に掲げる変更に係る運送機関が宿泊設備の利用を伴うものである場合は、一泊につき一件として取り扱います。")}<br />
                        {t("Agreement.注四　第四号に掲げる運送機関の会社名の変更については、等級又は設備がより高いものへの変更を伴う場合には適用しません。")}<br />
                        {t("Agreement.注五　第七号の宿泊機関の等級は、旅行契約締結の時点で契約書面に記載しているリスト又は当社の営業所若しくは当社のウェブページで閲覧に供しているリストによります。")}<br />
                        {t("Agreement.注六　第四号又は第七号若しくは第八号に掲げる変更が一乗車船等又は一泊の中で複数生じた場合であっても、一乗車船等又は一泊につき一件として取り扱います。")}<br />
                        <span hidden={languageCode !== LANGUAGES.JA}>{t("Agreement.注七　第九号に掲げる変更については、第一号から第八号までの率を適用せず、第九号によります。")}</span>
                      </td>
                    </tr>

                  </table>
                </div>
              </section>

              {/* 旅行業約款（募集型企画旅行契約の部）ここまで */}


              {/* 旅行業約款（受注型企画旅行契約の部）ここから */}

              {/* section: 第一章　総則 */}
              <section id="sec_09" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                  <h2 className="heading-2 mt-0 mb-16 mb-24-md">旅行業約款（受注型企画旅行契約の部）</h2>
                  <h3 className="mb-12">{t("Agreement.第一章　総則")}</h3>
                  <div className="card large-side-medium">
                    <p className="fz-16 fw-b mb-8 c-navy">適用範囲</p>
                    <p className="mb-4">第一条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>当社が旅行者との間で締結する受注型企画旅行に関する契約（以下「受注型企画旅行契約」といいます。）は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。</li>
                      <li>当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。</li>
                    </ul>

                    <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">用語の定義</p>
                    <p className="mb-4">第二条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>この約款で「受注型企画旅行」とは、当社が、旅行者からの依頼により、旅行の目的地及び日程、旅行者が提供を受けることができる運送又は宿泊のサービスの内容並びに旅行者が当社に支払うべき旅行代金の額を定めた旅行に関する計画を作成し、これにより実施する旅行をいいます。</li>
                      <li>この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。</li>
                      <li>この部で「通信契約」とは、当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員との間で電話、郵便、ファクシミリその他の通信手段による申込みを受けて締結する受注型企画旅行契約であって、当社が旅行者に対して有する受注型企画旅行契約に基づく旅行代金等に係る債権又は債務を、当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、旅行者があらかじめ承諾し、かつ当該受注型企画旅行契約の旅行代金等を第十二条第二項、第十六条第一項後段、第十九条第二項に定める方法により支払うことを内容とする受注型企画旅行契約をいいます。</li>
                      <li>この部で「電子承諾通知」とは、契約の申込みに対する承諾の通知であって、情報通信の技術を利用する方法のうち当社が使用する電子計算機、ファクシミリ装置、テレックス又は電話機（以下「電子計算機等」といいます。）と旅行者が使用する電子計算機等とを接続する電気通信回線を通じて送信する方法により行うものをいいます。</li>
                      <li>この約款で「カード利用日」とは、旅行者又は当社が受注型企画旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます。</li>
                    </ul>

                    <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">旅行契約の内容</p>
                    <p className="mb-4">第三条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>当社は、受注型企画旅行契約において、旅行者が当社の定める旅行日程に従って、運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように、手配し、旅程を管理することを引き受けます。</li>
                    </ul>

                    <p className="fz-16 fw-b mt-16 mt-24-md mb-8 c-navy">手配代行者</p>
                    <p className="mb-4">第四条</p>
                    <ul className="list-number-dot c-blue_gray mb-0">
                      <li>当社は、受注型企画旅行契約の履行に当たって、手配の全部又は一部を本邦内又は本邦外の他の旅行業者、手配を業として行う者その他の補助者に代行させることがあります。</li>
                    </ul>

                  </div>
                </section>

              {/* section: 第二章　契約の締結 */}
              <section id="sec_10" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                  <h3 className="mb-12">{t("Agreement.第二章　契約の締結")}</h3>
                  <div className="card large-side-medium">
                    <p className="fz-16 fw-b mb-8 c-navy">企画書面の交付</p>
                    <p className="mb-4">第五条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>当社は、当社に受注型企画旅行契約の申込みをしようとする旅行者からの依頼があったときは、当社の業務上の都合があるときを除き、当該依頼の内容に沿って作成した旅行日程、旅行サービスの内容、旅行代金その他の旅行条件に関する企画の内容を記載した書面（以下「企画書面」といいます。）を交付します。</li>
                      <li>当社は、前項の企画書面において、旅行代金の内訳として企画に関する取扱料金（以下「企画料金」といいます。）の金額を明示することがあります。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">契約の申込み</p>
                    <p className="mb-4">第六条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>前条第一項の企画書面に記載された企画の内容に関し、当社に受注型企画旅行契約の申込みをしようとする旅行者は、当社所定の申込書（以下「申込書」といいます。）に所定の事項を記入の上、当社が別に定める金額の申込金とともに、当社に提出しなければなりません。</li>
                      <li>前条第一項の企画書面に記載された企画の内容に関し、当社に通信契約の申込みをしようとする旅行者は、前項の規定にかかわらず、会員番号その他の事項を当社に通知しなければなりません。</li>
                      <li>第一項の申込金は、旅行代金（その内訳として金額が明示された企画料金を含みます。）又は取消料若しくは違約料の一部として取り扱います。</li>
                      <li>受注型企画旅行の参加に際し、特別な配慮を必要とする旅行者は、契約の申込時に申し出てください。このとき、当社は可能な範囲内でこれに応じます。</li>
                      <li>前項の申出に基づき、当社が旅行者のために講じた特別な措置に要する費用は、旅行者の負担とします。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">契約締結の拒否</p>
                    <p className="mb-4">第七条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>
                        当社は、次に掲げる場合において、受注型企画旅行契約の締結に応じないことがあります。
                        <ul className="list-kanji">
                          <li>旅行者が他の旅行者に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあるとき。</li>
                          <li>通信契約を締結しようとする場合であって、旅行者の有するクレジットカードが無効である等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できないとき。</li>
                          <li>旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。</li>
                          <li>旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。</li>
                          <li>旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。</li>
                          <li>その他当社の業務上の都合があるとき。</li>
                        </ul>
                      </li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">契約の成立時期</p>
                    <p className="mb-4">第八条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>受注型企画旅行契約は、当社が契約の締結を承諾し、第六条第一項の申込金を受理した時に成立するものとします。</li>
                      <li>通信契約は、前項の規定にかかわらず、当社が契約の締結を承諾する旨の通知を発した時に成立するものとします。ただし、当該契約において電子承諾通知を発する場合は、当該通知が旅行者に到達した時に成立するものとします。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">契約書面の交付</p>
                    <p className="mb-4">第九条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>当社は、前条の定める契約の成立後速やかに、旅行者に、旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面（以下「契約書面」といいます。）を交付します。</li>
                      <li>当社は、第五条第一項の企画書面において企画料金の金額を明示した場合は、当該金額を前項の契約書面において明示します。</li>
                      <li>当社が受注型企画旅行契約により手配し旅程を管理する義務を負う旅行サービスの範囲は、第一項の契約書面に記載するところによります。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">確定書面</p>
                    <p className="mb-4">第十条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>前条第一項の契約書面において、確定された旅行日程、運送若しくは宿泊機関の名称を記載できない場合には、当該契約書面において利用予定の宿泊機関及び旅行計画上重要な運送機関の名称を限定して列挙した上で、当該契約書面交付後、旅行開始日の前日（旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に受注型企画旅行契約の申込みがなされた場合にあっては、旅行開始日）までの当該契約書面に定める日までに、これらの確定状況を記載した書面（以下「確定書面」といいます。）を交付します。</li>
                      <li>前項の場合において、手配状況の確認を希望する旅行者から問い合わせがあったときは、確定書面の交付前であっても、当社は迅速かつ適切にこれに回答します。</li>
                      <li>第一項の確定書面を交付した場合には、前条第三項の規定により当社が手配し旅程を管理する義務を負う旅行サービスの範囲は、当該確定書面に記載するところに特定されます。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">情報通信の技術を利用する方法</p>
                    <p className="mb-4">第十一条</p>
                    <ul className="list-number-dot c-blue_gray">
                      <li>当社は、あらかじめ旅行者の承諾を得て、企画書面、受注型企画旅行契約を締結しようとするときに旅行者に交付する旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面、契約書面又は確定書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項（以下この条において「記載事項」といいます。）を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。</li>
                      <li>前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル（専ら当該旅行者の用に供するものに限ります。）に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。</li>
                    </ul>

                    <p className="fz-16 fw-b mb-8 c-navy">旅行代金</p>
                    <p className="mb-4">第十二条</p>
                    <ul className="list-number-dot c-blue_gray mb-0">
                      <li>旅行者は、旅行開始日までの契約書面に記載する期日までに、当社に対し、契約書面に記載する金額の旅行代金を支払わなければなりません。</li>
                      <li>通信契約を締結したときは、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして契約書面に記載する金額の旅行代金の支払いを受けます。また、カード利用日は旅行契約成立日とします。</li>
                    </ul>

                  </div>
              </section>

              {/* section: 第三章　契約の変更 */}           
              <section id="sec_11" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第三章　契約の変更")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">契約内容の変更</p>
                  <p className="mb-4">第十三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、当社に対し、旅行日程、旅行サービスの内容その他の受注型企画旅行契約の内容（以下「契約内容」といいます。）を変更するよう求めることができます。この場合において、当社は、可能な限り旅行者の求めに応じます。</li>
                    <li>当社は、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供その他の当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、旅行者にあらかじめ速やかに当該事由が関与し得ないものである理由及び当該事由との因果関係を説明して、契約内容を変更することがあります。ただし、緊急の場合において、やむを得ないときは、変更後に説明します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金の額の変更</p>
                  <p className="mb-4">第十四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>受注型企画旅行を実施するに当たり利用する運送機関について適用を受ける運賃・料金（以下この条において「適用運賃・料金」といいます。）が、著しい経済情勢の変化等により、受注型企画旅行の企画書面の交付の際に明示した時点において有効なものとして公示されている適用運賃・料金に比べて、通常想定される程度を大幅に超えて増額又は減額される場合においては、当社は、その増額又は減額される金額の範囲内で旅行代金の額を増加し、又は減少することができます。</li>
                    <li>当社は、前項の定めるところにより旅行代金を増額するときは、旅行開始日の前日から起算してさかのぼって十五日目に当たる日より前に旅行者にその旨を通知します。</li>
                    <li>当社は、第一項の定める適用運賃・料金の減額がなされるときは、同項の定めるところにより、その減少額だけ旅行代金を減額します。</li>
                    <li>当社は、前条の規定に基づく契約内容の変更により旅行の実施に要する費用（当該契約内容の変更のためにその提供を受けなかった旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を含みます。）の減少又は増加が生じる場合（費用の増加が、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによる場合を除きます。）には、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することがあります。</li>
                    <li>当社は、運送・宿泊機関等の利用人員により旅行代金が異なる旨を契約書面に記載した場合において、受注型企画旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人員が変更になったときは、契約書面に記載したところにより旅行代金の額を変更することがあります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の交替</p>
                  <p className="mb-4">第十五条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社と受注型企画旅行契約を締結した旅行者は、当社の承諾を得て、契約上の地位を第三者に譲り渡すことができます。</li>
                    <li>旅行者は、前項に定める当社の承諾を求めようとするときは、当社所定の用紙に所定の事項を記入の上、所定の金額の手数料とともに、当社に提出しなければなりません。</li>
                    <li>第一項の契約上の地位の譲渡は、当社の承諾があった時に効力を生ずるものとし、以後、旅行契約上の地位を譲り受けた第三者は、旅行者の当該受注型企画旅行契約に関する一切の権利及び義務を承継するものとします。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第四章　契約の解除 */}           
              <section id="sec_12" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第四章　契約の解除")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の解除権</p>
                  <p className="mb-4">第十六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、いつでも別表第一に定める取消料を当社に支払って受注型企画旅行契約を解除することができます。ただし、当社が、運送・宿泊機関等が定める取消料、違約料その他の運送・宿泊機関等との間の旅行サービスに係る契約の解除に要する費用（以下、総称して「運送・宿泊機関取消料等」という。）の金額を、第五条第一項の企画書面において証憑書類を添付して明示したときは、旅行者が旅行開始前に受注型企画旅行契約を解除した場合の取消料については、別表第一に定める取消料の金額にかかわらず、当社が運送・宿泊機関等に対して既に支払い、又はこれから支払わなければならない運送・宿泊機関取消料等の合計額以内の金額とします。通信契約を解除する場合にあっては、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして、本項に規定する取消料の支払いを受けます。</li>
                    <li>
                      旅行者は、次に掲げる場合において、前項の規定にかかわらず、旅行開始前に取消料を支払うことなく受注型企画旅行契約を解除することができます。
                      <ul className="list-kanji">
                        <li>当社によって契約内容が変更されたとき。ただし、その変更が別表第二上欄に掲げるものその他の重要なものであるときに限ります。</li>
                        <li>第十四条第一項の規定に基づいて旅行代金が増額されたとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。</li>
                        <li>当社が旅行者に対し、第十条第一項の期日までに、確定書面を交付しなかったとき。</li>
                        <li>当社の責に帰すべき事由により、契約書面に記載した旅行日程に従った旅行の実施が不可能となったとき。</li>
                      </ul>
                    </li>
                    <li>旅行者は、旅行開始後において、当該旅行者の責に帰すべき事由によらず契約書面に記載した旅行サービスを受領することができなくなったとき又は当社がその旨を告げたときは、第一項の規定にかかわらず、取消料を支払うことなく、旅行サービスの当該受領することができなくなった部分の契約を解除することができます。</li>
                    <li>前項の場合において、当社は、旅行代金のうち旅行サービスの当該受領することができなくなった部分に係る金額を旅行者に払い戻します。ただし、前項の場合が当社の責に帰すべき事由によらない場合においては、当該金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の解除権等－旅行開始前の解除</p>
                  <p className="mb-4">第十七条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、旅行者に理由を説明して、旅行開始前に受注型企画旅行契約を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められるとき。</li>
                        <li>旅行者が他の旅行者に迷惑を及ぼし、又は団体旅行の円滑な実施を妨げるおそれがあると認められるとき。</li>
                        <li>旅行者が、契約内容に関し合理的な範囲を超える負担を求めたとき。</li>
                        <li>スキーを目的とする旅行における必要な降雪量等の旅行実施条件であって契約の締結の際に明示したものが成就しないおそれが極めて大きいとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、契約書面に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。</li>
                        <li>通信契約を締結した場合であって、旅行者の有するクレジットカードが無効になる等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できなくなったとき。</li>
                        <li>旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。</li>
                      </ul>
                    </li>
                    <li>旅行者が第十二条第一項の契約書面に記載する期日までに旅行代金を支払わないときは、当該期日の翌日において旅行者が受注型企画旅行契約を解除したものとします。この場合において、旅行者は、当社に対し、前条第一項に定める取消料に相当する額の違約料を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の解除権－旅行開始後の解除</p>
                  <p className="mb-4">第十八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、旅行開始後であっても、旅行者に理由を説明して、募集型企画旅行契約の一部を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が病気、必要な介助者の不在その他の事由により旅行の継続に耐えられないとき。</li>
                        <li>旅行者が旅行を安全かつ円滑に実施するための添乗員その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等により団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。</li>
                        <li>旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。</li>
                      </ul>
                    </li>
                    <li>当社が前項の規定に基づいて受注型企画旅行契約を解除したときは、当社と旅行者との間の契約関係は、将来に向かってのみ消滅します。この場合において、旅行者が既に提供を受けた旅行サービスに関する当社の債務については、有効な弁済がなされたものとします。</li>
                    <li>前項の場合において、当社は、旅行代金のうち旅行者がいまだその提供を受けていない旅行サービスに係る部分に係る金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金の払戻し</p>
                  <p className="mb-4">第十九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により受注型企画旅行契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し当該金額を払い戻します。</li>
                    <li>当社は、旅行者と通信契約を締結した場合であって、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により通信契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、提携会社のカード会員規約に従って、旅行者に対し当該金額を払い戻します。この場合において、当社は、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し払い戻すべき額を通知するものとし、旅行者に当該通知を行った日をカード利用日とします。</li>
                    <li>前二項の規定は第二十八条又は第三十一条第一項に規定するところにより旅行者又は当社が損害賠償請求権を行使することを妨げるものではありません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約解除後の帰路手配</p>
                  <p className="mb-4">第二十条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、第十八条第一項第一号又は第四号の規定によって旅行開始後に募集型企画旅行契約を解除したときは、旅行者の求めに応じて、旅行者が当該旅行の出発地に戻るために必要な旅行サービスの手配を引き受けます。</li>
                    <li>前項の場合において、出発地に戻るための旅行に要する一切の費用は、旅行者の負担とします。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第五章　団体・グループ契約 */}           
              <section id="sec_13" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第五章　団体・グループ契約")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">団体・グループ契約</p>
                  <p className="mb-4">第二十一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、同じ行程を同時に旅行する複数の旅行者がその責任ある代表者（以下「契約責任者」といいます。）を定めて申し込んだ受注型企画旅行契約の締結については、本章の規定を適用します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約責任者</p>
                  <p className="mb-4">第二十二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、特約を結んだ場合を除き、契約責任者はその団体・グループを構成する旅行者（以下「構成者」といいます。）の受注型企画旅行契約の締結に関する一切の代理権を有しているものとみなし、当該団体・グループに係る旅行業務に関する取引は、当該契約責任者との間で行います。</li>
                    <li>契約責任者は、当社が定める日までに、構成者の名簿を当社に提出しなければなりません。</li>
                    <li>当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。</li>
                    <li>当社は、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約成立の特則</p>
                  <p className="mb-4">第二十三条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、契約責任者と受注型企画旅行契約を締結する場合において、第六条第一項の規定にかかわらず、申込金の支払いを受けることなく受注型企画旅行契約の締結を承諾することがあります。</li>
                    <li>前項の規定に基づき申込金の支払いを受けることなく受注型企画旅行契約を締結する場合には、当社は、契約責任者にその旨を記載した書面を交付するものとし、受注型企画旅行契約は、当社が当該書面を交付した時に成立するものとします。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第六章　旅程管理 */}           
              <section id="sec_14" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第六章　旅程管理")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">旅程管理</p>
                  <p className="mb-4">第二十四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、旅行者の安全かつ円滑な旅行の実施を確保することに努力し、旅行者に対し次に掲げる業務を行います。ただし、当社が旅行者とこれと異なる特約を結んだ場合には、この限りではありません。
                      <ul className="list-kanji">
                        <li>旅行者が旅行中旅行サービスを受けることができないおそれがあると認められるときは、受注型企画旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講ずること。</li>
                        <li>前号の措置を講じたにもかかわらず、契約内容を変更せざるを得ないときは、代替サービスの手配を行うこと。この際、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努めること、また、旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努めること等、契約内容の変更を最小限にとどめるよう努力すること。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の指示</p>
                  <p className="mb-4">第二十五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、旅行開始後旅行終了までの間において、団体で行動するときは、旅行を安全かつ円滑に実施するための当社の指示に従わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">添乗員等の業務</p>
                  <p className="mb-4">第二十六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、旅行の内容により添乗員その他の者を同行させて第二十四条各号に掲げる業務その他当該受注型企画旅行に付随して当社が必要と認める業務の全部又は一部を行わせることがあります。</li>
                    <li>前項の添乗員その他の者が同項の業務に従事する時間帯は、原則として八時から二十時までとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">保護措置</p>
                  <p className="mb-4">第二十七条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、旅行中の旅行者が、疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用は旅行者の負担とし、旅行者は当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第七章　責任 */}           
              <section id="sec_15" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第七章　責任")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">当社の責任</p>
                  <p className="mb-4">第二十八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、受注型企画旅行契約の履行に当たって、当社又は当社が第四条の規定に基づいて手配を代行させた者（以下「手配代行者」といいます。）が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して二年以内に当社に対して通知があったときに限ります。</li>
                    <li>旅行者が天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社又は当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は、前項の場合を除き、その損害を賠償する責任を負うものではありません。</li>
                    <li>当社は、手荷物について生じた第一項の損害については、同項の規定にかかわらず、損害発生の翌日から起算して、国内旅行にあっては十四日以内に、海外旅行にあっては二十一日以内に当社に対して通知があったときに限り、旅行者一名につき十五万円を限度（当社に故意又は重大な過失がある場合を除きます。）として賠償します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">特別補償</p>
                  <p className="mb-4">第二十九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、前条第一項の規定に基づく当社の責任が生ずるか否かを問わず、別紙特別補償規程で定めるところにより、旅行者が受注型企画旅行参加中にその生命、身体又は手荷物の上に被った一定の損害について、あらかじめ定める額の補償金及び見舞金を支払います。</li>
                    <li>前項の損害について当社が前条第一項の規定に基づく責任を負うときは、その責任に基づいて支払うべき損害賠償金の額の限度において、当社が支払うべき前項の補償金は、当該損害賠償金とみなします。</li>
                    <li>前項に規定する場合において、第一項の規定に基づく当社の補償金支払義務は、当社が前条第一項の規定に基づいて支払うべき損害賠償金（前項の規定により損害賠償金とみなされる補償金を含みます。）に相当する額だけ縮減するものとします。</li>
                    <li>当社の受注型企画旅行参加中の旅行者を対象として、別途の旅行代金を収受して当社が実施する募集型企画旅行については、受注型企画旅行契約の内容の一部として取り扱います。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅程保証</p>
                  <p className="mb-4">第三十条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、別表第二上欄に掲げる契約内容の重要な変更（次の各号に掲げる変更（運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによるものを除きます。）を除きます。）が生じた場合は、旅行代金に同表下欄に記載する率を乗じた額以上の変更補償金を旅行終了日の翌日から起算して三十日以内に支払います。ただし、当該変更について当社に第二十八条第一項の規定に基づく責任が発生することが明らかである場合には、この限りではありません。
                      <ul className="list-kanji">
                        <li>
                          次に掲げる事由による変更
                          <ul className="list-iroha">
                            <li>天災地変</li>
                            <li>戦乱</li>
                            <li>暴動</li>
                            <li>官公署の命令</li>
                            <li>運送・宿泊機関等の旅行サービス提供の中止</li>
                            <li>当初の運行計画によらない運送サービスの提供</li>
                            <li>旅行参加者の生命又は身体の安全確保のため必要な措置</li>
                          </ul>
                        </li>
                        <li>第十三条第一項の規定に基づいて受注型企画旅行契約が変更されたときの当該変更された部分及び第十六条から第十八条までの規定に基づいて受注型企画旅行契約が解除されたときの当該解除された部分に係る変更</li>
                      </ul>
                    </li>
                    <li>当社が支払うべき変更補償金の額は、旅行者一名に対して一受注型企画旅行につき旅行代金に十五％以上の当社が定める率を乗じた額をもって限度とします。また、旅行者一名に対して一受注型企画旅行につき支払うべき変更補償金の額が千円未満であるときは、当社は、変更補償金を支払いません。</li>
                    <li>当社が第一項の規定に基づき変更補償金を支払った後に、当該変更について当社に第二十八条第一項の規定に基づく責任が発生することが明らかになった場合には、旅行者は当該変更に係る変更補償金を当社に返還しなければなりません。この場合、当社は、同項の規定に基づき当社が支払うべき損害賠償金の額と旅行者が返還すべき変更補償金の額とを相殺した残額を支払います。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の責任</p>
                  <p className="mb-4">第三十一条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>旅行者の故意又は過失により当社が損害を被ったときは、当該旅行者は、損害を賠償しなければなりません。</li>
                    <li>旅行者は、受注型企画旅行契約を締結するに際しては、当社から提供された情報を活用し、旅行者の権利義務その他の受注型企画旅行契約の内容について理解するよう努めなければなりません。</li>
                    <li>旅行者は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者に申し出なければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第八章　弁済業務保証金 */}           
              <section id="sec_16" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第八章　弁済業務保証金")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">弁済業務保証金</p>
                  <p className="mb-4">第三十二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、一般社団法人日本旅行業協会（東京都千代田区霞が関三丁目3番3号）の保証社員になっております。</li>
                    <li>当社と受注型企画旅行契約を締結した旅行者又は構成者は、その取引によって生じた債権に関し、前項の一般社団法人日本旅行業協会が供託している弁済業務保証金から7千万円に達するまで弁済を受けることができます。</li>
                    <li>当社は、旅行業法第四十九条第一項の規定に基づき、一般社団法人日本旅行業協会に弁済業務保証金分担金を納付しておりますので、同法第七条第一項に基づく営業保証金は供託しておりません。</li>
                  </ul>


                  <p><span className="fw-b">別表第一</span>　取消料（第十六条第一項関係）</p>
                  <p className="mb-8">一 国内旅行に係る取消料</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tr>
                      <td className="table-bgc-light_navy fz-13 fz-16-md ta-c fw-b">区分</td>
                      <td className="table-bgc-light_navy fz-13 fz-16-md ta-c fw-b">取消料</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>（一）次項以外の受注型企画旅行契約</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">イ ロからヘまでに掲げる場合以外（当社が契約書面において企画料金の金額を明示した場合に限る。）</td>
                      <td className="fz-13 fz-16-md">企画料金に相当する金額</td>
                    </tr>
                    
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ロ 旅行開始日の前日から起算してさかのぼって二十日目（日帰り旅行にあっては十日目）に当たる日以降に解除する場合（ハからヘまでに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の20％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ハ 旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に解除する場合（ニからヘまでに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の30％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ニ 旅行開始日の前日に解除する場合</td>
                      <td className="fz-13 fz-16-md">旅行代金の40％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ホ 旅行開始当日に解除する場合（ヘに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の50％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ヘ 旅行開始後の解除又は無連絡不参加の場合</td>
                      <td className="fz-13 fz-16-md">旅行代金の100％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">（二）貸切船舶を利用する受注型企画旅行契約</td>
                      <td className="fz-13 fz-16-md">当該船舶に係る取消料の規定によります。</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        備考<br />
                        （一）取消料の金額は、契約書面に明示します。<br />
                        （二）本表の適用に当たって「旅行開始後」とは、別紙特別補償規程第二条三項に規定する「サービスの提供を受けることを開始した時」以降をいいます。
                      </td>
                    </tr>
                  </table>

                  <p className="mt-16 mt-24-md mb-8">二　海外旅行に係る取消料</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tr>
                      <td className="table-bgc-light_navy fz-13 fz-16-md ta-c fw-b">区分</td>
                      <td className="table-bgc-light_navy fz-13 fz-16-md ta-c fw-b">取消料</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>（一） 本邦出国時又は帰国時に航空機を利用する受注型企画旅行契約並びに本邦外を出発地及び到着地とする受注型企画旅行契約（次項及び第三項に掲げる旅行契約を除く。）</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">イ　ロからニまでに掲げる場合以外の場合（当社が契約書面において企画料金の金額を明示した場合に限る。）</td>
                      <td className="fz-13 fz-16-md">企画料金に相当する金額</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ロ　旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合（ハ及びニに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の20％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ハ　旅行開始日の前々日以降に解除する場合（ニに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の50％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ニ　旅行開始後の解除又は無連絡不参加の場合</td>
                      <td className="fz-13 fz-16-md">旅行代金の100％以内</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>（二）貸切航空機を利用する受注型企画旅行契約</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">イ　ロからホまでに掲げる場合以外の場合（当社が契約書面において企画料金の金額を明示した場合に限る。）</td>
                      <td className="fz-13 fz-16-md">企画料金に相当する金額</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ロ　旅行開始日の前日から起算してさかのぼって九十日目に当たる日以降に解除する場合（ハからホまでに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の20％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ハ　旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合（ニ及びホに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の50％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ニ　旅行開始日の前日から起算してさかのぼって二十日目に当たる日以降に解除する場合（ホに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">旅行代金の80％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ホ　旅行開始日の前日から起算してさかのぼって三日目に当たる日以降の解除又は無連絡不参加の場合</td>
                      <td className="fz-13 fz-16-md">旅行代金の100％以内</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>（三）旅行日程中に3泊以上のクルーズ日程を含む受注型企画旅行契約（次項に掲げる旅行契約を除く）</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">イ　ロからハまでに掲げる場合以外の場合（当社が契約書面において企画料金の金額を明示した場合に限る。）</td>
                      <td className="fz-13 fz-16-md">企画料金に相当する金額</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ロ　日程に含まれるクルーズに係る取消料規定の取消料収受期間の起算日であるクルーズ開始日を旅行開始日と読み替えた期間内に解除する場合（ハに掲げる場合を除く。）</td>
                      <td className="fz-13 fz-16-md">
                      ①クルーズ中の泊数が当該受注型企画旅行の日程中の宿泊数（航空機内のものを除く。②において同じ。）の50％以上のもの<br />
                      　当該期間に対応するクルーズの取消料収受期間の区分に適用される取消料率の2分の1に相当する率以内<br />
                      ②クルーズ中の泊数が当該受注型企画旅行の日程中の宿泊数の50%未満のもの<br />
                      　当該期間に対応するクルーズの取消料収受期間の区分に適用される取消料率の4分の1に相当する率以内
                      </td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">ハ　旅行開始後の解除又は無連絡不参加の場合</td>
                      <td className="fz-13 fz-16-md">旅行代金の100％以内</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">（四）本邦出国時及び帰国時に船舶を利用する受注型企画旅行契約</td>
                      <td className="fz-13 fz-16-md">当該船舶に係る取消料の規定によります。</td>
                    </tr>
                            

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        備考<br />
                        （一）取消料の金額は、契約書面に明示します。<br />
                        （二）本表の適用に当たって「旅行開始後」とは、別紙特別補償規程第二条第三項に規定する「サービスの提供を受けることを開始した時」以降をいいます。
                      </td>
                    </tr>
                  </table>

                  <p className="mb-8"><span className="fw-b">別表第二</span>　変更補償金（第三十条第一項関係）</p>
                  <table className="table-xsmall">
                    <tr>
                      <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c" rowSpan={2}>変更補償金の支払いが必要となる変更</td>
                      <td className="table-bgc-light_navy fw-b ta-c" colSpan={2}>一件あたりの率（％）</td>
                    </tr>
                    <tr>
                      <td className="table-bgc-light_navy fz-13 fz-16-md fw-b ta-c w-25per">旅行開始前</td>
                      <td className="table-bgc-light_navy fz-13 fz-16-md fw-b ta-c w-25per">旅行開始後</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">一　契約書面に記載した旅行開始日又は旅行終了日の変更</td>
                      <td className="fz-13 fz-16-md">1.5</td>
                      <td className="fz-13 fz-16-md">3.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">二　契約書面に記載した入場する観光地又は観光施設（レストランを含みます。）その他の旅行の目的地の変更</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">三　契約書面に記載した運送機関の等級又は設備のより低い料金のものへの変更（変更後の等級及び設備の料金の合計額が契約書面に記載した等級及び設備のそれを下回った場合に限ります。）</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">四　契約書面に記載した運送機関の種類又は会社名の変更</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">五　契約書面に記載した本邦内の旅行開始地たる空港又は旅行終了地たる空港の異なる便への変更</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">六　契約書面に記載した本邦内と本邦外との間における直行便の乗継便又は経由便への変更</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">七　契約書面に記載した宿泊機関の種類又は名称の変更（当社が宿泊機関の等級を定めている場合であって、変更後の宿泊機関の等級が契約書面に記載した宿泊機関の等級を上回った場合を除きます。）</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>
                    <tr>
                      <td className="w-50per fz-13 fz-16-md fw-b">八　契約書面に記載した宿泊機関の客室の種類、設備、景観その他の客室の条件の変更</td>
                      <td className="fz-13 fz-16-md">1.0</td>
                      <td className="fz-13 fz-16-md">2.0</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={3}>
                        注一　「旅行開始前」とは、当該変更について旅行開始日の前日までに旅行者に通知した場合をいい、「旅行開始後」とは、当該変更について旅行開始当日以降に旅行者に通知した場合をいいます。<br />
                        注二　確定書面が交付された場合には、「契約書面」とあるのを「確定書面」と読み替えた上で、この表を適用します。この場合において、契約書面の記載内容と確定書面の記載内容との間又は確定書面の記載内容と実際に提供された旅行サービスの内容との間に変更が生じたときは、それぞれの変更につき一件として取り扱います。<br />
                        注三　第三号又は第四号に掲げる変更に係る運送機関が宿泊設備の利用を伴うものである場合は、一泊につき一件として取り扱います。<br />
                        注四　第四号に掲げる運送機関の会社名の変更については、等級又は設備がより高いものへの変更を伴う場合には適用しません。<br />
                        注五　第七号の宿泊機関の等級は、旅行契約締結の時点で契約書面に記載しているリスト又は当社の営業所若しくは当社のウェブページで閲覧に供しているリストによります。<br />
                        注六　第四号又は第七号若しくは第八号に掲げる変更が一乗車船等又は一泊の中で複数生じた場合であっても、一乗車船等又は一泊につき一件として取り扱います。<br />
                      </td>
                    </tr>
                  </table>
                </div>
              </section>

              {/* 旅行業約款（受注型企画旅行契約の部）ここまで */}


              {/* 旅行業約款（事業者を相手方とする受注型企画旅行契約の部）ここから */}

              {/* section: 第一章　総則 */} 
              <section id="sec_17" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
              <h2 className="heading-2 mt-0 mb-16 mb-24-md">旅行業約款（事業者を相手方とする受注型企画旅行契約の部）</h2>
                <h3 className="mb-12">{t("Agreement.第一章　総則")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">適用範囲</p>
                  <p className="mb-4">第一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が事業者との間で締結する受注型企画旅行に関する契約（以下「受注型企画旅行契約」といいます。）は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。</li>
                    <li>当社が法令に反せず、かつ、旅行者の不利にならない範囲で事業者と書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">用語の定義</p>
                  <p className="mb-4">第二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>この約款で「受注型企画旅行」とは、当社が、事業者からの依頼により、旅行者が参加するために、旅行の目的地及び日程、旅行者が提供を受けることができる運送又は宿泊のサービスの内容並びに事業者が当社に支払うべき旅行代金の額を定めた旅行に関する計画を作成し、これにより実施する旅行をいいます。</li>
                    <li>この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。</li>
                    <li>この部で「通信契約」とは、当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員との間で電話、郵便、ファクシミリその他の通信手段による申込みを受けて締結する受注型企画旅行契約であって、当社が事業者に対して有する受注型企画旅行契約に基づく旅行代金等に係る債権又は債務を、当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、事業者があらかじめ承諾し、かつ当該受注型企画旅行契約の旅行代金等を第十二条第二項、第十七条第一項第二文、第二十条第二項に定める方法により支払うことを内容とする受注型企画旅行契約をいいます。</li>
                    <li>この部で「電子承諾通知」とは、契約の申込みに対する承諾の通知であって、情報通信の技術を利用する方法のうち当社が使用する電子計算機、ファクシミリ装置、テレックス又は電話機（以下「電子計算機等」といいます。）と事業者が使用する電子計算機等とを接続する電気通信回線を通じて送信する方法により行うものをいいます。</li>
                    <li>この約款で「カード利用日」とは、事業者又は当社が受注型企画旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます。</li>
                    <li>この部で「事業者」とは、法人その他の団体及び事業として又は事業のために契約の当事者となる場合における個人をいいます。</li>
                    <li>この部で「旅行者」とは、当社と事業者との間の受注型企画旅行契約に基づき、当該旅行に参加する個人をいいます。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行契約の内容</p>
                  <p className="mb-4">第三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、受注型企画旅行契約において、旅行者が当社の定める旅行日程に従って、運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように、手配し、旅程を管理することを引き受けます。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">手配代行者</p>
                  <p className="mb-4">第四条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、受注型企画旅行契約の履行に当たって、手配の全部又は一部を本邦内又は本邦外の他の旅行業者、手配を業として行う者その他の補助者に代行させることがあります。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第二章　契約の締結 */}           
              <section id="sec_18" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第二章　契約の締結")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">企画書面の交付</p>
                  <p className="mb-4">第五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、当社に受注型企画旅行契約の申込みをしようとする事業者からの依頼があったときは、当社の業務上の都合があるときを除き、当該依頼の内容に沿って作成した旅行日程、旅行サービスの内容、旅行代金その他の旅行条件に関する企画の内容を記載した書面（以下「企画書面」といいます。）を交付します。</li>
                    <li>当社は、前項の企画書面において、旅行代金の内訳として企画に関する取扱料金（以下「企画料金」といいます。）の金額を明示することがあります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の申込み</p>
                  <p className="mb-4">第六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>前条第一項の企画書面に記載された企画の内容に関し、当社に受注型企画旅行契約の申込みをしようとする事業者は、当社所定の申込書（以下「申込書」といいます。）に所定の事項を記入の上、当社が別に定める金額の申込金とともに、当社に提出しなければなりません。</li>
                    <li>前条第一項の企画書面に記載された企画の内容に関し、当社に通信契約の申込みをしようとする事業者は、前項の規定にかかわらず、会員番号その他の事項を当社に通知しなければなりません。</li>
                    <li>第一項の申込金は、旅行代金（その内訳として金額が明示された企画料金を含みます。）又は取消料若しくは違約料の一部として取り扱います。</li>
                    <li>事業者は、当社が別に定める日までに、当社に対し、旅行者の人数、氏名、連絡先、旅券番号その他の旅行サービスを提供するために必要となる情報として当社が求める情報を提供しなければなりません。また、特別な配慮を必要とする旅行者がいる場合、同日までの間に、事業者が特別な配慮を必要とする旅行者がいることが確認でき次第、速やかに申し出てください。このとき、当社は可能な範囲内でこれに応じます。</li>
                    <li>前項の申出に基づき、当社が旅行者のために講じた特別な措置に要する費用は、事業者の負担とします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約締結の拒否</p>
                  <p className="mb-4">第七条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、受注型企画旅行契約の締結に応じないことがあります。
                      <ul className="list-kanji">
                        <li>旅行者が他の旅行者に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあるとき。</li>
                        <li>通信契約を締結しようとする場合であって、事業者の有するクレジットカードが無効である等、事業者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できないとき。</li>
                        <li>事業者（代表者、役員又は実質的に経営を支配する者を含む。以下本条において同じ。）又は旅行者が、暴力団、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。</li>
                        <li>事業者又は旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>事業者又は旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>その他当社の業務上の都合があるとき。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の成立時期</p>
                  <p className="mb-4">第八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>受注型企画旅行契約は、当社が契約の締結を承諾し、第六条第一項の申込金を受理した時に成立するものとします。</li>
                    <li>当社は、第六条第一項の規定にかかわらず、申込金の支払いを受けることなく受注型企画旅行契約の締結を承諾することがあります。この場合、当社は、事業者にその旨を記載した書面を交付するものとし、受注型企画旅行契約は、当社が当該書面を交付した時に成立するものとします。</li>
                    <li>通信契約は、前二項の規定にかかわらず、当社が契約の締結を承諾する旨の通知を発した時に成立するものとします。ただし、当該契約において電子承諾通知を発する場合は、当該通知が事業者に到達した時に成立するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約書面の交付</p>
                  <p className="mb-4">第九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、前条の定める契約の成立後速やかに、事業者に、旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面（以下「契約書面」といいます。）を交付します。</li>
                    <li>当社は、第五条第一項の企画書面において企画料金の金額を明示した場合は、当該金額を前項の契約書面において明示します。</li>
                    <li>当社が受注型企画旅行契約により手配し旅程を管理する義務を負う旅行サービスの範囲は、第一項の契約書面に記載するところによります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">確定書面</p>
                  <p className="mb-4">第十条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>前条第一項の契約書面において、確定された旅行日程、運送若しくは宿泊機関の名称を記載できない場合には、当該契約書面において利用予定の宿泊機関及び旅行計画上重要な運送機関の名称を限定して列挙した上で、当該契約書面交付後、旅行開始日の前日（旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に受注型企画旅行契約の申込みがなされた場合にあっては、旅行開始日）までの当該契約書面に定める日までに、事業者に、これらの確定状況を記載した書面（以下「確定書面」といいます。）を交付します。</li>
                    <li>前項の場合において、手配状況の確認を希望する事業者から問い合わせがあったときは、確定書面の交付前であっても、当社は迅速かつ適切にこれに回答します。</li>
                    <li>第一項の確定書面を交付した場合には、前条第三項の規定により当社が手配し旅程を管理する義務を負う旅行サービスの範囲は、当該確定書面に記載するところに特定されます。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">情報通信の技術を利用する方法</p>
                  <p className="mb-4">第十一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、あらかじめ事業者の承諾を得て、企画書面、受注型企画旅行契約を締結しようとするときに事業者に交付する旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面、契約書面又は確定書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項（以下この条において「記載事項」といいます。）を提供したときは、事業者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。</li>
                    <li>前項の場合において、事業者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル（専ら当該事業者の用に供するものに限ります。）に記載事項を記録し、事業者が記載事項を閲覧したことを確認します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金</p>
                  <p className="mb-4">第十二条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>事業者は、旅行開始日までの契約書面に記載する期日までに、当社に対し、契約書面に記載する金額の旅行代金を支払わなければなりません。</li>
                    <li>通信契約を締結したときは、当社は、提携会社のカードにより所定の伝票への事業者の署名なくして契約書面に記載する金額の旅行代金の支払いを受けます。また、別途、当社と事業者との間で旅行契約成立日を定めた場合を除き、カード利用日を旅行契約成立日とします。</li>
                  </ul>
                </div>
              </section>
              
              {/* section: 第三章　契約の変更 */}           
              <section id="sec_19" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第三章　契約の変更")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">契約内容の変更</p>
                  <p className="mb-4">第十三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>事業者は、当社に対し、旅行日程、旅行サービスの内容その他の受注型企画旅行契約の内容（以下「契約内容」といいます。）を変更するよう求めることができます。この場合において、当社は、可能な限り事業者の求めに応じます。</li>
                    <li>当社は、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供その他の当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、事業者にあらかじめ速やかに当該事由が関与し得ないものである理由及び当該事由との因果関係を説明して、契約内容を変更することがあります。ただし、緊急の場合において、やむを得ないときは、変更後に説明します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金の額の変更</p>
                  <p className="mb-4">第十四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>受注型企画旅行を実施するに当たり利用する運送機関について適用を受ける運賃・料金（以下この条において「適用運賃・料金」といいます。）が、著しい経済情勢の変化等により、受注型企画旅行の企画書面の交付の際に明示した時点において有効なものとして公示されている適用運賃・料金に比べて、通常想定される程度を大幅に超えて増額又は減額される場合においては、当社は、その増額又は減額される金額の範囲内で旅行代金の額を増加し、又は減少することができます。</li>
                    <li>当社は、前項の定めるところにより旅行代金を増額するときは、旅行開始日の前日から起算して さかのぼって十五日目に当たる日より前に事業者にその旨を通知します。</li>
                    <li>当社は、第一項の定める適用運賃・料金の減額がなされるときは、同項の定めるところにより、その減少額だけ旅行代金を減額します。</li>
                    <li>当社は、前条の規定に基づく契約内容の変更により旅行の実施に要する費用（当該契約内容の変更のためにその提供を受けなかった旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を含みます。）の減少又は増加が生じる場合（費用の増加が、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによる場合を除きます。）には、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することがあります。</li>
                    <li>当社は、運送・宿泊機関等の利用人員により旅行代金が異なる旨を契約書面に記載した場合において、受注型企画旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人員が変更になったときは、契約書面に記載したところにより旅行代金の額を変更することがあります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約上の地位の譲渡</p>
                  <p className="mb-4">第十五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社と受注型企画旅行契約を締結した事業者は、当社の承諾を得て、契約上の地位を第三者に譲り渡すことができます。</li>
                    <li>事業者は、前項に定める当社の承諾を求めようとするときは、当社所定の用紙に所定の事項を記入の上、所定の金額の手数料とともに、当社に提出しなければなりません。</li>
                    <li>第一項の契約上の地位の譲渡は、当社の承諾があった時に効力を生ずるものとし、以後、旅行契約上の地位を譲り受けた第三者は、事業者の当該受注型企画旅行契約に関する一切の権利及び義務を承継するものとします。なお、当該第三者が個人である場合であっても、当該受注型企画旅行契約については、契約上の地位の譲渡の効力発生後も、引き続き、この部の約款が適用されるものとする。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の変更</p>
                  <p className="mb-4">第十六条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社と受注型企画旅行契約を締結した事業者は、当社の承諾を得て、旅行者を変更することができます。</li>
                    <li>事業者は、前項に基づき旅行者を変更するときは、当社所定の用紙に所定の事項を記入の上、所定の金額の手数料とともに、当社に提出しなければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第四章　契約の解除 */}           
              <section id="sec_20" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">{t("Agreement.第四章　契約の解除")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">事業者の解除権</p>
                  <p className="mb-4">第十七条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>事業者は、いつでも「受注型企画旅行契約の部」の別表第一に定める取消料を当社に支払って受注型企画旅行契約を解除することができます。通信契約を解除する場合にあっては、当社は、提携会社のカードにより所定の伝票への事業者の署名なくして取消料の支払いを受けます。ただし、当社が事業者との間で、次項に定める特約を結んだときは、事業者は、当該特約に基づく取消料を支払わなければなりません。</li>
                    <li>当社は、第一条第二項の規定にかかわらず、事業者との間で、取消料の額について特約を結ぶことができます。ただし、当該特約に基づく取消料の総額が「受注型企画旅行契約の部」の別表第一に定める取消料の額を超え、かつ、事業者と旅行者との間の契約その他の合意により、旅行者が、本契約に基づく当該旅行者にかかる旅行代金を基礎として同別表第一により算出される取消料の額を超える額の取消料又は違約料を負担することとなっている場合、当該特約は無効とします。なお、当社が、受注型企画旅行契約締結時点で、旅行者が当該超過分を負担することを知らず、かつ、知らなかったことについて過失がない場合、事業者は、当該特約の無効を主張することはできません。</li>
                    <li>
                      事業者は、次に掲げる場合において、第一項の規定にかかわらず、旅行開始前に取消料を支払うことなく受注型企画旅行契約を解除することができます。
                      <ul className="list-kanji">
                        <li>当社によって契約内容が変更されたとき。ただし、その変更が「受注型企画旅行契約の部」の別表第二上欄に掲げるものその他の重要なものであるときに限ります。</li>
                        <li>第十四条第一項の規定に基づいて旅行代金が増額されたとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。</li>
                        <li>当社が事業者に対し、第十条第一項の期日までに、確定書面を交付しなかったとき。</li>
                        <li>当社の責に帰すべき事由により、契約書面に記載した旅行日程に従った旅行の実施が不可能となったとき。</li>
                      </ul>
                    </li>
                    <li>事業者は、旅行開始後において、事業者若しくは旅行者の責に帰すべき事由によらず契約書面に記載した旅行サービスを受領することができなくなったとき又は当社がその旨を告げたときは、第一項の規定にかかわらず、取消料を支払うことなく、旅行サービスの当該受領することができなくなった部分の契約を解除することができます。</li>
                    <li>前項の場合において、当社は、旅行代金のうち旅行サービスの当該受領することができなくなった部分に係る金額を事業者に払い戻します。ただし、前項の場合が当社の責に帰すべき事由によらない場合においては、当該金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを事業者に払い戻します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の解除権等－旅行開始前の解除</p>
                  <p className="mb-4">第十八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、事業者に理由を説明して、旅行開始前に受注型企画旅行契約の全部又は一部を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められるとき。</li>
                        <li>旅行者が他の旅行者に迷惑を及ぼし、又は団体旅行の円滑な実施を妨げるおそれがあると認められるとき。</li>
                        <li>事業者又は旅行者が、契約内容に関し合理的な範囲を超える負担を求めたとき。</li>
                        <li>スキーを目的とする旅行における必要な降雪量等の旅行実施条件であって契約の締結の際に明示したものが成就しないおそれが極めて大きいとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、契約書面に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。</li>
                        <li>通信契約を締結した場合であって、事業者の有するクレジットカードが無効になる等、事業者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できなくなったとき。</li>
                        <li>事業者（代表者、役員又は実質的に経営を支配する者を含む。）又は旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。</li>
                      </ul>
                    </li>
                    <li>事業者が第十二条第一項の契約書面に記載する期日までに旅行代金を支払わないときは、当該期日の翌日において事業者が受注型企画旅行契約を解除したものとします。この場合において、事業者は、当社に対し、「受注型企画旅行契約の部」の別表第一に定める取消料に相当する額の違約料を支払わなければなりません。ただし、前条第二項に規定する取消料の特約がある場合、事業者は、当該特約に基づく取消料に相当する額の違約料を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の解除権－旅行開始後の解除</p>
                  <p className="mb-4">第十九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、旅行開始後であっても、事業者に理由を説明して、受注型企画旅行契約の一部を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が病気、必要な介助者の不在その他の事由により旅行の継続に耐えられないとき。</li>
                        <li>旅行者が旅行を安全かつ円滑に実施するための添乗員その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等により団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。</li>
                        <li>事業者（代表者、役員又は実質的に経営を支配する者を含む。）又は旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。</li>
                        <li>天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。</li>
                      </ul>
                    </li>
                    <li>当社が前項の規定に基づいて受注型企画旅行契約を解除したときは、当社と事業者との間の契約関係は、将来に向かってのみ消滅します。この場合において、旅行者が既に提供を受けた旅行サービスに関する当社の債務については、有効な弁済がなされたものとします。</li>
                    <li>前項の場合において、当社は、旅行代金のうち旅行者がいまだその提供を受けていない旅行サービスに係る部分に係る金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを事業者に払い戻します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金の払戻し</p>
                  <p className="mb-4">第二十条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により受注型企画旅行契約が解除された場合において、事業者に対し払い戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に事業者に対し当該金額を払い戻します。</li>
                    <li>当社は、事業者と通信契約を締結した場合であって、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により通信契約が解除された場合において、事業者に対し払い戻すべき金額が生じたときは、提携会社のカード会員規約に従って、事業者に対し当該金額を払い戻します。この場合において、当社は、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に事業者に対し払い戻すべき額を通知するものとし、事業者に当該通知を行った日をカード利用日とします。</li>
                    <li>前二項の規定は第二十六条又は第二十九条第一項に規定するところにより事業者、旅行者又は当社が損害賠償請求権を行使することを妨げるものではありません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約解除後の帰路手配</p>
                  <p className="mb-4">第二十一条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、第十九条第一項第一号又は第四号の規定によって旅行開始後に受注型企画旅行契約を解除したときは、事業者又は旅行者の求めに応じて、旅行者が当該旅行の出発地に戻るために必要な旅行サービスの手配を引き受けます。</li>
                    <li>前項の場合において、出発地に戻るための旅行に要する一切の費用は、事業者の負担とします。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第五章　旅程管理 */}           
              <section id="sec_21" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第五章　旅程管理</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">旅程管理</p>
                  <p className="mb-4">第二十二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、旅行者の安全かつ円滑な旅行の実施を確保することに努力し、事業者又は旅行者に対し次に掲げる業務を行います。ただし、当社が事業者とこれと異なる特約を結んだ場合には、この限りではありません。
                      <ul className="list-kanji">
                        <li>旅行者が旅行中旅行サービスを受けることができないおそれがあると認められるときは、受注型企画旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講ずること。</li>
                        <li>前号の措置を講じたにもかかわらず、契約内容を変更せざるを得ないときは、代替サービスの手配を行うこと。この際、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努めること、また、旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努めること等、契約内容の変更を最小限にとどめるよう努力すること。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の指示</p>
                  <p className="mb-4">第二十三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>事業者及び旅行者は、旅行開始後旅行終了までの間において、団体で行動するときは、旅行を安全かつ円滑に実施するための当社の指示に従わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">添乗員の業務</p>
                  <p className="mb-4">第二十四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、旅行の内容により添乗員その他の者を同行させて第二十二条各号に掲げる業務その他当該受注型企画旅行に付随して当社が必要と認める業務の全部又は一部を行わせることがあります。</li>
                    <li>前項の添乗員その他の者が同項の業務に従事する時間帯は、原則として八時から二十時までとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">保護措置</p>
                  <p className="mb-4">第二十五条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、旅行中の旅行者が、疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用は事業者の負担とし、事業者は当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第六章　責任 */}           
              <section id="sec_22" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
              <h3 className="mb-12">第六章　責任</h3>
              <div className="card large-side-medium">
                <p className="fz-16 fw-b mb-8 c-navy">当社の責任</p>
                <p className="mb-4">第二十六条</p>
                <ul className="list-number-dot c-blue_gray">
                  <li>当社は、受注型企画旅行契約の履行に当たって、当社又は当社が第四条の規定に基づいて手配を代行させた者（以下「手配代行者」といいます。）が故意又は過失により事業者又は旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して二年以内に当社に対して通知があったときに限ります。</li>
                  <li>事業者又は旅行者が天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社又は当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は、前項の場合を除き、その損害を賠償する責任を負うものではありません。</li>
                  <li>当社は、手荷物について生じた第一項の損害については、同項の規定にかかわらず、損害発生の翌日から起算して、国内旅行にあっては十四日以内に、海外旅行にあっては二十一日以内に当社に対して通知があったときに限り、旅行者一名につき十五万円を限度（当社に故意又は重大な過失がある場合を除きます。）として賠償します。</li>
                </ul>

                <p className="fz-16 fw-b mb-8 c-navy">特別補償</p>
                <p className="mb-4">第二十七条</p>
                <ul className="list-number-dot c-blue_gray">
                  <li>当社は、前条第一項の規定に基づく当社の責任が生ずるか否かを問わず、別紙特別補償規程で定めるところにより、旅行者が受注型企画旅行参加中にその生命、身体又は手荷物の上に被った一定の損害について、あらかじめ定める額の補償金及び見舞金を旅行者に対して支払います。</li>
                  <li>前項の損害について当社が前条第一項の規定に基づく責任を負うときは、その責任に基づいて支払うべき損害賠償金の額の限度において、当社が支払うべき前項の補償金は、当該損害賠償金とみなします。</li>
                  <li>前項に規定する場合において、第一項の規定に基づく当社の補償金支払義務は、当社が前条第一項の規定に基づいて支払うべき損害賠償金（前項の規定により損害賠償金とみなされる補償金を含みます。）に相当する額だけ縮減するものとします。</li>
                  <li>当社の受注型企画旅行参加中の旅行者を対象として、別途の旅行代金を収受して当社が実施する募集型企画旅行については、受注型企画旅行契約の内容の一部として取り扱います。</li>
                </ul>

                <p className="fz-16 fw-b mb-8 c-navy">旅程保証</p>
                <p className="mb-4">第二十八条</p>
                <ul className="list-number-dot c-blue_gray">
                  <li>
                    当社は、「受注型企画旅行契約の部」の別表第二上欄に掲げる契約内容の重要な変更（次の各号に掲げる変更（運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによるものを除きます。）を除きます。）が生じた場合は、事業者に対し、当該変更が生じた旅行者にかかる旅行代金に同表下欄に記載する率を乗じた額以上の変更補償金を旅行終了日の翌日から起算して三十日以内に支払います。ただし、当該変更について当社に第二十六条第一項の規定に基づく責任が発生することが明らかである場合には、この限りではありません。
                    <ul className="list-kanji">
                      <li>
                        次に掲げる事由による変更
                        <ul className="list-iroha">
                          <li>天災地変</li>
                          <li>戦乱</li>
                          <li>暴動</li>
                          <li>官公署の命令</li>
                          <li>運送・宿泊機関等の旅行サービス提供の中止</li>
                          <li>当初の運行計画によらない運送サービスの提供</li>
                          <li>旅行参加者の生命又は身体の安全確保のため必要な措置</li>
                        </ul>
                      </li>
                      <li>第十三条第一項の規定に基づいて受注型企画旅行契約が変更されたときの当該変更された部分及び第十七条から第十九条までの規定に基づいて受注型企画旅行契約が解除されたときの当該解除された部分に係る変更</li>
                    </ul>
                  </li>
                  <li>当社が支払うべき変更補償金の額は、前項の変更が生じた旅行者にかかる旅行代金に十五％以上の当社が定める率を乗じた額をもって限度とします。旅行者一名あたりの変更補償金の額が千円未満であるときは、当社は、変更補償金を支払いません。</li>
                  <li>当社が第一項の規定に基づき変更補償金を支払った後に、当該変更について当社に第二十六条第一項の規定に基づく責任が発生することが明らかになった場合には、事業者は当該変更に係る変更補償金を当社に返還しなければなりません。この場合、当社は、同項の規定に基づき当社が支払うべき損害賠償金の額と事業者が返還すべき変更補償金の額とを相殺した残額を支払います。</li>
                </ul>

                <p className="fz-16 fw-b mb-8 c-navy">事業者及び旅行者の責任</p>
                <p className="mb-4">第二十九条</p>
                <ul className="list-number-dot c-blue_gray mb-0">
                  <li>事業者又は旅行者の故意又は過失により当社が損害を被ったときは、事業者は、損害を賠償しなければなりません。</li>
                  <li>事業者は、受注型企画旅行契約を締結するに際しては、当社から提供された情報を活用し、事業者及び旅行者の権利義務その他の受注型企画旅行契約の内容について理解するよう努めなければなりません。</li>
                  <li>旅行者は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者に申し出なければなりません。</li>
                </ul>
              </div>
            </section>

              {/* section: 第七章　弁済業務保証金 */}           
              <section id="sec_23" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第七章　弁済業務保証金</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">弁済業務保証金</p>
                  <p className="mb-4">第三十条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、一般社団法人日本旅行業協会（東京都千代田区霞が関三丁目3番3号）の保証社員になっております。</li>
                    <li>当社と受注型企画旅行契約を締結した事業者又は当該契約に基づき旅行に参加する旅行者は、その取引によって生じた債権に関し、前項の一般社団法人日本旅行業協会が供託している弁済業務保証金から7千万円に達するまで弁済を受けることができます。</li>
                    <li>当社は、旅行業法第二十二条の十第一項の規定に基づき、一般社団法人日本旅行業協会に弁済業務保証金分担金を納付しておりますので、同法第七条第一項に基づく営業保証金は供託しておりません。</li>
                  </ul>
                </div>
              </section>
              
              {/* 旅行業約款（事業者を相手方とする受注型企画旅行契約の部）ここまで */}


              {/* 旅行業約款（特別補償規程）ここから */}
              
              {/* section: 第一章　補償金等の支払い */}           
              <section id="sec_24" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Agreement.旅行業約款（特別補償規程）")}</h2>
                <h3 className="mb-12">{t("Agreement.第一章　補償金等の支払い")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の支払責任")}</p>
                  <p className="mb-4">{t("Agreement.第一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、当社が実施する企画旅行に参加する旅行者が、その企画旅行参加中に急激かつ偶然な外来の事故（以下「事故」といいます。）によって身体に傷害を被ったときに、本章から第四章までの規定により、旅行者又はその法定相続人に死亡補償金、後遺障害補償金、入院見舞金及び通院見舞金（以下「補償金等」といいます。）を支払います。　")}</li>
                    <li>{t("Agreement.前項の傷害には、身体外部から有毒ガス又は有毒物質を偶然かつ一時に吸入、吸収又は摂取したときに急激に生ずる中毒症状（継続的に吸入、吸収又は摂取した結果生ずる中毒症状を除きます。）を含みます。ただし、細菌性食物中毒は含みません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.用語の定義")}</p>
                  <p className="mb-4">{t("Agreement.第二条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.この規程において「企画旅行」とは、標準旅行業約款募集型企画旅行契約の部第二条第一項及び受注型企画旅行契約の部第二条第一項に定めるものをいいます。")}</li>
                    <li>{t("Agreement.この規程において「企画旅行参加中」とは、旅行者が企画旅行に参加する目的をもって当社があらかじめ手配した乗車券類等によって提供される当該企画旅行日程に定める最初の運送・宿泊機関等のサービスの提供を受けることを開始した時から最後の運送・宿泊機関等のサービスの提供を受けることを完了した時までの期間をいいます。ただし、旅行者があらかじめ定められた企画旅行の行程から離脱する場合において、離脱及び復帰の予定日時をあらかじめ当社に届け出ていたときは、離脱の時から復帰の予定の時までの間は「企画旅行参加中」とし、また、旅行者が離脱及び復帰の予定日時をあらかじめ当社に届け出ることなく離脱したとき又は復帰の予定なく離脱したときは、その離脱の時から復帰の時までの間又はその離脱した時から後は「企画旅行参加中」とはいたしません。また、当該企画旅行日程に、旅行者が当社の手配に係る運送・宿泊機関等のサービスの提供を一切受けない日（旅行地の標準時によります。）が定められている場合において、その旨及び当該日に生じた事故によって旅行者が被った損害に対しこの規程による補償金及び見舞金の支払いが行われない旨を契約書面に明示したときは、当該日は「企画旅行参加中」とはいたしません。")}</li>
                    <li>{t("Agreement.前項の「サービスの提供を受けることを開始した時」とは、次の各号のいずれかの時をいいます。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.添乗員、当社の使用人又は代理人が受付を行う場合は、その受付完了時")}
                        </li>
                        <li>{t("Agreement.前号の受付が行われない場合において、最初の運送・宿泊機関等が、")}
                          <ul className="list-iroha" hidden={languageCode !== LANGUAGES.JA}>
                            <li>{t("Agreement.航空機であるときは、乗客のみが入場できる飛行場内における手荷物の検査等の完了時")}</li>
                            <li>{t("Agreement.船舶であるときは、乗船手続の完了時")}</li>
                            <li>{t("Agreement.鉄道であるときは、改札の終了時又は改札のないときは当該列車乗車時")}</li>
                            <li>{t("Agreement.車両であるときは、乗車時")}</li>
                            <li>{t("Agreement.宿泊機関であるときは、当該施設への入場時")}</li>
                            <li>{t("Agreement.宿泊機関以外の施設であるときは、当該施設の利用手続終了時とします。")}</li>
                          </ul>

                          <ul className="list-number-dot" hidden={languageCode !== LANGUAGES.TW}>
                            <li>{t("Agreement.航空機であるときは、乗客のみが入場できる飛行場内における手荷物の検査等の完了時")}</li>
                            <li>{t("Agreement.船舶であるときは、乗船手続の完了時")}</li>
                            <li>{t("Agreement.鉄道であるときは、改札の終了時又は改札のないときは当該列車乗車時")}</li>
                            <li>{t("Agreement.車両であるときは、乗車時")}</li>
                            <li>{t("Agreement.宿泊機関であるときは、当該施設への入場時")}</li>
                            <li>{t("Agreement.宿泊機関以外の施設であるときは、当該施設の利用手続終了時とします。")}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>{t("Agreement.第二項の「サービスの提供を受けることを完了した時」とは、次の各号のいずれかの時をいいます。")}
                      <ul className="list-kanji mb-0">
                        <li>{t("Agreement.添乗員、当社の使用人又は代理人が解散を告げる場合は、その告げた時")}
                        </li>
                        <li>{t("Agreement.前号の解散の告知が行われない場合において、最後の運送・宿泊機関等が、")}
                          <ul className="list-iroha mb-0" hidden={languageCode !== LANGUAGES.JA}>
                            <li>{t("Agreement.航空機であるときは、乗客のみが入場できる飛行場構内からの退場時")}</li>
                            <li>{t("Agreement.船舶であるときは、下船時")}</li>
                            <li>{t("Agreement.鉄道であるときは、改札終了時又は改札のないときは当該列車降車時")}</li>
                            <li>{t("Agreement.車両であるときは、降車時")}</li>
                            <li>{t("Agreement.宿泊機関であるときは、当該施設からの退場時")}</li>
                            <li>{t("Agreement.宿泊機関以外の施設であるときは、当該施設からの退場時とします。")}</li>
                          </ul>

                          <ul className="list-number-dot mb-0" hidden={languageCode !== LANGUAGES.TW}>
                            <li>{t("Agreement.航空機であるときは、乗客のみが入場できる飛行場構内からの退場時")}</li>
                            <li>{t("Agreement.船舶であるときは、下船時")}</li>
                            <li>{t("Agreement.鉄道であるときは、改札終了時又は改札のないときは当該列車降車時")}</li>
                            <li>{t("Agreement.車両であるときは、降車時")}</li>
                            <li>{t("Agreement.宿泊機関であるときは、当該施設からの退場時")}</li>
                            <li>{t("Agreement.宿泊機関以外の施設であるときは、当該施設からの退場時とします。")}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
              
              {/* section: 第二章　補償金等を支払わない場合 */}           
              <section id="sec_25" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第二章　補償金等を支払わない場合")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償金等を支払わない場合－その一")}</p>
                  <p className="mb-4">{t("Agreement.第三条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、次の各号に掲げる事由によって生じた傷害に対しては補償金等を支払いません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者の故意。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。")}</li>
                        <li>{t("Agreement.死亡補償金を受け取るべき者の故意。ただし、その者が死亡補償金の一部の受取人である場合には、他の者が受け取るべき金額については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者の自殺行為、犯罪行為又は闘争行為。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者が法令に定められた運転資格を持たないで、又は酒に酔って正常な運転ができないおそれがある状態で自動車又は原動機付自転車を運転している間に生じた事故。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者が故意に法令に違反する行為を行い、又は法令に違反するサービスの提供を受けている間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者の脳疾患、疾病又は心神喪失。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者の妊娠、出産、早産、流産又は外科的手術その他の医療処置。ただし、当社の補償すべき傷害を治療する場合には、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者の刑の執行又は拘留若しくは入監中に生じた事故")}</li>
                        <li>{t("Agreement.戦争、外国の武力行使、革命、政権奪取、内乱、武装反乱その他これらに類似の事変又は暴動（この規程においては、群衆又は多数の者の集団の行動によって、全国又は一部の地区において著しく平穏が害され、治安維持上重大な事態と認められる状態をいいます。）")}</li>
                        <li>{t("Agreement.核燃料物質（使用済燃料を含みます。以下同様とします。）若しくは核燃料物質によって汚染された物（原子核分裂生成物を含みます。）の放射性、爆発性その他の有害な特性又はこれらの特性による事故")}</li>
                        <li>{t("Agreement.前二号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故")}</li>
                        <li>{t("Agreement.第十号以外の放射線照射又は放射能汚染")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社は、原因のいかんを問わず、頸部症候群（いわゆる「むちうち症」）又は腰痛で他覚症状のないものに対して、補償金等を支払いません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償金等を支払わない場合－その二")}</p>
                  <p className="mb-4">{t("Agreement.第四条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、国内旅行を目的とする企画旅行の場合においては、前条に定めるほか、次の各号に掲げる事由によって生じた傷害に対しても、補償金等を支払いません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.地震、噴火又は津波")}</li>
                        <li>{t("Agreement.前号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償金等を支払わない場合－その三")}</p>
                  <p className="mb-4">{t("Agreement.第五条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、次の各号に掲げる傷害に対しては、各号の行為が当社があらかじめ定めた企画旅行の旅行日程に含まれている場合でなければ、補償金等を支払いません。ただし、各号の行為が当該旅行日程に含まれている場合においては、旅行日程外の企画旅行参加中に、同種の行為によって生じた傷害に対しても、補償金等を支払います。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者が別表第一に定める運動を行っている間に生じた傷害")}</li>
                        <li>{t("Agreement.旅行者が自動車、原動機付自転車又はモーターボートによる競技、競争、興行（いずれも練習を含みます。）又は試運転（性能試験を目的とする運転又は操縦をいいます。）をしている間に生じた傷害。ただし、自動車又は原動機付自転車を用いて道路上でこれらのことを行っている間に生じた傷害については、企画旅行の旅行日程に含まれていなくとも補償金等を支払います。")}</li>
                        <li>{t("Agreement.航空運送事業者が路線を定めて運行する航空機（定期便であると不定期便であるとを問いません。）以外の航空機を旅行者が操縦している間に生じた傷害")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償金等を支払わない場合－その四")}</p>
                  <p className="mb-4">{t("Agreement.第五条の二")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社は、旅行者又は死亡補償金を受け取るべき者が次の各号に掲げるいずれかに該当する事由がある場合には、補償金等を支払わないことがあります。ただし、その者が死亡補償金の一部の受取人である場合には、他の者が受け取るべき金額については、この限りではありません。")}
                      <ul className="list-kanji mb-0">
                        <li>{t("Agreement.暴力団、暴力団員、暴力団準構成員、暴力団関係企業その他の反社会的勢力（以下「反社会的勢力」といいます。）に該当すると認められること。")}</li>
                        <li>{t("Agreement.反社会的勢力に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められること。")}</li>
                        <li>{t("Agreement.反社会的勢力を不当に利用していると認められること。")}</li>
                        <li>{t("Agreement.その他反社会的勢力と社会的に非難されるべき関係を有していると認められること。")}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>

              {/* section: 第三章　補償金等の種類及び支払額 */}           
              <section id="sec_26" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第三章　補償金等の種類及び支払額")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.死亡補償金の支払い")}</p>
                  <p className="mb-4">{t("Agreement.第六条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者が第一条の傷害を被り、その直接の結果として、事故の日から百八十日以内に死亡した場合は、旅行者一名につき、海外旅行を目的とする企画旅行においては二千五百万円、国内旅行を目的とする企画旅行においては千五百万円（以下「補償金額」といいます。）を死亡補償金として旅行者の法定相続人に支払います。ただし、当該旅行者について、既に支払った後遺障害補償金がある場合は、補償金額から既に支払った金額を控除した残額を支払います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.後遺障害補償金の支払い")}</p>
                  <p className="mb-4">{t("Agreement.第七条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者が第一条の傷害を被り、その直接の結果として、事故の日から百八十日以内に後遺障害（身体に残された将来においても回復できない機能の重大な障害又は身体の一部の欠損で、かつ、その原因となった傷害が治った後のものをいいます。以下同様とします。）が生じた場合は、旅行者一名につき、補償金額に別表第二の各号に掲げる割合を乗じた額を後遺障害補償金として旅行者に支払います。")}</li>
                    <li>{t("Agreement.前項の規定にかかわらず、旅行者が事故の日から百八十日を超えてなお治療を要する状態にあるときは、当社は、事故の日から百八十一日目における医師の診断に基づき後遺障害の程度を認定して、後遺障害補償金を支払います。")}</li>
                    <li>{t("Agreement.別表第二の各号に掲げていない後遺障害に対しては、旅行者の職業、年齢、社会的地位等に関係なく、身体の障害の程度に応じ、かつ、別表第二の各号の区分に準じ後遺障害補償金の支払額を決定します。ただし、別表第二の一（三）、一（四）、二（三）、四（四）及び五（二）に掲げる機能障害に至らない障害に対しては、後遺障害補償金を支払いません。")}</li>
                    <li>{t("Agreement.同一事故により二種以上の後遺障害が生じた場合には、当社は、その各々に対し前三項を適用し、その合計額を支払います。ただし、別表第二の七、八及び九に規定する上肢（腕及び手）又は下肢（脚及び足）の後遺障害に対しては、一肢ごとの後遺障害補償金は、補償金額の六〇％をもって限度とします。")}</li>
                    <li>{t("Agreement.前各項に基づいて当社が支払うべき後遺障害補償金の額は、旅行者一名に対して一企画旅行につき、補償金額をもって限度とします。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.入院見舞金の支払い")}</p>
                  <p className="mb-4">{t("Agreement.第八条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者が第一条の傷害を被り、その直接の結果として、平常の業務に従事すること又は平常の生活ができなくなり、かつ、入院（医師による治療が必要な場合において、自宅等での治療が困難なため、病院又は診療所に入り、常に医師の管理下において治療に専念することをいいます。以下この条において同様とします。）した場合は、その日数（以下「入院日数」といいます。）に対し、次の区分に従って入院見舞金を旅行者に支払います。")}
                      <ul className="list-kanji" hidden={languageCode !== LANGUAGES.JA}>
                        <li>{t("Agreement.海外旅行を目的とする企画旅行の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.入院日数百八十日以上の傷害を被ったとき。 四十万円")}</li>
                            <li>{t("Agreement.入院日数九十日以上百八十日未満の傷害を被ったとき。 二十万円")}</li>
                            <li>{t("Agreement.入院日数七日以上九十日未満の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.入院日数七日未満の傷害を被ったとき。 四万円")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.国内旅行を目的とする企画旅行の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.入院日数百八十日以上の傷害を被ったとき。 二十万円")}</li>
                            <li>{t("Agreement.入院日数九十日以上百八十日未満の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.入院日数七日以上九十日未満の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.入院日数七日未満の傷害を被ったとき。 二万円")}</li>
                          </ul>
                        </li>
                      </ul>

                      <ul className="list-kanji" hidden={languageCode !== LANGUAGES.TW}>
                        <li>{t("Agreement.海外旅行を目的とする企画旅行の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.入院日数百八十日以上の傷害を被ったとき。 四十万円")}</li>
                            <li>{t("Agreement.入院日数九十日以上百八十日未満の傷害を被ったとき。 二十万円")}</li>
                            <li>{t("Agreement.入院日数七日以上九十日未満の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.入院日数七日未満の傷害を被ったとき。 四万円")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.国内旅行を目的とする企画旅行の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.入院日数百八十日以上の傷害を被ったとき。 二十万円")}</li>
                            <li>{t("Agreement.入院日数九十日以上百八十日未満の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.入院日数七日以上九十日未満の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.入院日数七日未満の傷害を被ったとき。 二万円")}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>{t("Agreement.旅行者が入院しない場合においても、別表第三の各号のいずれかに該当し、かつ、医師の治療を受けたときは、その状態にある期間については、前項の規定の適用上、入院日数とみなします。")}</li>
                    <li>{t("Agreement.当社は、旅行者一名について入院見舞金と死亡補償金又は入院見舞金と後遺障害補償金を重ねて支払うべき場合には、その合計額を支払います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.通院見舞金の支払い")}</p>
                  <p className="mb-4">{t("Agreement.第九条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者が第一条の傷害を被り、その直接の結果として、平常の業務に従事すること又は平常の生活に支障が生じ、かつ、通院（医師による治療が必要な場合において、病院又は診療所に通い、医師の治療を受けること（往診を含みます。）をいいます。以下この条において同様とします。）した場合において、その日数（以下「通院日数」といいます。）が三日以上となったときは、当該日数に対し、次の区分にしたがって通院見舞金を旅行者に支払います。")}
                      <ul className="list-kanji" hidden={languageCode !== LANGUAGES.JA}>
                        <li>{t("Agreement.海外旅行を目的とする企画旅行の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.通院日数九十日以上の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.通院日数七日以上九十日未満の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.通院日数三日以上七日未満の傷害を被ったとき。 二万円")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.国内旅行を目的とする企画旅行の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.通院日数九十日以上の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.通院日数七日以上九十日未満の傷害を被ったとき。 二万五千円")}</li>
                            <li>{t("Agreement.通院日数三日以上七日未満の傷害を被ったとき。 一万円")}</li>
                          </ul>
                        </li>
                      </ul>

                      <ul className="list-kanji" hidden={languageCode !== LANGUAGES.TW}>
                        <li>以海外旅遊為目的的策劃旅遊
                          <ul className="list-number-dot">
                            <li>{t("Agreement.通院日数九十日以上の傷害を被ったとき。 十万円")}</li>
                            <li>{t("Agreement.通院日数七日以上九十日未満の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.通院日数三日以上七日未満の傷害を被ったとき。 二万円")}</li>
                          </ul>
                        </li>
                        <li>以國內旅遊為目的的策劃旅遊
                          <ul className="list-number-dot">
                            <li>{t("Agreement.通院日数九十日以上の傷害を被ったとき。 五万円")}</li>
                            <li>{t("Agreement.通院日数七日以上九十日未満の傷害を被ったとき。 二万五千円")}</li>
                            <li>{t("Agreement.通院日数三日以上七日未満の傷害を被ったとき。 一万円")}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>{t("Agreement.旅行者が通院しない場合においても、骨折等の傷害を被った部位を固定するために医師の指示によりギプス等を常時装着した結果、平常の業務に従事すること又は平常の生活に著しい支障が生じたと当社が認めたときは、その状態にある期間については、前項の規定の適用上、通院日数とみなします。")}</li>
                    <li>{t("Agreement.当社は、平常の業務に従事すること又は平常の生活に支障がない程度に傷害が治ったとき以降の通院に対しては、通院見舞金を支払いません。")}</li>
                    <li>{t("Agreement.当社は、いかなる場合においても、事故の日から百八十日を経過した後の通院に対しては、通院見舞金を支払いません。")}</li>
                    <li>{t("Agreement.当社は、旅行者一名について通院見舞金と死亡補償金又は通院見舞金と後遺障害補償金を重ねて支払うべき場合には、その合計額を支払います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.入院見舞金及び通院見舞金の支払いに関する特則")}</p>
                  <p className="mb-4">{t("Agreement.第十条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者一名について入院日数及び通院日数がそれぞれ一日以上となった場合は、前二条の規定にかかわらず、次の各号に掲げる見舞金のうちいずれか金額の大きいもの（同額の場合には、第一号に掲げるもの）のみを支払います。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.当該入院日数に対し当社が支払うべき入院見舞金")}</li>
                        <li>{t("Agreement.当該通院日数（当社が入院見舞金を支払うべき期間中のものを除きます。）に当該入院日数を加えた日数を通院日数とみなした上で、当該日数に対し当社が支払うべき通院見舞金")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.死亡の推定")}</p>
                  <p className="mb-4">{t("Agreement.第十一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者が搭乗する航空機若しくは船舶が行方不明となってから、又は遭難してから三十日を経過してもなお旅行者が発見されないときは、航空機若しくは船舶が行方不明となった日又は遭難した日に、旅行者が第一条の傷害によって死亡したものと推定します。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.他の身体障害又は疾病の影響")}</p>
                  <p className="mb-4">{t("Agreement.第十二条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.旅行者が第一条の傷害を被ったとき既に存在していた身体障害若しくは疾病の影響により、又は第一条の傷害を被った後にその原因となった事故と関係なく発生した傷害若しくは疾病の影響により第一条の傷害が重大となったときは、その影響がなかった場合に相当する金額を決定してこれを支払います。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第四章　事故の発生及び補償金等の請求の手続 */}           
              <section id="sec_27" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第四章　事故の発生及び補償金等の請求の手続")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.傷害程度等に関する説明等の請求")}</p>
                  <p className="mb-4">{t("Agreement.第十三条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者が第一条の傷害を被ったときは、当社は、旅行者又は死亡補償金を受け取るべき者に対し、傷害の程度、その原因となった事故の概要等について説明を求め、又は旅行者の身体の診療若しくは死体の検案を求めることがあります。この場合において、旅行者又は死亡補償金を受け取るべき者は、これらの求めに協力しなければなりません。")}</li>
                    <li>{t("Agreement.旅行者又は死亡補償金を受け取るべき者は、当社の関知しない事由により第一条の傷害を被ったときは、傷害の程度、その原因となった事故の概要等について、当社に対し、当該事故の日から三十日以内に報告しなければなりません。")}</li>
                    <li>{t("Agreement.旅行者又は死亡補償金を受け取るべき者が、当社の認める正当な理由なく前二項の規定に違反したとき又はその説明若しくは報告につき知っている事実を告げず、若しくは不実のことを告げたときは、当社は、補償金等を支払いません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償金等の請求")}</p>
                  <p className="mb-4">{t("Agreement.第十四条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者又は死亡補償金を受け取るべき者が補償金等の支払いを受けようとするときは、当社に対し、当社所定の補償金等請求書及び次に掲げる書類を提出しなければなりません。")}

                      {/* 日本語 */}
                      <ul className="list-kanji"　hidden={languageCode !== LANGUAGES.JA}>
                        <li>{t("Agreement.死亡補償金請求の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.旅行者の戸籍謄本並びに法定相続人の戸籍謄本及び印鑑証明書")}</li>
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.旅行者の死亡診断書又は死体検案書")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.後遺障害補償金請求の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.旅行者の印鑑証明書")}</li>
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.後遺障害の程度を証明する医師の診断書")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.入院見舞金請求の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.傷害の程度を証明する医師の診断書")}</li>
                            <li>{t("Agreement.入院日数又は通院日数を記載した病院又は診療所の証明書類")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.通院見舞金請求の場合")}
                          <ul className="list-iroha">
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.傷害の程度を証明する医師の診断書")}</li>
                            <li>{t("Agreement.入院日数又は通院日数を記載した病院又は診療所の証明書類")}</li>
                          </ul>
                        </li>
                      </ul>

                      {/* 繁体語 */}
                      <ul className="list-kanji"　hidden={languageCode !== LANGUAGES.TW}>
                        <li>{t("Agreement.死亡補償金請求の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.旅行者の戸籍謄本並びに法定相続人の戸籍謄本及び印鑑証明書")}</li>
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.旅行者の死亡診断書又は死体検案書")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.後遺障害補償金請求の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.旅行者の印鑑証明書")}</li>
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.後遺障害の程度を証明する医師の診断書")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.入院見舞金請求の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.傷害の程度を証明する医師の診断書")}</li>
                            <li>{t("Agreement.入院日数又は通院日数を記載した病院又は診療所の証明書類")}</li>
                          </ul>
                        </li>
                        <li>{t("Agreement.通院見舞金請求の場合")}
                          <ul className="list-number-dot">
                            <li>{t("Agreement.公の機関（やむを得ない場合には、第三者）の事故証明書")}</li>
                            <li>{t("Agreement.傷害の程度を証明する医師の診断書")}</li>
                            <li>{t("Agreement.入院日数又は通院日数を記載した病院又は診療所の証明書類")}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社は、前項以外の書類の提出を求めること又は前項の提出書類の一部の省略を認めることがあります。")}</li>
                    <li>{t("Agreement.旅行者又は死亡補償金を受け取るべき者が第一項の規定に違反したとき又は提出書類につき知っている事実を告げず、若しくは不実のことを告げたときは、当社は、補償金等を支払いません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.代位")}</p>
                  <p className="mb-4">{t("Agreement.第十五条")}</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>{t("Agreement.当社が補償金等を支払った場合でも、旅行者又はその相続人が旅行者の被った傷害について第三者に対して有する損害賠償請求権は、当社に移転しません。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 第五章　携帯品損害補償 */}           
              <section id="sec_28" className="mt-48-md mt-24 pt-16">
                <h3 className="mb-12">{t("Agreement.第五章　携帯品損害補償")}</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.当社の支払責任")}</p>
                  <p className="mb-4">{t("Agreement.第十六条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、当社が実施する企画旅行に参加する旅行者が、その企画旅行参加中に生じた偶然な事故によってその所有の身の回り品（以下「補償対象品」といいます。）に損害を被ったときに、本章の規定により、携帯品損害補償金（以下「損害補償金」といいます。）を支払います。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.損害補償金を支払わない場合―その一")}</p>
                  <p className="mb-4">{t("Agreement.第十七条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、次の各号に掲げる事由によって生じた損害に対しては、損害補償金を支払いません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.旅行者の故意。ただし、当該旅行者以外の者が被った損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者と世帯を同じくする親族の故意。ただし、旅行者に損害補償金を受け取らせる目的でなかった場合は、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者の自殺行為、犯罪行為又は闘争行為。ただし、当該旅行者以外の者が被った損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者が法令に定められた運転資格を持たないで、又は酒に酔って正常な運転ができないおそれがある状態で自動車又は原動機付自転車を運転している間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.旅行者が故意に法令に違反する行為を行い、又は法令に違反するサービスの提供を受けている間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.差押え、徴発、没収、破壊等国又は公共団体の公権力の行使。ただし、火災消防又は避難に必要な処置としてなされた場合を除きます。")}</li>
                        <li>{t("Agreement.補償対象品の瑕疵。ただし、旅行者又はこれに代わって補償対象品を管理する者が相当の注意をもってしても発見し得なかった瑕疵を除きます。")}</li>
                        <li>{t("Agreement.補償対象品の自然の消耗、さび、かび、変色、ねずみ食い、虫食い等")}</li>
                        <li>{t("Agreement.単なる外観の損傷であって補償対象品の機能に支障をきたさない損害")}</li>
                        <li>{t("Agreement.補償対象品である液体の流出。ただし、その結果として他の補償対象品に生じた損害については、この限りではありません。")}</li>
                        <li>{t("Agreement.補償対象品の置き忘れ又は紛失")}</li>
                        <li>{t("Agreement.第三条第一項第九号から第十二号までに掲げる事由")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社は、国内旅行を目的とする企画旅行の場合においては、前項に定めるほか、次の各号に掲げる事由によって生じた損害に対しても、損害補償金を支払いません。")}
                      <ul className="list-kanji mb-0">
                        <li>{t("Agreement.地震、噴火又は津波")}</li>
                        <li>{t("Agreement.前号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.損害補償金を支払わない場合－その二")}</p>
                  <p className="mb-4">{t("Agreement.第十七条の二")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社は、旅行者が次の各号に掲げるいずれかに該当する事由がある場合には、損害補償金を支払わないことがあります。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.反社会的勢力に該当すると認められること。")}</li>
                        <li>{t("Agreement.反社会的勢力に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められること。")}</li>
                        <li>{t("Agreement.反社会的勢力を不当に利用していると認められること。")}</li>
                        <li>{t("Agreement.法人である場合において、反社会的勢力がその法人を支配し又はその法人の経営に実質的に関与していると認められること。")}</li>
                        <li hidden={languageCode !== LANGUAGES.JA}>{t("Agreement.その他反社会的勢力と社会的に非難されるべき関係を有していると認められること。")}</li>
                        <li hidden={languageCode !== LANGUAGES.TW}>被認為與反社會勢力具有應遭到社會譴責的其他關係。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.補償対象品及びその範囲")}</p>
                  <p className="mb-4">{t("Agreement.第十八条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.補償対象品は、旅行者が企画旅行参加中に携行するその所有の身の回り品に限ります。")}</li>
                    <li>{t("Agreement.前項の規定にかかわらず、次の各号に掲げるものは、補償対象品に含まれません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.現金、小切手その他の有価証券、印紙、切手その他これらに準ずるもの")}</li>
                        <li>{t("Agreement.クレジットカード、クーポン券、航空券、パスポートその他これらに準ずるもの")}</li>
                        <li>{t("Agreement.稿本、設計書、図案、帳簿その他これらに準ずるもの（磁気テープ、磁気ディスク、シー・ディー・ロム、光ディスク等情報機器（コンピュータ及びその端末装置等の周辺機器）で直接処理を行える記録媒体に記録されたものを含みます。）")}</li>
                        <li>{t("Agreement.船舶（ヨット、モーターボート及びボートを含みます。）及び自動車、原動機付自転車及びこれらの付属品")}</li>
                        <li>{t("Agreement.山岳登はん用具、探検用具その他これらに類するもの")}</li>
                        <li>{t("Agreement.義歯、義肢、コンタクトレンズその他これらに類するもの")}</li>
                        <li>{t("Agreement.動物及び植物")}</li>
                        <li>{t("Agreement.その他当社があらかじめ指定するもの")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.損害額及び損害補償金の支払額")}</p>
                  <p className="mb-4">{t("Agreement.第十九条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社が損害補償金を支払うべき損害の額（以下「損害額」といいます。）は、その損害が生じた地及び時における補償対象品の価額又は補償対象品を損害発生の直前の状態に復するに必要な修繕費及び次条第三項の費用の合計額のいずれか低い方の金額を基準として定めることとします。")}</li>
                    <li>{t("Agreement.補償対象品の一個又は一対についての損害額が十万円を超えるときは、当社は、そのものの損害の額を十万円とみなして前項の規定を適用します。")}</li>
                    <li>{t("Agreement.当社が支払うべき損害補償金の額は、旅行者一名に対して一企画旅行につき十五万円をもって限度とします。ただし、損害額が旅行者一名について一回の事故につき三千円を超えない場合は、当社は、損害補償金を支払いません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.損害の防止等")}</p>
                  <p className="mb-4">{t("Agreement.第二十条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者は、補償対象品について第十六条に規定する損害が発生したことを知ったときは、次の事項を履行しなければなりません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.損害の防止軽減に努めること。")}</li>
                        <li>{t("Agreement.損害の程度、原因となった事故の概要及び旅行者が損害を被った補償対象品についての保険契約の有無を、遅滞なく当社に通知すること。")}</li>
                        <li>{t("Agreement.旅行者が他人から損害の賠償を受けることができる場合は、その権利の行使について必要な手続をとること。")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.当社は、旅行者が正当な理由なく前項第一号に違反したときは、防止軽減することができたと認められる額を差し引いた残額を損害の額とみなし、同項第二号に違反したときは、損害補償金を支払わず、また、同項第三号に違反したときは、取得すべき権利の行使によって受けることができたと認められる額を差し引いた残額を損害の額とみなします。")}</li>
                    <li>{t("Agreement.当社は、次に掲げる費用を支払います。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.第一項第一号に規定する損害の防止軽減のために要した費用のうちで当社が必要又は有益であったと認めたもの")}</li>
                        <li>{t("Agreement.第一項第三号に規定する手続のために必要な費用")}</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.損害補償金の請求")}</p>
                  <p className="mb-4">{t("Agreement.第二十一条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.旅行者は、損害補償金の支払いを受けようとするときは、当社に対し、当社所定の損害補償金請求書及び次に掲げる書類を提出しなければなりません。")}
                      <ul className="list-kanji">
                        <li>{t("Agreement.警察署又はこれに代わるべき第三者の事故証明書")}</li>
                        <li>{t("Agreement.補償対象品の損害の程度を証明する書類")}</li>
                        <li>{t("Agreement.その他当社の要求する書類")}</li>
                      </ul>
                    </li>
                    <li>{t("Agreement.旅行者が前項の規定に違反したとき又は提出書類につき故意に不実のことを表示し、又はその書類を偽造若しくは変造したとき（第三者をしてなさしめたときも、同様とします。）は、当社は、損害補償金を支払いません。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.保険契約がある場合")}</p>
                  <p className="mb-4">{t("Agreement.第二十二条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.第十六条の損害に対して保険金を支払うべき保険契約がある場合は、当社は、当社が支払うべき損害補償金の額を減額することがあります。")}</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">{t("Agreement.代位")}</p>
                  <p className="mb-4">{t("Agreement.第二十三条")}</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>{t("Agreement.当社が損害補償金を支払うべき損害について、旅行者が第三者に対して損害賠償請求権を有する場合には、その損害賠償請求権は、当社が旅行者に支払った損害補償金の額の限度内で当社に移転します。")}</li>
                  </ul>

                  <p className="mb-8"><span className="fw-b">{t("Agreement.別表第一")}</span>{t("Agreement.（第五条第一号関係）")}</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        {t("Agreement.山岳登はん（ピッケル、アイゼン、ザイル、ハンマー等の登山用具を使用するもの）　リュージュ　ボブスレー　スカイダイビング　ハンググライダー搭乗　超軽量動力機（モーターハンググライダー、マイクロライト機、ウルトラライト機等）搭乗　ジャイロプレーン搭乗その他これらに類する危険な運動")}
                      </td>
                    </tr>
                  </table>

                  <p className="mt-16 mt-24-md mb-8"><span className="fw-b">{t("Agreement.別表第二")}</span>{t("Agreement.（第七条第一項、第三項及び第四項関係）")}</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.一　眼の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　両眼が失明したとき。")}</td>
                      <td className="fz-13 fz-16-md">100％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　一眼が失明したとき。")}</td>
                      <td className="fz-13 fz-16-md">60％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　一眼の矯正視力が〇・六以下となったとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（四）　一眼の視野狭窄（正常視野の角度の合計の六〇％以下となった場合をいう。）となったとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.二　耳の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　両耳の聴力を全く失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">80％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　一耳の聴力を全く失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">30％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　一耳の聴力が五〇センチメートル以上では通常の話声を解せないとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.三　鼻の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.鼻の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">20％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.四　そしゃく、言語の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　そしゃく又は言語の機能を全く廃したとき。")}</td>
                      <td className="fz-13 fz-16-md">100％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　そしゃく又は言語の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">35％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　そしゃく又は言語の機能に障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">15％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（四）　歯に五本以上の欠損を生じたとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.五　外ぼう貌（顔面・頭部・頸部をいう。）の醜状")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　外貌に著しい醜状を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">15％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　外貌に醜状（顔面においては直径二センチメートルの瘢痕、長さ三センチメートルの線状こん痕程度をいう。）を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">3％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.六　脊柱の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　脊柱に著しい奇形又は著しい運動障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">40％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　脊柱に運動障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">30％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　脊柱に奇形を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">15％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.七　腕（手関節以上をいう。）、脚（足関節以上をいう。）の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　一腕又は一脚を失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">60％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　一腕又は一脚の三大関節中の二関節又は三関節の機能を全く廃したとき。")}</td>
                      <td className="fz-13 fz-16-md">50％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　一腕又は一脚の三大関節中の一関節の機能を全く廃したとき。")}</td>
                      <td className="fz-13 fz-16-md">35％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（四）　一腕又は一脚の機能に障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.八　手指の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　一手の母指を指関節（指節間関節）以上で失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">20％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　一手の母指の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">15％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　母指以外の一指を第二指関節（遠位指節間関節）以上で失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">8％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（四）　母指以外の一指の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.九　足指の障害")}</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（一）　一足の第一足指を趾関節（指節間関節）以上で失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">10％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（二）　一足の第一足指の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">8％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（三）　第一足指以外の一足指を第二趾関節（遠位指節間関節）以上で失ったとき。")}</td>
                      <td className="fz-13 fz-16-md">5％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.（四）　第一足指以外の一足指の機能に著しい障害を残すとき。")}</td>
                      <td className="fz-13 fz-16-md">3％</td>
                    </tr>

                    <tr>
                      <td className="w-50per fz-13 fz-16-md">{t("Agreement.十　その他身体の著しい障害により終身自用を弁ずることができないとき。")}</td>
                      <td className="fz-13 fz-16-md">100％</td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.注　第七号、第八号及び第九号の規定中「以上」とは、当該関節より心臓に近い部分をいいます。")}</td>
                    </tr>
                  </table>

                  <p className="mt-16 mt-24-md mb-8"><span className="fw-b">{t("Agreement.別表第三")}</span>{t("Agreement.（第八条第二項関係）")}</p>
                  <table className="table-xsmall">
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        {t("Agreement.一　両眼の矯正視力が〇・〇六以下になっていること。")}<br />
                        {t("Agreement.二　そしゃく又は言語の機能を失っていること。")}<br />
                        {t("Agreement.三　両耳の聴力を失っていること。")}<br />
                        {t("Agreement.四　両上肢の手関節以上のすべての関節の機能を失っていること。")}<br />
                        {t("Agreement.五　一下肢の機能を失っていること。")}<br />
                        {t("Agreement.六　胸腹部臓器の障害のため身体の自由が主に摂食、洗面等の起居動作に限られていること。")}<br />
                        {t("Agreement.七　神経系統又は精神の障害のため身体の自由が主に摂食、洗面等の起居動作に限られていること。")}<br />
                        {t("Agreement.八　その他上記部位の合併障害等のため身体の自由が主に摂食、洗面等の起居動作に限られていること。")}
                      </td>
                    </tr>

                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>{t("Agreement.（注）第四号の規定中「以上」とは、当該関節より心臓に近い部分をいいます。")}</td>
                    </tr>
                  </table>
                </div>
              </section>

              {/* 旅行業約款（特別補償規程）ここまで */}


              {/* 旅行業約款（特別補償規程）ここから */}

              {/* section: 第一章　総則 */}           
              <section id="sec_29" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
               <h2 className="heading-2 mt-0 mb-16 mb-24-md">旅行業約款（手配旅行契約の部）</h2>
                <h3 className="mb-12">第一章　総則</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">適用範囲</p>
                  <p className="mb-4">第一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が旅行者との間で締結する手配旅行契約は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。</li>
                    <li>当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">用語の定義</p>
                  <p className="mb-4">第二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>この約款で「手配旅行契約」とは、当社が旅行者の委託により、旅行者のために代理、媒介又は取次をすること等により旅行者が運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス（以下「旅行サービス」といいます。）の提供を受けることができるように、手配することを引き受ける契約をいいます。</li>
                    <li>この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。</li>
                    <li>この約款で「旅行代金」とは、当社が旅行サービスを手配するために、運賃、宿泊料その他の運送・宿泊機関等に対して支払う費用及び当社所定の旅行業務取扱料金（変更手続料金及び取消手続料金を除きます。）をいいます。</li>
                    <li>この部で「通信契約」とは、当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員との間で電話、郵便、ファクシミリその他の通信手段による申込みを受けて締結する手配旅行契約であって、当社が旅行者に対して有する手配旅行契約に基づく旅行代金等に係る債権又は債務を、当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、旅行者があらかじめ承諾し、かつ旅行代金等を第十六条第二項又は第五項に定める方法により支払うことを内容とする手配旅行契約をいいます。</li>
                    <li>この部で「電子承諾通知」とは、契約の申込みに対する承諾の通知であって、情報通信の技術を利用する方法のうち当社が使用する電子計算機、ファクシミリ装置、テレックス又は電話機（以下「電子計算機等」といいます。）と旅行者が使用する電子計算機等とを接続する電気通信回線を通じて送信する方法により行うものをいいます。</li>
                    <li>この約款で「カード利用日」とは、旅行者又は当社が手配旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">手配債務の終了</p>
                  <p className="mb-4">第三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が善良な管理者の注意をもって旅行サービスの手配をしたときは、手配旅行契約に基づく当社の債務の履行は終了します。したがって、満員、休業、条件不適当等の事由により、運送・宿泊機関等との間で旅行サービスの提供をする契約を締結できなかった場合であっても、当社がその義務を果たしたときは、旅行者は、当社に対し、当社所定の旅行業務取扱料金（以下「取扱料金」といいます。）を支払わなければなりません。通信契約を締結した場合においては、カード利用日は、当社が運送・宿泊機関等との間で旅行サービスの提供をする契約を締結できなかった旨、旅行者に通知した日とします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">手配代行者</p>
                  <p className="mb-4">第四条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、手配旅行契約の履行に当たって、手配の全部又は一部を本邦内又は本邦外の他の旅行業者、手配を業として行う者その他の補助者に代行させることがあります。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第二章　契約の成立 */}           
              <section id="sec_30" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第二章　契約の成立</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">契約の申込み</p>
                  <p className="mb-4">第五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社と手配旅行契約を締結しようとする旅行者は、当社所定の申込書に所定の事項を記入の上、当社が別に定める金額の申込金とともに、当社に提出しなければなりません。</li>
                    <li>当社と通信契約を締結しようとする旅行者は、前項の規定にかかわらず、会員番号及び依頼しようとする旅行サービスの内容を当社に通知しなければなりません。</li>
                    <li>第一項の申込金は、旅行代金、取消料その他の旅行者が当社に支払うべき金銭の一部として取り扱います。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約締結の拒否</p>
                  <p className="mb-4">第六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、手配旅行契約の締結に応じないことがあります。
                      <ul className="list-kanji">
                        <li>通信契約を締結しようとする場合であって、旅行者の有するクレジットカードが無効である等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できないとき。</li>
                        <li>旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。</li>
                        <li>旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>その他当社の業務上の都合があるとき。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の成立時期</p>
                  <p className="mb-4">第七条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>手配旅行契約は、当社が契約の締結を承諾し、第五条第一項の申込金を受理した時に成立するものとします。</li>
                    <li>通信契約は、前項の規定にかかわらず、当社が第五条第二項の申込みを承諾する旨の通知を発した時に成立するものとします。ただし、当該契約において電子承諾通知を発する場合は、当該通知が旅行者に到達した時に成立するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約成立の特則</p>
                  <p className="mb-4">第八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、第五条第一項の規定にかかわらず、書面による特約をもって、申込金の支払いを受けることなく、契約の締結の承諾のみにより手配旅行契約を成立させることがあります。</li>
                    <li>前項の場合において、手配旅行契約の成立時期は、前項の書面において明らかにします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">乗車券及び宿泊券等の特則</p>
                  <p className="mb-4">第九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、第五条第一項及び前条第一項の規定にかかわらず、運送サービス又は宿泊サービスの手配のみを目的とする手配旅行契約であって旅行代金と引換えに当該旅行サービスの提供を受ける権利を表示した書面を交付するものについては、口頭による申込みを受け付けることがあります。</li>
                    <li>前項の場合において、手配旅行契約は、当社が契約の締結を承諾した時に成立するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約書面</p>
                  <p className="mb-4">第十条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、手配旅行契約の成立後速やかに、旅行者に、旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面（以下「契約書面」といいます。）を交付します。ただし、当社が手配するすべての旅行サービスについて乗車券類、宿泊券その他の旅行サービスの提供を受ける権利を表示した書面を交付するときは、当該契約書面を交付しないことがあります。</li>
                    <li>前項本文の契約書面を交付した場合において、当社が手配旅行契約により手配する義務を負う旅行サービスの範囲は、当該契約書面に記載するところによります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">情報通信の技術を利用する方法</p>
                  <p className="mb-4">第十一条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、あらかじめ旅行者の承諾を得て、手配旅行契約を締結しようとするときに旅行者に交付する旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面又は契約書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項（以下この条において「記載事項」といいます。）を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。</li>
                    <li>前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル（専ら当該旅行者の用に供するものに限ります。）に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第三章　契約の変更及び解除 */}           
              <section id="sec_31" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第三章　契約の変更及び解除</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">契約内容の変更</p>
                  <p className="mb-4">第十二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、当社に対し、旅行日程、旅行サービスの内容その他の手配旅行契約の内容を変更するよう求めることができます。この場合において、当社は、可能な限り旅行者の求めに応じます。</li>
                    <li>前項の旅行者の求めにより手配旅行契約の内容を変更する場合、旅行者は、既に完了した手配を取り消す際に運送・宿泊機関等に支払うべき取消料、違約料その他の手配の変更に要する費用を負担するほか、当社に対し、当社所定の変更手続料金を支払わなければなりません。また、当該手配旅行契約の内容の変更によって生ずる旅行代金の増加又は減少は旅行者に帰属するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者による任意解除</p>
                  <p className="mb-4">第十三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、いつでも手配旅行契約の全部又は一部を解除することができます。</li>
                    <li>前項の規定に基づいて手配旅行契約が解除されたときは、旅行者は、既に旅行者が提供を受けた旅行サービスの対価として、又はいまだ提供を受けていない旅行サービスに係る取消料、違約料その他の運送・宿泊機関等に対して既に支払い、又はこれから支払う費用を負担するほか、当社に対し、当社所定の取消手続料金及び当社が得るはずであった取扱料金を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の責に帰すべき事由による解除</p>
                  <p className="mb-4">第十四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      当社は、次に掲げる場合において、手配旅行契約を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が所定の期日までに旅行代金を支払わないとき。</li>
                        <li>通信契約を締結した場合であって、旅行者の有するクレジットカードが無効になる等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できなくなったとき。</li>
                        <li>旅行者が第六条第二号から第四号までのいずれかに該当することが判明したとき。</li>
                      </ul>
                    </li>
                    <li>前項の規定に基づいて手配旅行契約が解除されたときは、旅行者は、いまだ提供を受けていない旅行サービスに係る取消料、違約料その他の運送・宿泊機関等に対して既に支払い、又はこれから支払わなければならない費用を負担するほか、当社に対し、当社所定の取消手続料金及び当社が得るはずであった取扱料金を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の責に帰すべき事由による解除</p>
                  <p className="mb-4">第十五条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>旅行者は、当社の責に帰すべき事由により旅行サービスの手配が不可能になったときは、手配旅行契約を解除することができます。</li>
                    <li>前項の規定に基づいて手配旅行契約が解除されたときは、当社は、旅行者が既にその提供を受けた旅行サービスの対価として、運送・宿泊機関等に対して既に支払い、又はこれから支払わなければならない費用を除いて、既に収受した旅行代金を旅行者に払い戻します。</li>
                    <li>前項の規定は、旅行者の当社に対する損害賠償の請求を妨げるものではありません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第四章　旅行代金 */}           
              <section id="sec_32" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第四章　旅行代金</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金</p>
                  <p className="mb-4">第十六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、旅行開始前の当社が定める期間までに、当社に対し、旅行代金を支払わなければなりません。</li>
                    <li>通信契約を締結したときは、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして旅行代金の支払いを受けます。この場合において、カード利用日は、当社が確定した旅行サービスの内容を旅行者に通知した日とします。</li>
                    <li>当社は、旅行開始前において、運送・宿泊機関等の運賃・料金の改訂、為替相場の変動その他の事由により旅行代金の変動を生じた場合は、当該旅行代金を変更することがあります。</li>
                    <li>前項の場合において、旅行代金の増加又は減少は、旅行者に帰属するものとします。</li>
                    <li>当社は、旅行者と通信契約を締結した場合であって、第三章又は第四章の規定により旅行者が負担すべき費用等が生じたときは、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして当該費用等の支払いを受けます。この場合において、カード利用日は旅行者が当社に支払うべき費用等の額又は当社が旅行者に払い戻すべき額を、当社が旅行者に通知した日とします。ただし、第十四条第一項第二号の規定により当社が手配旅行契約を解除した場合は、旅行者は、当社の定める期日までに、当社の定める支払方法により、旅行者が当社に支払うべき費用等を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行代金の精算</p>
                  <p className="mb-4">第十七条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、当社が旅行サービスを手配するために、運送・宿泊機関等に対して支払った費用で旅行者の負担に帰すべきもの及び取扱料金（以下「精算旅行代金」といいます。）と旅行代金として既に収受した金額とが合致しない場合において、旅行終了後、次項及び第三項に定めるところにより速やかに旅行代金の精算をします。</li>
                    <li>精算旅行代金が旅行代金として既に収受した金額を超えるときは、旅行者は、当社に対し、その差額を支払わなければなりません。</li>
                    <li>精算旅行代金が旅行代金として既に収受した金額に満たないときは、当社は、旅行者にその差額を払い戻します。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第五章　団体・グループ手配 */}           
              <section id="sec_33" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第五章　団体・グループ手配</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">団体・グループ手配</p>
                  <p className="mb-4">第十八条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、同じ行程を同時に旅行する複数の旅行者がその責任ある代表者（以下「契約責任者」といいます。）を定めて申し込んだ手配旅行契約の締結については、本章の規定を適用します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約責任者</p>
                  <p className="mb-4">第十九条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、特約を結んだ場合を除き、契約責任者はその団体・グループを構成する旅行者（以下「構成者」といいます。）の手配旅行契約の締結に関する一切の代理権を有しているものとみなし、当該団体・グループに係る旅行業務に関する取引及び第二十二条第一項の業務は、当該契約責任者との間で行います。</li>
                    <li>契約責任者は、当社が定める日までに、構成者の名簿を当社に提出し、又は人数を当社に通知しなければなりません。</li>
                    <li>当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。</li>
                    <li>当社は、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約成立の特則</p>
                  <p className="mb-4">第二十条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、契約責任者と手配旅行契約を締結する場合において、第五条第一項の規定にかかわらず、申込金の支払いを受けることなく手配旅行契約の締結を承諾することがあります。</li>
                    <li>前項の規定に基づき申込金の支払いを受けることなく手配旅行契約を締結する場合には、当社は、契約責任者にその旨を記載した書面を交付するものとし、手配旅行契約は、当社が当該書面を交付した時に成立するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">構成者の変更</p>
                  <p className="mb-4">第二十一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、契約責任者から構成者の変更の申出があったときは、可能な限りこれに応じます。</li>
                    <li>前項の変更によって生じる旅行代金の増加又は減少及び当該変更に要する費用は、構成者に帰属するものとします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">添乗サービス</p>
                  <p className="mb-4">第二十二条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、契約責任者からの求めにより、団体・グループに添乗員を同行させ、添乗サービスを提供することがあります。</li>
                    <li>添乗員が行う添乗サービスの内容は、原則として、あらかじめ定められた旅行日程上、団体・グループ行動を行うために必要な業務とします。</li>
                    <li>添乗員が添乗サービスを提供する時間帯は、原則として、八時から二十時までとします。</li>
                    <li>当社が添乗サービスを提供するときは、契約責任者は、当社に対し、所定の添乗サービス料を支払わなければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第六章　責任 */}           
              <section id="sec_34" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第六章　責任</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">当社の責任</p>
                  <p className="mb-4">第二十三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、手配旅行契約の履行に当たって、当社又は当社が第四条の規定に基づいて手配を代行させた者（以下「手配代行者」といいます。）が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して二年以内に当社に対して通知があったときに限ります。</li>
                    <li>旅行者が天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社又は当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は、前項の場合を除き、その損害を賠償する責任を負うものではありません。</li>
                    <li>当社は、手荷物について生じた第一項の損害については、同項の規定にかかわらず、損害発生の翌日から起算して、国内旅行にあっては十四日以内に、海外旅行にあっては二十一日以内に当社に対して通知があったときに限り、旅行者一名につき十五万円を限度（当社に故意又は重大な過失がある場合を除きます。）として賠償します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の責任</p>
                  <p className="mb-4">第二十四条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>旅行者の故意又は過失により当社が損害を被ったときは、当該旅行者は、損害を賠償しなければなりません。</li>
                    <li>旅行者は、手配旅行契約を締結するに際しては、当社から提供された情報を活用し、旅行者の権利義務その他の手配旅行契約の内容について理解するよう努めなければなりません。</li>
                    <li>旅行者は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者に申し出なければなりません。</li>
                  </ul>
                </div>
              </section>

              {/* section: 第七章　弁済業務保証金 */}           
              <section id="sec_35" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h3 className="mb-12">第七章　弁済業務保証金</h3>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">弁済業務保証金</p>
                  <p className="mb-4">第二十五条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、一般社団法人日本旅行業協会（東京都千代田区霞が関三丁目3番3号）の保証社員になっております。</li>
                    <li>当社と手配旅行契約を締結した旅行者又は構成者は、その取引によって生じた債権に関し、前項の一般社団法人日本旅行業協会が供託している弁済業務保証金から7千万円に達するまで弁済を受けることができます。</li>
                    <li>当社は、旅行業法第四十九条第一項の規定に基づき、一般社団法人日本旅行業協会に弁済業務保証金分担金を納付しておりますので、同法第七条第一項に基づく営業保証金は供託しておりません。</li>
                  </ul>
                </div>
              </section>

              {/* 旅行業約款（特別補償規程）ここまで */}


              {/* 旅行業約款（渡航手続代行契約の部）ここから */}    

              {/* section: 旅行業約款（渡航手続代行契約の部） */}           
              <section id="sec_36" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">旅行業約款（渡航手続代行契約の部）</h2>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">適用範囲</p>
                  <p className="mb-4">第一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が旅行者との間で締結する渡航手続代行契約は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。</li>
                    <li>当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">渡航手続代行契約を締結する旅行者</p>
                  <p className="mb-4">第二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が渡航手続代行契約を締結する旅行者は、当社と募集型企画旅行契約、受注型企画旅行契約若しくは手配旅行契約を締結した旅行者又は当社が受託している他の旅行業者の募集型企画旅行について当社が代理して契約を締結した旅行者とします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">渡航手続代行契約の定義</p>
                  <p className="mb-4">第三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      この約款で「渡航手続代行契約」とは、当社が渡航手続の代行に対する旅行業務取扱料金（以下「渡航手続代行料金」といいます。）を収受することを約して、旅行者の委託により、次に掲げる業務（以下「代行業務」といいます。）を行うことを引き受ける契約をいいます。
                      <ul className="list-kanji">
                        <li>旅券、査証、再入国許可及び各種証明書の取得に関する手続</li>
                        <li>出入国手続書類の作成</li>
                        <li>その他前各号に関連する業務</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の成立</p>
                  <p className="mb-4">第四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社と渡航手続代行契約を締結しようとする旅行者は、当社所定の申込書に所定の事項を記入の上、当社に提出しなければなりません。</li>
                    <li>渡航手続代行契約は、当社が契約の締結を承諾し、前項の申込書を受理した時に成立するものとします。</li>
                    <li>当社は、前二項の規定にかかわらず、申込書の提出を受けることなく電話、郵便、ファクシミリその他の通信手段による渡航手続代行契約の申込みを受け付けることがあります。この場合において、渡航手続代行契約は、当社が契約の締結を承諾した時に成立するものとします。</li>
                    <li>
                      当社は、次に掲げる場合において、渡航手続代行契約の締結に応じないことがあります。
                      <ul className="list-kanji">
                        <li>旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。</li>
                        <li>旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>その他当社の業務上の都合があるとき。</li>
                      </ul>
                    </li>
                    <li>当社は、渡航手続代行契約の成立後速やかに、旅行者に、当該渡航手続代行契約により引き受けた代行業務（以下「受託業務」といいます。）の内容、渡航手続代行料金の額、その収受の方法、当社の責任その他必要な事項を記載した書面を交付します。</li>
                    <li>当社は、あらかじめ旅行者の承諾を得て、前項の書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項（以下この条において「記載事項」といいます。）を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。</li>
                    <li>前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル（専ら当該旅行者の用に供するものに限ります。）に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">守秘義務</p>
                  <p className="mb-4">第五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、受託業務を行うに当たって知り得た情報を他に漏らすことのないようにいたします。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行者の義務</p>
                  <p className="mb-4">第六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、当社が定める期日までに、渡航手続代行料金を支払わなければなりません。</li>
                    <li>旅行者は、当社が定める期日までに、受託業務に必要な書類、資料その他の物（以下「渡航手続書類等」といいます。）を当社に提出しなければなりません。</li>
                    <li>当社が、受託業務を行うに当たって、本邦の官公署、在日外国公館その他の者に、手数料、査証料、委託料その他の料金（以下「査証料等」といいます。）を支払わなければならないときは、旅行者は、当社が定める期日までに当社に対して当該査証料等を支払わなければなりません。</li>
                    <li>受託業務を行うに当たって、郵送費、交通費その他の費用が生じたときは、旅行者は、当社が定める期日までに当社に対して当該費用を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の解除</p>
                  <p className="mb-4">第七条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>旅行者は、いつでも渡航手続代行契約の全部又は一部を解除することができます。</li>
                    <li>
                      当社は、次に掲げる場合において、渡航手続代行契約を解除することがあります。
                      <ul className="list-kanji">
                        <li>旅行者が、所定の期日までに渡航手続書類等を提出しないとき。</li>
                        <li>当社が、旅行者から提出された渡航手続書類等に不備があると認めたとき。</li>
                        <li>旅行者が、渡航手続代行料金、査証料等又は前条第四項の費用を所定の期日までに支払わないとき。</li>
                        <li>旅行者が第四条第四項第一号から第三号までのいずれかに該当することが判明したとき。</li>
                        <li>第三条第一号の代行業務を引き受けた場合において、旅行者が、当社の責に帰すべき事由によらず、旅券、査証又は再入国許可（以下「旅券等」といいます。）を取得できないおそれが極めて大きいと当社が認めるとき。</li>
                      </ul>
                    </li>
                    <li>前二項の規定に基づいて渡航手続代行契約が解除されたときは、旅行者は、既に支払った査証料等及び前条第四項の費用を負担するほか、当社に対し、当社が既に行った受託業務に係る渡航手続代行料金を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の責任</p>
                  <p className="mb-4">第八条</p>
                  <ul className="list-number-dot c-blue_gray mb-0">
                    <li>当社は、渡航手続代行契約の履行に当たって、当社が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して六月以内に当社に対して通知があったときに限ります。</li>
                    <li>当社は、渡航手続代行契約により、実際に旅行者が旅券等を取得できること及び関係国への出入国が許可されることを保証するものではありません。したがって、当社の責に帰すべき事由によらず、旅行者が旅券等の取得ができず、又は関係国への出入国が許可されなかったとしても、当社はその責任を負うものではありません。</li>
                  </ul>
                </div>
              </section>

              {/* 旅行業約款（渡航手続代行契約の部）ここまで */}


              {/* 旅行業約款（旅行相談契約の部）ここから */} 

              <section id="sec_37" className="mt-48-md mt-24 pt-16" hidden={languageCode !== LANGUAGES.JA}>
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">旅行業約款（旅行相談契約の部）</h2>
                <div className="card large-side-medium">
                  <p className="fz-16 fw-b mb-8 c-navy">適用範囲</p>
                  <p className="mb-4">第一条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が旅行者との間で締結する旅行相談契約は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。</li>
                    <li>当社が法令に反せず、かつ、旅行者に不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">旅行相談契約の定義</p>
                  <p className="mb-4">第二条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>
                      この約款で「旅行相談契約」とは、当社が相談に対する旅行業務取扱料金（以下「相談料金」といいます。）を収受することを約して、旅行者の委託により、次に掲げる業務を行うことを引き受ける契約をいいます。
                      <ul className="list-kanji">
                        <li>旅行者が旅行の計画を作成するために必要な助言</li>
                        <li>旅行の計画の作成</li>
                        <li>旅行に必要な経費の見積り</li>
                        <li>旅行地及び運送・宿泊機関等に関する情報提供</li>
                        <li>その他旅行に必要な助言及び情報提供</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の成立</p>
                  <p className="mb-4">第三条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社と旅行相談契約を締結しようとする旅行者は、所定の事項を記入した申込書を当社に提出しなければなりません。</li>
                    <li>旅行相談契約は、当社が契約の締結を承諾し、前項の申込書を受理した時に成立するものとします。</li>
                    <li>当社は、前二項の規定にかかわらず、申込書の提出を受けることなく電話、郵便、ファクシミリその他の通信手段による旅行相談契約の申込みを受け付けることがあります。この場合において、旅行相談契約は、当社が契約の締結を承諾した時に成立するものとします。</li>
                    <li>
                      当社は、次に掲げる場合において、旅行相談契約の締結に応じないことがあります。
                      <ul className="list-kanji">
                        <li>旅行者の相談内容が公序良俗に反し、若しくは旅行地において施行されている法令に違反するおそれがあるものであるとき。</li>
                        <li>旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。</li>
                        <li>旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。</li>
                        <li>その他当社の業務上の都合があるとき。</li>
                      </ul>
                    </li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">相談料金</p>
                  <p className="mb-4">第四条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社が第二条に掲げる業務を行ったときは、旅行者は、当社に対し、当社が定める期日までに、当社所定の相談料金を支払わなければなりません。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">契約の解除</p>
                  <p className="mb-4">第五条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は旅行者が第三条第四項第二号から第四号までのいずれかに該当することが判明したときは、旅行相談契約を解除することがあります。</li>
                  </ul>

                  <p className="fz-16 fw-b mb-8 c-navy">当社の責任</p>
                  <p className="mb-4">第六条</p>
                  <ul className="list-number-dot c-blue_gray">
                    <li>当社は、旅行相談契約の履行に当たって、当社が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して六月以内に当社に対して通知があったときに限ります。</li>
                    <li>当社は、当社が作成した旅行の計画に記載した運送・宿泊機関等について、実際に手配が可能であることを保証するものではありません。したがって、満員等の事由により、運送・宿泊機関等との間で当該機関が提供する運送、宿泊その他の旅行に関するサービスの提供をする契約を締結できなかったとしても、当社はその責任を負うものではありません。</li>
                  </ul>

                  <table className="table-xsmall">
                    <tr>
                      <td className="fz-13 fz-16-md" colSpan={2}>
                        <p className="mb-8">（苦情の申出）</p>
                        <p>旅行者は、当社との旅行業務に関する苦情について、当事者感で解決ができなかった場合には、下記の協会に、その解決について助力を求めるための申出をすることができます。</p>
                        <p className="mt-12 mb-12 ta-c">記</p>
                        <p>
                          名称　　一般社団法人　日本旅行業協会<br />
                          所在地　東京都千代田区霞が関3-3-3 全日通霞が関ビル3階<br />
                          電話　　03-3592-1266
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </section>

              {/* 旅行業約款（旅行相談契約の部）ここまで */}   
               

              <p className="mt-48">更新日：2021年3月23日</p>

            </div>
          </div>
        </div>

           
    </MainTemplate>
  );
};

export default Agreement;
