import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {IActivity} from "../../types/Activity";

type Props = {
  selectActivity: IActivity| undefined,
}

/**
 * プラン選択のアイコン説明
 * 
 * @returns 
 */
const PartsIconDescription: React.FC<Props> = ({ selectActivity }) => {

  const { t } = useTranslation();

  return (
    <div className="pl-8 pl-40-md pr-8 pr-40-md">
      <p className="fz-14 fz-16-md fw-b c-navy mb-8 mb-16-md">{t("ComprehensiveOptionDetailsModal.アイコンについて")}</p>
      <div className="box-light-gray medium mb-24">
        <ul className="box_select-hotel-icon-list">
          <li className="box_select-hotel-icon-list-item">
            <div className="box_select-hotel-icon-status">
              <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">{t("Common.空き")}</span>
              <i className="icon-possible fz-14 fz-16-md ml-5-md"></i>
            </div>
            <div className="fz-12 fz-14-md c-blue_gray">{t("ComprehensiveOptionDetailsModal.10名以上の空きあり")}</div>
          </li>
          {selectActivity?.timeType === "no_settime" ? (
            <>
              <li className="box_select-hotel-icon-list-item">
                <div className="box_select-hotel-icon-status">
                  <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">{t("Common.空き")}</span>
                  <span className="fz-14 lh-1 c-orange">9</span>
                </div>
                <div className="fz-12 fz-14-md c-blue_gray">{t("ComprehensiveOptionDetailsModal.1-9名の空きあり")}</div>
              </li>
            </>
          ):<></>}
          <li className="box_select-hotel-icon-list-item">
            <div className="box_select-hotel-icon-status">
              <span className="d-b fz-10 fz-14-md fw-b mb-5 mb-0-md c-navy">{t("Common.空き")}</span>
              <i className="icon-impossible fz-14 fz-16-md ml-5-md"></i>
            </div>
            <div className="fz-12 fz-14-md c-blue_gray">{t("ComprehensiveOptionDetailsModal.受付終了")}</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PartsIconDescription;
