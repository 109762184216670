import React from "react";

import StaySearchbox from "./../../search/StaySearchbox";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

import { Hotel } from "../../../types/Hotel"
import {
  PlanSelectFormData, 
} from "../../../types/planSelectForm";
import { useTranslation } from "react-i18next";

type Props = {
  canSelect: boolean,
  isLoading: boolean,
  hotels: Hotel | undefined,
  defaultHotels: Hotel | undefined,
  setHotels: any,
  planSelectFormData: PlanSelectFormData,
  selectPlan(planSelectFormData:PlanSelectFormData) : void,
  // callback: () => void;
}

const SectionStay: React.FC<Props> = ({
  canSelect,
  isLoading,
  hotels,
  defaultHotels,
  setHotels,
  planSelectFormData,
  selectPlan,
  // callback,
}) => {
  
  const { t } = useTranslation();

  const selectHotelPlan = (selectData: PlanSelectFormData ) => {
    selectPlan(selectData);
  }
  
  return (
    <>
        <section>
          <div className="wrap-content-900">
            {/* <h2 className="heading-2 mb-24-md">請選擇住宿</h2> */}
            <h2 className="heading-2 mb-24-md">{t("SectionStay.宿泊の選択")}</h2>

            { canSelect === false ? 
              <div className="box-white large">
                <p className="c-light_gray fw-b">
                  {/* 基本プランの日程を設定後に選択いただけます。 */}
                  {/* 決定好基本行程的出發日期後即可選擇 */}
                  {t("SectionStay.基本プランの日程を設定後に選択いただけます。")}
                </p>
              </div> 
              : 
              <>
                {hotels && hotels.hotelList && hotels.hotelList.length === 1 ?
                  <>
                    <p className="fz-14 fz-16-md fw-b mb-16 mb-20-md">
                      {/* ご希望の宿泊プランをお選びください */}
                      {/* 請選擇您所希望的住宿方案 */}
                      {t("SectionStay.ご希望の宿泊プランをお選びください")}
                    </p>
                  </>
                  : hotels ?
                    <>
                    <p className="fz-14 fz-16-md fw-b mb-16 mb-20-md">
                      {/* ご希望の宿泊施設をお選びください */}
                      {/* 請選擇您所希望的入住設施 */}
                      {t("SectionStay.ご希望の宿泊施設をお選びください")}
                    </p>
                  </>
                  :<></>
                }
                <StaySearchbox 
                  planSelectFormData={planSelectFormData}
                  isLoading={isLoading}
                  selectPlanSelectFormData={selectHotelPlan}
                  data={hotels}
                  defaultHotels={defaultHotels}
                  setHotels={setHotels}
                  // callback={callback}
                />
              </>
            }
            
          </div>
        </section>      
    </>
  );
};

export default SectionStay;
