export const LANGUAGES = {
  JA: "ja",
  TW: "tw",
}

export const RECAPTCHA_LANGUAGES = {
  JA: "ja",
  TW: "zh-TW",
}

export const DAYJS_LOCALES = {
  JA: "ja",
  TW: "zh-tw",
}

export const DATEPICKER_LOCALES = {
  JA: "ja",
  TW: "zhTW",
}