// import { Navigate, Outlet } from "react-router-dom";

// import { ReactNode } from "react";

// // ページ追加時に追加する
// import {
//   Form1,
//   Form2,
//   Form3,
//   Form4,
//   Form5,
//   Form6,
//   PlanSelectTop,
//   PlanSelectComplete,
//   Page404,
//   PageServiceStop,
//   Conditions,
//   Agreement
// } from "../components/";

// ページ追加する
export const ROUTES = {
  Default: "",
  Form1: "/:lang/form1",
  Form2: "/:lang/form2",
  Form4: "/:lang/form4",
  Form5: "/:lang/form5",
  Form6: "/:lang/form6",
  PlanSelectTop: "/:lang/:id",
  PlanSelectConfirm: "/:lang/planselectconfirm",
  PlanSelectComplete: "/:lang/planselectcomplete",
  ReservationCancelLogin: "/:lang/login",
  ReservationCancelDetail: "/:lang/ReservationCancelDetail",
  ReservationCancelFee: "/:lang/ReservationCancelFee",
  Page404: "/:lang/Page404",
  PageServiceStop: "/:lang/PageServiceStop",
  Conditions: "/:lang/Conditions",
  Agreement: "/:lang/Agreement",
  Privacypolicy: "/:lang/Privacypolicy",
  Faq: "/:lang/Faq",
};
