import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

//css
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {reserveCancelDetailResponce} from "../../../types/reserveCancel";
import {
  dateFormatWithLocale, 
  // getAge, 
  toCircled, 
  toDisplayTime
} from "../../../utils/convert";
// import {getActivityUseTime} from "../../../utils/plan";
import {getReserveGenderLabel} from "../../../utils/reserve";
import {getCarRentalAnshinOptionItem} from "../../../utils/carRental";
import i18n from "i18next";
// import {
//   convertCancelDetailFlightData,
//   getFlightClassLabel,
//   getFlightCodeByLabel,
//   getFlightCodeLabel,
//   getFlightNumberForDisplay,
//   getFlightTime,
//   getReserveFlightPeoples
// } from "../../../utils/flight";


//--------------------------------------------------
// テストデータ
//--------------------------------------------------
// //テストデータ(参加者)
// const createData_participant = (
//   participant_id: number,
//   participant_name: string,
//   participant_old: number,
//   participant_sexual: string,
//   participant_option: string,
//   ) => {
//   return { participant_id, participant_name, participant_old, participant_sexual, participant_option };
// };

// const rows_participant = [
//   createData_participant(1,"オキナワ タロウ", 41,"男性","",),
//   createData_participant(2,"オキナワ ハナコ", 40,"女性","",),
//   createData_participant(3,"オキナワ  イチロウ", 14,"男性","",),
//   createData_participant(4,"オキナワ サクラ", 11,"女性","",),
//   createData_participant(5,"オキナワ ジロウ", 2,"男性","添い寝：あり",),
// ];


// //テストデータ(フライト：住路)
// const createData_living_route = (
//   createData_living_route_id: string,
//   createData_living_route_departure_date: string,
//   createData_living_route_flight_number: string,
//   createData_living_route_class: string,
//   createData_living_route_seat: string,
//   ) => {
//   return { createData_living_route_id,
//     createData_living_route_departure_date,
//     createData_living_route_flight_number,
//     createData_living_route_class,
//     createData_living_route_seat,
//   };
// };

// const rows_createData_living_route = [
//   createData_living_route(
//     "①",
//     "2021年3月15日(月)", 
//     "ANA463(羽田空港 07:25 - 那覇空港 10:19)",
//     "普通席",
//     "大人3席／子供1席",
//     ),

//     createData_living_route(
//       "②",
//       "2021年3月15日(月)", 
//       "ANA463那覇空港 10:55 - 石垣空港 12:55)",
//       "普通席",
//       "大人3席／子供1席",
//       ),
// ];

// //テストデータ(フライト：復路)
// const createData_return_trip = (
//   createData_return_trip_id: string,
//   createData_return_trip_departure_date: string,
//   createData_return_trip_flight_number: string,
//   createData_return_trip_class: string,
//   createData_return_trip_seat: string,
//   ) => {
//   return { createData_return_trip_id,
//     createData_return_trip_departure_date,
//     createData_return_trip_flight_number,
//     createData_return_trip_class,
//     createData_return_trip_seat,
//   };
// };

// const rows_createData_return_trip = [
//   createData_return_trip(
//     "①",
//     "2021年3月17日(水)", 
//     "ANA463(那覇空港 07:25 - 羽田空港 10:19)",
//     "普通席",
//     "大人3席／子供1席",
//     ),

//     createData_return_trip(
//       "②",
//       "2021年3月15日(月)", 
//       "ANA463那覇空港 10:55 - 石垣空港 12:55)",
//       "普通席",
//       "大人3席／子供1席",
//       ),
// ];

// //テストデータ(宿泊)
// const createData_stay = (
//   createData_stay_hotel: string,
//   createData_stay_number: string,
//   createData_stay_checkin: string,
//   createData_stay_checkout: string,
//   createData_stay_roomtype: string,
//   createData_stay_plan: string,
//   createData_stay_room_number: number,

//   ) => {
//   return { 
//     createData_stay_hotel,
//     createData_stay_number,
//     createData_stay_checkin,
//     createData_stay_checkout,
//     createData_stay_roomtype,
//     createData_stay_plan,
//     createData_stay_room_number,
//   };
// };

// const rows_createData_stay = [
//   createData_stay(
//     "ホテルモントレ沖縄　スパ＆リゾート",
//     "111-2222",
//     "2021年3月15日(月) 15:00",
//     "2021年3月17日(水) 11:00",
//     "	デラックス＜禁煙／6F～11F＞",
//     "【2021 OTS夏旅】期間限定！非日常を感じて寛ぎステイ＜食事なし＞",
//     3
//     ),

//     createData_stay(
//       "ロワジール那覇",
//       "333-4444",
//       "2021年3月15日(月) 15:00",
//       "2021年3月17日(水) 11:00",
//       "	デラックス＜禁煙／6F～11F＞",
//       "【2021 OTS夏旅】期間限定！非日常を感じて寛ぎステイ＜食事なし＞",
//       4
//     ),
// ];

// //テストデータ(基本プラン)
// const createData_plan = (
//   createData_plan_id: string,
//   createData_plan_number: string,
//   createData_plan_date: string,
//   createData_plan_plan: string,
//   createData_plan_detail: string,
//   ) => {
//   return { 
//     createData_plan_id,
//     createData_plan_number,
//     createData_plan_date,
//     createData_plan_plan,
//     createData_plan_detail
//   };
// };
// const rows_createData_plan = [
//   createData_plan(
//     "①",
//     "111-2222",
//     "2021年3月16日(火) 11:00",
//     "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
//     "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
//     ),
//     createData_plan(
//       "②",
//       "111-2222",
//       "2021年3月16日(火) 11:00",
//       "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
//       "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
//     ),
// ];

// //テストデータ(レンタカー)
// const createData_rentacar = (
//   createData_rentacar_id: string,
//   createData_rentacar_number: string,
//   createData_rentacar_status: string,
//   createData_rentacar_lending: string,
//   createData_rentacar_return: string,
//   createData_rentacar_class: string,
//   createData_rentacar_option: string,
//   ) => {
//   return { 
//     createData_rentacar_id,
//     createData_rentacar_number,
//     createData_rentacar_status,
//     createData_rentacar_lending,
//     createData_rentacar_return,
//     createData_rentacar_class,
//     createData_rentacar_option,
//   };
// };
// const rows_createData_rentacar = [
//   createData_rentacar(
//     "①",
//     "111-2222",
//     "キャンセル済み",
//     "2021年3月15日(月) OTSレンタカー 臨空豊崎営業所(那覇空港)",
//     "2021年3月17日(水) OTSレンタカー 臨空豊崎営業所(那覇空港)",
//     "Sクラス(フィット、ヴィッツ)×1 HBFクラス(カローラフィールダーW×B)×1",
//     "幼児用チャイルドシート×1 学童用ジュニアシート×1",
//     ),
//     createData_rentacar(
//     "②",
//     "111-2222",
//     "",
//     "2021年3月15日(月) OTSレンタカー 臨空豊崎営業所(那覇空港)",
//     "2021年3月17日(水) OTSレンタカー 臨空豊崎営業所(那覇空港)",
//     "Sクラス(フィット、ヴィッツ)×1 HBFクラス(カローラフィールダーW×B)×1",
//     "幼児用チャイルドシート×1 学童用ジュニアシート×1",
//     ),
// ];

// //テストデータ(追加オプション)
// const createData_option = (
//   createData_option_id: string,
//   createData_option_number: string,
//   createData_option_status: string,
//   createData_option_date: string,
//   createData_option_plan: string,
//   createData_option_detail: string,
//   ) => {
//   return { 
//     createData_option_id,
//     createData_option_number,
//     createData_option_status,
//     createData_option_date,
//     createData_option_plan,
//     createData_option_detail,
//   };
// };
// const rows_createData_option = [
//   createData_option(
//     "①",
//     "111-2222",
//     "キャンセル済み",
//     "2021年3月16日(火) 11:00",
//     "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
//     "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
//     ),
//     createData_option(
//       "①",
//       "111-2222",
//       "",
//       "2021年3月16日(火) 11:00",
//       "手ぶらOK最新GoPro【青の洞窟体験ダイビング】完全貸切！新品マウスピース！無料撮影♪話題のGoPro9使用！すぐにスマホへ！ベテランインストラクターで初心者も安心！当日予約大歓迎！徹底除菌で衛生的♪プライベート感満喫！家族、カップル、女子旅等の友達同士に人気♪[支援対象]◯",
//       "10歳～54歳×2　8歳〜9歳×1　55歳〜59歳×1　新品マウスピース販売×1",
//     ),
// ];



//-------------------- ↑ここまでテストデータ↑ -----------------------------


// todo: 型指定する
type Props = {
  reservation: reserveCancelDetailResponce;
};


const SectionDetail: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();

  const reserve = props.reservation.data;
  // const flights = convertCancelDetailFlightData(props.reservation.data.subData.flight);
  // const flightPeoples = getReserveFlightPeoples(props.reservation);

  // const airLineName = props.reservation.data.subData.flight.length > 0 ? props.reservation.data.subData.flight[0].data.airline : "ANA";

  // // 末尾の「便」を抜いた表示用フライト番号を返す
  // const getFlightNumberForDisplayExcludeSuffix = (flightData: { airCode: string, airSubNo: string }) => {
  //   const flightNumber = getFlightNumberForDisplay(airLineName, flightData.airCode, flightData.airSubNo);

  //   return flightNumber.replace("便", "");
  // }


  // 敬称
  const showSuffix = () => {

    return t("ReservationCancelDetailSectionDetail.様");
  }


  return (
    <>
      <section>
        {/* 予約情報詳細 */}
        <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("ReservationCancelDetailSectionDetail.予約情報詳細")}</h2>
        {/* <h2 className="heading-2 mt-0 mb-16 mb-24-md">預約的詳細內容</h2> */}
        <div className="card large">
          <div className="mb-16 mb-24-md">
            <table className="table-xsmall">
              <tbody>
                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b w-110 w-175-md">
                    {t("ReservationCancelDetailSectionDetail.状態")}
                    {/* 狀態 */}
                  </th>
                  <td>
                    {/* 以下のステータスのどちらかが入る */}
                    { reserve.bookingData.status === 'reserved' ?
                      <span className="label-light_blue-sausage">{t("ReservationCancelDetailSectionDetail.予約済み")}</span>
                      // <span className="label-light_blue-sausage">已完成預約</span>
                      :
                      <span className="label-orange-sausage">{t("ReservationCancelDetailSectionDetail.キャンセル済み")}</span>
                      // <span className="label-orange-sausage">已完成取消</span>
                    }
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    {t("ReservationCancelDetailSectionDetail.旅行予約番号")}
                    {/* 行程預約號碼 */}
                  </th>
                  <td>
                    {/* 12-345678 */}
                    {reserve.bookingData.bookingId}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    {t("ReservationCancelDetailSectionDetail.予約日")}
                    {/* 預約日期 */}
                  </th>
                  <td>
                    {dateFormatWithLocale(reserve.bookingData.bookingDate, "YYYY年MM月DD日(ddd)", i18n.language)}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    {t("ReservationCancelDetailSectionDetail.予約者")}
                    {/* 預約者姓名 */}
                  </th>
                  <td>
                    {/* 沖縄 太郎 */}
                    {/* {reserve.bookingData.applicant.applicantName} */}
                    {reserve.bookingData.applicant.lastName} {reserve.bookingData.applicant.firstName}
                  </td>
                </tr>

                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b">
                    {t("ReservationCancelDetailSectionDetail.予約完了メール")}<br className="d-n d-b-md" />{t("ReservationCancelDetailSectionDetail.送信先")}
                    {/* 收取完成預約後的<br className="d-n d-b-md" />E-mail信箱 */}
                  </th>
                  <td>
                    <div className="mb-8">{reserve.bookingData.applicant.email}</div>
                    <ul className="list-note light-gray">
                      <li>{t("ReservationCancelDetailSectionDetail.迷惑メール設定をしている場合、「digitaldmoplatform.com」のメールを受信出来るように設定をお願いいたします。")}</li>
                      {/* <li>若有設定圾垃郵件，請將digitaldmoplatform.com設定成可收取的郵件。</li> */}
                      <li>{t("ReservationCancelDetailSectionDetail.予約完了メールはお送りしておりますので必ずご確認ください。")}</li>
                      {/* <li>預約完成後會發送預約確認信至您的E-mail，請您務必確認。</li> */}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 参加者情報 */}
          <div className="mb-16 mb-24">
            <table className="table-responsive table-xsmall">
              <tbody>
                <tr>
                  <th
                    colSpan={2}
                    className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large"
                  >
                    {t("ReservationCancelDetailSectionDetail.代表者情報")}
                  </th>
                </tr>
                <tr>
                  <th className="table-bgc-light_gray ta-c-md fw-b w-175-md">
                    {/* 参加者{toCircled(1)} */}
                    {t("ReservationCancelDetailSectionDetail.旅行の代表者")}
                    {/* 旅遊行程的代表者 */}
                  </th>
                  <td>
                    <p>
                      <span className="mr-10 fw-b">{`${reserve.bookingData.representative.lastName} ${reserve.bookingData.representative.firstName} ${showSuffix()}`}</span>
                      {/* <span className="mr-10 fz-14">{reserve.bookingData.representative.age}歳</span> */}
                      <span className="fz-14">{getReserveGenderLabel(reserve.bookingData.representative.gender)}</span>
                    </p>
                  </td>
                </tr>
                {/* {reserve?.bookingData?.travelCompanions?.map((travelCompanions, tIndex) => (
                  <Fragment key={tIndex}>
                    <tr>
                      <th className="table-bgc-light_gray ta-c-md fw-b w-175-md">
                        参加者{toCircled(tIndex + 2)}
                      </th>
                      <td>
                        <p>
                          <span className="mr-10 fw-b">{travelCompanions.lastNameKana + travelCompanions.firstNameKana} 様</span>
                          <span className="mr-10 fz-14">{getAge(travelCompanions.birthdate)}歳</span>
                          <span className="fz-14">{getReserveGenderLabel(travelCompanions.gender)}</span>
                        </p>
                        {getAge(travelCompanions.birthdate) <= 12 && (
                          <>
                            {travelCompanions.meal && travelCompanions.futon ?
                              <>
                                <p className="fz-12">食事・布団あり</p>
                              </>
                            : travelCompanions.meal ?
                              <>
                                <p className="fz-12">食事あり、布団なし</p>
                              </>
                            : travelCompanions.futon ?
                              <>
                                <p className="fz-12">食事なし、布団あり</p>
                              </>
                            : !travelCompanions.futon && !travelCompanions.meal ?
                              <>
                                <p className="fz-12">食事・布団なし</p>
                              </>:<></>
                            }
                          </>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                ))} */}
              </tbody>
            </table>
          </div>

          {/* 宿泊 */}
          <div className="mb-16 mb-24">
            <table className="table-xsmall">
              <tbody>
                <tr>
                  <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>{t("SectionSchedule.宿泊")}</th>
                  {/* <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>住宿</th> */}
                </tr>
                
                {/* 宿泊1 */}
                {reserve.subData.hotel.map((row, hId) => (
                  <Fragment key={hId}>
                  <tr>
                    <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>{row.data.hotelName}</th>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.予約番号")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約號碼</th> */}
                    <td>{row.data.bookingNumber?? ""}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("PlanSelectTop.チェックイン")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">入住日期</th> */}
                    <td>{dateFormatWithLocale(row.data.check_in, "YYYY年MM月DD日（ddd）", i18n.language)}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("PlanSelectTop.チェックアウト")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">退房日期</th> */}
                    <td>{dateFormatWithLocale(row.data.check_out, "YYYY年MM月DD日（ddd）", i18n.language)}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("Hotel.部屋タイプ")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">房型</th> */}
                    <td>{row.data.roomName}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.予約プラン")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">住宿方案</th> */}
                    <td>{row.data.planName}</td>
                  </tr>
                  <tr>
                    <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("Hotel.客室数")}</th>
                    {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">房間数</th> */}
                    <td>{row.data.count_rooms}{t("Hotel.室")}</td>
                    {/* <td>{row.data.count_rooms}間</td> */}
                  </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>  
          </div>

          {reserve.subData.plan && (
            <>
              {/* 基本プラン */}
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>{t("SectionSchedule.基本プラン")}</th>
                    {/* <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>基本行程</th> */}
                  </tr>

                  {/* 基本プラン1 */}

                  {reserve.subData.plan.map((row,rId) => (
                    <Fragment key={rId}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>{t("SectionSchedule.基本プラン")}{toCircled(row.data.order)}</th>
                        {/* <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>基本行程{toCircled(row.data.order)}</th> */}
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.予約番号")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約號碼</th> */}
                        <td>{row.data.bookingNumber ?? ""}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ScheduleDetailModal.ご利用日")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">利用日期</th> */}
                        <td>{dateFormatWithLocale(row.data.use_date, "YYYY年MM月DD日（ddd）", i18n.language) + toDisplayTime(row.data.use_time)}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.プラン名")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">方案名稱</th> */}
                        <td>{row.data.planName}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.申し込み内容")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約內容</th> */}
                        <td>
                          {row.data.price_type_detail.map((priceDetail, pId) => (
                            <Fragment key={pId}>
                              {priceDetail.num > 0 && (
                                <>
                                  {priceDetail.name} × {priceDetail.num}
                                </>
                              )}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {reserve.subData.rentalCar  && (
            <>
              {/* レンタカー */}
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>{t("CarRental.レンタカー")}</th>
                    {/* <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>租車</th> */}
                  </tr>

                  {reserve.subData?.rentalCar?.map((row, rIndex) => (
                    <Fragment key={rIndex}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>
                          <div className="d-f jc-sb">
                            <p>{t("CarRental.レンタカー")}{toCircled(row.data.order)}</p>
                            {/* <p>租車{toCircled(row.data.order)}</p> */}
                            {
                              (() => {
                                if (row.data.status === "cancel") {
                                  return (<p><span className="label-orange-sausage">{t("ReservationCancelDetailSectionDetail.キャンセル済み")}</span></p>);
                                  // return (<p><span className="label-orange-sausage">已完成取消</span></p>);
                                }
                              })()
                            }
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.予約番号")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約號碼</th> */}
                        <td>{row.data.bookingNumber}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("CarRental.貸出")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">取車</th> */}
                        <td>
                          {row.data.departure_date_label}<br/>
                          {row.data.departure_office_label}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("CarRental.返却")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">還車</th> */}
                        <td>
                          {row.data.return_date_label}<br/>
                          {row.data.return_office_label}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("CarRental.クラス")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">車款等級</th> */}
                        <td>
                          {row.data.car_detail.map((carDetail, cId) => (
                            <Fragment key={cId}>
                              {carDetail.grade}({carDetail.car_model})
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("CarRental.オプション")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">追加項目</th> */}
                        <td>
                          {row.data.car_detail[0]?.count_option?.anshin?.used && (
                            <>
                              {t('CarRental.'+getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin.option_name, 'label', i18n.language))}　
                            </>
                          )}
                          {row.data.car_detail[0]?.count_option?.anshin_premium?.used && (
                            <>
                              {t('CarRental.'+getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin_premium.option_name, 'label', i18n.language))}　
                            </>
                          )}
                          {row.data.count_option.baby_seat > 0 && (
                            <>
                              {t("CarRental.乳幼児ベビーシート")} × {row.data.count_option.baby_seat}　
                              {/* 嬰兒座椅 × {row.data.count_option.baby_seat}　 */}
                            </>
                          )}
                          {row.data.count_option.child_seat > 0 && (
                            <>
                              {t("CarRental.幼児用チャイルドシート")} × {row.data.count_option.child_seat}　
                              {/* 幼兒座椅 × {row.data.count_option.child_seat}　 */}
                            </>
                          )}
                          {row.data.count_option.junior_seat > 0 && (
                            <>
                              {t("CarRental.学童用ジュニアシート")} × {row.data.count_option.junior_seat}　
                              {/* 兒童用座椅 × {row.data.count_option.junior_seat}　 */}
                            </>
                          )}
                        </td>
                      </tr>

                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {/* 追加オプション */}
          {reserve.subData.options && (
            <>
              <div className="mb-16 mb-24">
                <table className="table-xsmall">
                  <tbody>
                  <tr>
                    <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>{t("SectionDetail.追加オプション")}</th>
                    {/* <th className="table-bgc-nevy fw-b fz-16 fz-18-md padding-side-large" colSpan={2}>追加項目</th> */}
                  </tr>

                  {/* 追加オプション1 */}
                  {reserve.subData?.options?.map((row,oIndex) => (
                    <Fragment key={oIndex}>
                      <tr>
                        <th className="table-bgc-light_navy fw-b padding-side-large" colSpan={2}>
                          <div className="d-f jc-sb">
                            <p>{t("SectionDetail.追加オプション")}{toCircled(row.data.order)}</p>
                            {/* <p>追加項目{toCircled(row.data.order)}</p> */}
                            {
                              (() => {
                                if (row.data.status === "cancel") {
                                  return (<p><span className="label-orange-sausage">{t("ReservationCancelDetailSectionDetail.キャンセル済み")}</span></p>);
                                  // return (<p><span className="label-orange-sausage">已完成取消</span></p>);
                                }
                              })()
                            }
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.予約番号")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約號碼</th> */}
                        <td>{row.data.bookingNumber}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ScheduleDetailModal.ご利用日")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">利用日期</th> */}
                        <td>{dateFormatWithLocale(row.data.use_date, "YYYY年MM月DD日（ddd）", i18n.language) + toDisplayTime(row.data.use_time)}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.プラン名")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">方案名稱</th> */}
                        <td>{row.data.planName}</td>
                      </tr>
                      <tr>
                        <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">{t("ReservationCancelDetailSectionDetail.申し込み内容")}</th>
                        {/* <th className="table-bgc-light_gray fw-b w-120 w-170-md ta-c-md">預約內容</th> */}
                        <td>
                          {row.data.price_type_detail.map((priceDetail, pId) => (
                            <Fragment key={pId}>
                              {priceDetail.num > 0 && (
                                <>
                                  {priceDetail.name} × {priceDetail.num}　
                                </>
                              )}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SectionDetail;
