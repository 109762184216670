import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { InputsForm4 } from "../../../types/InputsForm4";
import { InputText } from "../../input/InputText";
import { Select } from "../../input/Select";
import { PartsCardExpireYear, PartsMonth } from "../../parts";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/config";
import { LANGUAGES } from "../../../constants/languages";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";



type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm4>;
}

const SectionPayment: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    <>
      <section>
        <h2 className="heading-2">{t("SectionPayment.お支払い方法")}</h2>
        {/* <h2 className="heading-2">支付方式</h2> */}
        <div className="card mb-20">
          <div className="box-light-gray small mb-24">
            <p>{t("SectionPayment.お支払いはクレジットカード決済のみとなります。")}</p>
            {/* <p>支付方式僅限線上刷卡。</p> */}
            <p>
              {t("SectionPayment.クレジットカードの名義は、お申込者様(契約者)のものでなければお引き受けできません。予めご確認、ご了承ください。")}
              {/* 請注意，信用卡持有人姓名需與預約申請人（契約者）相同，若不相同請恕無法受理您的預約。 */}
            </p>
          </div>
          <div className="form-accompany">
            <div className="form-accompany-item">
              <div className="form-accompany-item-heading">
                {t("SectionPayment.クレジットカード情報")}
                {/* 輸入卡信息 */}
                <span className="label-required ml-5">必須</span>
              </div>
              <div className="form-accompany-item-content">
                <div className="box-separator_bottom-large">
                  {/*利用可能なクレジットカード */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">
                          {/* 利用可能な
                          <br />
                          クレジットカード */}
                          {/* 可使用信用卡 */}
                          {
                            i18n.language === LANGUAGES.JA ? (
                              <>利用可能な<br />クレジットカード</>
                            ) : (
                              <>可使用信用卡</>
                            )
                          }
                        </h3>
                      </div>
                      <div className="form-content-input">
                        <img
                          src={
                            require("../../../img/share/icon/card_brand.jpg")
                              .default
                          }
                          alt={`${t("SectionPayment.利用可能なクレジットカード")}`}
                          // alt="可使用信用卡"
                        />
                      </div>
                    </div>
                  </div>

                  {/*カード番号 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">{t("SectionPayment.カード番号")}</h3>
                        {/* <h3 className="heading-3-small">信用卡號</h3> */}
                      </div>
                      <div className="form-content-input">
                        <div>
                          <InputText
                            name="payment.cashcard_number"
                            placeholder="例：1234 5678 9012 3456"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 16,
                              pattern: /^(\d{14}|\d{15}|\d{16})$/
                            }}
                            error={props.errors.payment?.cashcard_number}
                          ></InputText>
                          <ul className="list-note">
                            <li>{t("SectionPayment.「 - 」ハイフン無しでご入力ください。")}</li>
                            {/* <li>半角輸入（不加連字符）</li> */}
                          </ul>
                          {/* todo: error表示までcomponentに入れるか？ 入れないとmaxlengthとかの制御がめんどくさい。*/}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "required" && (
                              <p className="form-error_text">
                                {t("SectionPayment.カード番号をご入力ください。")}
                                {/* 請填入信用卡卡號。 */}
                              </p>
                            )}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "pattern" && (
                              <p className="form-error_text">
                                {t("SectionPayment.カード番号を正しくご入力ください。")}
                                {/* 請填入正確的信用卡號。 */}
                              </p>
                            )}
                          {props.errors.payment?.cashcard_number &&
                            props.errors.payment?.cashcard_number.type ===
                              "maxLength" && (
                              <p className="form-error_text">
                                {t("SectionPayment.16桁以内でご入力ください。")}
                                {/* 信用卡號請填入16以內的文字。 */}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*有効期限 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">{t("SectionPayment.有効期限")}</h3>
                        {/* <h3 className="heading-3-small">有効期間</h3> */}
                      </div>
                      <div className="form-content-input">
                        <div
                          className={
                            "form-birthday" +
                            (props.errors.payment?.cashcard_month ||
                            props.errors.payment?.cashcard_year
                              ? " error"
                              : "")
                          }
                        >
                          <div className="form-birthday-month">
                            <div className="select_box-default">
                              <PartsMonth
                                name="payment.cashcard_month"
                                register={props.register}
                                options={{ required: true }}
                              />
                            </div>
                            <p className="mr-10 ml-10 color-dark_blue">
                              月
                            </p>
                          </div>

                          <div className="form-birthday-year">
                            <div className="select_box-default">
                              <PartsCardExpireYear
                                name="payment.cashcard_year"
                                register={props.register}
                                options={{ required: true }}
                              />
                            </div>
                            <p className="mr-10 ml-10 color-dark_blue">
                              年
                            </p>
                          </div>
                          {(props.errors.payment?.cashcard_month ||
                            props.errors.payment?.cashcard_year) && (
                            <p className="form-error_text">
                              {t("SectionPayment.有効期限を選択ください。")}
                              {/* 請選擇有効期間。 */}
                            </p>
                          )}      
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*カード名義人 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">{t("SectionPayment.カード名義人")}</h3>
                        {/* <h3 className="heading-3-small">持卡人姓名</h3> */}
                      </div>
                      <div className="form-content-input">
                        <div>
                          <InputText
                            name="payment.cashcard_name"
                            placeholder="例：TARO OKINAWA"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 50,
                            }}
                            error={props.errors.payment?.cashcard_name}
                          ></InputText>
                          <ul className="list-note">
                            <li>
                              {t("SectionPayment.半角英数字、半角スペースで入力してください。")}
                              {/* 英文字母及空格請以半形輸入（例：TARO OKINAWA） */}
                            </li>
                          </ul>
                          {props.errors.payment?.cashcard_name &&
                            props.errors.payment?.cashcard_name.type ===
                              "required" && (
                              <p className="form-error_text">
                                {t("SectionPayment.カード名義人をご入力ください。")}
                                {/* 請填入信用卡持有人姓名 */}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*支払い回数 */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">{t("SectionPayment.支払い回数")}</h3>
                        {/* <h3 className="heading-3-small">分期次數</h3> */}
                      </div>
                      <div className="form-content-input">
                        <div className="w-100 d-f ai-c">
                          <div className="select_box-default">
                            <Select
                                name="payment.cashcard_number_of_payments"
                                register={props.register}
                                options={{ required: true }}
                                selectOptions={[
                                  { value: "10", label: "01" },
                                  { value: "02", label: "02" },
                                  { value: "03", label: "03" },
                                  { value: "06", label: "06" },
                                  { value: "12", label: "12" },
                                  { value: "24", label: "24" },
                                ]}
                              />
                          </div>
                          <p className="mr-10 ml-10 color-dark_blue">
                            回
                          </p>
                        </div>
                        {props.errors.payment?.cashcard_number_of_payments && (
                          <p className="form-error_text">
                            {t("SectionPayment.支払い回数を選択ください。")}
                            {/* 請選擇分期次數。 */}
                          </p>
                        )} 
                      </div>
                    </div>
                  </div>

                  {/*セキュリティコード */}
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div className="form-content">
                      <div className="form-content-heading">
                        <h3 className="heading-3-small">{t("SectionPayment.セキュリティコード")}</h3>
                        {/* <h3 className="heading-3-small">安全碼</h3> */}
                      </div>
                      <div
                        className={
                          "form-content-input" +
                          (props.errors.payment?.cashcard_cvc
                            ? " error"
                            : "")
                        }
                      >
                        <div className="w-100">
                          <InputText
                            name="payment.cashcard_cvc"
                            placeholder="例：123"
                            register={props.register}
                            options={{
                              required: true,
                              maxLength: 4, 
                              pattern: /^(\d{3}|\d{4})$/  // アメリカン・エキスプレスは4桁
                            }}
                            error={props.errors.payment?.cashcard_cvc}
                          ></InputText>
                        </div>
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "required" && (
                            <p className="form-error_text">
                              {t("SectionPayment.セキュリティコードをご入力ください。")}
                              {/* 請填入信用卡安全碼。 */}
                            </p>
                        )}
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "pattern" && (
                            <p className="form-error_text">
                              {t("SectionPayment.セキュリティコードを正しくご入力ください。")}
                              {/* 請填入正確的安全碼。 */}
                            </p>
                        )}
                        {props.errors.payment?.cashcard_cvc &&
                          props.errors.payment?.cashcard_cvc.type === "maxLength" && (
                            <p className="form-error_text">
                              {t("SectionPayment.4桁以内でご入力ください。")}
                              {/* 安全碼請填入4以內的文字。 */}
                            </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionPayment;
