import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { clear as clearReservationCancel } from "../redux/reservationCancelSlice";
import { useTranslation } from "react-i18next";

//MainTemplate
import MainTemplate from "./MainTemplate";

//css
import "../scss/common.scss";
import "../scss/component.scss";

//import parts
import {PartsCancellationChargeText} from "./parts"
import {
  SectionDetail,
  SectionTravelPrice
} from "./section/ReservationCancelDetail"
import {reserveCancelDetailResponce} from "../types/reserveCancel";
import { ROUTES } from "../constants/routes";
import { LANGUAGES } from "../constants/languages";



const ReservationCancelDetail: React.FC = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // 15分経過でログイン画面に戻す
  const [time, setTime] = useState(0);

  const timeoutMinutes = 15;

  const intervalSeconds = 5;

  useEffect(() => {
    const id = setInterval(() => {
      setTime(t => t + 1);
    }, intervalSeconds * 1000); // 5秒ごとに更新

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // 15分で終了
    if (time >= (timeoutMinutes * 60 / intervalSeconds)) {
      // redux内のデータをクリア
      dispatch(clearReservationCancel());
      navigate(ROUTES.ReservationCancelLogin.replace(":lang", i18n.language), { replace: true });
    }

    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ time, dispatch, navigate ])
  
  
  // reduxに保存されているデータを取得
  const savedReservationCancelState = useSelector((state: RootState) => state.reservationCancel);

  // ログイン画面で取得した予約情報
  const saveReserveData = savedReservationCancelState.reservation as reserveCancelDetailResponce;
  const reservation = saveReserveData.data;

  // 予約情報が無ければログイン画面にリダイレクト
  if (reservation === undefined) {

    const path = window.location.pathname;

    const languageCode = path.startsWith(`/${LANGUAGES.JA}/`) ? LANGUAGES.JA : LANGUAGES.TW;

    return (
      <Navigate
        to={ROUTES.ReservationCancelLogin.replace(":lang", languageCode)}
        replace
      />
    );
  }

  const handleClick = () => {
    navigate(ROUTES.ReservationCancelFee.replace(":lang", i18n.language));
  }

  return (
    <MainTemplate
      title={t("ReservationCancelDetail.予約情報詳細")}
      logo={undefined}
      supportDial={undefined} 
      sellerId={undefined}
      planSelectFormData={undefined}
    >

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("ReservationCancelDetail.予約情報詳細")}</h1>
            {/* <h1 className="l-page-content-upper_navi-heading">預約的詳細內容</h1> */}
          </div>
        </div>

        <div className="l-page-content">
          <div className="pt-24 pt-56-md">
            <div className="wrap-content-900">

              {/* 予約情報詳細 */}
              <SectionDetail reservation={saveReserveData} />
           
              {/* 旅行代金 */}
              <SectionTravelPrice reservation={saveReserveData} />
            
              {/* キャンセル料について */}
              <PartsCancellationChargeText />
            
              {/* 
                個別キャンセルボタン 
                ※キャンセル後はボタン非表示
              */}
              {reservation.bookingData.status === "reserved" && (
                <div className="mt-50 mt-64-md">
                  <button className="button-large-red w-100per" onClick={() => handleClick()}>{t("ReservationCancelDetail.旅行の一部または全体をキャンセルする")}</button>
                  {/* <button className="button-large-red w-100per" onClick={() => handleClick()}>取消部份或全部的行程內容</button> */}
                </div>
              )}
            </div>
          </div>
        </div>

    </MainTemplate>
  );
};

export default ReservationCancelDetail;
