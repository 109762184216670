// TODO: add airport code for select box
export const Airports = {
    NAHA: '那覇空港',
}

// TODO: valueで使う数字を入れる
export const AirportNumber = {
    '那覇空港': '47'
}

export const departureAirCode: {[key:string]:string} = {
  "TOKYO": "東京（羽田・成田）",
  "HND" : "羽田空港",
  "NRT" : "成田国際空港",
  "OSA" : "大阪（伊丹・関空・神戸）",
  "ITM" : "伊丹空港",
  "KIX" : "関西国際空港",
  "UKB" : "神戸空港",
  "NGO": "中部国際空港",
  "FUK" : "福岡空港",
  "CTS" : "新千歳空港",
  "FSZ": "静岡空港",
  "KMQ" : "小松空港",
  "OKJ" : "岡山空港",
  "HIJ" : "広島空港",
  "IWK" : "岩国空港",
  "TAK" : "高松空港",
  "MYJ" : "松山空港",
  "KMJ" : "熊本空港",
  "KMI" : "宮崎空港",
  "KOJ" : "鹿児島空港",
  // okinawan island
  // "ISG": "石垣空港",
  // "MMY": "宮古空港",
  // "UEO": "久米島空港",
}

export const multiAirport: {[key:string]:string} = {
  "TOKYO": "関東",
  "HND" : "関東",
  "NRT" : "関東",
  "OSA" : "関西",
  "ITM" : "関西",
  "KIX" : "関西",
  "UKB" : "関西",
  "FUK" : "九州",
  // "HSG" : "九州",
  // "KKJ" : "九州",
}

export const compositeAirport: {[key:string]:string} = {
  "HND" : "TOKYO",
  "NRT" : "TOKYO",
  "ITM" : "OSA",
  "KIX" : "OSA",
  "UKB" : "OSA",
}

// 到着または出発が複数あるパターンのもの
export const multipleArrivals = [
  'TOKYO',
  'OSA'
]

export const connectingAirCode: {[key:string]:string} = {
  // 関東
  "TOKYO": "東京（羽田・成田）",
  "HND": "羽田空港",
  "NRT": "成田国際空港",
  // 関西
  "OSA" : "大阪（伊丹・関空・神戸）",
  "ITM": "伊丹空港",
  "KIX": "関空空港",
  "UKB": "神戸空港",
  // 九州
  "FUK": "福岡空港",
  // "HSG": "佐賀空港",
  // "KKJ": "北九州空港",
}

export const okinawaAirCode: {[key:string]:string} = {
  "OKA": "那覇空港",
  "ISG": "石垣空港",
  "MMY": "宮古空港",
  "UEO": "久米島空港",
}

export const airCode: {[key:string]:string} = {
  "TOKYO": "東京（羽田・成田）",
  "OSA" : "大阪（伊丹・関空・神戸）",
  "AGJ": "粟国空港",
  "AKJ": "旭川空港",
  "AOJ": "青森空港",
  "ASJ": "奄美空港",
  "AXT": "秋田空港",
  "SPK": "新千歳空港",
  "CTS": "新千歳空港",
  "FKS": "福島空港",
  "FSZ": "静岡空港",
  "FUJ": "五島福江空港",
  "FUK": "福岡空港",
  "GAJ": "山形空港",
  "HAC": "八丈島空港",
  "HIJ": "広島空港",
  "HIW": "広島西飛行場",
  "HKD": "函館空港",
  "HNA": "いわて花巻空港",
  "HND": "羽田空港",
  "HSG": "有明佐賀空港",
  "HTR": "波照間空港",
  "IKI": "壱岐空港",
  "ISG": "石垣空港",
  "ITM": "伊丹空港",
  "IWJ": "石見空港",
  "IZO": "出雲空港",
  "KCZ": "高知龍馬空港",
  "KIJ": "新潟空港",
  "KIX": "関西国際空港",
  "KJP": "慶良間空港",
  "KKJ": "北九州空港",
  "KKX": "喜界空港",
  "KMI": "宮崎空港",
  "KMJ": "熊本空港",
  "KMQ": "小松空港",
  "KOJ": "鹿児島空港",
  "KTD": "北大東空港",
  "KUH": "釧路空港",
  "KUM": "屋久島空港",
  "MBE": "オホーツク紋別空港",
  "MMB": "女満別空港",
  "MMD": "南大東空港",
  "MMJ": "松本空港",
  "MMY": "宮古空港",
  "MSJ": "三沢空港",
  "MYE": "三宅島空港",
  "MYJ": "松山空港",
  "NGO": "中部国際空港",
  "NGS": "長崎空港",
  "NKM": "名古屋空港",
  "NRT": "成田国際空港",
  "NTQ": "能登空港",
  "OBO": "帯広空港",
  "OGN": "与那国空港",
  "OIM": "大島空港",
  "OIR": "奥尻空港",
  "OIT": "大分空港",
  "OKA": "那覇空港",
  "OKD": "丘珠空港",
  "OKE": "沖永良部空港",
  "OKI": "隠岐空港",
  "OKJ": "岡山空港",
  "ONJ": "大館能代空港",
  "RBJ": "礼文空港",
  "RIS": "利尻空港",
  "RNJ": "与論空港",
  "SDJ": "仙台空港",
  "SDS": "佐渡空港",
  "SHB": "中標津空港",
  "SHI": "下地島空港",
  "SHM": "南紀白浜空港",
  "SYO": "庄内空港",
  "TAK": "高松空港",
  "TKG": "コウノトリ但馬空港",
  "TKN": "徳之島空港",
  "TKS": "徳島空港",
  "TNE": "種子島空港",
  "TOY": "富山空港",
  "TRA": "新多良間空港",
  "TSJ": "対馬空港",
  "TTJ": "鳥取空港",
  "UBJ": "山口宇部空港",
  "UEO": "久米島空港",
  "UKB": "神戸空港",
  "WKJ": "稚内空港",
  "IWK": "岩国空港",
}

// ANA系は就航していない区間
export const anaHasNoFlightAirCode: {[key:string]:string} = {
  "KMQ": "小松空港",
  "OKJ": "岡山空港",
}

// JAL系は就航していない区間
export const jalHasNoFlightAirCode: {[key:string]:string} = {
  "FSZ": "静岡空港",  // 使ってなさそうだけど、先方からの指定なので一旦入れておく(codeはwikiから取得)
  "HIJ": "広島空港",
  "IWK": "岩国空港",
  "TAK": "高松空港",
  "MYJ": "松山空港",
  "KMJ" : "熊本空港",
  "NGS": "長崎空港",
  "KMI" : "宮崎空港",
  "KOJ" : "鹿児島空港"
}

export const flightClassNormalCodes = ["Y","B","H","K","M","L","W","R","E","V","S","P","Z"]
export const flightClassJCodes = ["J","C","D","X"]

export const anaVacancyCodes = ["A","B","1","2","3","4","5","6","7","8","9"]
export const jalVacancyCodes = ["1","2","3","4","5","6","7","8","9"]