import {PlanSelectFormData, PlanSelectFromHotelType} from "../types/planSelectForm";
// import {otsPriceRate} from "../config/price";
import {carRentalUseOptions, hotelPeoplePrice, priceRequestParams, stay_detail, use_plan_types} from "../types/price";
import {childrenRoomNum, getHotelTotalPeople, sortHotelStayDetail} from "./hotel";
// import {getFlightPeopleNums} from "./flight";
import {InputsForm3} from "../types/InputsForm3";
// import dayjs from "dayjs";
import {InputsForm1} from "../types/InputsForm1";
// import {getCarRentalOptionCode} from "./carRental";
import {perSheetMaxNum} from "../config/carRental";
import {getActivityTotalPrice, getPlanAgeConfig, getPlanIgnorePeopleNum} from "./plan";
import {hotelPlanDetail, stayDetailType, updateHotel, roomDetail, stayDetailList} from "../types/Hotel";
// import {PlanSearch} from "../types/Search";
import {dateFormat, dayAgo, rangeDate} from "./convert";
import {devLog} from "./errors";
import _ from "lodash";
import {IActivity} from "../types/Activity";
import {comprehensiveItems} from "../types/sller";
import {reserveCarRentalDetail, reserveRentalCar} from "../types/reserve";
import {getCarRentalReservePeople} from "./carRental";
import {LANGUAGES} from "../constants/languages";
import {getComprehensiveItems} from "./sellerProduct";

/**
 * 初期合計金額算出
 * @param planSelectFormData
 */
export const defaultBasicTotalPrice = (planSelectFormData: PlanSelectFormData) => {

  devLog("planSelectFormData", planSelectFormData);
  return getBasicTotalPrice({
    isUpdate: false,
    step: 'default',
    updateHotelIndex: undefined,
    updatePlan: undefined,
    updateHotel: undefined,
    checkIn: planSelectFormData.searchParams.checkin,
    checkOut: planSelectFormData.searchParams.checkout
  }, planSelectFormData)
}

/**
 * 基本プラン合計金額計算
 * @param updateHotel
 * @param planSelectFormData
 */
export const getBasicTotalPrice = (updateHotel: updateHotel, planSelectFormData: PlanSelectFormData ) => {
  const comprehensiveItems = getComprehensiveItems(planSelectFormData);
  const otsRate = getProfitRate(planSelectFormData);
  const roomDetail = _.cloneDeep(planSelectFormData.searchParams.roomDetail);
  const totalPrice = {
    total: 0,
    roomsPrices: initRoomDetail(roomDetail)
  }

  // ホテル
  const hotelPrice = setByPersonHotelPrice(totalPrice.roomsPrices, planSelectFormData.hotel, updateHotel, planSelectFormData);
  totalPrice.roomsPrices = hotelPrice.roomDetail;

  // 基本プラン
  const planPrice = setByPersonPlanPrice(totalPrice.roomsPrices, planSelectFormData.activity, comprehensiveItems);
  totalPrice.roomsPrices = planPrice.roomDetail;

  // console.log("basicTotalPriceCostPrice", {
  //   updateHotel: updateHotel,
  //   planSelectFormData: planSelectFormData,
  //   hotel: hotelPrice.total,
  //   plan: planPrice.total,
  //   roomsPrices: totalPrice.roomsPrices,
  // });

  //  合計算出
  totalPrice.roomsPrices.forEach((roomDetail, rIndex) => {
    const adultPrice = roomDetail.price?? 0;
    const adultPerPrice = addOtsRate(adultPrice / roomDetail.adult, otsRate);
    totalPrice.total += adultPerPrice * roomDetail.adult;
    if (roomDetail.child && roomDetail.child.length > 0) {
      roomDetail.child.forEach((child) => {
        const childPrice = child.price?? 0;
        totalPrice.total += addOtsRate(childPrice, otsRate);
      })
    }
  })

  return  totalPrice.total;
}

/**
 * 選択した旅行プランの合計金額取得
 * @param planSelectFormData
 */
export const getTotalPrice = (planSelectFormData: PlanSelectFormData) => {
  
  // 基本料金
  const basicTotal = getBasicTotalPrice( {
    isUpdate: false,
    step: 'total',
    updateHotelIndex: undefined,
    updatePlan: undefined,
    updateHotel: undefined,
    checkIn: planSelectFormData.searchParams.checkin,
    checkOut: planSelectFormData.searchParams.checkout
  }, planSelectFormData);

  // 追加オプション料金
  let optionsTotal = 0;
  if (planSelectFormData.options && planSelectFormData.options.length) {
    const useOptions = planSelectFormData.options.filter((option) => {
      return option.active;
    });
    
    if (useOptions.length > 0) {
      useOptions.forEach((useOption) => {
        optionsTotal += getActivityTotalPrice(useOption);
      });
    }
  }
  
  // レンタカー料金
  let carRentalTotal = 0;
  if (planSelectFormData.carRental && planSelectFormData.carRental.length > 0) {
    planSelectFormData.carRental.forEach((carRental) => {
      carRentalTotal += carRental.total;
    });
  }

  const couponDiscountTotal = planSelectFormData.couponDiscountTotal;
  return  (basicTotal + optionsTotal + carRentalTotal) - couponDiscountTotal;
}

/**
 * 一人当たりのホテル金額を取得（大人、子供別で取得）
 * @param plan
 * @param hotelTotal
 * @param checkIn
 * @param checkOut
 * @param roomIndex
 * @param planSelectFormData
 */
// export const hotelPerPrices = (plan: hotelPlanDetail | undefined ,hotelTotal: number , checkIn: string, checkOut: string, roomIndex: number, planSelectFormData: PlanSelectFormData) => {
//   const prices = {
//     adult: 0,
//     child1Prices: 0,
//     child2Prices: 0,
//     child3Prices: 0,
//     child4Prices: 0
//   }
//   if (plan && plan.prices) {
//     const stayDate = rangeDate(checkIn, dayAgo(checkOut, 1, 'YYYY-MM-DD'));
//     stayDate.forEach((date) => {
//       const monthDate = dateFormat(date, "YYYYMM");
//       const day = Number(dateFormat(date, "D"));
//       const paramKey = day - 1;
//       if (plan && plan.prices && plan.prices[roomIndex]) {
//         const findIndex = plan.prices[roomIndex].findIndex((hotelAgeDetailPrice) => {
//           return hotelAgeDetailPrice.date === monthDate;
//         });
//         if (findIndex >= 0) {
//           const priceData = plan.prices[roomIndex][findIndex];
//           if (priceData.adultPrices !== undefined && priceData.adultPrices[paramKey] !== 'NULL') {
//             prices.adult += Number(priceData.adultPrices[paramKey] ?? 0);
//           }
//           if (priceData.child1Prices !== undefined && priceData.child1Prices[paramKey] !== 'NULL') {
//             prices.child1Prices += Number(priceData.child1Prices[paramKey] ?? 0);
//           }
//           if (priceData.child2Prices !== undefined && priceData.child2Prices[paramKey] !== 'NULL') {
//             prices.child2Prices += Number(priceData.child2Prices[paramKey] ?? 0);
//           }
//           if (priceData.child3Prices !== undefined && priceData.child3Prices[paramKey] !== 'NULL') {
//             prices.child3Prices += Number(priceData.child3Prices[paramKey] ?? 0);
//           }
//           if (priceData.child4Prices !== undefined && priceData.child4Prices[paramKey] !== 'NULL') {
//             prices.child4Prices += Number(priceData.child4Prices[paramKey] ?? 0);
//           }
//         }
//       }
//     });
//    
//   } else {
//     // ホテルプランの選択がまだの場合、ホテル最小金額で一人当たりの金額を計算
//     const totalPeople = getHotelTotalPeople(planSelectFormData);
//     const perPrice = hotelTotal / totalPeople;
//     prices.adult = perPrice;
//     prices.child1Prices = perPrice;
//     prices.child2Prices = perPrice;
//     prices.child3Prices = perPrice;
//     prices.child4Prices = perPrice;
//   }
//  
//  
//   return prices;
// }


/**
 * 設定した値の切り上げを行う
 * @param price
 * @param n
 */
export function ceilDecimal(price:number, n:number) {
  return Math.ceil(price * Math.pow(10, n) ) / Math.pow(10, n);
}

export const diffBasicPrice = (totalPrice: number, updateTotalPrice: number) => {

  if (totalPrice === updateTotalPrice) {
    return "0";
  }

  if (totalPrice > updateTotalPrice) {
    const price = ceilDecimal((totalPrice - updateTotalPrice) , -2);
    return "-" + price.toLocaleString();
  }

  if (totalPrice < updateTotalPrice) {
    const price = ceilDecimal((updateTotalPrice - totalPrice) , -2);
    return "+" + price.toLocaleString();
  }

  return "0";
}

export const getPriceRequestParams = (planSelectFormData: PlanSelectFormData, form1: InputsForm1|undefined, form3: InputsForm3|undefined, languageCode: string) => {
  const requestPrams: priceRequestParams = {
    sellerId: planSelectFormData.planData?.info.body.userId?? "",
    productId: planSelectFormData.planData?.info.body.productId?? "",
    hotel: [],
    air: {
      people_detail: {
        count_adults: 0,
        count_children: 0
      },
      flights: []
    },
    plan: [],
    options: [],
    carRental: [],
    roomDetail: planSelectFormData.searchParams.roomDetail,
    insurance: {
      isDTA: false,
      isTripCXL: false,
      DTA: {
        tripEndDate: '',
        tripStartDate: '',
      },
      TripCXL: {
        tripEndDate: '',
        tripStartDate: '',
      },
      applicantBirth: '',
      travelCompanions: [],
      cancelTravelCompanions: []
    },
    coupons: planSelectFormData.coupons,
    language_code: languageCode,
    departureDate: planSelectFormData.searchParams.start,
    returnDate: planSelectFormData.searchParams.end,
  }
  // set hotel
  if (planSelectFormData.hotel.length > 0) {
    const stayDetailParams: stay_detail[] = [];
    planSelectFormData.searchParams.roomDetail.forEach((roomDetail) => {
      const stayDetailParam: stayDetailType = {
        count_adults: roomDetail.adult
      }
      if (roomDetail.child.length > 0) {
        const childrenNums = childrenRoomNum(roomDetail.child);
        if (childrenNums.children_a > 0) {
          stayDetailParam.count_children_a = childrenNums.children_a;
        }
        if (childrenNums.children_b > 0) {
          stayDetailParam.count_children_b = childrenNums.children_b;
        }
        if (childrenNums.children_c > 0) {
          stayDetailParam.count_children_c = childrenNums.children_c;
        }
        if (childrenNums.children_d > 0) {
          stayDetailParam.count_children_d = childrenNums.children_d;
        }
        if (childrenNums.children_e > 0) {
          stayDetailParam.count_children_e = childrenNums.children_e;
        }
        if (childrenNums.children_f > 0) {
          stayDetailParam.count_children_f = childrenNums.children_f;
        }
      }
      stayDetailParams.push(stayDetailParam);
    });

    planSelectFormData.hotel.forEach((hotel) => {
      if (hotel.roomData && hotel.plan) {
        requestPrams.hotel.push({
          hotel_code: hotel.detail.hotel_code,
          room_code: hotel.roomData.room_code,
          plan_code: hotel.plan.plan_code,
          check_in: hotel.checkIn,
          check_out: hotel.checkOut,
          count_rooms: planSelectFormData.searchParams.rooms,
          stay_detail: sortHotelStayDetail(stayDetailParams)
        });
      }
    })
  }

  // // flight
  // if (planSelectFormData.flight.length > 0) {
  //   const flightPeopleNums = getFlightPeopleNums(planSelectFormData.searchParams.roomDetail);
  //   requestPrams.air.people_detail = flightPeopleNums;
  //   planSelectFormData.flight.forEach((flight) => {
  //     flight.entity.forEach((entity) => {
  //       requestPrams.air.flights.push({
  //         airline: flight.airline,
  //         departure_date: entity.date,
  //         depapo: entity.depapo,
  //         arrapo: entity.arrapo,
  //         air_code: entity.air_code,
  //         air_sub_no: entity.air_sub_no,
  //         class_code: entity.class_code
  //       })
  //     })
  //   })
  // }

  // activity
  const selectPlans = planSelectFormData.activity.filter((activity) => {
    return activity.active;
  })
  if (selectPlans.length > 0) {
    selectPlans.forEach((activity, pIndex) => {
      if (activity.active) {
        const usePlanTypes:use_plan_types[] = [];
        if (activity.price_list !== undefined) {
          activity.price_list.forEach((priceList) => {
            if (priceList.num && priceList.num > 0) {
              usePlanTypes.push({
                price_type_code: priceList.type_code,
                people: priceList.num
              });
            }
          });
        }
        requestPrams.plan.push({
          id: "plan_" + activity.plan_info.plan_code + "_" + pIndex,
          use_date: activity.date,
          plan_code:activity.plan_info.plan_code,
          max_ignore_age: Number(activity.oldmax),
          min_ignore_age: Number(activity.oldmin),
          use_plan_types: usePlanTypes
        })
      }
    })
  }

  // option
  const selectOptions = planSelectFormData.options.filter((activity) => {
    return activity.active;
  })
  if (selectOptions.length > 0) {
    selectOptions.forEach((option, oIndex) => {
      if (option.active) {
        const usePlanTypes:use_plan_types[] = [];
        if (option.price_list !== undefined) {
          option.price_list.forEach((priceList) => {
            if (priceList.num && priceList.num > 0) {
              usePlanTypes.push({
                price_type_code: priceList.type_code,
                people: priceList.num
              });
            }
          });
        }
        requestPrams.options.push({
          id: "option_" + option.plan_info.plan_code + "_" + oIndex,
          use_date: option.date,
          plan_code:option.plan_info.plan_code,
          use_plan_types: usePlanTypes
        })
      }
    })
  }

  // carRental
  if (planSelectFormData.carRental.length > 0) {
    const rentalCarRentalPeople = getCarRentalReservePeople(planSelectFormData.searchParams);
    let carRentalOrder = 1;
    planSelectFormData.carRental.forEach((carRental, cIndex) => {
      carRental.detail.forEach((carRentalDetail, dIndex) => {
        let totalPrice = 0;
        const carRentalField: reserveRentalCar = {
          id: "carRental_" + carRental.departure_date + "_" + cIndex + "_" + dIndex,
          "reservation_name1": languageCode === LANGUAGES.JA ? "オキナワ" : "WANG",      // 日本語はカタカナ、多言語は英字
          "reservation_name2": languageCode === LANGUAGES.JA ? "タロウ" : "TAILANG",     // 日本語はカタカナ、多言語は英字
          "reservation_tel": "0801111111",
          "reservation_email": "noreply@example.com",
          "departure_date": carRental.departure_date,
          "departure_time": carRental.departure_time,
          "return_date": carRental.return_date,
          "return_time": carRental.return_time,
          'access_type': carRental.access_type,
          departure_date_label: carRental.departure_date_label,
          departure_office_label: carRental.departure_office_label,
          return_date_label: carRental.return_date_label,
          return_office_label: carRental.return_office_label,
          car_detail: [carRentalDetail],
          count_option: {
            baby_seat: carRentalDetail.count_option.baby_seat.num,
            child_seat: carRentalDetail.count_option.child_seat.num,
            junior_seat: carRentalDetail.count_option.junior_seat.num
          },
          "total_price": totalPrice.toString(),
          "detail": [],
          "order": carRentalOrder,
          language_code: languageCode
        }
        let total = 0;
        const carRentalData:reserveCarRentalDetail = {
          "grade": carRentalDetail.planDetail?.class_name ?? "",
          "car_model": carRentalDetail.planDetail?.class_model ?? "",
          "class": carRentalDetail.planDetail?.class_name ?? "",
          "classModel": carRentalDetail.planDetail?.class_model ?? "",
          "plan_code": carRentalDetail.plan_code,
          "departure_office_code": carRental.departure_office_code,
          "return_office_code": carRental.return_office_code,
          "departure_date": carRental.departure_date,
          "departure_time": carRental.departure_time,
          "return_date": carRental.return_date,
          "return_time": carRental.return_time,
          "access_type": carRental.access_type,
          flight_arrival_no: carRental.flight_arrival_no,
          flight_arrival_code: carRental.flight_arrival_code,
          flight_departure_no: carRental.flight_departure_no,
          flight_departure_code: carRental.flight_departure_code,
          "joshaadt": rentalCarRentalPeople.joshaadt.toString(),
          "joshainf": rentalCarRentalPeople.joshainf.toString(),
          "basic_price": carRentalDetail.planDetail?.basic_price.toString() ?? "",
          "indemnity_price": carRentalDetail.planDetail?.indemnity_price?.toString() ?? "",
          "anshin_price": "",
          "anshinpremium_price": "",
          "total_price": 0,
          "option_list": []
        };
        total += carRentalDetail.planDetail?.total_price ?? 0;

        // 安心パック設定
        if (carRentalDetail.count_option.anshin.used) {
          const anshinName = carRentalDetail.count_option.anshin.option_name
          const anshinData = carRentalDetail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
          if (anshinData) {
            const anshinPrice = anshinData.anshin_price;
            total += anshinPrice;
            carRentalData.anshin_price = anshinPrice.toString()
          }
        }

        if (carRentalDetail.count_option.anshin_premium.used) {
          const anshinName = carRentalDetail.count_option.anshin_premium.option_name
          const anshinData = carRentalDetail.planDetail?.anshin_pack_list.find((option) => option.anshin_name === anshinName);
          if (anshinData) {
            const anshinPrice = anshinData.anshin_price;
            total += anshinPrice;
            carRentalData.anshinpremium_price = anshinPrice.toString()
          }
        }

        // オプション設定
        if (carRentalDetail.count_option.baby_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.baby_seat.option_code;
          const babySeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (babySeatData) {
            const num = carRentalDetail.count_option.baby_seat.num;
            const babySeatPrice = babySeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": babySeatData.option_code,
              "option_name": babySeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        if (carRentalDetail.count_option.child_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.child_seat.option_code;
          const childSeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (childSeatData) {
            const num = carRentalDetail.count_option.child_seat.num;
            const babySeatPrice = childSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": childSeatData.option_code,
              "option_name": childSeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        if (carRentalDetail.count_option.junior_seat.num > 0) {
          const optionCode = carRentalDetail.count_option.junior_seat.option_code;
          const juniorSeatData = carRentalDetail.planDetail?.option_list.find((option) => option.option_code === optionCode);
          if (juniorSeatData) {
            const num = carRentalDetail.count_option.junior_seat.num;
            const babySeatPrice = juniorSeatData.option_vaule_list.find((option) => option.num === num)?.price ?? 0;
            total += babySeatPrice;
            carRentalData.option_list.push({
              "option_code": juniorSeatData.option_code,
              "option_name": juniorSeatData.option_name,
              "num": num.toString(),
              "price": babySeatPrice.toString(),
            });
          }
        }

        carRentalData.total_price = total;
        totalPrice += total;
        carRentalField.detail.push(carRentalData);
        carRentalField.total_price = totalPrice.toString();
        requestPrams.carRental.push(carRentalField);
        carRentalOrder++;
      })
    })
  }

  // //insurance
  // if (form3) {
  //   if (form3.disability_insurance.agree === 'yes' && form3.disability_insurance.we_do_join === 'yes') {
  //     requestPrams.insurance.isDTA = true;
  //     requestPrams.insurance.DTA.tripStartDate = form3.disability_insurance.subscription_period.start;
  //     requestPrams.insurance.DTA.tripEndDate = form3.disability_insurance.subscription_period.end;
  //   }

  //   if (form3.cancellation_insurance.agree === 'yes'  && form3.cancellation_insurance.we_do_join === 'yes') {
  //     requestPrams.insurance.isTripCXL = true;
  //     requestPrams.insurance.TripCXL.tripStartDate = dayjs().format('YYYY-MM-DD');
  //     requestPrams.insurance.TripCXL.tripEndDate = planSelectFormData.searchParams.start;
  //   }

  //   if (form1) {
  //     requestPrams.insurance.applicantBirth = form1.applicant.birthday_year + '-' + form1.applicant.birthday_month + '-' + form1.applicant.birthday_day;
  //     if (form1.representative.is_applicant_join === 'yes') {
  //       const year = form1.applicant.birthday_year;
  //       const month = form1.applicant.birthday_month;
  //       const day = form1.applicant.birthday_day;
  //       if (getAgeFromBirthday(year, month, day, requestPrams.insurance.DTA.tripStartDate) <= 80) {
  //         requestPrams.insurance.travelCompanions.push(
  //           {
  //             "dateOfBirth": form1.applicant.birthday_year + '-' + form1.applicant.birthday_month + '-' + form1.applicant.birthday_day
  //           });
  //       }
  //       requestPrams.insurance.cancelTravelCompanions.push(
  //         {
  //           "dateOfBirth": form1.applicant.birthday_year + '-' + form1.applicant.birthday_month + '-' + form1.applicant.birthday_day
  //         });
  //     } else {
  //       const year = form1.representative.birthday_year;
  //       const month = form1.representative.birthday_month;
  //       const day = form1.representative.birthday_day;
  //       if (getAgeFromBirthday(year, month, day, requestPrams.insurance.DTA.tripStartDate) <= 80) {
  //         requestPrams.insurance.travelCompanions.push(
  //           {
  //             "dateOfBirth": form1.representative.birthday_year + '-' + form1.representative.birthday_month + '-' + form1.representative.birthday_day
  //           });
  //       }
  //       requestPrams.insurance.cancelTravelCompanions.push(
  //         {
  //           "dateOfBirth": form1.representative.birthday_year + '-' + form1.representative.birthday_month + '-' + form1.representative.birthday_day
  //         });
  //     }
  //     if (form1.followers.length > 0){
  //       form1.followers.forEach((followers) => {
  //         const year = followers.birthday_year;
  //         const month = followers.birthday_month;
  //         const day = followers.birthday_day;
  //         if (getAgeFromBirthday(year, month, day, requestPrams.insurance.DTA.tripStartDate) <= 80) {
  //           requestPrams.insurance.travelCompanions.push(
  //             {
  //               "dateOfBirth": followers.birthday_year + '-' + followers.birthday_month + '-' + followers.birthday_day
  //             });
  //         }
  //       })
  //     }
  //   }
  // }


  return requestPrams;
}


/**
 * ホテル料金を人別で設定
 * @param roomDetail
 * @param hotels
 * @param planSelectFormData
 */
const setByPersonHotelPrice = (roomDetail: roomDetail[], hotels: PlanSelectFromHotelType[], updateHotel: updateHotel, planSelectFormData: PlanSelectFormData) => {

  let hotelTotalPrice = 0;
  let hotelsPerPrices = {
    adult: 0,
    child1Prices: 0,
    child2Prices: 0,
    child3Prices: 0,
    child4Prices: 0
  };
  if (hotels.length > 0){
    // ホテル一人当たりの金額計算
    hotels.forEach((hotel, hIndex) => {
      if (updateHotel.updateHotelIndex !== hIndex) {
        const feeCategory = Number(hotel.plan?.room_plan_detail.fee_category) ?? 0;
        const hotelPrice = getHotelPeoplePrice(hotel.plan?.room_plan_detail.stay_detail_list, hotel.total, planSelectFormData, roomDetail.length, feeCategory);
        roomDetail.forEach((room, rIndex) => {
          if (hotelPrice[rIndex]) {
            room.price = room.price ? room.price : 0;
            room.price += Number(hotelPrice[rIndex].price_adults) * room.adult;
            hotelTotalPrice += Number(hotelPrice[rIndex].price_adults) * room.adult;
            room.child.forEach((child) => {
              child.price = child.price ? child.price : 0;
              if (child.age >= 10) {
                child.price += Number(hotelPrice[rIndex].price_children_a);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_a);
              } else if (child.age >= 6) {
                child.price += Number(hotelPrice[rIndex].price_children_b);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_b);
              } else if (child.age <= 5 && (child.futon || child.meal)) {
                child.price += Number(hotelPrice[rIndex].price_children_c);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_c);
              } else if (child.age <= 5 && (!child.futon || !child.meal)) {
                child.price += Number(hotelPrice[rIndex].price_children_f);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_f);
              }
            })
          }
        })
      }
    })

    // 追加・更新ホテル
    if (updateHotel.updateHotel || updateHotel.updatePlan) {
      if (updateHotel.step === 'hotel' || updateHotel.step === 'plan') {
        const hotelTotal = updateHotel.step === 'hotel' ? updateHotel.updateHotel?.min_price : updateHotel.updatePlan?.room_plan_detail.total_price;
        const feeCategory = Number(updateHotel?.updatePlan?.room_plan_detail.fee_category) ?? 0;
        const hotelPrice = getHotelPeoplePrice(updateHotel?.updatePlan?.room_plan_detail.stay_detail_list, hotelTotal ?? 0, planSelectFormData, roomDetail.length, feeCategory);
        roomDetail.forEach((room, rIndex) => {
          if (hotelPrice[rIndex]) {
            room.price = room.price ? room.price : 0;
            room.price += Number(hotelPrice[rIndex].price_adults) * room.adult;
            hotelTotalPrice += Number(hotelPrice[rIndex].price_adults) * room.adult;
            room.child.forEach((child) => {
              child.price = child.price ? child.price : 0;
              if (child.age >= 10) {
                child.price += Number(hotelPrice[rIndex].price_children_a);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_a);
              } else if (child.age >= 6) {
                child.price += Number(hotelPrice[rIndex].price_children_b);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_b);
              } else if (child.age <= 5 && (child.futon || child.meal)) {
                child.price += Number(hotelPrice[rIndex].price_children_c);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_c);
              } else if (child.age <= 5 && (!child.futon || !child.meal)) {
                child.price += Number(hotelPrice[rIndex].price_children_f);
                hotelTotalPrice += Number(hotelPrice[rIndex].price_children_f);
              }
            })
          }
        })
      }
    }
  }

  return {roomDetail: roomDetail, total: hotelTotalPrice};
};


/*
 * 一人当たりのホテル金額を取得（大人、子供別で取得）
 * @param hotelAgeDetailPrices
 * @param date
 * @param people
 */
const getHotelPeoplePrice = (stayDetailLists: stayDetailList[] | undefined, hotelTotal:number, planSelectFormData: PlanSelectFormData, room: number, feeCategory: number): hotelPeoplePrice[] => {
  const prices: hotelPeoplePrice[] = [];
  for (let i = 0; i < room; i++){
    prices.push({
      "room": i,
      "price_adults": 0,
      "price_children_a": 0,
      "price_children_b": 0,
      "price_children_c": 0,
      "price_children_d": 0,
      "price_children_e": 0,
      "price_children_f": 0,
    });
  }

  if (stayDetailLists) {
    const people = {
      "room": 0,
      "count_adults": 0,
      "count_children_a": 0,
      "count_children_b": 0,
      "count_children_c": 0,
      "count_children_d": 0,
      "count_children_e": 0,
      "count_children_f": 0,
    };

    stayDetailLists.forEach((stayDetailList) => {
      people.room = Number(stayDetailList.room_num);
      people.count_adults = Number(stayDetailList.count_adults);
      people.count_children_a = Number(stayDetailList.count_children_a);
      people.count_children_b = Number(stayDetailList.count_children_b);
      people.count_children_c = Number(stayDetailList.count_children_c);
      people.count_children_d = Number(stayDetailList.count_children_d);
      people.count_children_e = Number(stayDetailList.count_children_e);
      people.count_children_f = Number(stayDetailList.count_children_f);
      const roomIndex = people.room -1;
      stayDetailList.subtotal_price_list.forEach((subtotalPriceList) => {
        if (prices[roomIndex] && feeCategory === 0){
          prices[roomIndex].room = people.room;
          prices[roomIndex].price_adults += hotelSubTotalPerPrice(subtotalPriceList.price_adults, people.count_adults);
          prices[roomIndex].price_children_a += hotelSubTotalPerPrice(subtotalPriceList.price_children_a, people.count_children_a);
          prices[roomIndex].price_children_b += hotelSubTotalPerPrice(subtotalPriceList.price_children_b, people.count_children_b);
          prices[roomIndex].price_children_c += hotelSubTotalPerPrice(subtotalPriceList.price_children_c, people.count_children_c);
          prices[roomIndex].price_children_d += hotelSubTotalPerPrice(subtotalPriceList.price_children_d, people.count_children_d);
          prices[roomIndex].price_children_e += hotelSubTotalPerPrice(subtotalPriceList.price_children_e, people.count_children_e);
          prices[roomIndex].price_children_f += hotelSubTotalPerPrice(subtotalPriceList.price_children_f, people.count_children_f);
        } else {
          let peopleNum = people.count_adults;
          peopleNum += people.count_children_a;
          peopleNum += people.count_children_b;
          peopleNum += people.count_children_c;
          peopleNum += people.count_children_d;
          const perPrice = subtotalPriceList.subtotal_price / peopleNum;
          prices[roomIndex].price_adults += perPrice;
          prices[roomIndex].price_children_a += perPrice;
          prices[roomIndex].price_children_b += perPrice;
          prices[roomIndex].price_children_c += perPrice;
          prices[roomIndex].price_children_d += perPrice;
        }
      });
    });
  } else {
    // ホテル最小金額またはプラン金額で一人当たりの金額を計算
    const totalPeople = getHotelTotalPeople(planSelectFormData);
    const perPrice = hotelTotal / totalPeople;
    for (let r = 0; r < room; r++) {
      if (prices[r]) {
        prices[r].room = r;
        prices[r].price_adults = perPrice;
        prices[r].price_children_a = perPrice;
        prices[r].price_children_b = perPrice;
        prices[r].price_children_c = perPrice;
        prices[r].price_children_d = perPrice;
        prices[r].price_children_e = perPrice;
        prices[r].price_children_f = perPrice;
      }
    }
  }

  return prices;
};

const hotelSubTotalPerPrice = (price: number, people: number) => {
  if (people > 0) {
    return price / people;
  }

  return 0;
};


/**
 * 基本プラン料金を人別で設定
 * @param roomDetail
 * @param plans
 * @param comprehensiveItems
 */
const setByPersonPlanPrice = (roomDetail: roomDetail[], plans: IActivity[], comprehensiveItems: comprehensiveItems[] | undefined) => {

  let planTotalPrice = 0;
  if (plans.length > 0 && comprehensiveItems){
    plans.forEach((activity) => {
      if (activity.active) {
        const config = getPlanAgeConfig(comprehensiveItems, activity.plan_info.plan_code);
        if (config && activity.price_list !== undefined && activity.price_list.length > 0) {
          let price = Number(activity.price_list[0].price);
          const reserveNum = activity.price_list[0].num ?? 0;
          let perPrice = 0;
          const people = getPlanIgnorePeopleNum(roomDetail, comprehensiveItems, activity.plan_info.plan_code);
          const totalPrice = price * reserveNum;
          perPrice = totalPrice / people;

          roomDetail.forEach((room) => {
            room.price = room.price ? room.price : 0;
            room.price += (room.adult * perPrice);
            planTotalPrice += (room.adult * perPrice);
            if (room.child.length > 0) {
              room.child.forEach((child) => {
                const maxIgnoreAge = Number(config.oldmax);
                const minIgnoreAge = Number(config.oldmin);
                const childAge = child.age;
                if (maxIgnoreAge < childAge || minIgnoreAge > childAge ) {
                  child.price = child.price ? child.price : 0;
                  child.price += perPrice;
                  planTotalPrice += perPrice;
                }
              })
            }
          })
        }

      }
    });
  }

  return {roomDetail: roomDetail, total: planTotalPrice};
};


/**
 * OTS利率取得
 * @param planSelectFormData
 */
export const getProfitRate = (planSelectFormData: PlanSelectFormData) => {
  if (planSelectFormData.sellerProduct?.profitRate) {
    return Number(planSelectFormData.sellerProduct.profitRate);
  }
  if (planSelectFormData.planData?.sellerIndo) {
    return  Number(planSelectFormData.planData.sellerIndo.profitRate);
  }

  return 0
}

/**
 * OTS利益率を加算する
 * @param price
 * @param rate
 */
export const addOtsRate = (price: number, rate: number) => {
  const otsRate = 1 - (Number(rate) / 100);
  const otsRatePrice = price / otsRate;

  return ceilDecimal((otsRatePrice), -2);
}


/**
 * 人別金額詳細初期化
 * @param roomDetail
 */
const initRoomDetail = (roomDetail: roomDetail[]) => {
  roomDetail.forEach((room) => {
    room.price = 0;
    room.child.forEach((child) => {
      child.price = 0;
    })
  })
  return roomDetail;
}

export const getOptionTotalPrice = (planSelectFormData: PlanSelectFormData) => {
  let optionsTotal = 0;
  if (planSelectFormData.options && planSelectFormData.options.length) {
    const useOptions = planSelectFormData.options.filter((option) => {
      return option.active;
    });

    if (useOptions.length > 0) {
      useOptions.forEach((useOption) => {
        optionsTotal += getActivityTotalPrice(useOption);
      });
    }
  }

  return optionsTotal;
}

export const getCarRentalTotalPrice = (planSelectFormData: PlanSelectFormData) => {
  let carRentalTotal = 0;
  if (planSelectFormData.carRental && planSelectFormData.carRental.length > 0) {
    planSelectFormData.carRental.forEach((carRental) => {
      carRentalTotal += carRental.total;
    });
  }

  return carRentalTotal;
}