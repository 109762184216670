import React from "react";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


type Props = {
  steps: {text: string, active: string}[];
  activeStep: number;
}

/**
 * モーダル画面のステップ
 * 
 * @param props 
 * @returns 
 */
const PartsModalSteps: React.FC<Props> = (props) => {
  return (
    <div className="step-option no-padding">
      <ul className="step-option-list">
        {
          props.steps.map((step, index) => {
            return <li key={index} className={`step-option-list-item${step.active}`}>{step.text}</li>
          })
        }
      </ul>
    </div>
  );
};

export default PartsModalSteps;
