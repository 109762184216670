import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

// const app = process.env.REACT_APP_MODE;

// モーダルに何か渡したい場合はここ追加する
type Props = {
    activeStep: number,
    isOption: boolean
}

const FormStep: React.FC<Props> = ({
    activeStep,
    isOption
}) => {

  const { t } = useTranslation();

  let stepList: string[] = [];
  // todo 保険UATが通ったら修正
  // todo: 改行位置がこれでいいのかは確認が必要
  if (isOption) {
    // if (app === "prod") {
    //   stepList = [
    //     // "お客様<br />情報入力",
    //     "請輸入<br />旅客資料",
    //     // "オプション<br />情報入力",
    //     "請輸入<br />體驗活動項目",
    //     // "決済情報の<br />入力",
    //     "請輸入<br />支付類型",
    //     // "予約<br />最終確認",
    //     "訂單<br />最終確認",
    //     // "予約完了",
    //     "成立訂單",
    //   ];
    // } else {
    //   stepList = [
    //     // "お客様<br />情報入力",
    //     "請輸入<br />旅客資料",
    //     // "オプション<br />情報入力",
    //     "請輸入<br />體驗活動項目",
    //     // "保険加入の<br />選択",
    //     "請選擇所<br />加入的保險",
    //     // "決済情報の<br />入力",
    //     "請輸入<br />支付類型",
    //     // "予約<br />最終確認",
    //     "訂單<br />最終確認",
    //     // "予約完了",
    //     "成立訂單",
    //   ];
    // }

    stepList = [
      t("FormStep.お客様br情報入力", { br: "<br />" }),
      // "請輸入<br />旅客資料",
      t("FormStep.オプションbr情報入力", { br: "<br />" }),
      // "請輸入<br />體驗活動項目",
      t("FormStep.決済情報のbr入力", { br: "<br />" }),
      // "請輸入<br />支付類型",
      t("FormStep.予約br最終確認", { br: "<br />" }),
      // "訂單<br />最終確認",
      t("FormStep.予約完了"),
      // "成立訂單",
    ];
  } else {
    // if (app === 'prod') {
    //   stepList = [
    //     // "お客様<br />情報入力",
    //     "請輸入<br />旅客資料",
    //     // "決済情報の<br />入力",
    //     "請輸入<br />支付類型",
    //     // "予約<br />最終確認",
    //     "訂單<br />最終確認",
    //     // "予約完了",
    //     "成立訂單",
    //   ];
    // } else {
    //   stepList = [
    //     // "お客様<br />情報入力",
    //     "請輸入<br />旅客資料",
    //     // "保険加入の<br />選択",
    //     "請選擇所<br />加入的保險",
    //     // "決済情報の<br />入力",
    //     "請輸入<br />支付類型",
    //     // "予約<br />最終確認",
    //     "訂單<br />最終確認",
    //     // "予約完了",
    //     "成立訂單",
    //   ];
    // }
    stepList = [
      t("FormStep.お客様br情報入力", { br: "<br />" }),
      // "請輸入<br />旅客資料",
      t("FormStep.決済情報のbr入力", { br: "<br />" }),
      // "請輸入<br />支付類型",
      t("FormStep.予約br最終確認", { br: "<br />" }),
      // "訂單<br />最終確認",
      t("FormStep.予約完了"),
      // "成立訂單",
    ];

    if (activeStep !== 1) {
      activeStep--;
    }
  }
  
  return (
    <>
         <div className="form-step">
            <ul className="form-step-list">
              {stepList.map((step, sIndex) => (
                <li key={sIndex} className={"form-step-list-item" + (activeStep === (sIndex + 1)? " active":"")}>
                  <span className="form-step-list-item-label">{sIndex + 1}</span>
                  <span dangerouslySetInnerHTML={{ __html: step }}></span>
                </li>
              ))}
            </ul>
        </div>
    </>
  );
};

export default FormStep;
