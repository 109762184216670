import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//ファイル分割化
import {
  RentalModal,
  LinkListModal,
} from "./../../modal"

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";


//ダイアログインポート
import { 
  // DialogCancel,
  // DialogChange,
  DialogCarRentalCancel,
} from "./../../dialog"
import {
  // carRentalPlanParamsType, 
  carRentalSaveData
} from "../../../types/carRental";
import {toCircled} from "../../../utils/convert";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {scheduleListModalData} from "../../../types/planSelectConfirm";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import rentcarImage01PC from "../../../img/pages/confirm/img_rentcar_01_pc.png"
import rentcarImage01SP from "../../../img/pages/confirm/img_rentcar_01_sp.png"
import rentcarTWImage01PC from "../../../img/pages/confirm/tw/img_tw_rentcar_01_pc.png"
import rentcarTWImage01SP from "../../../img/pages/confirm/tw/img_tw_rentcar_01_sp.png"
import {getCarRentalAnshinOptionItem, isCarRentalOptions} from "../../../utils/carRental";

type Props = {
  planSelectFormData: PlanSelectFormData,
  scheduleListData: scheduleListModalData,
  updatePlanSelectFormData(updatePlanData: PlanSelectFormData): void
  updateScheduleListData(scheduleListData: scheduleListModalData): void
}


/**
 * レンタカーセクションピックアップ画像取得
 * @param lang
 * @param type
 */
export const getCarRentalPickupImage = (lang: string, type: string) => {
    if (lang === "tw") {
        return type === "pc" ? rentcarImage01PC : rentcarImage01SP;
    }

    return type === "pc" ? rentcarImage01PC : rentcarImage01SP;
};


const SectionCarRental: React.FC<Props> = ({
   planSelectFormData,
   scheduleListData,
   updateScheduleListData,
   updatePlanSelectFormData
 }) => {
  // const searchParams = planSelectFormData.searchParams;
  // const params = {
  //   "departure_office_code": "toyosaki",
  //   "return_office_code": "toyosaki",
  //   "departure_date": searchParams.start,
  //   "departure_time": "11:00",
  //   "return_date": searchParams.end,
  //   "return_time": "15:00"
  // }
  // const [carRentalPlanParams, setCarRentalPlanParams] = useState<carRentalPlanParamsType>(params)
  const [carRentalSaveData, setCarRentalSaveData] = useState<carRentalSaveData[]>([]);
  // const [carRentalUpdateNum, setCarRentalUpdateNum] = useState<number>(0);
  // const [addDataNum, setAddDataNum] = useState<number>(0);
  const [isRentalData, setIsRentalData] = useState<boolean>(false);

  const { t } = useTranslation();
  const pickupImagePC = getCarRentalPickupImage(i18n.language, 'pc');
  const pickupImageSP = getCarRentalPickupImage(i18n.language, 'sp');
  
  useEffect(() => {
    setCarRentalSaveData((prevState) => ([...planSelectFormData.carRental]))
    if (carRentalSaveData.length > 0) {
      setIsRentalData(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  // モーダルのステータスを追加する
  const [linkListModal, setLinkListModal] = React.useState(false);

  // モーダルのhandleを追加する

  //プチモーダル：ページ内遷移用
  const handleLinkListModal = () => {
    setLinkListModal(!linkListModal)
  }

  //rent a car
  const [rentalModalState, setRentalModalState] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [updateIndex, setUpdateIndex] = React.useState(0);
  const handleRentalModal = () => {
    setRentalModalState(!rentalModalState);
  };
  const handleUpdateRentalModal = (updateNum:number) => {
    setIsUpdate(true);
    setUpdateIndex(updateNum);
    setRentalModalState(!rentalModalState);
  };
  const handleAddRentalModal = () => {
    setIsUpdate(false);
    setRentalModalState(!rentalModalState);
  };

  //-------------ここまでモーダルの処理------------------

  //ダイアログの処理
  const [dialogCancel, setDialogCancel] = React.useState(false);
  // const [dialogChange, setDialogChange] = React.useState(false);
  const [cancelName, setCancelName] = React.useState("");
  const [cancelIndex, setCancelIndex] = React.useState(0);
  
  //モーダル：キャンセル
  const handleDialogCancelNo = () => {
    setDialogCancel(!dialogCancel)
  }
  const handleDialogCancel = (cancelName: string, cancelIndex: number) => {
    setCancelName(cancelName);
    setCancelIndex(cancelIndex);
    setDialogCancel(!dialogCancel);
  }
  
  const updateCarRental = (data: PlanSelectFormData) => {
    const updateCarRentalData = data.carRental;
    setCarRentalSaveData([...updateCarRentalData]);
    setRentalModalState(!rentalModalState);
    updatePlanSelectFormData({...data});
  }
  useEffect(() => {
    if (carRentalSaveData && carRentalSaveData.length > 0) {
      setIsRentalData(true);
      scheduleListData.useCarRental = true;
    } else {
      setIsRentalData(false);
      scheduleListData.useCarRental = false;
    }
    updateScheduleListData({...scheduleListData})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carRentalSaveData])
  
  const carRentalCancel = (cancelIndex: number) => {
    if (carRentalSaveData[cancelIndex] !== undefined) {
      carRentalSaveData.splice(cancelIndex, 1);
      setCarRentalSaveData(carRentalSaveData);
      planSelectFormData.carRental = carRentalSaveData;
      updatePlanSelectFormData({...planSelectFormData});

      if (carRentalSaveData.length === 0) {
        setIsRentalData(false);
      }
    }
    setDialogCancel(!dialogCancel)
  }
  
  //-------------ここまでダイアログの処理------------------

  return (
    <>
      <section>
        <div className="wrap-content-900">
          {/* <h2 className="heading-2 mb-16">加購租車</h2> */}
          <h2 className="heading-2 mb-16">{t("SectionCarRental.レンタカーの追加")}</h2>
          <p className="fz-16 fw-b mb-16 mb-24-md">
            {t("SectionCarRental.ご希望の方はレンタカーを下記より追加してください")}
            {/* 如需加購租車 請從下方選擇加購方案 */}
          </p>
          <div className="sticky-top-list">
            <div id="rentacar" className="sticky-top-list-item">
              <Accordion className="accordion-schedule" expanded={true}>
                <AccordionSummary 
                  className="accordion-schedule-heading"
                  onClick={handleLinkListModal}
                >
                  {t("LinkListModal.追加可能なレンタカー")}
                  {/* 現可加購的租車 */}
                </AccordionSummary>
                <AccordionDetails className="accordion-schedule-content large">
                  <div className="box-rentalcar-pickup">
                    <p className="box-rentalcar-pickup-heading">
                      <img
                        src={require("../../../img/pages/confirm/ots-rentacar-logo.png").default}
                        alt=""
                      />
                    </p>
                    <p className="box-rentalcar-pickup-content">
                      <picture>
                        <source
                          srcSet={pickupImagePC}
                          media="(min-width:992px)"
                        />
                        <img
                          src={pickupImageSP}
                          alt=""
                        />
                      </picture>
                    </p>
                    <div className="box-light-gray small">
                      <button
                        className="button-medium-light_blue large w-100per"
                        onClick={handleAddRentalModal}
                      >
                        {t("SectionCarRental.レンタカーを追加する")}
                        {/* 加購租車 */}
                      </button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            {isRentalData? (
                <div id="add_rentacar" className="sticky-top-list-item">
                  <Accordion className="accordion-schedule" expanded={true}>
                    <AccordionSummary
                      className="accordion-schedule-heading"
                      onClick={handleLinkListModal}
                    >
                      {t('LinkListModal.追加済みのレンタカー')}
                    </AccordionSummary>
                    <AccordionDetails className="accordion-schedule-content large">
                      <div className="box-rentalcar-set">
                        {carRentalSaveData?.map((row, index) => (
                          <div key={index} className="box-rentalcar-set-item">
                            <Accordion
                              className="accordion light_gray"
                              defaultExpanded={true}
                            >
                              <AccordionSummary className="accordion-heading">
                                {t('CarRental.レンタカー')}{toCircled(index + 1)}
                              </AccordionSummary>
                              <AccordionDetails className="accordion-content xsmall">
                                <div className="w-100per">
                                  {/* 貸出・返却 */}
                                  <div className="mb-24">
                                    <table className="table-small">
                                      <tbody>
                                      <tr>
                                        <th className="w-60 ta-c pl-8-md pr-8-md fz-14-md fw-b">
                                          {t('CarRental.貸出')}
                                        </th>
                                        <td className="fz-12 fz-14">
                                      <span className="d-b d-ib-md mr-10-md">
                                        {row.departure_date_label}
                                      </span>
                                          <span>{row.departure_office_label}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="w-60 ta-c pl-8-md pr-8-md fz-14-md fw-b">
                                          {t('CarRental.返却')}
                                        </th>
                                        <td className="fz-12 fz-14">
                                      <span className="d-b d-ib-md mr-10-md">
                                        {row.return_date_label}
                                      </span>
                                          <span>{row.return_office_label}</span>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  {/* 車種 */}
                                  <div className="box-rentalcar-set-item-select">
                                    <div className="box-rentalcar-set-item-select-detail">
                                      {row.detail?.map((detailRow, detailIndex) => (
                                        <>
                                          <div className="box-rentalcar-set-item-select-detail-car">
                                            <div className="box-rentalcar-set-item-select-detail-car-heading">
                                              {t('CarRental.車種')}
                                            </div>
                                            <div className="box-rentalcar-set-item-select-detail-car-list">
                                              <div key={detailIndex}
                                                   className="box-rentalcar-set-item-select-detail-car-list-item">
                                                <div
                                                  className="box-rentalcar-set-item-select-detail-car-list-item-thumbnail">
                                                  <img
                                                    src={detailRow.planDetail?.class_image_url}
                                                    alt=""
                                                  />
                                                </div>
                                                <div
                                                  className="box-rentalcar-set-item-select-detail-car-list-item-detail">
                                                  <div
                                                    className="box-rentalcar-set-item-select-detail-car-list-item-detail-heading">
                                                    <p
                                                      className="box-rentalcar-set-item-select-detail-car-list-item-detail-heading-main">
                                                      {detailRow.planDetail?.class_name}／{t('CarRental.定員')}{detailRow.planDetail?.max_member}{t('Common.名')}
                                                    </p>
                                                    <p
                                                      className="box-rentalcar-set-item-select-detail-car-list-item-detail-heading-sub">
                                                      {detailRow.planDetail?.class_model}
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="box-rentalcar-set-item-select-detail-car-list-item-detail-subtotal">
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                          {isCarRentalOptions(detailRow.count_option) && (
                                            <div className="box-rentalcar-set-item-select-detail-option">
                                              <div className="box-rentalcar-set-item-select-detail-optipn-heading">
                                                {t('CarRental.オプション')}
                                              </div>
                                              <div className="box-rentalcar-set-item-select-detail-optipn-list">
                                                {detailRow.count_option.anshin.used && (
                                                  <div className="box-rentalcar-set-item-select-detail-optipn-list-item">
                                                    <p className="fz-14 fz-16-md fw-b">
                                                      {t('CarRental.' + getCarRentalAnshinOptionItem(detailRow.count_option.anshin.option_name, 'label', i18n.language))}
                                                    </p>
                                                    <p className="c-red fz-13 fz-16-md fw-b">
                                                      {detailRow.count_option.anshin.price.toLocaleString()}{t('Common.円')}
                                                    </p>
                                                  </div>
                                                )}
                                                {detailRow.count_option.anshin_premium.used && (
                                                  <div className="box-rentalcar-set-item-select-detail-optipn-list-item">
                                                    <p className="fz-14 fz-16-md fw-b">
                                                      {t('CarRental.'+getCarRentalAnshinOptionItem(detailRow.count_option.anshin_premium.option_name, 'label', i18n.language))}
                                                    </p>
                                                    <p className="c-red fz-13 fz-16-md fw-b">
                                                      {detailRow.count_option.anshin_premium.price.toLocaleString()}{t('Common.円')}
                                                    </p>
                                                  </div>
                                                )}

                                                {detailRow.count_option.baby_seat.num > 0 && (
                                                  <div className="box-rentalcar-set-item-select-detail-optipn-list-item">
                                                    <p className="fz-14 fz-16-md fw-b">
                                                      {t('CarRental.乳幼児ベビーシート')}
                                                    </p>
                                                    <p className="c-red fz-13 fz-16-md fw-b">
                                                      {detailRow.count_option.baby_seat.price.toLocaleString()}{t('Common.円')}
                                                      ×{detailRow.count_option.baby_seat.num}{t('CarRental.台')}
                                                    </p>
                                                  </div>
                                                )}

                                                {detailRow.count_option.child_seat.num > 0 && (
                                                  <div className="box-rentalcar-set-item-select-detail-optipn-list-item">
                                                    <p className="fz-14 fz-16-md fw-b">
                                                      {t('CarRental.幼児用チャイルドシート')}
                                                    </p>
                                                    <p className="c-red fz-13 fz-16-md fw-b">
                                                      {detailRow.count_option.child_seat.price.toLocaleString()}{t('Common.円')}
                                                      ×{detailRow.count_option.child_seat.num}{t('CarRental.台')}
                                                    </p>
                                                  </div>
                                                )}

                                                {detailRow.count_option.junior_seat.num > 0 && (
                                                  <div className="box-rentalcar-set-item-select-detail-optipn-list-item">
                                                    <p className="fz-14 fz-16-md fw-b">
                                                      {t('CarRental.学童用ジュニアシート')}
                                                    </p>
                                                    <p className="c-red fz-13 fz-16-md fw-b">
                                                      {detailRow.count_option.junior_seat.price.toLocaleString()}{t('Common.円')}
                                                      ×{detailRow.count_option.junior_seat.num}{t('CarRental.台')}
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>))}
                                    </div>
                                    <div className="box-rentalcar-set-item-select-price">
                                      <div className="box-rentalcar-set-item-select-price-inner">
                                        <div className="box-rentalcar-set-item-select-price-total">
                                    <span className="mr-5 fz-13 fw-b">
                                      {t('ReservationCancelFee.合計')}
                                    </span>
                                          <span className="c-red fz-20 fw-b">
                                      +{row.total.toLocaleString()}
                                    </span>
                                          <span className="c-red fz-12 fw-b">
                                      {t('Common.円')}
                                    </span>
                                        </div>
                                        <div className="box-rentalcar-set-item-select-price-link w-120-md">
                                          <button
                                            className="button-border-medium w-100per"
                                            onClick={() => handleUpdateRentalModal(index)}
                                          >
                                            {t('SectionPlan.変更する')}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="box-rentalcar-set-item-select-cancel">
                                      <p>
                                        <button
                                          className="button-text-link fz-12"
                                          onClick={() => handleDialogCancel("レンタカー" + toCircled(index + 1), index)}
                                        >
                                          <i className="icon-bad-circle-black mr-5"></i>
                                          {t('DialogChange.キャンセル')}
                                        </button>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ):(<div></div>)}
          </div>
        </div>
      </section>

      {/* ここに作成したモーダルを追加する */}
      {/* リンクリスト */}
      <LinkListModal isShow={linkListModal} scheduleListModalData={scheduleListData} callback={handleLinkListModal}/>
      {/* レンタカー */}
      <RentalModal isUpdate={isUpdate} updateIndex={updateIndex} planSelectFormData={planSelectFormData}
                   isShow={rentalModalState} updateCarRental={updateCarRental} callback={handleRentalModal}/>

      {/* ダイアログ： キャンセル */}
      <DialogCarRentalCancel isShow={dialogCancel} callback={handleDialogCancelNo} carRentalName={cancelName}
                             carRentalIndex={cancelIndex} carRentalCancel={carRentalCancel}/>

    </>
  );
};

export default SectionCarRental;
