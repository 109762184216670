import React from "react";
import { UseFormRegister } from "react-hook-form";

import { InputText } from "../input/InputText";


type Props = {
  name: string;
  placeholder: string;
  register: UseFormRegister<any>;
  required: boolean;
  error?: any;
}

const PartsKana: React.FC<Props> = (props) => {

  return (
    <InputText
      name={props.name}
      placeholder={props.placeholder}
      register={props.register}
      options={{
        required: props.required,
        maxLength: 10,
        pattern: /^[ァ-ンヴー]+$/i
      }}
      error={props.error}
    />
  );
};

export default PartsKana;
