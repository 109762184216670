import {hotelList} from "../types/Hotel";

export const defaultHotelDetail:hotelList = {
  address: "",
  hotel_code: "",
  hotel_detail_url: "",
  hotel_name: "",
  hotel_type_list: [{hotel_type_code: ""}],
  hotel_facility_list: [],
  image_l_url: "",
  image_m_url: "",
  lang_support_staff: "",
  map_latitude: "",
  map_longitude: "",
  min_price: 0,
  postal_code: "",
  pr_main_description: "",
  review_rating: "",
  hotelData: {
    hotel_info: {
      pr_main_title: "",
      access: "",
      hotel_code: "",
      hotel_name: "",
      tel: "",
      fax: "",
      parking: "",
      total_room_num: "",
      hotel_facility_list: [],
      pr_list: [],
      check_in_start: "",
      check_in_end: "",
      check_in_other: "",
      check_out: "",
      other_notes: "",
      map_latitude: "",
      map_longitude: "",
      room_amenity_list: [],
      room_equipment_list: []
    },
    room_list:[{
      min_price: 0,
      plan_list: [],
      room_code: "",
      room_name: "",
      room_type_code: "",
    }],
  },
  roomData: [],
  room_url: "",
  sold_out_flg: 0,
  sub_area_code: undefined,
  sub_area_name: undefined,
}