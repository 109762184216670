import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";


const Page404: React.FC = () => {

  const [ , setLanguageCode ] = useState<string>("tw");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);
 
  return (
    <MainTemplate title={t("Page404.ページが見つかりません")} logo={undefined} sellerId={undefined} supportDial={undefined} planSelectFormData={undefined}>

      <section className="pt-50 pt-100-md">
        <div className="wrap-content-900">
          <div className="card sp-small">
            <p className="mb-10 ta-c">
              <img
                src={
                  require("../img/share/icon/icon_searchzero.svg")
                    .default
                }
                className="d-ib"
                alt=""
              />
            </p>
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">ページが見つかりません</p> */}
            {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">無法顯示網頁</p> */}
            <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("Page404.ページが見つかりません")}</p>
            <p className="fz-16 ta-c-md c-blue_gray">
            {/* お探しのページは一時的にアクセスできない状況にあるか、<br className="d-n d-b-md" />
            移動もしくは削除された可能性があります。 */}
              {/* 您所搜尋的頁面無法顯示，有可能是因為暫時無法連線，<br className="d-n d-b-md" />
              或頁面轉移或被削除。 */}
              {t("Page404.お探しのページは一時的にアクセスできない状況にあるか、")}<br className="d-n d-b-md" />
              {t("Page404.移動もしくは削除された可能性があります。")}
            </p>
          </div>
        </div>
      </section>
         
           
    </MainTemplate>
  );
};

export default Page404;
