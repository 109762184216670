import React, { Fragment } from "react";

import { HashLink } from 'react-router-hash-link';

// for styles
import {
  // Button,
  Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {scheduleListModalData} from "../../types/planSelectConfirm";
import { useTranslation } from "react-i18next";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  scheduleListModalData: scheduleListModalData,
  callback: () => void,
}

const LinkListModal: React.FC<Props> = ({
  isShow,
  scheduleListModalData,
  callback
}) => {

  const { t } = useTranslation();

  return (
    <>
    <div>
      <Modal
        open={isShow}
        onClose={callback}
        className="modal-simple dark"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <div className="modal-simple-content">
          <div className="modal-simple-content-close">
            <button className="button-clear" onClick={callback}>
              <i className="icon-modal-close-navy fz-30"></i>
            </button>
          </div>

          <div>
            <ul className="list-modal-schedule">
              <li className="list-modal-schedule-item">
                {/* <HashLink className="list-modal-schedule-item-link" smooth to="#" onClick={callback}>ページトップ</HashLink> */}
                {/* <HashLink className="list-modal-schedule-item-link" smooth to="#" onClick={callback}>返回頁面頂端</HashLink> */}
                <HashLink className="list-modal-schedule-item-link" smooth to="#" onClick={callback}>{t("LinkListModal.ページトップ")}</HashLink>
              </li>
              {scheduleListModalData.travelSchedule?.map((travelSchedule, tIndex) => (
                <Fragment key={tIndex}>
                  <li className="list-modal-schedule-item">
                    <HashLink className="list-modal-schedule-item-link" smooth to={travelSchedule.anchor} onClick={callback}>{travelSchedule.label}</HashLink>
                  </li>
                </Fragment>
              ))}
              {scheduleListModalData.useOptions && (
                <>
                  <li className="list-modal-schedule-item">
                    {/* <HashLink className="list-modal-schedule-item-link" smooth to="#set_option" onClick={callback}>已追加的活動方案</HashLink> */}
                    <HashLink className="list-modal-schedule-item-link" smooth to="#set_option" onClick={callback}>{t("LinkListModal.追加済みのオプション")}</HashLink>
                  </li>
                </>
              )}
              <li className="list-modal-schedule-item">
                {/* <HashLink className="list-modal-schedule-item-link" smooth to="#add_option" onClick={callback}>追加選購其他的活動方案</HashLink> */}
                <HashLink className="list-modal-schedule-item-link" smooth to="#add_option" onClick={callback}>{t("LinkListModal.追加可能なオプション")}</HashLink>
              </li>
              <li className="list-modal-schedule-item">
                {/* <HashLink className="list-modal-schedule-item-link" smooth to="#rentacar" onClick={callback}>追加可能なレンタカー</HashLink> */}
                {/* <HashLink className="list-modal-schedule-item-link" smooth to="#rentacar" onClick={callback}>現可加購的租車</HashLink> */}
                <HashLink className="list-modal-schedule-item-link" smooth to="#rentacar" onClick={callback}>{t("LinkListModal.追加可能なレンタカー")}</HashLink>
              </li>
              {scheduleListModalData.useCarRental && (
                <>
                  <li className="list-modal-schedule-item">
                    {/* <HashLink className="list-modal-schedule-item-link" smooth to="#add_rentacar" onClick={callback}>追加済みのレンタカー</HashLink> */}
                    {/* <HashLink className="list-modal-schedule-item-link" smooth to="#add_rentacar" onClick={callback}>已加購的租車內容</HashLink> */}
                    <HashLink className="list-modal-schedule-item-link" smooth to="#add_rentacar" onClick={callback}>{t("LinkListModal.追加済みのレンタカー")}</HashLink>
                  </li>
                </>
              )}
            </ul>
          </div>
          
        </div>
      </Modal>
    </div>
    </>
  );
};

export default LinkListModal;
