import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom"

//パーツをインポート
import {PartsStep, PartsTotalPrice,} from "./parts"
import {/*SectionFlight,*/ SectionPlan, SectionStay,} from "./section/PlanSelectTop"
import {SearchNotFound} from "./"

import TopSearchbox from "./search/TopSearchbox";

// MainTemplate
import MainTemplate from "./MainTemplate";

// scss
import "../scss/common.scss";
import "../scss/component.scss";

// for api
import {apiClient} from "../utils/apiClient";

// // query parser
// import qs from 'qs';

// types
import {IActivity} from "../types/Activity";
import {PlanSearch, /*searchResult*/} from "../types/Search";
// import { AxiosResponse } from "axios";

// // フライト
// import {
//   // Flights,
//   // FlightType,
//   FlightEntity,
//   // reservationFlight,
//   // viaReservationFlight,
//   // ReservationFlight,
//   // ViaReservationFlight,
//   // AirLines,
//   // FReservationFlightEntity,
//   // TravelType,
//   // Airline
// } from "../types/Flight";

// ホテル
import {Hotel} from "../types/Hotel";
import {PlanSelectFormData, /*PlanSelectFromHotelType, PlanSelectFromFlightType*/} from "../types/planSelectForm";
import {FreeSpace} from "../types/Freespace";


// lib
import {dateFormat, /*rangeDate*/} from "../utils/convert"
// import { ContactSupportOutlined } from "@material-ui/icons";
// import {Seller} from "../types/seller";
// import {
//   getFlightCodeLabel,
//   getFlightCodeByLabel,
//   getFlightTotalPrice,
//   getFlightByTransit,
//   getCheapestRoute, isNotCurrentFlights,
// } from "../utils/flight";
import {getHotelAreaCode, getHotelRoomPeopleLabel, getHotelSearchParms} from "../utils/hotel";
// import {defaultSearchParams} from "../defaults/search";
import {sellerInfo} from "../types/sller";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {/*checkSearchResult,*/ getPlanSelectInfoData, initSearchParams} from "../utils/search";
// import freeSpace from "./FreeSpace";
import FreeSpaceSection from "./FreeSpace";
import LoadingOverlay from "react-loading-overlay-ts";
import {getPlansTotalPrice, hasActivityStock} from "../utils/plan";
import {defaultBasicTotalPrice, /*getBasicTotalPrice*/} from "../utils/price";
// import { airCode, anaHasNoFlightAirCode, jalHasNoFlightAirCode } from "../config/Airport";
import Error500 from "./error/Error500";
import ServiceStop from "./ServiceStop";
import _ from "lodash";

import {getSearchURL} from "../utils/search";
import {validateFlightStartDate} from "../utils/validate";
// import {devLog} from "../utils/errors";

import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import {sellerProductsType} from "../types/sellerProducts";


const PlanSelectPage: React.FC = () => {

  // use navigate
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  // 検索ボックスのパラメータ
  const search = useLocation().search.slice(1);
  const [searchParams, setSearchParams] = useState<PlanSearch>(initSearchParams(search));
  const [searchLoading, setSearchLoading] = useState(true);
  const [planType, setPlanType] = useState("");
  let notFound = false;
  if (!validateFlightStartDate(searchParams.start)) {
    notFound = true;
  }
  
  // is loading for any sections, you can add new loading value for section
  // const [isLoadingComprehenshive, setLoadingComprehenshive] = useState(false);
  // const [isLoadingFlight, setLoadingFlight] = useState(false);
  const [isLoadingHotel, setLoadingHotel] = useState(true);
  const [planSelectFormData, setPlanSelectFormData] = useState<PlanSelectFormData>(defaultPlanSelectForm);
  const [is500Error, setIs500Error] = useState(false);
  const [isNotFound, setIsNotFound] = useState(notFound);
  const [isPlan, setIsPlan] = useState(true);

  const [logo, setLogo] = useState<string|undefined>(undefined);

  // get url params
  const urlParams = useParams<{ lang: string; id: string; }>();

  // 包括オプション
  const [activities, setActivities] = useState<IActivity[]>([]);

  // フライトと宿泊の表示非表示（選択できるできないのhandle）
  const [canSelect, setCanSelect] = useState(false);
  const [sellerId, setSellerId] = useState<string|undefined>(undefined);
  const [sellerInfo, setSellerInfo] = useState<sellerInfo|undefined>(undefined);

  // // entities
  // const [currentFlightEntities /*, setFlightEntities*/] = useState<FlightEntity[]>([])

  const [actionHotel, setActionHotel] = useState(false);

  // 宿泊施設(ホテル)
  const [hotels, setHotel] = useState<Hotel>();
  const [defaultHotels, setDefaultHotels] = useState<Hotel>();
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flightTotal, setFlightTotal] = useState(0);

  // // 宿泊施設(ホテル)のプラン
  // const [reserveHotel, setReserveHotel] = useState<PlanSelectFromHotelType[]>([]);

  // // 航空会社タブ切り替え時に初期データの航空会社も切り替えるために使用する
  // const [ currentCheapestFlights, setCurrentCheapestFlights ] = useState<FReservationFlightEntity[]>([]);

  // // 各航空会社が就航していない区間ならtrueになる
  // const [ anaHasNoFlight, setAnaHasNoFlight ] = useState(false);
  // const [ jalHasNoFlight, setJalHasNoFlight ] = useState(false);


  // 検索
  const handleSearch = (q: PlanSearch) => {
    setSearchParams({...q})
    window.location.href = getSearchURL(q);
  }

  // // フライトの選択や更新
  // const updateFlightEntities = (entity:FlightEntity) => {

  //   if(currentFlightEntities
  //     && currentFlightEntities.length > 0
  //     && currentFlightEntities.find((v) => v.airlineName == entity.airlineName && v.travelType == entity.travelType)) {

  //     setFlightEntities((prevState) =>
  //       prevState.map(obj => obj.airlineName == entity.airlineName && obj.travelType == entity.travelType ? {
  //           ...obj,
  //           currentFlights: entity.currentFlights,
  //           totalPrice: getFlightTotalPrice(entity.currentFlights, searchParams.roomDetail)
  //       } : obj)
  //     )
  //   } else {
  //     currentFlightEntities.push(entity)
  //     setFlightEntities(currentFlightEntities)
  //   }
  // }

  // // 利用空港の変更callback
  // const changeAirportCallback = (airport: string) => {
  //   const code = getFlightCodeByLabel(airport);
  //   if(code) {
  //     if (searchParams !== undefined && code !== searchParams.from) {
  //       setSearchParams({...searchParams,
  //         from: code,
  //       });
  //       setPlanSelectFormData((prevState) => ({...prevState, searchParams: searchParams}))
  //     }
  //   }
  // }

  // // 裏で初期選択状態にする航空会社名
  // //  就航していない区間の場合は、逆の航空会社を初期選択状態にするために使う
  // const getDefaultSelectedAirLineName = () => {
  //   if (anaHasNoFlightAirCode[searchParams.from] || anaHasNoFlightAirCode[searchParams.to]) {
  //     return "JAL";
  //   }

  //   return "ANA";
  // }

  // // 最初の自動取得する際と、モーダル選択時（確定）に呼ばれる
  // //    changeTabInSectionFlightはフライトの選択タブで切り替えた場合にtrueが入る
  // const handleFlightSelectCallback = (
  //   airline:string,
  //   action:string,
  //   flights:FReservationFlightEntity[],
  //   isInit: boolean = false, 
  //   changeTabInSectionFlight: boolean = false
  // ) => {

  //   // 各航空会社が就航していない区間なら処理しない
  //   if (airline === "ANA" && anaHasNoFlight === true) {
  //     return;
  //   }
    
  //   if (airline === "JAL" && jalHasNoFlight === true) {
  //     return;
  //   }
    
  //   if(flights.length > 0) {
  //     // update entity
  //     const isEntities = currentFlightEntities.find((elm) => elm.travelType == action && elm.airlineName == airline)
  //     // flightEntityの配列を更新する
  //     if(currentFlightEntities && isEntities) {
  //       const uFlights = currentFlightEntities.map(flight =>
  //         flight.airlineName == airline && flight.travelType == action && changeTabInSectionFlight === false ? {
  //           ...flight,
  //           currentFlights: flights,
  //           selected: isInit === true ? flight.airlineName === getDefaultSelectedAirLineName() : flight.airlineName === airline,
  //           totalPrice: getFlightTotalPrice(flights, searchParams.roomDetail)
  //         } : {
  //           ...flight,
  //           selected: isInit == true ? flight.airlineName == getDefaultSelectedAirLineName() : flight.airlineName == airline
  //         })
  //       setFlightEntities([...uFlights])
  //     } else {
  //       const uFlights = [{
  //         ...currentFlightEntities,
  //         airlineName: airline,
  //         travelType: action,
  //         currentFlights: flights,
  //         selected: isInit == true ? airline == getDefaultSelectedAirLineName() : true,
  //         totalPrice: getFlightTotalPrice(flights, searchParams.roomDetail)
  //       }];
  //       setFlightEntities([...uFlights])
  //     }

  //     // todo: 以下はuseEffectでやってるからいらない？
  //     const setFlightsFormData = currentFlightEntities.filter((ce) => ce.selected == true).map((cf) => {
  //       return {
  //         airline: cf.airlineName,
  //         entity: cf.currentFlights,
  //         action: cf.travelType,
  //         connection: (cf.currentFlights.length >= 2),
  //         selected: cf.airlineName == airline,
  //         totalPrice: cf.totalPrice,
  //         useDate: cf.currentFlights[0]?.date ?? "",
  //       } as PlanSelectFromFlightType
  //     })

  //     let flightsPrice = 0;
  //     setFlightsFormData.map(u => {
  //       if(u && u.selected) {
  //         flightsPrice += u.totalPrice
  //       }
  //     })

  //     // フォームに送るデータをセットする
  //     setPlanSelectFormData((prevState) => ({
  //       ...prevState,
  //       flight: setFlightsFormData,
  //       flightTotal: flightsPrice
  //     }))
  //     setFlightTotal(flightsPrice);
  //   } else {

  //     // 初回表示時にフライトがなければタブ切り替え不可にする
  //     if (!changeTabInSectionFlight) {
  //       if (airline === "JAL") {
  //         setJalHasNoFlight(true);
  //       }
  
  //       if (airline === "ANA") {
  //         setAnaHasNoFlight(true);
  //       }
  //     }

  //   }
  // }
  
  // useEffect(() => {
  //   const setFlightsFormData = currentFlightEntities.filter((ce) => ce.selected === true).map((cf) => {
  //     return {
  //       airline: cf.airlineName,
  //       entity: cf.currentFlights,
  //       action: cf.travelType,
  //       connection: (cf.currentFlights.length >= 2),
  //       selected: true,
  //       totalPrice: cf.totalPrice,
  //       useDate: cf.currentFlights && cf.currentFlights.length > 0 ? cf.currentFlights[0].date : new Date()
  //     } as PlanSelectFromFlightType
  //   })

  //   let flightsPrice = 0;
  //   setFlightsFormData.map(u => {
  //     if(u && u.selected) {
  //       flightsPrice += u.totalPrice
  //     }
  //   })

  //   // フォームに送るデータをセットする
  //   setPlanSelectFormData((prevState) => ({
  //     ...prevState,
  //     flight: setFlightsFormData,
  //     flightTotal: flightsPrice
  //   }))
  //   setFlightTotal(flightsPrice);
  // }, [currentFlightEntities])

  // const row = (
  //   name: string, // ANA,JAL
  //   endpoint: string,
  //   action: string,
  // ) => {
  //   return {
  //     name,
  //     endpoint,
  //     action,
  //   };
  // };

  // // フライト
  // const rows = [
  //   row(
  //     "ANA",
  //     "/air/air-ana-search",
  //     "inbound",
  //   ),
  //   row(
  //     "ANA",
  //     "/air/air-ana-search",
  //     "outbound",
  //   ),
  //   row(
  //     "JAL",
  //     "/air/air-jal-search",
  //     "inbound",
  //   ),
  //   row(
  //     "JAL",
  //     "/air/air-jal-search",
  //     "outbound",
  //   ),
  // ];

  /**
   * 初回実行処理
   */
  useEffect(() => {
    setPlanSelectFormData((prevState) => (
      {...prevState,
        id : urlParams.id?? "",
        info: getPlanSelectInfoData(searchParams, t),
        searchParams: searchParams
      }
    ));

    (async () => {
      if(activities.length <= 0) {
        // setLoadingComprehenshive(true);

        try {
          const response = await apiClient.post("/plan", JSON.stringify({
            "plan" : urlParams.id,
            "use_date" : searchParams?.start,
            "language_code" : i18n.language,
          }));

          if (response.data.status === 500) {
            throw Error("Server Error");
          }

          // setLoadingComprehenshive(false);
          const results = response.data['plans'];
          const resultsOptions = response.data['options'];
          const data = results as IActivity[];

          if (response.data.info.status === 200) {
            const logoImage = response.data.sellerIndo.companyInfo.logo;
            const type = response.data.info.body.type?? "option";
            const arrival = response.data.sellerIndo.useAirport.arrival;
            setLogo(logoImage);
            searchParams.to = arrival;
            searchParams.c_airport_from = arrival;
            
            
            setPlanSelectFormData((prevState) => (
              {
                ...prevState,
                activity: data,
                planData: response.data,
                options: resultsOptions,
                logo: logoImage?? "",
                planType: type,
                searchParams:searchParams,
                sellerProduct: response.data.product,
              }))
            setActivities(data);
            setSellerId(response.data.info.body.userId)
            setPlanType(type);
            setSellerInfo(response.data.sellerIndo);
            setSearchParams({...searchParams});
            if (type === "option") {
              setCanSelect(true);
            }
            
            // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK
            if (response.data.sellerIndo.active === '停止中') {
              setIsPlan(false);
            }
          } else {
            setIsPlan(false);
          }

        } catch (error) {
          console.error(error);
          setIs500Error(true);
          // setLoadingComprehenshive(false);
        }
          
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [freeSpaceContent, setFreeSpaceContent] = React.useState<FreeSpace>();
  useEffect(() => {
    // フリースペース取得（ローディング無し）
    if(sellerId) {
      (async () => {
        if (!planSelectFormData.freeSpace) {

          try {
            const response = await apiClient.post("/freeSpace", JSON.stringify({
              seller: sellerId,
              languageCode: i18n.language,
            }));

            const data = response.data.freeSpace;
            const adminData = response.data.adminFreeSpace;
            const updatePlanSelectFormData = planSelectFormData
            if (data || adminData) {
              updatePlanSelectFormData.freeSpace = {
                freeSpace: data.body,
                adminFreeSpace: adminData.body
              }
              setFreeSpaceContent({...updatePlanSelectFormData.freeSpace})
              setPlanSelectFormData({...updatePlanSelectFormData})
            }

          } catch (error) {
            setIs500Error(true);
            console.error(error);
          }
          
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sellerId])

  useEffect(() => {

    // 基本プランの日程選択
    if (searchParams !== undefined && planSelectFormData.planData !== undefined) {
      setActionHotel(true);
      setSearchLoading(false);

      // const rate = Number(planSelectFormData.planData.sellerIndo.profitRate?? 0);
      // // フライト
      // (async () => {
      //   for(const [index, airline] of rows.entries()) {


      //     // 各航空会社が就航していない区間なら処理しない
      //     if (airline.name === "ANA") {
      //       if (anaHasNoFlightAirCode[searchParams.from] || anaHasNoFlightAirCode[searchParams.to]) {
      //         setAnaHasNoFlight(true);
      //         continue;
      //       }

      //       setAnaHasNoFlight(false);
      //     }

      //     if (airline.name === "JAL") {
      //       if (jalHasNoFlightAirCode[searchParams.from] || jalHasNoFlightAirCode[searchParams.to]) {
      //         setJalHasNoFlight(true);
      //         continue;
      //       }

      //       setJalHasNoFlight(false);
      //     }
          
      //     // Loading
      //     setLoadingFlight(true)

      //     // 復路
      //     const outboundTo = () => {
      //       return searchParams?.isChange ? getFlightCodeByLabel(searchParams?.c_airport_to) : getFlightCodeByLabel(searchParams?.from)
      //     }
      //     // 往路
      //     const inboundFrom = () => {
      //       return getFlightCodeByLabel(searchParams?.from)
      //     }

      //     // comb
      //     const from = airline.action == "inbound" ? inboundFrom() : getFlightCodeByLabel(searchParams?.to)
      //     const to = airline.action == "inbound" ? getFlightCodeByLabel(searchParams?.to) : outboundTo()

      //     const useParam = {
      //       "depapo": from,
      //       "arrapo": to,
      //       "departure_date": airline.action == "inbound" ? (searchParams?.start).replace(/-/g, "") : (searchParams?.end).replace(/-/g, "") // "20220622" format
      //     }
      //     const airtype = airline.action == "inbound" ? "depapo" : "arrapo"

      //     const mResponse = await apiClient.post(airline.endpoint, JSON.stringify({
      //       "air_type": airtype,
      //       "params": useParam
      //     })).then(function (response: AxiosResponse<Flights>) {
      //       // Loading
      //       setLoadingFlight(false)
      //       const fs = response.data

      //       devLog("FS")
      //       devLog(fs)
           
      //       const cheapestFlights = getCheapestRoute(fs, to, airline, searchParams.roomDetail, rate)
      //       updateFlightEntities({
      //         ...cheapestFlights,
      //         airlineName: airline.name,
      //         travelType: airline.action,
      //         totalPrice: getFlightTotalPrice(cheapestFlights.currentFlights, searchParams.roomDetail)
      //       })
      //       setCurrentCheapestFlights(cheapestFlights.currentFlights)
      //       handleFlightSelectCallback(airline.name, airline.action, cheapestFlights.currentFlights, true)
      //     })
      //       .catch(function (error) {
      //         setIs500Error(true);
      //         setLoadingFlight(false)
      //         setSearchLoading(false);
      //         // TODO: handle error
      //         console.log('Error');
      //         console.log(error);
      //       });
      //   }
      //   setActionHotel(true);
      //   setSearchLoading(false);
      // })();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    /**
     * ホテル情報取得
     */
    if (sellerInfo !== undefined && searchParams !== undefined && actionHotel) {
      // devLog("currentFlightEntities", currentFlightEntities);
      // if (isNotCurrentFlights(currentFlightEntities)) {
      //   setIsNotFound(true);
      // } else {
        let target = sellerInfo.hotelSettings.target;
        let code = sellerInfo.hotelSettings.hotelCode;
        const hotelAreaCode = getHotelAreaCode(planSelectFormData);

      // 基本プランに商品が設定されている場合、商品のデータを使用する
      if (planSelectFormData.sellerProduct) {
        target = planSelectFormData.sellerProduct.hotelSettings.target;
        code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
      }
        
        // ホテル（宿泊施設）
        (async () => {
          if(!hotels) {
            try {
              setLoadingHotel(true);

              const hotelSearchParams = getHotelSearchParms(hotelAreaCode, target, code, searchParams, i18n.language);
              const response = await apiClient.post("/hotel/hotel-all-search", JSON.stringify(hotelSearchParams));

              setLoadingHotel(false);

              let hotels:Hotel = response.data;
              const updatePlanSelectFormData = planSelectFormData;
              if (hotels?.hotelList.length > 0) {
                const codes = code.split(',');

                // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK
                if (target === "ホテル指定" && codes.length > 0) {
                  const sortHotelList: any = [];
                  codes.forEach((hotelCode: string) => {
                    const targetHotel = _.find(hotels.hotelList, ["hotel_code", hotelCode]);
                    if (targetHotel) {
                      sortHotelList.push(targetHotel);
                    }
                  })
                  hotels.hotelList = sortHotelList;
                }

                // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK
                if (target === "ホテル指定" && code.split(',').length === 1) {
                  updatePlanSelectFormData.isHotels = false;
                } else {
                  updatePlanSelectFormData.isHotels = true;
                }
                updatePlanSelectFormData.hotel = [{
                  useDate: searchParams.checkin,
                  detail: hotels?.hotelList[0],
                  checkIn: searchParams.checkin,
                  checkOut: searchParams.checkout,
                  area: hotels?.hotelList[0].sub_area_code ?? "",
                  total: hotels.hotelList[0].min_price
                }];
                updatePlanSelectFormData.hotelData = hotels?.hotelList;
                setPlanSelectFormData((prevState) => ({...prevState,
                  isHotels: updatePlanSelectFormData.isHotels,
                  hotelData:updatePlanSelectFormData.hotelData,
                  hotel:updatePlanSelectFormData.hotel,
                  hotelTotal:updatePlanSelectFormData.hotel[0].detail.min_price
                }));

                if (hotels.hotelList.length > 0) {
                  // @ts-ignore
                  hotels.hotelList = hotels.hotelList.map((item, index) => {
                    let result = item;
                    // @ts-ignore
                    result["sortIndex"] = index;
                    return result;
                  })
                }

                setHotel(hotels);
                setDefaultHotels(response.data);

                setIsNotFound(false)
              } else {
                setIsNotFound(true)
              }

            } catch (error) {
              setIs500Error(true);
              setLoadingHotel(false);
              console.error(error);
            }

          }
        })();
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionHotel]);

  useEffect(() => {
    if (hotels && canSelect) {
      updateBasicTotalPrice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hotels, canSelect, flightTotal])
  
  const updateBasicTotalPrice = () => {
    /**
     * 基本料金設定（データが変更されるたびに再計算）
     */
    // todo フライト追加時はこちらを復活させるか修正
    // if (canSelect && planSelectFormData.flightTotal > 0 && planSelectFormData.hotelTotal > 0) {
    //   const total = defaultBasicTotalPrice(planSelectFormData);
    //   if (total > 0) {
    //     setPlanSelectFormData((prevState) => ({...prevState, basicPriceTotal:total}))
    //   }
    // }
    if (planSelectFormData.hotelTotal > 0) {
      const total = defaultBasicTotalPrice(planSelectFormData);
      if (total > 0) {
        setPlanSelectFormData((prevState) => ({...prevState, basicPriceTotal:total}))
      }
    }
  }

  /**
   * 基本プラン(アクティビティ)設定更新
   * @param acts
   */
  const setActivityCallBack = (acts: IActivity[]) => {
    setActivities(acts)
    setPlanSelectFormData((prevState) => ({...prevState, activity:acts}))
    // const active = acts.filter((item) => item.active === true);
    const planTotal = getPlansTotalPrice(acts, planSelectFormData);
    setPlanSelectFormData((prevState) => ({...prevState, planTotal:planTotal}))
    // フライト非表示解除チェック
    let activeSelectCheck = false
    const noSelectActivity = acts.find((activity) => {
      return !activity.active && hasActivityStock(activity)
    })
    if (!noSelectActivity) {
      activeSelectCheck = true;
    }
    setCanSelect(activeSelectCheck)
  }

  const [activityStockExist, setActivityStockExist] = useState(true);

  /**
   * 予約 確認・変更画面に遷移
   * @param planSelectFormData
   */
  const handleMove = (planSelectFormData:PlanSelectFormData) => {
    /**
     * 基本料金設定（データが変更されるたびに再計算）
     */
    updateBasicTotalPrice();

    if(planSelectFormData.hotel.length > 0 && planSelectFormData.hotel[0].plan !== undefined) {
      navigate(ROUTES.PlanSelectConfirm.replace(":lang", i18n.language), { state: {
          data: planSelectFormData
        } })
    }
  }

  return (
    <MainTemplate title={t("Common.デジタルDMOプラットフォーム")} fixedBottom={true} logo={logo}  sellerId={planSelectFormData?.planData?.sellerIndo.sellerId} supportDial={planSelectFormData?.planData?.sellerIndo.supportDial} planSelectFormData={planSelectFormData}>
      <div className="bgc-white border-bottom-gray pb-16 pb-24-md">
        <div className="wrap-content-900-no_padding">
          <PartsStep activeStep={1}></PartsStep>
        </div>

        <FreeSpaceSection freeSpaceContent={freeSpaceContent}></FreeSpaceSection>

        <div className="wrap-content-900">
          {/* <!--アラート--> */}
          <div className="box-alert-red-small icon-alert d-n">
            {/* <p className="fz-16">選択した内容、旅行代金をご確認ください</p> */}
            {/* <p className="fz-16">確認您的旅行商品的內容及費用</p> */}
            <p className="fz-16">{t("PlanSelectTop.選択した内容、旅行代金をご確認ください")}</p>
          </div>

          {/* 選択中の検索条件 */}
          {/* <p className="fz-12 fz-13-md fw-b mt-0 mt-20-md mb-4">您所選擇中的搜尋條件</p> */}
          <p className="fz-12 fz-13-md fw-b mt-0 mt-20-md mb-4">{t("PlanSelectTop.選択中の検索条件")}</p>
          <div className="box-light-gray xsmall">
            <div className="search-result">
              {/* <dl className="search-result-item">
                <dt className="search-result-item-dt">利用空港</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-flight">
                    <li key={"search_from"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.from)}
                    </li>
                    <li key={"search_to"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.to)}
                    </li>
                  </ul>
                </dd>
              </dl> */}

              <dl className="search-result-item">
                {/* <dt className="search-result-item-dt">旅遊日期</dt> */}
                <dt className="search-result-item-dt">{t("PlanSelectTop.旅行期間")}</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-date">
                    <li key={"search_start"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.start, "YYYY/MM/DD")}
                    </li>
                    <li key={"search_end"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.end, "YYYY/MM/DD")}
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="search-result-item">
                {/* <dt className="search-result-item-dt">房間數・人數</dt> */}
                <dt className="search-result-item-dt">{t("PlanSelectTop.部屋数・人数")}</dt>
                <dd className="search-result-item-dd">
                  {getHotelRoomPeopleLabel(searchParams?.roomDetail, searchParams?.rooms, t)}
                  {/* 1室・4名（大人1/子供3） */}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* 検索ボックス */}
      <TopSearchbox query={searchParams} callbackSearch={handleSearch}></TopSearchbox>

      {is500Error && (
        <Error500 />
      )}

      {isNotFound && (
        <SearchNotFound />
      )}

      {!is500Error && !isNotFound && (
        isPlan ? ( // プランあり
          <>
            {searchLoading ? ( // ローディング中の表示
              <div className="mt-32 mt-40-md">
                <div className="wrap-content-900">
                  <LoadingOverlay active={searchLoading}>
                    <div className="pb-100 pt-80"></div>
                  </LoadingOverlay>
                </div>
              </div>
            )
            : activityStockExist ? ( // 選択可能なプランあり
              <>
                {planType !== "option" && (
                  <>
                    {/* 基本プラン */}
                    <SectionPlan
                      activityStockExist={activityStockExist}
                      setActivityStockExist={setActivityStockExist}
                      planSelectFormData={planSelectFormData}
                      activities={activities}
                      searchParams={searchParams}
                      // isLoading={isLoadingComprehenshive}
                      setActivities={setActivityCallBack}
                    />
                  </>
                )}

                {/* フライト選択 */}
                {/* <SectionFlight
                  flights={currentFlightEntities} 
                  changeFlightCallback={handleFlightSelectCallback} 
                  search={searchParams} 
                  changeAirportCallback={changeAirportCallback} 
                  isLoading={isLoadingFlight} 
                  canSelect={canSelect}
                  currentCheapestFlights={currentCheapestFlights}
                  anaHasNoFlight={anaHasNoFlight}
                  jalHasNoFlight={jalHasNoFlight}
                  profitRate={planSelectFormData?.planData?.sellerIndo.profitRate?? "0"}
                ></SectionFlight> */}

                {/* ホテル選択 */}
                <SectionStay 
                  planSelectFormData={planSelectFormData}
                  selectPlan={handleMove}
                  hotels={hotels}
                  defaultHotels={defaultHotels}
                  setHotels={setHotel}
                  isLoading={isLoadingHotel}
                  canSelect={canSelect}
                  // callback={()=> {}}
                  // languageCode={i18n.language}
                />
              </>
            )
            : (<SearchNotFound />) // 選択可能なプランなし
            }
            {/* <div className="parts-bottom-fixed">
              <div className="wrap-content-900-no_padding ">
                <div className="box-white fixed-padding">
                  <div className="d-f ai-c jc-sb jc-fe-md">
                    <p className="fw-b pr-24">現在選擇中的合計費用</p>
                    <p className="fz-24 fz-28-md c-red fw-b">
                      {planSelectFormData.basicPriceTotal.toLocaleString()}
                      <span className="fz-14 fz-16-md">日圓(税込)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <PartsTotalPrice totalPrice={planSelectFormData.basicPriceTotal} />
          </>
        )
        : (<ServiceStop></ServiceStop>) // プランなし
      )}
    </MainTemplate>
  );
};

export default PlanSelectPage;
