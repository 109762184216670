import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "../scss/common.scss";
import "../scss/component.scss";
import { Footer, Header } from "./layout";
import { HeadBlock } from "./layout/HeadBlock";
import {PlanSelectFormData} from "../types/planSelectForm";

//パレットカラー変更
const theme = createTheme({
  palette: {
    secondary: {
      light: "#3387df",
      main: "#0169D8",
      dark: "#004997",
      contrastText: "#fff",
    },
  },
});



export interface Props {
  children: React.ReactNode;
  title: string;
  logo: string|undefined,
  sellerId: number | undefined
  supportDial: string | undefined,
  fixedBottom?:boolean; //下部に固定ナビがある場合はtrue
  planSelectFormData: PlanSelectFormData | undefined
}

const MainTemplate: React.FC<Props> = ({
    children,
    title,
    logo,
    sellerId,
    supportDial,
    fixedBottom,
    planSelectFormData
}) => {
  const footer = {
    supportDial: ''
  }
  if (planSelectFormData) {
    if (planSelectFormData?.sellerProduct?.supportDial) {
      footer.supportDial = planSelectFormData?.sellerProduct.supportDial;
    } else if (planSelectFormData?.planData?.sellerIndo.supportDial) {
      footer.supportDial = planSelectFormData?.planData?.sellerIndo.supportDial
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <HeadBlock title={title} />

      <div className="theme-wrap">
        <Header logo={logo}/>
        <main>
            <div className="l-page-content">
              {children}
            </div>
        </main>
        <div className={(fixedBottom? "pb-100":"")}>
          <Footer
          sellerId={sellerId}
          supportDial={footer.supportDial}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default MainTemplate;
