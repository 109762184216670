import { FC, FocusEvent, SyntheticEvent, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { transformFullCharToHalf } from "./../../../utils/transformFullCharToHalf";
import { LANGUAGES } from "../../../constants/languages";
import ValidationError from "../../error/ValidationError";
import { useTranslation } from "react-i18next";

//input
import { InputText } from "./../../input/InputText";
import { InputEmail } from "./../../input/InputEmail";
import { InputRadio } from "./../../input/InputRadio";
import { InputsForm1 } from "./../../../types/InputsForm1";

import {
  PartsMonthHasEmptyOption,
  PartsDayHasEmptyOption,
  PartsKana,
  PartsPrefecture,
} from "../../parts";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";


type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm1>;
  setValue: any;
  sendTestMail: (email: string, languageCode: string) => Promise<boolean>;
}

const SectionApplicant: FC<Props> = (props) => {

  const { t, i18n } = useTranslation(); 

  // バリデーション用正規表現: 半角英字
  const patternAlphabeticCharacters = /^[a-zA-Z]+$/;

  const handleBlurInText = (
    event: FocusEvent<HTMLInputElement>,
    options: {
      transformFullCharToHalf?: boolean;
    }
  ) => {
    // 全角から半角に変換
    if (options.transformFullCharToHalf) {
      const targetName: any = event.target.name; // todo: 型指定したい
      const transformedValue = transformFullCharToHalf(event.target.value);
      props.setValue(targetName, transformedValue);
    }
  };

  //ラジオボタンで表示・非表示
  // todo: 非表示にした際に別の代表者情報は消した方がいいかも(必須はfalseにしているけど、入力されているデータ自体にはvalidationがかかるので)
  const isApplicantJoin = props.getValues("representative.is_applicant_join");
  const [radioStatus, setRadioStatus] = useState(isApplicantJoin); // 初期値を変える場合は「app/front/src/redux/form1Slice.ts」の値も変える

  // テストメール送信ボタンの使用可・不可
  const [sendingTestMail, setSendingTestMail] = useState(false);

  // テストメール送信
  const handleSendTestMailClick = async (event: SyntheticEvent) => {
    setSendingTestMail(true);

    event.preventDefault();

    const targetEmail = props.getValues("applicant.email");

    await props.sendTestMail(targetEmail, i18n.language);

    setSendingTestMail(false);
  }

  // // 申込者の年齢バリデーション
  // const minAge = () => {
  //   const minAge = 18;
  //   const year = props.getValues("applicant.birthday_year");
  //   const month = props.getValues("applicant.birthday_month");
  //   const day = props.getValues("applicant.birthday_day");
  //   const birthday = dayjs(year+'-'+month+'-'+day);
  //   const today = dayjs();
  //   const age = today.diff(birthday, "year");
  //   return minAge <= age;
  // }

  // todo: バリデーションエラー後にselectboxをいじってもエラーが消えないので解消する
  // 生年月日の項目が1つでも入力されていればバリデーションをかける
  const correctBirthday = (fieldName: string) => {
    const year = props.getValues(`${fieldName}.birthday_year`);
    const month = props.getValues(`${fieldName}.birthday_month`);
    const day = props.getValues(`${fieldName}.birthday_day`);

    if (year === "" && month === "" && day === "") {
      return true;
    }

    if (year !== "" && month !== "" && day !== "") {
      return true;
    }

    return false;
  }

  return (
    <>
      {/* <!--申込者情報入力--> */}
      <section>
        {/* <h2 className="heading-2 mb-8">請輸入旅客資料</h2> */}
        <h2 className="heading-2 mb-8">{t("SectionApplicant.申込者情報の入力")}</h2>
        <p className="mb-24">
          {t("SectionApplicant.内容を確認のうえ、必要事項を入力してください。")}
          {/* 請先確認內容，再於必要項目中填入所需資料 */}
        </p>
        <div className="card mb-20">
          <div className="box-separator_bottom-large">
            {/* <!--名前--> */}
            <div
              id="form-name-kanji"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicant.氏名")} <span hidden={i18n.language !== LANGUAGES.JA}>( 漢字 )</span>
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-pc">
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">{t("SectionApplicant.姓")}</div>
                      <div>
                        <InputText
                          name="applicant.name.kanji.last"
                          placeholder={t("SectionApplicant.例：沖縄")}
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 10,
                            pattern: i18n.language !== LANGUAGES.JA ? patternAlphabeticCharacters : undefined
                          }}
                          error={props.errors.applicant?.name?.kanji?.last}
                        />
                        {props.errors.applicant?.name?.kanji?.last?.type === "required" && (
                          <ValidationError message={t("SectionApplicant.お名前（姓）をご入力ください。")} />
                        )}
                        {props.errors.applicant?.name?.kanji?.last?.type === "maxLength" && (
                          <ValidationError message={t("SectionApplicant.お名前（姓）は10文字以内でご入力ください。")} />
                        )}
                        {props.errors.applicant?.name?.kanji?.last?.type === "pattern" && i18n.language !== LANGUAGES.JA && (
                          <ValidationError message={"請填入字母字符的形式。"} />
                        )}
                      </div>
                    </div>
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">{t("SectionApplicant.名")}</div>
                      <div>
                        <InputText
                          name="applicant.name.kanji.first"
                          placeholder={t("SectionApplicant.例：太郎")}
                          register={props.register}
                          options={{ 
                            required: true, 
                            maxLength: 10,
                            pattern: i18n.language !== LANGUAGES.JA ? patternAlphabeticCharacters : undefined
                          }}
                          error={props.errors.applicant?.name?.kanji?.first}
                        />
                        {props.errors.applicant?.name?.kanji?.first?.type === "required" && (
                          <ValidationError message={t("SectionApplicant.お名前（名）をご入力ください。")} />
                        )}
                        {props.errors.applicant?.name?.kanji?.first?.type === "maxLength" && (
                          <ValidationError message={t("SectionApplicant.お名前（名）は10文字以内でご入力ください。")} />
                        )}
                        {props.errors.applicant?.name?.kanji?.first?.type === "pattern" && i18n.language !== LANGUAGES.JA && (
                          <ValidationError message={"請填入字母字符的形式。"} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--名前(フリガナ) 日本語の時だけ表示するので翻訳は不要--> */}
            {
              i18n.language === LANGUAGES.JA && (
                <div
                  id="form-name-hurigana"
                  className="box-separator_bottom-large-item"
                >
                  <div className="form-content">
                    <div className="form-content-heading">
                      <h3 className="heading-3-small">
                        氏名 <span className="">( フリガナ )</span>
                        <span className="label-required ml-5">必須</span>
                      </h3>
                    </div>
                    <div className="form-content-input">
                      <div className="form-column-pc">
                        <div className="form-column-pc-item">
                          <div className="heading-item_6 fw-b">セイ</div>
                          <div>
                            <PartsKana
                              name="applicant.name.kana.last"
                              placeholder="例：オキナワ"
                              register={props.register}
                              required={true}
                              error={props.errors.applicant?.name?.kana?.last}
                            />
                            {props.errors.applicant?.name?.kana?.last &&
                              props.errors.applicant?.name?.kana?.last.type === "required" && (
                                <p className="form-error_text">
                                  お名前（セイ）をご入力ください。
                                </p>
                              )}
                            {props.errors.applicant?.name?.kana?.last &&
                              props.errors.applicant?.name?.kana?.last.type === "pattern" && (
                                <p className="form-error_text">
                                  全角カタカナでご入力ください。
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="form-column-pc-item">
                          <div className="heading-item_6 fw-b">メイ</div>
                          <div>
                            <PartsKana
                              name="applicant.name.kana.first"
                              placeholder="例：タロウ"
                              register={props.register}
                              required={true}
                              error={props.errors.applicant?.name?.kana?.first}
                            />
                            {props.errors.applicant?.name?.kana?.first &&
                              props.errors.applicant?.name?.kana?.first.type === "required" && (
                                <p className="form-error_text">
                                  お名前（メイ）をご入力ください。
                                </p>
                              )}
                            {props.errors.applicant?.name?.kana?.first &&
                              props.errors.applicant?.name?.kana?.first.type === "pattern" && (
                                <p className="form-error_text">
                                  全角カタカナでご入力ください。
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }


            {/* <!--生年月日--> */}
            <div
              id="form-name-birthday"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    生年月日
                    {/* <span className="label-required ml-5">必須</span> */}
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-birthday" +
                      (props.errors.applicant?.birthday_year ||
                      props.errors.applicant?.birthday_month ||
                      props.errors.applicant?.birthday_day ||
                      props.errors.applicant?.minAge
                        ? " error"
                        : "")
                    }
                  >
                    <div className="form-birthday-year">
                      <div>
                        <InputText
                          name="applicant.birthday_year"
                          // placeholder="例如: 1990"
                          placeholder={t("SectionApplicant.例1990")}
                          isSmall={true}
                          register={props.register}
                          options={{
                            required: false,
                            min: 1900,
                            max: 3000,
                            maxLength: 4,
                            minLength: 4,
                            onBlur: (event) =>
                              handleBlurInText(event, {
                                transformFullCharToHalf: true,
                              }),
                            validate: () => correctBirthday("applicant"),
                            pattern: /\d{4}/
                          }}
                          error={props.errors.applicant?.birthday_year}
                        ></InputText>
                      </div>
                      <p className="mr-10 ml-10 color-dark_blue">年</p>
                    </div>
                    <div className="form-birthday-month">
                      <div className="select_box-default">
                        <PartsMonthHasEmptyOption
                          name="applicant.birthday_month"
                          register={props.register}
                          options={{ required: false, validate: () => correctBirthday("applicant") }}
                        />
                      </div>
                      <p className="mr-10 ml-10 color-dark_blue">月</p>
                    </div>
                    <div className="form-birthday-day">
                      <div className="select_box-default">
                        <PartsDayHasEmptyOption
                          name="applicant.birthday_day"
                          register={props.register}
                          options={{ required: false, validate: () => correctBirthday("applicant") }}
                        />
                      </div>
                    </div>
                    {/* {(props.errors.applicant?.birthday_year?.type === "minAge" ||
                      props.errors.applicant?.birthday_month?.type === "minAge" ||
                      props.errors.applicant?.birthday_day?.type === "minAge")
                      ?
                        <><p className="form-error_text">※予約日に18歳未満の方はお申し込みできません。</p></>
                      :
                        (props.errors.applicant?.birthday_year ||
                        props.errors.applicant?.birthday_month ||
                        props.errors.applicant?.birthday_day) &&
                          <><p className="form-error_text">生年月日をご入力ください。</p></>
                    } */}
                    {/* {(props.errors.applicant?.birthday_year?.type === "correctBirthday" ||
                      props.errors.applicant?.birthday_month?.type === "correctBirthday" ||
                      props.errors.applicant?.birthday_day?.type === "correctBirthday" ||
                      props.errors.applicant?.birthday_year?.type === "min" || 
                      props.errors.applicant?.birthday_year?.type === "max" || 
                      props.errors.applicant?.birthday_year?.type === "minLength" || 
                      props.errors.applicant?.birthday_year?.type === "maxLength" || 
                      props.errors.applicant?.birthday_year?.type === "pattern") && (
                        <p className="form-error_text">
                          請填入正確的生年月日。
                        </p>
                      )} */}
                    {(props.errors.applicant?.birthday_year ||
                      props.errors.applicant?.birthday_month ||
                      props.errors.applicant?.birthday_day) && (
                        <p className="form-error_text">
                          {t("SectionApplicant.生年月日を正しくご入力ください。")}
                          {/* 請填入正確的生年月日。 */}
                        </p>
                    )}
                  </div>
                  {/* <ul className="list-note light-gray mt-8">
                    <li>予約日に18歳未満の方はお申し込みできません。</li>
                  </ul> */}
                </div>
              </div>
            </div>

            {/* <!--性別--> */}
            <div
              id="form-sexual"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    性別
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-content-input-sexual" +
                      (props.errors.applicant?.gender ? " error" : "")
                    }
                  >
                    <InputRadio
                      name="applicant.gender"
                      control={props.control}
                      radios={[
                        { label: "男性", value: "male" },
                        { label: "女性", value: "female" },
                      ]}
                      options={{ required: true }}
                      isRow={true}
                    ></InputRadio>
                    {props.errors.applicant?.gender && (
                      <p className="form-error_text">
                        {t("SectionApplicant.性別をご選択ください。")}
                        {/* 請選擇性別。 */}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--メールアドレス--> */}
            <div
              id="form-name-mailaddress"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicant.メールアドレス")}
                    {/* 郵件地址 */}
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-mail">
                    <div className="form-column-mail-item large">
                      <div>
                        {/* todo: typeも渡せるようなcomponentにする？ */}
                        <InputEmail
                          name="applicant.email"
                          placeholder="例：mail@example.com"
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 200,
                            pattern: /^\S+@\S+$/i, // todo: もっとちゃんとしたのを入れる
                            onBlur: (event) =>
                              handleBlurInText(event, {
                                transformFullCharToHalf: true,
                              }),
                          }}
                          error={props.errors.applicant?.email}
                        ></InputEmail>
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type === "required" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスをご入力ください。")}
                              {/* 請填入您的電子郵件。 */}
                            </p>
                          )}
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスは200文字以内でご入力ください。")}
                              {/* 郵件地址請填入200以內的文字。 */}
                            </p>
                          )}
                        {props.errors.applicant?.email &&
                          props.errors.applicant?.email.type === "pattern" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスを正しくご入力ください。")}
                              {/* 請填入正確的郵件地址。 */}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-mail-item small">
                      <div>
                        <button className="button-blue-form_button-small" onClick={handleSendTestMailClick} disabled={sendingTestMail}>
                          {t("SectionApplicant.テストメールを送信")}
                          {/* 發送測試郵件 */}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <ul className="list-note light-gray">
                    <li>半角英数字で入力ください。</li>
                  </ul> */}
                  <div className="box-small-light-red mt-16">
                    <ul className="list-note">
                      <li>
                        {t("SectionApplicant.予約完了メールは予約の確認、問い合わせ先などが記載されている大切なメールです。確実に受信できるように「テストメール送信」をお試しください。")}
                        {/* 預訂完成通知郵件內文是有記載您的預約內容、諮詢處聯繫方式等資訊的重要郵件。為確保您能收到郵件，請按「發送測試郵件」進行測試。 */}
                      </li>
                      <li>
                        {/* 迷惑メール設定をしている場合、「digitaldmoplatform.com
                        」のメールを受信出来るように設定をお願いいたします。 */}
                        {t("SectionApplicant.迷惑メール設定をしている場合、「digitaldmoplatform.com」のメールを受信出来るように設定をお願いいたします。")}
                        {/* 如有設定阻止垃圾郵件時，請將本網站郵件「digitaldmoplatform.com」加入安全清單內，謝謝。。 */}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      {t("SectionApplicant.メールアドレス(確認)")}
                      {/* 郵件地址(確認用) */}
                      <span className="label-required ml-5">必須</span>
                    </p>
                    <InputEmail
                      name="applicant.email_confirm"
                      placeholder="例：mail@example.com"
                      register={props.register}
                      options={{
                        required: true,
                        validate: {
                          sameEmail: (value) => {
                            return props.getValues("applicant.email") === value;
                          },
                        },
                      }}
                      error={props.errors.applicant?.email_confirm}
                    ></InputEmail>
                    {props.errors.applicant?.email_confirm &&
                      props.errors.applicant?.email_confirm.type === "required" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.メールアドレスをご入力ください。")}
                        </p>
                      )}
                    {props.errors.applicant?.email_confirm &&
                      props.errors.applicant?.email_confirm.type === "sameEmail" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.メールアドレスが異なります。")}
                          {/* 郵件地址不相同。 */}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--住所--> */}
            <div
              id="form-name-address"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicant.住所")}
                    {/* 地址 */}
                    {/* <span className="label-required ml-5">必須</span> */}
                  </h3>
                </div>
                <div className="form-content-input">
                  {/* <p className="mb-8 fw-b">郵遞區號</p> */}
                  <p className="mb-8 fw-b">{t("SectionApplicant.郵便番号")}</p>
                  <div className="form-column-address">
                    <div className="form-column-address-item">
                      <div>
                        <InputText
                          name="applicant.address.zip_code"
                          placeholder="例：9010000"
                          register={props.register}
                          options={{ required: false, pattern: /^[0-9]+$/ }}
                          error={props.errors.applicant?.address?.zip_code}
                        ></InputText>
                        {/* {props.errors.applicant?.address?.zip_code &&
                          props.errors.applicant?.address?.zip_code.type ===
                            "required" && (
                            <p className="form-error_text">
                              郵便番号をご入力ください。
                            </p>
                          )} */}
                        {props.errors.applicant?.address?.zip_code &&
                          props.errors.applicant?.address?.zip_code.type ===
                            "pattern" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.郵便番号の形式でご入力ください。")}
                              {/* 請填入郵遞區號的形式。 */}
                            </p>
                          )}
                      </div>
                    </div>
                    {/* <div className="form-column-mail-item">
                      <div className="ml-20-md">
                        <SearchAddress
                          elementNames={{
                            zipCode: "applicant.address.zip_code",
                            prefecture: "applicant.address.prefecture",
                            addressMain: "applicant.address.address_main",
                          }}
                        ></SearchAddress>
                      </div>
                    </div>
                    <div className="form-column-mail-item mt-8 mt-10-md">
                      <div className="ml-20-md">
                        <a href="https://www.post.japanpost.jp/zipcode/" target="_blank" className="fz-12 fz-13-md" rel="noreferrer">
                          郵便番号検索<i className="icon-blank ml-5"></i>
                        </a>
                      </div>
                    </div> */}
                  </div>

                  <ul className="list-note light-gray mb-16">
                    <li>{t("SectionApplicant.半角数字ハイフン無しでご入力ください。")}</li>
                    {/* <li>「-」無需輸入連字號（-）。</li> */}
                  </ul>

                  <div>
                    {/* <p className="mb-8 fw-b">縣市名稱</p> */}
                    <p className="mb-8 fw-b">{t("SectionApplicant.都道府県")}</p>
                    {
                      i18n.language === LANGUAGES.JA ? (
                        <div className="select_box-default w-200">
                          <PartsPrefecture
                            name="applicant.address.prefecture"
                            register={props.register}
                            options={{required: false}}
                          />
                        </div>
                      ) : (
                        <InputText
                          name="applicant.address.prefecture"
                          placeholder={t("SectionApplicant.例：東京都")}
                          register={props.register}
                          options={{ required: false }}
                          error={props.errors.applicant?.address?.prefecture}
                        />
                      )
                    }
                  </div>

                  <div>
                    {/* <p className="mt-16 mb-8 fw-b">市區鎮村街名</p> */}
                    <p className="mt-16 mb-8 fw-b">{t("SectionApplicant.市区町村 町名番地")}</p>
                    <InputText
                      name="applicant.address.address_main"
                      placeholder={t("SectionApplicant.例：港区浜松町2丁目8-14")}
                      register={props.register}
                      options={{ required: false }}
                      error={props.errors.applicant?.address?.address_main}
                    ></InputText>
                    {/* {props.errors.applicant?.address?.address_main &&
                      props.errors.applicant?.address?.address_main.type ===
                        "required" && (
                        <p className="form-error_text">
                          市区町村 町名番地をご入力ください。
                        </p>
                      )} */}
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      {t("SectionApplicant.ビル・マンション・お部屋番号")}
                      {/* 建物名・室號等 */}
                    </p>
                    <InputText
                      name="applicant.address.address_sub"
                      placeholder={t("SectionApplicant.例：OTSマンション2-202")}
                      register={props.register}
                      options={{ required: false }}
                      error={props.errors.applicant?.address?.address_sub}
                    ></InputText>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--ご連絡先--> */}
            <div
              id="form-name-tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicant.ご連絡先")}
                    {/* 電話號碼 */}
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  {/* <div className="heading-item_6 fw-b">手機號碼</div> */}
                  <div className="heading-item_6 fw-b">{t("SectionApplicant.携帯番号")}</div>
                  <div>
                    <InputText
                      name="applicant.tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      // options={{ required: true, pattern: /^(0{1}\d{9,10})$/i }}
                      options={{ required: true, pattern: /^[0-9]+$/ }}
                      error={props.errors.applicant?.tel}
                    ></InputText>
                    <ul className="list-note light-gray">
                      <li>{t("SectionApplicant.半角数字ハイフン無しでご入力ください。")}</li>
                      {/* <li>「-」無需輸入連字號（-）。</li> */}
                    </ul>
                    {props.errors.applicant?.tel &&
                      props.errors.applicant?.tel.type ===
                        "required" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.携帯番号をご入力ください。")}
                          {/* 請填入您的手機號碼。 */}
                        </p>
                      )}
                    {props.errors.applicant?.tel &&
                      props.errors.applicant?.tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.携帯番号の形式でご入力ください。")}
                          {/* 請填入手機號碼的形式。 */}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--緊急連絡先--> */}
            {/* <div
              id="form-name-emergency_tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">緊急連絡先</h3>
                </div>
                <div className="form-content-input">
                  <div className="heading-item_6 fw-b">電話番号</div>
                  <div>
                    <InputText
                      name="applicant.emergency_tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      options={{ required: false, pattern: /^(0{1}\d{9,10})$/i }}
                      error={props.errors.applicant?.emergency_tel}
                    ></InputText>
                    <ul className="list-note light-gray">
                      <li>半角数字ハイフン無しでご入力ください。</li>
                    </ul>
                    {props.errors.applicant?.emergency_tel &&
                      props.errors.applicant?.emergency_tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          電話番号の形式でご入力ください。
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <!--代表者情報--> */}
            <div
              id="form-representative"
              className="box-separator_bottom-large-item"
            >
              <h3 className="heading-3-small mb-16 mb-8-md">
                {t("SectionApplicant.申込者のご旅行参加の有無")}
                {/* 預約者是否有參加此行 */}
                <span className="label-required ml-5">必須</span>
              </h3>
              <div className="form-content-input mb-16">
                <div>
                  <InputRadio
                    name="representative.is_applicant_join"
                    control={props.control}
                    radios={[
                      {
                        label: t("SectionApplicant.お申込者様本人が、代表者として旅行に参加する。"),
                        // label: "預約者本人為此行程的代表人。・ 住宿者非預訂者",
                        value: "yes",
                      },
                      {
                        label: (
                          <span>
                            <span className="marker-orange fw-b">
                              {t("SectionApplicant.お申込者様は旅行に参加せず")}
                              {/* 預約者本人無參加此行程 */}
                            </span>
                            {t("SectionApplicant.、別途、旅行の代表者を決定する。")}
                            {/* ，行程代表人另行決定。 */}
                          </span>
                        ),
                        value: "no",
                      },
                    ]}
                    options={{ required: true }}
                    isRow={false}
                    onChange={(event: any) => {
                      setRadioStatus(event.target.value);
                    }}
                  ></InputRadio>
                  {props.errors.representative?.is_applicant_join &&
                    props.errors.representative?.is_applicant_join.type ===
                      "required" && (
                      <p className="form-error_text">
                        {t("SectionApplicant.申込者のご旅行参加の有無をご選択ください。")}
                        {/* 請選擇預約者是否有參加此行。 */}
                      </p>
                    )}
                </div>
              </div>

              <div className={"box-heading-blue" + (radioStatus === "yes" ? " d-n" : "")}>
                <div className="box-heading-blue-text">
                  {t("SectionApplicant.旅行の代表者様情報入力")}
                  {/* 請輸入代表人資訊 */}
                </div>
                <div className="box-heading-blue-content">
                  <div className="box-separator_bottom-large">
                    {/* <!--名前--> */}
                    <div
                      id="form-name-kanji"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            {t("SectionApplicant.氏名")} <span hidden={i18n.language !== LANGUAGES.JA}>( 漢字 )</span>
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div className="form-column-pc">
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">{t("SectionApplicant.姓")}</div>
                              <div>
                              <InputText
                                name="representative.name.kanji.last"
                                placeholder={t("SectionApplicant.例：沖縄")}
                                register={props.register}
                                options={{
                                  required: radioStatus !== "yes",
                                  maxLength: 10,
                                  pattern: i18n.language !== LANGUAGES.JA ? patternAlphabeticCharacters : undefined
                                }}
                                error={props.errors.representative?.name?.kanji?.last}
                              />
                              {props.errors.representative?.name?.kanji?.last?.type === "required" && (
                                <ValidationError message={t("SectionApplicant.お名前（姓）をご入力ください。")} />
                              )}
                              {props.errors.representative?.name?.kanji?.last?.type === "maxLength" && (
                                <ValidationError message={t("SectionApplicant.お名前（姓）は10文字以内でご入力ください。")} />
                              )}
                              {props.errors.representative?.name?.kanji?.last?.type === "pattern" && i18n.language !== LANGUAGES.JA && (
                                <ValidationError message={"請填入字母字符的形式。"} />
                              )}
                              </div>
                            </div>
                            <div className="form-column-pc-item">
                              <div className="heading-item_6 fw-b">{t("SectionApplicant.名")}</div>
                              <div>
                              <InputText
                                name="representative.name.kanji.first"
                                placeholder={t("SectionApplicant.例：太郎")}
                                register={props.register}
                                options={{ 
                                  required: radioStatus !== "yes",
                                  maxLength: 10,
                                  pattern: i18n.language !== LANGUAGES.JA ? patternAlphabeticCharacters : undefined
                                }}
                                error={props.errors.representative?.name?.kanji?.first}
                              />
                              {props.errors.representative?.name?.kanji?.first?.type === "required" && (
                                <ValidationError message={t("SectionApplicant.お名前（名）をご入力ください。")} />
                              )}
                              {props.errors.representative?.name?.kanji?.first?.type === "maxLength" && (
                                <ValidationError message={t("SectionApplicant.お名前（名）は10文字以内でご入力ください。")} />
                              )}
                              {props.errors.representative?.name?.kanji?.first?.type === "pattern" && i18n.language !== LANGUAGES.JA && (
                                <ValidationError message={"請填入字母字符的形式。"} />
                              )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--名前(フリガナ) 日本語の時だけ表示するので翻訳は不要--> */}
                    {
                      i18n.language === LANGUAGES.JA && (
                        <div
                          id="form-name-hurigana"
                          className="box-separator_bottom-large-item"
                        >
                          <div className="form-content">
                            <div className="form-content-heading">
                              <h3 className="heading-3-small">
                                氏名 <span className="">( フリガナ )</span>
                                <span className="label-required ml-5">
                                  必須
                                </span>
                              </h3>
                            </div>
                            <div className="form-content-input">
                              <div className="form-column-pc">
                                <div className="form-column-pc-item">
                                  <div className="heading-item_6 fw-b">セイ</div>
                                  <div>
                                    <PartsKana
                                      name="representative.name.kana.last"
                                      placeholder="例：オキナワ"
                                      register={props.register}
                                      required={radioStatus !== "yes"}
                                      error={props.errors.representative?.name?.kana?.last}
                                    />
                                    {props.errors.representative?.name?.kana?.last &&
                                      props.errors.representative?.name?.kana?.last.type === "required" && (
                                        <p className="form-error_text">
                                          お名前（セイ）をご入力ください。
                                        </p>
                                      )
                                    }
                                    {props.errors.representative?.name?.kana?.last &&
                                      props.errors.representative?.name?.kana?.last.type === "pattern" && (
                                        <p className="form-error_text">
                                          全角カタカナでご入力ください。
                                        </p>
                                      )
                                    }
                                  </div>
                                </div>
                                <div className="form-column-pc-item">
                                  <div className="heading-item_6 fw-b">メイ</div>
                                  <div>
                                    <PartsKana
                                      name="representative.name.kana.first"
                                      placeholder="例：タロウ"
                                      register={props.register}
                                      required={radioStatus !== "yes"}
                                      error={props.errors.representative?.name?.kana?.first}
                                    />
                                    {props.errors.representative?.name?.kana?.first &&
                                      props.errors.representative?.name?.kana?.first.type === "required" && (
                                        <p className="form-error_text">
                                          お名前（メイ）をご入力ください。
                                        </p>
                                      )
                                    }
                                    {props.errors.representative?.name?.kana?.first &&
                                      props.errors.representative?.name?.kana?.first.type === "pattern" && (
                                        <p className="form-error_text">
                                          全角カタカナでご入力ください。
                                        </p>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }


                    {/* <!--性別--> */}
                    <div
                      id="form-sexual"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            性別
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>

                        <div className="form-content-input">
                          <div
                            className={
                              "form-content-input-sexual" +
                              (props.errors.representative?.gender ? " error" : "")
                            }
                          >
                            <InputRadio
                              name="representative.gender"
                              control={props.control}
                              radios={[
                                { label: "男性", value: "male" },
                                { label: "女性", value: "female" },
                              ]}
                              options={{ required: radioStatus !== "yes" }}
                              isRow={true}
                            ></InputRadio>
                            {props.errors.representative?.gender && (
                              <p className="form-error_text">
                                {t("SectionApplicant.性別をご選択ください。")}
                                {/* 請選擇性別。 */}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--生年月日--> */}
                    <div
                      id="form-name-birthday"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            生年月日
                            {/* <span className="label-required ml-5">必須</span> */}
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div
                            className={
                              "form-birthday" +
                              (props.errors.representative?.birthday_year ||
                              props.errors.representative?.birthday_month ||
                              props.errors.representative?.birthday_day
                                ? " error"
                                : "")
                            }
                          >
                            <div className="form-birthday-year">
                              <div>
                                <InputText
                                  name="representative.birthday_year"
                                  // placeholder="例如: 1990"
                                  placeholder={t("SectionApplicant.例1990")}
                                  isSmall={true}
                                  register={props.register}
                                  options={{
                                    required: false,
                                    min: 1900,
                                    max: 3000,
                                    maxLength: 4,
                                    minLength: 4,
                                    onBlur: (event) =>
                                      handleBlurInText(event, {
                                        transformFullCharToHalf: true,
                                      }),
                                    validate: () => correctBirthday("representative"),
                                    pattern: /\d{4}/
                                  }}
                                  error={props.errors.representative?.birthday_year}
                                ></InputText>
                              </div>
                              <p className="mr-10 ml-10 color-dark_blue">年</p>
                            </div>
                            <div className="form-birthday-month">
                              <div className="select_box-default">
                                {/* <PartsMonth
                                  name="representative.birthday_month"
                                  register={props.register}
                                  options={{ required: true }}
                                /> */}
                                <PartsMonthHasEmptyOption
                                  name="representative.birthday_month"
                                  register={props.register}
                                  options={{ required: false, validate: () => correctBirthday("representative") }}
                                />
                              </div>
                              <p className="mr-10 ml-10 color-dark_blue">月</p>
                            </div>
                            <div className="form-birthday-day">
                              <div className="select_box-default">
                                {/* <PartsDay
                                  name="representative.birthday_day"
                                  register={props.register}
                                  options={{ required: true }}
                                /> */}
                                <PartsDayHasEmptyOption
                                  name="representative.birthday_day"
                                  register={props.register}
                                  options={{ required: false, validate:() => correctBirthday("representative") }}
                                />
                              </div>
                            </div>
                            {(props.errors.representative?.birthday_year ||
                              props.errors.representative?.birthday_month ||
                              props.errors.representative?.birthday_day) && (
                              <p className="form-error_text">
                                {t("SectionApplicant.生年月日を正しくご入力ください。")}
                                {/* 請填入正確的生年月日。 */}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* <!--ご連絡先--> */}
                    <div
                      id="form-name-tel"
                      className="box-separator_bottom-large-item"
                    >
                      <div className="form-content">
                        <div className="form-content-heading">
                          <h3 className="heading-3-small">
                            {t("SectionApplicant.ご連絡先")}
                            {/* 電話號碼 */}
                            <span className="label-required ml-5">
                              必須
                            </span>
                          </h3>
                        </div>
                        <div className="form-content-input">
                          <div className="heading-item_6 fw-b">{t("SectionApplicant.携帯番号")}</div>
                          <div>
                            <InputText
                              name="representative.tel"
                              placeholder="例：00011112222"
                              register={props.register}
                              // options={{ required: radioStatus !== "yes", pattern: /^(0{1}\d{9,10})$/i }}
                              options={{ required: radioStatus !== "yes", pattern: /^[0-9]+$/ }}
                              error={props.errors.representative?.tel}
                            ></InputText>
                            <ul className="list-note light-gray">
                                {/* <li>半角数字ハイフン無しでご入力ください。</li>
                                <li>
                                  ご旅行先でもご連絡の取れる番号を優先してご記入ください。
                                </li> */}
                                <li>{t("SectionApplicant.半角数字ハイフン無しでご入力ください。")}</li>
                            </ul>
                            {props.errors.representative?.tel &&
                              props.errors.representative?.tel.type === "required" && (
                                <p className="form-error_text">
                                  {t("SectionApplicant.携帯番号をご入力ください。")}
                                  {/* 請輸入手機號碼。 */}
                                </p>
                              )}
                            {props.errors.representative?.tel &&
                              props.errors.representative?.tel.type === "pattern" && (
                                <p className="form-error_text">
                                  {t("SectionApplicant.携帯番号の形式でご入力ください。")}
                                  {/* 請填入手機號碼的形式。 */}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--連絡方法--> */}
                    <div className="box-separator_bottom-large-item">
                      <div className="box-navy">
                        <div className="box-navy-heading small">
                          <ul>
                            <li className="fw-b">
                              <i className="icon-alert-white mr-5"></i>{t("SectionApplicant.お申込者様と旅行の代表者様が異なる場合のご連絡方法")}
                              {/* <i className="icon-alert-white mr-5"></i>預約申請者與旅遊行程代表者不同時的聯絡方法。 */}
                            </li>
                          </ul>
                        </div>
                        <div className="box-navy-content small">
                          <p className="mb-16">
                            {t("SectionApplicant.お申込者様と旅行の代表者様が異なる場合、当社のご連絡は以下のようになります。下記以外の連絡方法をご希望されるお客様はデジタルDMOカスタマーサポートにお問い合わせください。")}
                            {/* 若預約申請者與旅遊行程代表者不同時，本公司會以下列方式進行聯絡。若希望下列以外的方式與您聯繫時，請來信尋問DMO網路預約平臺，謝謝。 */}
                          </p>
                          {/* <ul className="list-note red mb-16">
                            <li>航空券(eチケット)などの送付先を指定したい場合は、その他情報に入力してください。</li>
                          </ul> */}

                          <table>
                            <tbody>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  {t("SectionApplicant.予約後の自動返信メール・予約確認メールの発信")}
                                  {/* 完成預約後的電子郵件自動回信・預約確認電子郵件的發送 */}
                                </th>
                              </tr>
                              <tr>
                                <th className="w-40per fz-16-md">{t("SectionApplicant.お申込み者様")}</th>
                                {/* <th className="w-40per fz-16-md">預約申請者</th> */}
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">{t("SectionApplicant.旅行の代表者様")}</th>
                                {/* <th className="fz-16-md">旅遊行程代表者</th> */}
                                <td className="ta-c">✕</td>
                              </tr>
                              <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  {t("SectionApplicant.予約内容の確認・変更・取消を含むご連絡(旅行開始前)")}
                                  {/* （旅遊開始前的）預約內容的確認・變更・取消等的聯絡 */}
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">{t("SectionApplicant.お申込み者様")}</th>
                                {/* <th className="fz-16-md">預約申請者</th> */}
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">{t("SectionApplicant.旅行の代表者様")}</th>
                                {/* <th className="fz-16-md">旅遊行程代表者</th> */}
                                <td className="ta-c">✕</td>
                              </tr>
                              {/* <tr>
                                <th colSpan={2} className="table-bgc-light_navy fw-b fz-16-md">
                                  最終案内書・航空券 (eチケット) の発送
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">お申込み者様</th>
                                <td className="ta-c">○</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">旅行の代表者様</th>
                                <td className="ta-c">✕</td>
                              </tr> */}
                              <tr>
                                <th colSpan={2} className="table-bgc-light_red fw-b fz-16-md">
                                  {t("SectionApplicant.旅行開始後のご連絡")}
                                  {/* 旅遊開始後的聯絡 */}
                                </th>
                              </tr>
                              <tr>
                                <th className="fz-16-md">{t("SectionApplicant.お申込み者様")}</th>
                                {/* <th className="fz-16-md">預約申請者</th> */}
                                <td className="ta-c">△</td>
                              </tr>
                              <tr>
                                <th className="fz-16-md">{t("SectionApplicant.旅行の代表者様")}</th>
                                {/* <th className="fz-16-md">旅遊行程代表者</th> */}
                                <td className="ta-c">○</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* /box-navy-content */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionApplicant;
