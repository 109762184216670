import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
// import {reserveCancelDetailResponce} from "../../types/reserveCancel";

const PartsCancellationChargeText: React.VFC = () => {

  const { t } = useTranslation();

  return (
    <>
      {/* <h2 className="heading-2 mb-8 mb-16-md">有關於取消費用</h2> */}
      <h2 className="heading-2 mb-8 mb-16-md">{t("TourCancelPolicy.キャンセル料金について")}</h2>
      {/* <p className="mb-16 mb-24-md">在預約（旅遊契約）成立後，若因旅客的原因解除旅遊契約時，本公司會收取下列所規定的取消費用。</p> */}
      <p className="mb-16 mb-24-md">{t("TourCancelPolicy.ご予約（旅行契約）の成立後にお客様の都合により旅行契約を解除した場合には、所定の取消料を申し受けます。")}</p>
      <div className="card large">
        <h3 className="heading-3 mb-0">{t("TourCancelPolicy.変動型ツアーのキャンセル料金")}</h3>
        {/* <h3 className="heading-3 mb-0">費用變動型旅遊商品的取消費用</h3> */}
        <p className="mb-16 fz-14">（{t("TourCancelPolicy.お客様お1人様につき")}）</p>
        {/* <p className="mb-16 fz-14">（每一位客人酌收下列取消費用）</p> */}
        <table className="mb-24 mb-32-md table-xsmall">
          <tbody>
            <tr>
              <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c">{t("TourCancelPolicy.契約の解除日")}</td>
              <td className="table-bgc-light_navy fw-b ta-c">{t("TourCancelPolicy.取消料")}</td>
              {/* <td className="table-bgc-light_navy w-50per w-a-md fw-b ta-c">契約解除日</td>
              <td className="table-bgc-light_navy fw-b ta-c">取消費</td> */}
            </tr>
            <tr>
              <th className="w-40per fz-13 fz-16-md fw-b">{t("TourCancelPolicy.旅行開始日の前日から起算して8日前まで")}</th>
              {/* <th className="w-40per fz-13 fz-16-md fw-b">旅遊開始日的前一天起算，至8天前為止。</th> */}
              <td>{t("TourCancelPolicy.無料")}</td>
              {/* <td>無取消費</td> */}
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">{t("TourCancelPolicy.旅行開始日の前日から起算して7日前～2日前まで")}</th>
              {/* <th className="fz-13 fz-16-md fw-b">旅遊開始日的前一天起算，2天前至7天前為止。</th> */}
              <td>{t("TourCancelPolicy.旅行代金の30％")}</td>
              {/* <td>旅遊費用的30％</td> */}
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">{t("TourCancelPolicy.旅行開始日の前日")}</th>
              {/* <th className="fz-13 fz-16-md fw-b">旅遊開始日的前一天。</th> */}
              <td>{t("TourCancelPolicy.旅行代金の40％")}</td>
              {/* <td>旅遊費用的40％</td> */}
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">{t("TourCancelPolicy.旅行開始日の当日")}</th>
              {/* <th className="fz-13 fz-16-md fw-b">旅遊開始日的當天。</th> */}
              <td>{t("TourCancelPolicy.旅行代金の50％")}</td>
              {/* <td>旅遊費用的50％</td> */}
            </tr>
            <tr>
              <th className="fz-13 fz-16-md fw-b">{t("TourCancelPolicy.旅行開始後の解除、無連絡不参加")}</th>
              {/* <th className="fz-13 fz-16-md fw-b">旅遊開始日或開始後解除契約或未通知不參加者</th> */}
              <td>{t("TourCancelPolicy.旅行代金の100％")}</td>
              {/* <td>旅遊費用的100％</td> */}
            </tr>
          </tbody>
        </table>

        {/* レンタカー取消料 */}
        <h3 className="heading-3 mb-0">{t("CarRentalCancelPolicy.レンタカー取消料")}</h3>
        {/* <h3 className="heading-3 mb-0">租車取消費</h3> */}
        {/* <p className="mb-16 fz-14">（您所預約的內容可因無法抗拒的原因取消，但本公司會依下列規定跟您收取取消費用。）</p> */}
        <p className="mb-16 fz-14">（{t("CarRentalCancelPolicy.ご予約内容をやむを得ない場合取消可能です。但し規定により下記の予約手数料を申し受けます。")}）</p>

        <table className="mb-16 table-xsmall">
          <tbody>
            <tr>
              {/* <th className="w-60per w-40per-md table-bgc-light_navy fw-b ta-c">契約解除日</th>
              <th className="table-bgc-light_navy fw-b ta-c">取消費</th> */}
              <th className="w-60per w-40per-md table-bgc-light_navy fw-b ta-c">{t("OptionCancelPolicy.契約の解除日")}</th>
              <th className="table-bgc-light_navy fw-b ta-c">{t("OptionCancelPolicy.取消料")}</th>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">取車日的7天前</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("CarRentalCancelPolicy.乗車日の7日前")}</th>
              {/* <td>無取消費</td> */}
              <td>{t("CarRentalCancelPolicy.無料")}</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">6天前-3天前</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("CarRentalCancelPolicy.6日前〜3日前")}</th>
              <td>20%</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">2天前-1天前</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("CarRentalCancelPolicy.2日前〜前日")}</th>
              <td>30%</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">當天以後</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("CarRentalCancelPolicy.当日以降")}</th>
              <td>50%</td>
            </tr>
          </tbody>
        </table>
        <ul className="list-note light-gray mb-24 mb-32-md">
          {/* <li>若超過預定取車時間一小時以上，且無與本公司聯絡時，本公司會自行取消您的預約。</li> */}
          <li>{t("CarRentalCancelPolicy.ご予約の乗車時間を1時間以上過ぎてもご連絡のない場合は、予約の取り消しとさせていただきます。")}</li>
          {/* <li>
            若因任何原因致使無法在契約時間內完成取車/還車時，請務必聯絡本公司的營業所，以取得時間變更的許可。<br />
            若您擅自變更/延長契約時間時，則視為違反契約條款，並請您支付因其所產生的延遲費用，敬請注意。
          </li> */}
          <li>
            {t("CarRentalCancelPolicy.契約時間内に返還できない事態が発生したときは、営業所に連絡し、許可を得てください。")}<br />
            {t("CarRentalCancelPolicy.無断で契約期間を延長した場合は、貸渡約款違反となり、延滞違約金をお支払いいただきますので、特にご注意ください。")}
          </li>
        </ul>

        {/* オプション取消料 */}
        <h3 className="heading-3 mb-16">{t("OptionCancelPolicy.オプション取消料")}</h3>
        {/* <h3 className="heading-3 mb-16">加購項目取消費</h3> */}
        <table className="mb-16 table-xsmall">
          <tbody>
            <tr>
              <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">{t("OptionCancelPolicy.契約の解除日")}</th>
              <th className="table-bgc-light_navy fw-b ta-c">{t("OptionCancelPolicy.取消料")}</th>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">利用開始日的4天前</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日の4日前まで")}</th>
              {/* <td>無取消費</td> */}
              <td>{t("OptionCancelPolicy.無料")}</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">利用開始日的3天前-2天前</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日の3日前～2日前まで")}</th>
              <td>20%</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">利用開始日的前1天</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日前日")}</th>
              <td>30%</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">利用開始日當天</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始日当日")}</th>
              <td>50%</td>
            </tr>
            <tr>
              {/* <th className="fz-13 fz-16-md fw-b">利用開始後或無聯絡不參加</th> */}
              <th className="fz-13 fz-16-md fw-b">{t("OptionCancelPolicy.利用開始後又は無連絡不参加")}</th>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
        <ul className="list-note light-gray">
          <li>{t("OptionCancelPolicy.各コース・オプショナルプランに適用取消料が記載されている場合は、その取消料を適用いたします。")}</li>
          {/* <li>若各行程，各加購方案裡記載有各別的取消費用時，則適用其各別所記載的取消費用規定。</li> */}
        </ul>
      </div>
    </>
  );
};

export default PartsCancellationChargeText;
