import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputsForm1 } from "../../../types/InputsForm1";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";

type Props = {
  errors: FieldErrors;
  register: UseFormRegister<InputsForm1>;
}

const SectionOther: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    <>
      {/* <!--その他情報の入力--> */}
      <section>
        {/* <h2 className="heading-2">請輸入其它資訊</h2> */}
        <h2 className="heading-2">{t("SectionOther.その他情報の入力")}</h2>
        <div className="card">
          {/* <h3 className="heading-3-small mb-16">其它需求・詢問</h3> */}
          <h3 className="heading-3-small mb-16">{t("SectionOther.ご希望・ご質問など")}</h3>
          <textarea
            cols={30}
            rows={5}
            // placeholder="請於此處輸入您的其它需求，或是不明等相關詢問。"
            placeholder={`${t("SectionOther.ご希望・ご質問などありましたらこちらにご入力ください。")}`}
            className={"form-input-textarea" + (props.errors.note ? " error" : "")}
            {...props.register("note", { maxLength: 1000 })}
          />
          {props.errors.note &&
            props.errors.note.type === "maxLength" && (
            <p className="form-error_text">
              {t("SectionOther.ご希望・ご質問は1000文字以内でご入力ください。")}
              {/* 其它需求・詢問請填入1000以內的文字。 */}
            </p>           
          )}
        </div>
      </section>
    </>
  );
};

export default SectionOther;
