import React, {useEffect} from "react";
// import TopSearchbox from "./search/TopSearchbox";
// import {IActivity} from "../types/Activity";
// import {PlanSearch} from "../types/Search";
// import {PlanSelectFormData} from "../types/planSelectForm";
import {FreeSpace} from "../types/Freespace";
// for HTML
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import { convertFromRaw, EditorState } from "draft-js";
import {useTranslation} from "react-i18next";

type Props = {
  freeSpaceContent: FreeSpace|undefined
}

const FreeSpaceSection: React.FC<Props> = ({
  freeSpaceContent
}) => {
  
  const [freeSpace, setFreeSpace] = React.useState<FreeSpace>();
  const { t } = useTranslation();
  
  useEffect(() => {
    if (freeSpaceContent) {
      setFreeSpace({...freeSpaceContent});
    }
  },[freeSpaceContent])

  const createMarkup = (content: string) => {
    if(content) {
      const html = draftToHtml(JSON.parse(content));
      return  {
          __html: DOMPurify.sanitize(html)
      }
    }
  }

  /**
   * マークアップの内容が存在するかチェック
   * @param content 
   * @returns 
   */
  const markupContentExist = (content: string) => {
    if(content) {
      const htmlContent = EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
      return (htmlContent.getCurrentContent().hasText() && htmlContent.getCurrentContent().getPlainText().length > 0);
    }
    return false;
  }

  return (
    <>
    {freeSpace && freeSpace?.freeSpace?.banner?.pc && freeSpace?.freeSpace.banner?.sp ?
    <>
      <div className="wrap-content-900-no_padding">
        <div className="mb-0">
          <a href={freeSpace.freeSpace.banner.link} target="_blank" className="parts-hover w-100per" rel="noreferrer">
            <picture>
              <source
                srcSet={freeSpace.freeSpace.banner.pc}
                media="(min-width: 992px)"
              />
              <img
                src={freeSpace.freeSpace.banner.sp}
                alt="バナー"
              />
            </picture>
          </a>
        </div>
      </div>
    </>:<></>  
    }

    {/* フリースペース */}
    {/* PC */}
    {
    (freeSpace && freeSpace?.adminFreeSpace?.content?.pc && markupContentExist(freeSpace.adminFreeSpace.content.pc)) ||
    (freeSpace && freeSpace?.freeSpace?.content?.pc && markupContentExist(freeSpace.freeSpace.content.pc)) ?
      <div className="wrap-content-900 d-n d-b-md">
        <div className="box-alert-red-small icon-alert mt-16-md mb-16">
          <p className="fz-14 fz-16-md c-red mb-8">{t("FreeSpace.重要なお知らせ")}</p>
          {freeSpace?.adminFreeSpace?.content?.pc && markupContentExist(freeSpace.adminFreeSpace.content.pc) &&
            <div className="freespace-wrap">
              <div className="freespace-wrap-item">
                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(freeSpace.adminFreeSpace.content.pc)}></p>
              </div>
            </div>
          }
          {freeSpace?.freeSpace?.content?.pc && markupContentExist(freeSpace.freeSpace.content.pc) &&
            <div className="freespace-wrap">
              <div className="freespace-wrap-item">
                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(freeSpace.freeSpace.content.pc)}></p>
              </div>
            </div>
          }
        </div>
      </div>
    : ""}

    {/* SP */}
    {
    (freeSpace && freeSpace?.adminFreeSpace?.content?.sp && markupContentExist(freeSpace.adminFreeSpace.content.sp)) ||
    (freeSpace && freeSpace?.freeSpace?.content?.sp && markupContentExist(freeSpace.freeSpace.content.sp)) ?
      <div className="wrap-content-900 d-n-md">
        <div className="box-alert-red-small icon-alert mt-16-md mb-16">
          <p className="fz-14 fz-16-md c-red mb-8">{t("FreeSpace.重要なお知らせ")}</p>
          {freeSpace?.adminFreeSpace?.content?.sp &&
            <div className="freespace-wrap">
              <div className="freespace-wrap-item">
                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(freeSpace.adminFreeSpace.content.sp)}></p>
              </div>
            </div>
          }
          {freeSpace?.freeSpace?.content?.sp &&
            <div className="freespace-wrap">
              <div className="freespace-wrap-item">
                <p className="fz-14" dangerouslySetInnerHTML={createMarkup(freeSpace.freeSpace.content.sp)}></p>
              </div>
            </div>
          }
        </div>
      </div>
    : ""}
  </>);
}

export default FreeSpaceSection;