import React, {Fragment, useEffect} from "react";

//モーダルインポート
import {Hotel, hotelList, hotelPlanDetail, roomData} from "../../types/Hotel";
import {
  diffHotelPrice,
  getHotelDetailSearchParms,
  getHotelRoomsSearchParms,
  getRoomTypeLabel, hotelRoomSearch,
  mergeHotelRoomPlans,
  filterRooms,
  getHotelAreaCode,
  getRoomTypeCodeConfigByLanguageCode
} from "../../utils/hotel";
import RoomDetailModal from "../modal/RoomDetailModal";
import PlanDetailModal from "../modal/PlanDetailModal";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, CircularProgress} from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay-ts";
import {apiClient} from "../../utils/apiClient";
import {PlanSelectFormData} from "../../types/planSelectForm";
import _ from "lodash";
// import {roomTypeCode} from "../../config/hotel";
import {devLog} from "../../utils/errors";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/languages";

type Props = {
  staySearchPageView: number
  setStaySearchPageView: ( index: number ) => void,
  hotel: Hotel | undefined,
  // defaultHotels: Hotel | undefined,
  // setHotels: any,
  planSelectFormData: PlanSelectFormData | undefined,
  hotelCode: string,
  updateSelectHotel(planSelectFormData: PlanSelectFormData): void,
  // callback: () => void,
  mealFilter: any,
  setMealFilter: any,
  roomTypeFilter: any,
  setRoomTypeFilter: any,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const HotelPlanSearchResult: React.VFC<Props> = ({
    staySearchPageView,
    setStaySearchPageView,
    hotelCode,
    hotel,
    // defaultHotels,
    // setHotels,
    planSelectFormData,
    updateSelectHotel,
    // callback,
    mealFilter,
    setMealFilter,
    roomTypeFilter,
    setRoomTypeFilter,
}) => {
  const classesForLoading = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [roomData, setRoomData] = React.useState<roomData|undefined>(undefined);
  const [planData, setPlanData] = React.useState<hotelPlanDetail|undefined>(undefined);
  const [hotelDetail, setHotelDetail] = React.useState<hotelList>();
  const [defaultHotelDetail, setDefaultHotelDetail] = React.useState<hotelList>();
  const [planExists, setPlanExists] = React.useState(false); // プランが1つでも存在するか
  const checkIn = planSelectFormData?.searchParams.checkin ?? '';
  const checkOut = planSelectFormData?.searchParams.checkout ?? '';
  const hotelAreaCode = getHotelAreaCode(planSelectFormData);

  const { t, i18n } = useTranslation();

  //アコーディオン：制御
  const[StayAccordionStateList, SetStayAccordionStateList] = React.useState<boolean[]>([]);
  const handleStayAccordionList = (index: number) => {
    if (StayAccordionStateList[index] !== undefined) {
      const updateStatusList = _.cloneDeep(StayAccordionStateList);
      updateStatusList[index] = !updateStatusList[index];
      SetStayAccordionStateList(updateStatusList);
    }
  }

  // モーダル：客室詳細
  const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
  const handleRoomDetailModal = () => {
    setRoomDetailModalState(!roomDetailModalState);
  };
  const openRoomDetailModal = (room: roomData) => {
    setRoomData({...room});
    setRoomDetailModalState(!roomDetailModalState);
  }

  //モーダル：プラン詳細詳細
  const [PlanDetailModalState, setPlanDetailModalState] = React.useState(false);
  const callbackHandlPlandetailModal = () => {
    setPlanDetailModalState(!PlanDetailModalState);
  };
  const handlPlandetailModal = (planDetail: hotelPlanDetail|undefined, roomDetail: roomData) => {
    setRoomData({...roomDetail});
    if (planDetail !== undefined) {
      setPlanData({...planDetail})
    }
    setPlanDetailModalState(!PlanDetailModalState);
  };

  useEffect(() => {
    const cHotel = hotel?.hotelList.filter((item) => item.hotel_code === hotelCode)[0];
    if (cHotel !== undefined) {
      setHotelDetail({...cHotel});
      setDefaultHotelDetail({...cHotel});
    }
    (async () => {
      if(cHotel !== undefined && cHotel.hotelData === undefined && planSelectFormData !== undefined) {
        setLoading(true)
        const hotelSearchParams = getHotelDetailSearchParms(hotelAreaCode, cHotel.hotel_code, planSelectFormData.searchParams, i18n.language);
        await apiClient.post("/hotel/hotel-detail", JSON.stringify(hotelSearchParams))
          .then(function (response) {
            cHotel.hotelData = response.data.response;
          })
          .catch(function (error) {
            // TODO: handle error
            console.error(error);
          });
      }

      if(cHotel !== undefined && cHotel.roomData === undefined && planSelectFormData !== undefined) {
        devLog("planSelectFormData.searchParams", planSelectFormData.searchParams);
        const hotelRoomsSearchParams = getHotelRoomsSearchParms(hotelAreaCode, cHotel.hotel_code, planSelectFormData.searchParams, i18n.language);
        await apiClient.post("/hotel/hotel-all-room", JSON.stringify(hotelRoomsSearchParams))
          .then(function (response) {
            cHotel.roomData = mergeHotelRoomPlans( cHotel.hotelData, response.data.roomData);
          })
          .catch(function (error) {
            // TODO: handle error
            console.error(error);
          });
      }

      const planIsExistsRooms = _.filter(cHotel?.roomData, (room) => room.planList.length > 0)
      if (planIsExistsRooms.length > 0) {
        setPlanExists(true);
        let roomTypeList: {label: string, value: string}[] = []; // 選択可能なルームタイプ
        let mealList: {label: string, value: string}[] = []; // 選択可能な食事
        // プランを絞り込む
        let newHotelDetail = _.cloneDeep(cHotel);
        if (newHotelDetail?.hotelData?.room_list) {
          const accordionStateList: boolean[] = [];
          const roomTypeCode = getRoomTypeCodeConfigByLanguageCode(i18n.language);

          // map内でtを繰り返し使いたくないので外で翻訳する
          const roomTypes = [
            t("Hotel.シングル"), 
            t("Hotel.セミダブル"), 
            t("Hotel.ダブル"), 
            t("Hotel.ツイン"), 
            t("Hotel.トリプル"), 
            t("Hotel.4ベッド"), 
          ];

          const roomTypeLabelWesternStyle = t("Hotel.洋室");

          const mealLabelBreakfast = t("Hotel.朝食付き");
          const mealLabelLunch = t("Hotel.昼食付き");
          const mealLabelDinner = t("Hotel.夕食付き");
          const mealLabelNothing = t("Hotel.食事なし");

          // @ts-ignore
          newHotelDetail.hotelData.room_list = newHotelDetail.hotelData.room_list.map((roomData) => {
            const roomType = roomTypeCode[roomData.room_type_code];
            // if (["シングル", "セミダブル", "ダブル", "ツイン", "トリプル", "4ベッド"].includes(roomType)) {
            // if (["單人床房", "小型雙人床房", "雙人床房", "雙床房", "三床房", "四床房"].includes(roomType)) {
            if (roomTypes.includes(roomType)) {
              // roomTypeList.push({label: "洋室", value: "western_style"});
              // roomTypeList.push({label: "西式客房", value: "western_style"});
              roomTypeList.push({label: roomTypeLabelWesternStyle, value: "western_style"});
            } else {
              roomTypeList.push({label: roomType, value: roomData.room_type_code});
            }

            // 食事の有無("1"=あり、"0"=なし)
            roomData.plan_list.forEach((plan: any) => {
              if (plan.meal_breakfast === "1") {
                // mealList.push({label: "朝食付き", value: "meal_breakfast"});
                // mealList.push({label: "附早餐", value: "meal_breakfast"});
                mealList.push({label: mealLabelBreakfast, value: "meal_breakfast"});
              }
              if (plan.meal_lunch === "1") {
                // mealList.push({label: "昼食付き", value: "meal_lunch"});
                // mealList.push({label: "附午餐", value: "meal_lunch"});
                mealList.push({label: mealLabelLunch, value: "meal_lunch"});
              }
              if (plan.meal_dinner === "1") {
                // mealList.push({label: "夕食付き", value: "meal_dinner"});
                // mealList.push({label: "附晚餐", value: "meal_dinner"});
                mealList.push({label: mealLabelDinner, value: "meal_dinner"});
              }
              if (plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
                // mealList.push({label: "食事なし", value: "meal_nothing"});
                // mealList.push({label: "無附餐", value: "meal_nothing"});
                mealList.push({label: mealLabelNothing, value: "meal_nothing"});
              }
            })
            accordionStateList.push(false);
            return roomData;
          })
          roomTypeList = _.uniqBy(roomTypeList, "label");
          mealList = _.uniqBy(mealList, "label");
          setMealFilter(mealList);
          setRoomTypeFilter(roomTypeList);
          SetStayAccordionStateList(accordionStateList); // アコーディオンの状態
        }
      } else {
        setPlanExists(false);
        setMealFilter([]); // 絞り込み条件(食事)
        setRoomTypeFilter([]); // 絞り込み条件(部屋タイプ)
        SetStayAccordionStateList([]); // アコーディオンの状態
      }
      

      if (cHotel !== undefined) {
        setHotelDetail({...cHotel});
        setDefaultHotelDetail({...cHotel});
      }
      
      setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelCode, hotel]);

    const roomBaxClass = (index:number) => {
      if (index > 0) {
        return "box-white radius-none small-pc mt-40";
      }
      return "box-white radius-none small-pc "
    };
    
    const callbackUpdateSelectHotel = (planData: hotelPlanDetail|undefined, room:roomData) => {
      if (planSelectFormData !== undefined && hotelDetail !== undefined) {
        ( async () => {
          setUpdateLoading(true);
          
          // ルーム詳細取得
          const resultRoom = await hotelRoomSearch(hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.detail.room_info.room_code, planData?.plan_code, i18n.language);

          if (resultRoom && resultRoom.success) {
            planSelectFormData.hotel[0].detail = hotelDetail;
            planSelectFormData.hotel[0].plan = planData;
            planSelectFormData.hotel[0].roomData = room;
            planSelectFormData.hotel[0].checkTime = hotelDetail.hotelData?.hotel_info.check_in_start;
            planSelectFormData.hotel[0].planRoomCode = resultRoom.response.room_plan_detail.room_plan_code;
            planSelectFormData.hotel[0].total = planData?.room_plan_detail.total_price?? 0;
            updateSelectHotel({...planSelectFormData});
          } else {
            // alert("プランの取得に失敗しました。");
            // alert("讀取活動方案失敗");
            alert(t("HotelPlanSearchResult.プランの取得に失敗しました。"));
          }
          setUpdateLoading(false);
        })();
      }
    }

    const getHotelPlanThumbnailUrl = (roomData: roomData) => {
      if (roomData.first_plan?.room_plan_detail.plan_image_list != null) {
        return roomData.first_plan.room_plan_detail.plan_image_list[0].plan_image_url;
      }

      if (roomData.detail.room_info.image_list != null) {
        return roomData.detail.room_info.image_list[0]?.image_url ;
      }

      return hotelDetail?.image_l_url;
    }

    // 絞り込み条件変更時
    useEffect(() => {
      const updateHotelData = filterRooms(defaultHotelDetail, {
        roomTypes: roomTypeFilter,
        mealTypes: mealFilter
      });
      if (updateHotelData) {
        setHotelDetail({...updateHotelData});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomTypeFilter, mealFilter]);

    return (
        <>
            <div className={(staySearchPageView === 2? " animation-fade active":" animation-fade")}>

              <Backdrop className={classesForLoading.backdrop} open={updateLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              {loading ?
                <LoadingOverlay
                  active={loading}
                  spinner
                >
                  <div className="pb-100"></div>
                </LoadingOverlay>
                : 
                <>
                  {hotelDetail !== undefined ?
                    <>
                      <div>
                        <div className="box-heading-navy">{hotelDetail?.hotel_name}</div>
                        {/* 現在選択されているプラン */}
                        {planExists === true ?
                        hotelDetail?.roomData?.map((data, rindex) => (
                          data?.planList && data.planList.length > 0 ?
                          <Fragment key={data.room_code}>
                            <div className={roomBaxClass(rindex)}>
                              <div className="box-stay">
                                <div className="box-stay-item">
                                  <div className="box-stay-item-thumbnail large">
                                    {data?.detail.room_info.image_list !== undefined?
                                      <img
                                        src={data?.detail.room_info.image_list[0]?.image_url}
                                        alt=""
                                      />
                                      :
                                      <img
                                        src={hotelDetail?.image_l_url}
                                        alt=""
                                      />
                                    }
                                  </div>
                                  <div className="box-stay-item-content">
                                    <div className="box-stay-item-content-heading">
                                      {data.detail?.room_info.room_name}
                                    </div>
                                    <div className="mt-5 mb-16-md">
                                      <button 
                                        type="button" 
                                        onClick={() => openRoomDetailModal(data)} 
                                        className="button-border-small-blue w-100per w-a-md"
                                      >
                                        {/* 客房簡介 */}
                                        {t("HotelPlanSearchResult.客室詳細を見る")}
                                      </button>
                                    </div>

                                    {/* 以下PCのみ */}
                                    <div className="box-stay-item-content-label d-n d-b-md">
                                      <div className="list-stay-label">
                                        <ul className="list-stay-label-list">
                                          {data.detail.room_info?.room_feature_list?.map((room_feature,index) => (
                                            <li key={"roomInfo_pc"+index} className="list-stay-label-list-item">{room_feature.name}</li>
                                          ))}
                                        </ul>
                                      </div>
                                      <p className="fz-13">
                                        {/* 【ルームタイプ】{getRoomTypeLabel(data.detail.room_info.room_type_code)} */}
                                        {/* 【房型】{getRoomTypeLabel(data.detail.room_info.room_type_code, i18n.language)} */}
                                        【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.detail.room_info.room_type_code, i18n.language)}
                                        {/* {data.detail.room_info.room_size ? <> 【広さ】{data.detail.room_info.room_size}㎡</> : ""} */}
                                        {data.detail.room_info.room_size ? <> 【客房大小】{data.detail.room_info.room_size}㎡</> : ""}
                                        {data.detail.room_info.bedding_list?
                                          <>
                                            {/* 【ベッドサイズ】 */}
                                            {/* 【床型大小】 */}
                                            【{t("Hotel.ベッドサイズ")}】
                                            {data.detail.room_info.bedding_list?.map((bedding, indexOfBedding)  => (
                                              <Fragment key={indexOfBedding}>
                                                {bedding.name}
                                              </Fragment>
                                            ))}
                                          </>  :<></>
                                        }

                                      </p>
                                    </div>
                                  </div>

                                  {/* sp時 */}
                                  <div className="box-stay-item-content-sp mt-16 mt-0-md mb-16 mb-0-md w-100per">
                                    <div className="d-n-md">
                                      <div className="list-stay-label">
                                        <ul className="list-stay-label-list mb-20-md">
                                          {data.detail.room_info?.room_feature_list?.map((room_feature, index) => (
                                            <li key={"roomInfo_sp"+index} className="list-stay-label-list-item">{room_feature.name}</li>
                                          ))}
                                        </ul>
                                      </div>
                                      <p className="fz-13">
                                        {/* 【ルームタイプ】{getRoomTypeLabel(data.detail.room_info.room_type_code)} */}
                                        【{t("Hotel.ルームタイプ")}】{getRoomTypeLabel(data.detail.room_info.room_type_code, i18n.language)}
                                        {/* {data.detail.room_info.room_size ? <> 【広さ】{data.detail.room_info.room_size}㎡</> : ""} */}
                                        {data.detail.room_info.room_size ? <> 【{t("Hotel.広さ")}】{data.detail.room_info.room_size}㎡</> : ""}
                                        {data.detail.room_info.bedding_list?
                                          <>
                                            {/* 【ベッドサイズ】 */}
                                            【{t("Hotel.ベッドサイズ")}】
                                            {data.detail.room_info.bedding_list?.map((bedding, indexOfBedding)  => (
                                              <Fragment key={indexOfBedding}>
                                                {bedding.name}
                                              </Fragment>
                                            ))}
                                          </>  :<></>}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-light_navy small radius-bottom">
                              {/* アコーディオンに隠れない一個目の内藤 */}
                              {data.first_plan !== undefined ? <>
                                <div className="box-stay-list">
                                  <div className="box-stay-list-item">
                                    <div className="box-white small-pc mb-8 mb-16-md">
                                      <div className="box-stay">
                                        <div className="box-stay-item">
                                          <div className="box-stay-item-thumbnail xmiddle">
                                            <img
                                              src={getHotelPlanThumbnailUrl(data)}
                                              alt=""
                                            />
                                          </div>
                                          <div className="box-stay-item-content">

                                            <div className="box-stay-item-content-heading">
                                              {data.first_plan.room_plan_detail.plan_name}
                                            </div>
                                            <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                              <button 
                                                onClick={() => handlPlandetailModal(data.first_plan, data)} 
                                                className="button-border-small-blue w-100per w-a-md"
                                              >
                                                {/* 查看住宿方案 */}
                                                {t("HotelPlanSearchResult.プラン詳細を見る")}
                                              </button>
                                            </div>
                                            <div className="box-stay-item-content-label">
                                              <ul className="box-stay-item-content-label-list light-green">
                                                {data.first_plan.room_plan_detail.meal_breakfast === "0" && data.first_plan.room_plan_detail.meal_lunch === "0" && data.first_plan.room_plan_detail.meal_dinner === "0" && (
                                                  <li className="box-stay-item-content-label-list-item">
                                                    {/* 食事なし */}
                                                    {/* 無附餐 */}
                                                    {t("Hotel.食事なし")}
                                                  </li>
                                                )}
                                                {data.first_plan.room_plan_detail.meal_breakfast === "1" && (
                                                  <li className="box-stay-item-content-label-list-item">
                                                    {/* 朝食 */}
                                                    {/* 早餐 */}
                                                    {t("Hotel.朝食")}
                                                  </li>
                                                )}
                                                {data.first_plan.room_plan_detail.meal_lunch === "1" && (
                                                  <li className="box-stay-item-content-label-list-item">
                                                    {/* 昼食 */}
                                                    {/* 午餐 */}
                                                    {t("Hotel.昼食")}
                                                  </li>
                                                )}
                                                {data.first_plan.room_plan_detail.meal_dinner === "1" && (
                                                  <li className="box-stay-item-content-label-list-item">
                                                    {/* 夕食 */}
                                                    {/* 晚餐 */}
                                                    {t("Hotel.夕食")}
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                            <p className="fz-13">
                                              {/* 辦理入住時間：{data.detail.room_plan_detail.check_in_start}　辦理退房時間：{data.detail.room_plan_detail.check_out} */}
                                              {t("HotelPlanSearchResult.チェックイン")}：{data.detail.room_plan_detail.check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{data.detail.room_plan_detail.check_out}
                                            </p>
                                          </div>


                                          <div className="box-stay-item-detail top-hotel_select mt-10 mt-0-md">
                                            <div className="w-100per">
                                              <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                <p className="mb-0-md fz-13 fw-b ta-c-md">
                                                  {/* 追加費用<br /> */}
                                                  {t("HotelPlanSearchResult.追加料金")}<br />
                                                  {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                                  {/* <span className="fz-10 fz-11-md">（每一人）</span> */}
                                                  <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                </p>
                                                <p className="mb-5-md c-red fz-24 fw-b ta-c-md">
                                                  {diffHotelPrice(planSelectFormData, {
                                                    isUpdate: false,
                                                    step: 'plan',
                                                    updateHotelIndex: 0,
                                                    updatePlan: data.first_plan,
                                                    updateHotel: undefined,
                                                    checkIn: checkIn,
                                                    checkOut: checkOut
                                                  })}<span className="fz-12">{t("Common.円")}</span>
                                                </p>
                                              </div>
                                              <p className="mb-0-md">
                                                <button
                                                  onClick={() => callbackUpdateSelectHotel(data.first_plan, data)}
                                                  className="button-medium-light_blue button-variable sp-medium w-100per"
                                                >
                                                  {/* このプランを<br className="d-n d-b-md" />
                                                  選択する */}
                                                  {/* 選擇此住宿方案 */}
                                                  {
                                                    i18n.language === LANGUAGES.JA ? (
                                                      <>このプランを<br className="d-n d-b-md" />選択する</>
                                                    ) : (
                                                      <>選擇此住宿方案</>
                                                    )
                                                  }
                                                </button>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>:<></>}
                              {/* アコーディオンに隠れるコンテンツ */}
                              {data.planList !== undefined && data.planList?.length > 1 ?
                                <>
                                  <div>
                                    <div className="accordion-stay">
                                      <div className={"accordion-stay-detail" + (!StayAccordionStateList[rindex] ? " ":" active")}>
                                        <div className="box-stay-list mb-8 mb-16-md">
                                          {data.planList?.map((plan_list_data,pIndex) => (
                                            <Fragment key={plan_list_data.room_plan_detail.room_plan_code}>
                                            {pIndex !== 0 ?
                                            <>
                                              <div className="box-stay-list-item">
                                                <div className="box-white small-pc">
                                                  <div className="box-stay">
                                                    <div className="box-stay-item">
                                                      <div className="box-stay-item-thumbnail sp_small-pc_large">
                                                        {plan_list_data.room_plan_detail.plan_image_list !== undefined?
                                                          <img
                                                            src={plan_list_data.room_plan_detail.plan_image_list[0].plan_image_url}
                                                            alt=""
                                                          />
                                                          :
                                                          <img
                                                            src={roomData?.detail.room_info.image_list[0].image_url}
                                                            alt=""
                                                          />
                                                        }
                                                      </div>
                                                      <div className="box-stay-item-content">

                                                        <div className="box-stay-item-content-heading">
                                                          {plan_list_data.room_plan_detail.plan_name}
                                                        </div>
                                                        <div className="mt-16 mt-20-md mb-16 mb-20-md">
                                                          <button 
                                                            onClick={() => handlPlandetailModal(plan_list_data, data)}
                                                            className="button-border-small-blue w-100per w-a-md"
                                                           >
                                                            {t("HotelPlanSearchResult.プラン詳細を見る")}
                                                          </button>
                                                        </div>
                                                        <div className="box-stay-item-content-label">
                                                          <ul className="box-stay-item-content-label-list light-green">
                                                            {plan_list_data.room_plan_detail.meal_breakfast === "0" && plan_list_data.room_plan_detail.meal_lunch === "0" && plan_list_data.room_plan_detail.meal_dinner === "0" && (
                                                              <li className="box-stay-item-content-label-list-item">
                                                                {t("Hotel.食事なし")}
                                                              </li>
                                                            )}
                                                            {plan_list_data.room_plan_detail.meal_breakfast === "1" && (
                                                              <li className="box-stay-item-content-label-list-item">
                                                                {t("Hotel.朝食")}
                                                              </li>
                                                            )}
                                                            {plan_list_data.room_plan_detail.meal_lunch === "1" && (
                                                              <li className="box-stay-item-content-label-list-item">
                                                                {t("Hotel.昼食")}
                                                              </li>
                                                            )}
                                                            {plan_list_data.room_plan_detail.meal_dinner === "1" && (
                                                              <li className="box-stay-item-content-label-list-item">
                                                                {t("Hotel.夕食")}
                                                              </li>
                                                            )}
                                                          </ul>
                                                        </div>
                                                        <p className="fz-13">
                                                          {t("HotelPlanSearchResult.チェックイン")}：{data.detail.room_plan_detail.check_in_start}　{t("HotelPlanSearchResult.チェックアウト")}：{data.detail.room_plan_detail.check_out}
                                                        </p>
                                                      </div>

                                                      <div className="box-stay-item-detail top-hotel_select mt-10 mt-0-md">
                                                        <div className="w-100per">
                                                          <div className="d-f jc-sb d-ib-md w-100per-md mb-10 mb-0-md">
                                                            <p className="mb-0-md fz-13 fw-b ta-c-md">
                                                              {t("HotelPlanSearchResult.追加料金")}<br />
                                                              {/* <span className="fz-10 fz-11-md">（１人あたり）</span> */}
                                                              <span className="fz-10 fz-11-md">（{t("HotelPlanSearchResult.１人あたり")}）</span>
                                                            </p>
                                                            <p className="mb-5-md c-red fz-24 fw-b ta-c-md">
                                                              {diffHotelPrice(planSelectFormData, {
                                                                isUpdate: false,
                                                                step: 'plan',
                                                                updateHotelIndex: 0,
                                                                updatePlan: plan_list_data,
                                                                updateHotel: undefined,
                                                                checkIn: checkIn,
                                                                checkOut: checkOut
                                                              })}<span className="fz-12">{t("Common.円")}</span>
                                                            </p>
                                                          </div>
                                                          <p className="mb-10-md">
                                                            <button
                                                              onClick={() => callbackUpdateSelectHotel(plan_list_data, data)}
                                                              className="button-medium-light_blue button-variable sp-medium w-100per"
                                                            >
                                                              {/* このプランを<br className="d-n d-b-md" />
                                                              選択する */}
                                                              {/* 選擇此住宿方案 */}
                                                              {
                                                                i18n.language === LANGUAGES.JA ? (
                                                                  <>このプランを<br className="d-n d-b-md" />選択する</>
                                                                ) : (
                                                                  <>選擇此住宿方案</>
                                                                )
                                                              }
                                                            </button>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>:<></>
                                            }
                                            </Fragment>
                                          ))}

                                        </div>
                                      </div>

                                      {/* アコーディオンの開閉ボタン */}
                                      <div className="accordion-stay-heading">
                                        <button onClick={() => handleStayAccordionList(rindex)} className="accordion-stay-button button-clear">
                                          {
                                            (() => {
                                              if (!StayAccordionStateList[rindex]) {
                                                // return (<span><span className="pr-24">查看所有的住宿方案</span><span className="accordion-stay-icon"></span></span>);
                                                return (<span><span className="pr-24">{t("HotelPlanSearchResult.宿泊プランをすべて見る")}</span><span className="accordion-stay-icon"></span></span>);
                                              } else {
                                                // return (<span><span className="pr-24">閉じる</span><span className="accordion-stay-icon active"></span></span>);
                                                // return (<span><span className="pr-24">關閉視窗</span><span className="accordion-stay-icon active"></span></span>);
                                                return (<span><span className="pr-24">{t("HotelPlanSearchResult.閉じる")}</span><span className="accordion-stay-icon active"></span></span>);
                                              }
                                            })()
                                          }
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :<></>}

                            </div>
                          </Fragment>
                          : ""
                        ))
                        :<>
                          {/* プランが存在しない場合 */}
                          <div className="box-white radius-none small-pc">
                            <div className="box-stay">
                              <div className="box-stay-item">
                                <div className="box-stay-item-content">
                                  <section className="pt-20 pb-20">
                                    <div className="wrap-content-900">
                                      <p className="mb-10 ta-c">
                                        <img src={require("../../img/share/icon/icon_searchzero.svg").default} alt="" className="d-ib" />
                                      </p>
                                      {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">選択可能なプランが存在しません</p> */}
                                      {/* <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">沒有可選擇的住宿方案</p> */}
                                      <p className="fz-18 fw-b ta-c mb-16 c-dark_blue2">{t("HotelPlanSearchResult.選択可能なプランが存在しません")}</p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>}
                      </div>
                    </>
                  :<></>}
                </>
              }

              {planSelectFormData && planSelectFormData?.hotelData && planSelectFormData?.hotelData?.length > 1 && (
                <>
                  <div className="mt-32 ta-c">
                    <button onClick={()=> setStaySearchPageView(staySearchPageView-1)} className="button-border-small-blue medium w-100per w-a-md ml-a-md mr-a-md">
                      <i className="icon-left-arrow-light_blue mr-5"></i>
                      {/* 宿泊施設の選択に戻る */}
                      {/* 返回上一頁  選擇住宿設施 */}
                      {t("StaySearchbox.宿泊施設の選択に戻る")}
                    </button>
                  </div>
                </>
              )}
            </div>

          {/* モーダル：客室詳細 */}
          <RoomDetailModal 
            isShow={roomDetailModalState} 
            callback={handleRoomDetailModal} 
            roomData={roomData} 
          />

          {/*/!* モーダル：プラン詳細 *!/*/}
          <PlanDetailModal planSelectFormData={planSelectFormData} isUpdate={true} updateIndex={0} isShow={PlanDetailModalState} updateSelectHotel={callbackUpdateSelectHotel} callback={callbackHandlPlandetailModal} planData={planData} roomData={roomData} />

        </>
    );
}
export default HotelPlanSearchResult;