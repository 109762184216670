import { FC, SyntheticEvent, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { LANGUAGES } from "../../../constants/languages";
import { PartsPrefecture } from "../../parts";
import ValidationError from "../../error/ValidationError";
import { useTranslation } from "react-i18next";

//input
import { InputText } from "./../../input/InputText";
import { InputRadio } from "./../../input/InputRadio";
import { InputEmail } from "./../../input/InputEmail";
import { InputsForm2 } from "../../../types/InputsForm2";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";

type Props = {
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm2>;
  sendTestMail: (email: string, languageCode: string) => Promise<boolean>;
}

const SectionApplicantOption: FC<Props> = (props) => {

  const { t, i18n } = useTranslation(); 

  // テストメール送信ボタンの使用可・不可
  const [sendingTestMail, setSendingTestMail] = useState(false);

  // バリデーション用正規表現: 半角英字
  const patternAlphabeticCharacters = /^[a-zA-Z]+$/;

  // バリデーション用正規表現: 全角カタカナ
  const patternKana = /^[ァ-ンヴー]+$/i;


  // テストメール送信
  const handleSendTestMailClick = async (event: SyntheticEvent) => {
    setSendingTestMail(true);

    event.preventDefault();

    const targetEmail = props.getValues("applicant2.email");

    await props.sendTestMail(targetEmail, i18n.language);

    setSendingTestMail(false);
  }

  return (
    <>
      {/* <!--オプションの代表者情報の入力--> */}
      <section>
        {/* <h2 className="heading-2 mt-0 mb-8">請輸入參加體驗活動的代表人資料</h2> */}
        <h2 className="heading-2 mt-0 mb-8">{t("SectionApplicantOption.オプションの代表者情報")}</h2>
        {/* <p className="mb-24">
          基本プランおよび追加オプションのお申し込みに必要な確認事項となります。
        </p> */}
        <div className="card mb-20">
          <div className="box-separator_bottom-large">
            {/* <!--名前(フリガナ)--> */}
            <div
              id="form_2-name-hurigana"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicantOption.氏名")} <span hidden={i18n.language !== LANGUAGES.JA}>( フリガナ )</span>
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-pc">
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">{t("SectionApplicantOption.セイ")}</div>
                      <div>
                        <InputText
                          name="applicant2.name.kana.last"
                          placeholder={t("SectionApplicantOption.例：オキナワ")}
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 10,
                            pattern: i18n.language === LANGUAGES.JA ? patternKana : patternAlphabeticCharacters
                          }}
                          error={props.errors.applicant2?.name?.kana?.last}
                        />
                        {props.errors.applicant2?.name?.kana?.last?.type === "required" && (
                          <ValidationError message={t("SectionApplicantOption.お名前（セイ）をご入力ください。")} />
                        )}
                        {props.errors.applicant2?.name?.kana?.last?.type === "maxLength" && (
                          <ValidationError message={t("SectionApplicantOption.お名前（セイ）は10文字以内でご入力ください。")} />
                        )}
                        {props.errors.applicant2?.name?.kana?.last?.type === "pattern" && i18n.language === LANGUAGES.TW && (
                          <ValidationError message={"請填入字母字符的形式。"} />
                        )}
                        {props.errors.applicant2?.name?.kana?.last?.type === "pattern" && i18n.language === LANGUAGES.JA && (
                          <ValidationError message={"全角カタカナでご入力ください。"} />
                        )}
                      </div>
                    </div>
                    <div className="form-column-pc-item">
                      <div className="heading-item_6 fw-b">{t("SectionApplicantOption.メイ")}</div>
                      <div>
                        <InputText
                          name="applicant2.name.kana.first"
                          placeholder={t("SectionApplicantOption.例：タロウ")}
                          register={props.register}
                          options={{ 
                            required: true,
                            maxLength: 10,
                            pattern: i18n.language === LANGUAGES.JA ? patternKana : patternAlphabeticCharacters
                          }}
                          error={props.errors.applicant2?.name?.kana?.first}
                        />
                        {props.errors.applicant2?.name?.kana?.first?.type === "required" && (
                          <ValidationError message={t("SectionApplicantOption.お名前（メイ）をご入力ください。")} />
                        )}
                        {props.errors.applicant2?.name?.kana?.first?.type === "maxLength" && (
                          <ValidationError message={t("SectionApplicantOption.お名前（メイ）は10文字以内でご入力ください。")} />
                        )}
                        {props.errors.applicant2?.name?.kana?.first?.type === "pattern" && i18n.language === LANGUAGES.TW && (
                          <ValidationError message={"請填入字母字符的形式。"} />
                        )}
                        {props.errors.applicant2?.name?.kana?.first?.type === "pattern" && i18n.language === LANGUAGES.JA && (
                          <ValidationError message={"全角カタカナでご入力ください。"} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--住所 日本語の時のみ表示するので翻訳は不要 --> */}
            {
              i18n.language === LANGUAGES.JA && (
                <div
                  id="form_2-address"
                  className="box-separator_bottom-large-item"
                >
                  <div className="form-content">
                    <div className="form-content-heading">
                      <h3 className="heading-3-small">
                        都道府県
                        <span className="label-required ml-5">必須</span>
                      </h3>
                    </div>
                    <div 
                      className={
                        "form-content-input" +
                        (props.errors.applicant2?.prefecture
                          ? " error"
                          : "")
                      }
                    >
                      <div>
                        {
                          // selectにしないと予約時の都道府県コード変換ができない可能性が高くなる(例: 県まで入力しないパターン)
                          <div className="select_box-default w-200">
                            <PartsPrefecture
                              name="applicant2.prefecture"
                              register={props.register}
                              options={{required: true}}
                            />
                          </div>
                        }
                        {props.errors.applicant2?.prefecture &&
                          props.errors.applicant2?.prefecture.type === "required" && (
                            <p className="form-error_text">
                             都道府県を選択してください。
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {/* <!--ご連絡先--> */}
            <div
              id="form_2-tel"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicantOption.電話番号")}
                    {/* 電話號碼 */}
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div>
                    <InputText
                      name="applicant2.tel"
                      placeholder="例：00011112222"
                      register={props.register}
                      // options={{ required: true, pattern: /^(0{1}\d{9,10})$/i }}
                      options={{ required: true, pattern: /^[0-9]+$/ }}
                      error={props.errors.applicant2?.tel}
                    ></InputText>
                    <p className="fz-13">※{t("SectionApplicantOption.当日、連絡がとれる電話番号をご入力ください")}</p>
                    {/* <p className="fz-13">※請輸入當天可以連絡之電話號碼</p> */}
                    {props.errors.applicant2?.tel &&
                      props.errors.applicant2?.tel.type ===
                        "required" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.携帯番号をご入力ください。")}
                          {/* 請填入您的手機號碼。 */}
                        </p>
                      )}
                    {props.errors.applicant2?.tel &&
                      props.errors.applicant2?.tel.type ===
                        "pattern" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.携帯番号の形式でご入力ください。")}
                          {/* 請填入手機號碼的形式。 */}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--メールアドレス--> */}
            <div
              id="form_2-mail_address"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    {t("SectionApplicant.メールアドレス")}
                    {/* 郵件地址 */}
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div className="form-column-mail">
                    <div className="form-column-mail-item">
                      <div>
                        <InputEmail
                          name="applicant2.email"
                          placeholder="例：mail@example.com"
                          register={props.register}
                          options={{
                            required: true,
                            maxLength: 200,
                            pattern: /^\S+@\S+$/i, // todo: もっとちゃんとしたのを入れる
                          }}
                          error={props.errors.applicant2?.email}
                        ></InputEmail>
                        {/* <p className="fz-13">
                          ※半角英数字でご入力ください。
                        </p> */}
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type === "required" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスをご入力ください。")}
                              {/* 請填入您的電子郵件。 */}
                            </p>
                          )}
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type ===
                            "maxLength" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスは200文字以内でご入力ください。")}
                              {/* 郵件地址請填入200以內的文字。 */}
                            </p>
                          )}
                        {props.errors.applicant2?.email &&
                          props.errors.applicant2?.email.type === "pattern" && (
                            <p className="form-error_text">
                              {t("SectionApplicant.メールアドレスを正しくご入力ください。")}
                              {/* 請填入正確的郵件地址。 */}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-column-mail-item">
                      <div>
                        <button className="button-blue-form_button-small" onClick={handleSendTestMailClick} disabled={sendingTestMail}>
                          {t("SectionApplicant.テストメールを送信")}
                          {/* 發送測試郵件 */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="box-small-light-red mt-16">
                    <ul className="list-note light-gray">
                      <li>
                        {t("SectionApplicant.予約完了メールは予約の確認、問い合わせ先などが記載されている大切なメールです。確実に受信できるように「テストメール送信」をお試しください。")}
                        {/* 預訂完成通知郵件內文是有記載您的預約內容、諮詢處聯繫方式等資訊的重要郵件。為確保您能收到郵件，請按「發送測試郵件」進行測試。 */}
                      </li>
                      <li>
                        {/* 迷惑メール設定をしている場合、「digitaldmoplatform.com
                        」のメールを受信出来るように設定をお願いいたします。 */}
                        {t("SectionApplicant.迷惑メール設定をしている場合、「digitaldmoplatform.com」のメールを受信出来るように設定をお願いいたします。")}
                        {/* 如有設定阻止垃圾郵件時，請將本網站郵件「digitaldmoplatform.com」加入安全清單內，謝謝。。 */}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="mt-16 mb-8 fw-b">
                      {t("SectionApplicant.メールアドレス(確認)")}
                      {/* 郵件地址(確認用) */}
                      <span className="label-required ml-5">必須</span>
                    </p>
                    <InputEmail
                      name="applicant2.email_confirm"
                      placeholder="例：mail@example.com"
                      register={props.register}
                      options={{
                        required: true,
                        validate: {
                          sameEmail: (value) => {
                            return props.getValues("applicant2.email") === value;
                          },
                        },
                      }}
                      error={props.errors.applicant2?.email_confirm}
                    ></InputEmail>
                    {props.errors.applicant2?.email_confirm &&
                      props.errors.applicant2?.email_confirm.type === "required" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.メールアドレスをご入力ください。")}
                          {/* 請填入您的電子郵件。 */}
                        </p>
                      )}
                    {props.errors.applicant2?.email_confirm &&
                      props.errors.applicant2?.email_confirm.type === "sameEmail" && (
                        <p className="form-error_text">
                          {t("SectionApplicant.メールアドレスが異なります。")}
                          {/* 郵件地址不相同。 */}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* <!--性別--> */}
            <div
              id="form_2-sexual"
              className="box-separator_bottom-large-item"
            >
              <div className="form-content">
                <div className="form-content-heading">
                  <h3 className="heading-3-small">
                    性別
                    <span className="label-required ml-5">必須</span>
                  </h3>
                </div>
                <div className="form-content-input">
                  <div
                    className={
                      "form-content-input-sexual" +
                      (props.errors.applicant2?.gender ? " error" : "")
                    }
                  >
                    <InputRadio
                      name="applicant2.gender"
                      control={props.control}
                      radios={[
                        { label: "男性", value: "male" },
                        { label: "女性", value: "female" },
                      ]}
                      options={{ required: true }}
                      isRow={true}
                    ></InputRadio>
                    {props.errors.applicant2?.gender && (
                      <p className="form-error_text">
                        {/* 性別をご選択ください。 */}
                        {t("SectionApplicant.性別をご選択ください。")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionApplicantOption;
