import React, { Fragment } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//scss
import "../../scss/common.scss";
import "../../scss/component.scss";
import {priceResponce} from "../../types/price";
import {
  // dateFormat, 
  toCircled
} from "../../utils/convert";
import { useTranslation } from "react-i18next";
import {getCarRentalAnshinOptionItem} from "../../utils/carRental";
import SectionCouponsDiscountPrice from "./Coupons/SectionTravelDiscountPrice";

type Props = {
  priceData: priceResponce | undefined,
  cardNumber?: number | undefined,
  cardName?: string
}

const SectionTravelPrice: React.FC<Props> = (props) => {

  const { t,i18n } = useTranslation();
  
  const priceData = props.priceData?.result;
  return (
    <>
      {priceData && (
        <>
          <section>
            {/* 旅行代金 */}
            {/* <h2 className="heading-2">費用金額</h2> */}
            <h2 className="heading-2">{t("SectionTravelPrice.旅行代金")}</h2>
            <div className="box-total">
              {/* <span className="box-total-heading">支付費用總金額</span> */}
              <span className="box-total-heading">{t("SectionTravelPrice.お支払い総額")}</span>
              <span className="box-total-pay">
                {priceData?.taxTotalPrice?.toLocaleString()}
                    <span className="c-red fz-16">{t("Common.円")}(税込)</span>
              </span>
              {props.cardNumber && props.cardName && (
                <>
                  <span className="box-total-credit">
                    <span className="box-total-credit-shield">
                      ＊＊＊＊-＊＊＊＊-＊＊＊＊-
                    </span>
                    {props.cardNumber.toString().slice(-4)}：{props.cardName}
                  </span>
                </>
              )}
            </div>
            <Accordion className="accordion-total">
              <AccordionSummary
                aria-controls="panel1a-content"
                className="accordion-total-heading"
                id="panel1a-header"
              >
                {t("SectionTravelPrice.旅行代金内訳を見る")}
                {/* 查看費用明細 */}
                <span className="icon-accodion"></span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-total-content">
                  <div className="box-separate">
                    <div className="box-separate-item">
                      {priceData?.basicPriceDetail.map((basicPriceDetail, bIndex) => (
                          <Fragment key={bIndex}>
                            <div className="box-separate-item-block large">
                              {bIndex === 0 ?
                                /* 最初の要素のみ見出しを出力 */
                                <>
                                  <h3 className="c-red fz-16 fw-b mb-16">
                                    {t("SectionTravelPrice.基本旅行代金")}
                                    {/* 行程基本費用 */}
                                  </h3>
                                </>:<></>
                              }
                              <p className="fz-14 fz-16-md fw-b mb-8 mb-16-md">{t("Hotel.客室")}{toCircled(bIndex + 1)}</p>
                              <div className="box-separate-item-block-subtotal_list">
                                <div className="box-separate-item-block-subtotal_list-item">
                                  <p className="box-separate-item-block-subtotal_list-item-label">
                                    {t("Common.大人")}
                                    {/* 成人 */}
                                  </p>
                                  <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                    {basicPriceDetail.adult.price.toLocaleString()}{t("Common.円")}<span className="ml-5">×</span>{basicPriceDetail.adult.num}
                                  </p>
                                  <p className="box-separate-item-block-subtotal_list-total-total">
                                    <span className="fz-16 fz-18-md fz-20-md c-red">{(basicPriceDetail.adult.price * basicPriceDetail.adult.num).toLocaleString()}</span>
                                    <span className="fz-14 c-red">{t("Common.円")}</span>
                                  </p>
                                </div>
                                {basicPriceDetail.child?.map((child, bcIndex) => (
                                  <Fragment key={bcIndex}>
                                    <div className="box-separate-item-block-subtotal_list-item">
                                      <p className="box-separate-item-block-subtotal_list-item-label">
                                        {t("Common.子供")} {child.age}{t("Common.歳")} <br className="d-n-md" />
                                        {Number(child.age) <= 5 && !child.meal && !child.futon ?
                                          <>
                                            ({t("SearchCounter.食事・布団なし")})
                                          </>
                                        :
                                          <>
                                            ({t("SearchCounter.食事・布団あり")})
                                            {/* (含餐食・床位) */}
                                          </>
                                        }
                                      </p>
                                      <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                        {child.price.toLocaleString()}{t("Common.円")}<span className="ml-5">×</span>{child.num}
                                      </p>
                                      <p className="box-separate-item-block-subtotal_list-total-total">
                                        <span className="fz-16 fz-18-md fz-20-md c-red">{(child.price * child.num).toLocaleString()}</span>
                                        <span className="fz-14 c-red">{t("Common.円")}</span>
                                      </p>
                                    </div>
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </Fragment>
                      ))}
                    </div>

                    {priceData?.carRental && priceData?.carRental.length ?
                      <>
                        <div className="box-separate-item">
                          {priceData?.carRental.map((carRental,cIndex) => (
                            <Fragment key={cIndex}>
                              <div className="box-separate-item-block large">
                                {/* <h3 className="c-red fz-16 fw-b mb-16">租車</h3> */}
                                <h3 className="c-red fz-16 fw-b mb-16">{t("CarRental.レンタカー")}</h3>
                                <p className="fz-14 fz-16-md fw-b">{t("CarRental.レンタカー")}{toCircled(cIndex + 1)}</p>
                                <p className="fz-14 fw-b mb-8 mb-16-md">
                                  {carRental.use_date}
                                </p>

                                <div className="box-separate-item-block-subtotal_list">
                                  {carRental.detail.map((carRentalDetail, cdIndex) => (
                                    <Fragment key={cdIndex}>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {carRentalDetail.class_name}（{carRentalDetail.class_model}）
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRentalDetail.price.toLocaleString()}{t("Common.円")}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRentalDetail.price * carRentalDetail.num).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t("Common.円")}</span>
                                        </p>
                                      </div>
                                    </Fragment>
                                  ))}


                                  {carRental.options.anshin_price > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {t('CarRental.安心パック/ベーシック')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {(carRental.options.anshin_price).toLocaleString()}{t('Common.円')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span
                                            className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.anshin_price).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t('Common.円')}</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.anshinpremium_price > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {t('CarRental.安心パック/プレミアム')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {(carRental.options.anshinpremium_price).toLocaleString()}{t('Common.円')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span
                                            className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.anshinpremium_price).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t('Common.円')}</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.baby_seat > 0 && (
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {t('CarRental.乳幼児ベビーシート')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.baby_seat_price.toLocaleString()}{t('Common.円')}<span className="ml-5">×</span>{carRental.options.baby_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.baby_seat_price * carRental.options.baby_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t('Common.円')}</span>
                                        </p>
                                      </div>
                                    </>)}

                                  {carRental.options.child_seat > 0 ?
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {t('CarRental.幼児用チャイルドシート')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.child_seat_price.toLocaleString()}{t('Common.円')}<span className="ml-5">×</span>{carRental.options.child_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.child_seat_price * carRental.options.child_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t('Common.円')}</span>
                                        </p>
                                      </div>
                                    </>:<></>
                                  }

                                  {carRental.options.junior_seat > 0 ?
                                    <>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {t('CarRental.学童用ジュニアシート')}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {carRental.options.junior_seat_price.toLocaleString()}{t('Common.円')}<span className="ml-5">×</span>{carRental.options.junior_seat}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(carRental.options.junior_seat_price * carRental.options.junior_seat).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t('Common.円')}</span>
                                        </p>
                                      </div>
                                    </>:<></>
                                  }

                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </>:<></>
                    }

                    {priceData?.options && priceData?.options.length ?
                      <>
                        <div className="box-separate-item">
                        {priceData.options.map((option,oIndex) => (
                          <div className="box-separate-item-block large" key={oIndex}>
                            {oIndex === 0 ?
                                /* 最初の要素のみ見出しを出力 */
                                <>
                                  <h3 className="c-red fz-16 fw-b mb-16">
                                  {t("SectionDetail.追加オプション")}
                                  {/* 追加項目 */}
                                  </h3>
                                </>:<></>
                              }
                            
                              <Fragment key={oIndex}>
                                <p className="fz-14 fw-b mb-5">{t("SectionDetail.追加オプション")}{toCircled(oIndex + 1)}</p>
                                {/* <p className="fz-14 fw-b mb-5">追加項目{toCircled(oIndex + 1)}</p> */}
                                <p className="fz-14 fw-b mb-5 mt-0">
                                  {t("SectionTravelPrice.ご利用日時")}：{option.use_date}
                                </p>
                                <p className="fz-13 fz-14-md fw-b mb-20">
                                  {option.plan_name}
                                </p>
                                <div className="box-separate-item-block-subtotal_list">
                                  {option.detail.map((optionDetail, odIndex) => (
                                    <Fragment key={odIndex}>
                                      <div className="box-separate-item-block-subtotal_list-item">
                                        <p className="box-separate-item-block-subtotal_list-item-label">
                                          {optionDetail.name}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-item-subtotal">
                                          {(optionDetail.price - 0).toLocaleString()}{t("Common.円")}<span className="ml-5">×</span>{optionDetail.num}
                                        </p>
                                        <p className="box-separate-item-block-subtotal_list-total-total">
                                          <span className="fz-16 fz-18-md fz-20-md c-red">{(optionDetail.price * optionDetail.num).toLocaleString()}</span>
                                          <span className="fz-14 c-red">{t("Common.円")}</span>
                                        </p>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>
                              </Fragment>
                            </div>
                          ))}
                        </div>
                      </>:<></>
                    } 

                    <SectionCouponsDiscountPrice
                      isCoupon={priceData?.couponsPrices.length > 0}
                      couponPrice={priceData?.couponDiscountPrice ?? 0}
                      couponName={priceData?.couponsPrices.length > 0 ? priceData?.couponsPrices[0].name : "" }
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

          </section>
        </>
      )}
    </>
  );
};

export default SectionTravelPrice;
