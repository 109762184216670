import {PlanSelectFormData} from "../types/planSelectForm";

export const getComprehensiveItems = (planSelectFormData: PlanSelectFormData) => {
  if (planSelectFormData?.sellerProduct?.comprehensiveItems) {
    return planSelectFormData?.sellerProduct?.comprehensiveItems;
  }
  return planSelectFormData.planData?.sellerIndo.comprehensiveItems;
}

export const getHotelSettings = (planSelectFormData: PlanSelectFormData) => {
  if (planSelectFormData?.sellerProduct?.hotelSettings) {
    return planSelectFormData?.sellerProduct?.hotelSettings;
  }
  return planSelectFormData.planData?.sellerIndo.hotelSettings;
}