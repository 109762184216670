import React, { useEffect, useState } from "react";

import { Link as Scroll } from 'react-scroll';

import { LANGUAGES } from "../constants/languages";
import { useTranslation } from "react-i18next";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";
import { ROUTES } from "../constants/routes";

const Conditions: React.FC = () => {

  const [ languageCode, setLanguageCode ] = useState<string>("tw");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);
  
  return (
    <MainTemplate title={t("Conditions.旅行条件書（国内募集型企画旅行）")} logo={undefined} supportDial={undefined} sellerId={undefined} planSelectFormData={undefined}>
        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("Conditions.旅行条件書（国内募集型企画旅行）")}</h1>
          </div>
        </div>

        <div className="l-page-content">
          <div className="pt-24 pt-80-md">
            <div className="wrap-content-900">

              <section className="parts-loaclnavi">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.目次")}</h2>
                <div className="card large-side-medium">
                  <ul className="parts-loaclnavi-list list-number-circle-blue-link">
                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_01" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.本旅行条件書の意義")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_02" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.募集型企画旅行契約")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_03" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行のお申込みと契約の成立時期")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_04" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.お申し込み条件")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_05" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.契約書面と確定書面のお渡し")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_06" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金のお支払い")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_07" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金について")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_08" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金に含まれるもの")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_09" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金に含まれないもの")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_10" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.追加代金")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_11" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行契約内容の変更")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_12" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金の額の変更")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_13" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.お客様の交替")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_14" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.取消料")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_15" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行開始前の解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_16" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行開始後の解除")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_17" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行代金の払い戻し")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_18" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅程管理業務")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_19" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.当社の責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_20" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.特別補償")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_21" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.お客様の責任")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_22" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.オプショナルツアー又は情報提供")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_23" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅程保証")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_24" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.通信契約による旅行条件")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_25" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.個人情報の取扱い")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_26" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.旅行条件・旅行代金の基準")}</Scroll>
                    </li>

                    <li className="parts-loaclnavi-list-item">
                      <Scroll to="sec_27" className="parts-loaclnavi-list-item-link" smooth={true}>{t("Conditions.その他")}</Scroll>
                    </li>
                  </ul>
                </div>
              </section>

              {/* section: 1.本旅行条件書の意義 */}
              <section id="sec_01" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.1.本旅行条件書の意義")}</h2>
                <div className="card large-side-medium">
                  <p>{t("Conditions.本旅行条件書は、旅行業法第１２条の４に定める取引条件説明書面であり、国内旅行の募集型企画旅行契約が成立した場合には、同法第１２条の５に定める契約書面の一部となります。")}</p>
                </div>
              </section>

              {/* section: 2.募集型企画旅行契約 */}
              <section id="sec_02" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.2.募集型企画旅行契約")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.本旅行条件書の対象となる旅行は、沖縄ツーリスト株式会社（以下「当社」といいます。）が企画・実施するものであり、この旅行に参加されるお客様は当社と国内募集型企画旅行契約を締結することになります。")}</li>
                    <li>{t("Conditions.本旅行条件書において「旅行契約」とは、お客様が当社の定める旅行日程に従って、運送・宿泊機関等の提供する運送、宿泊その他旅行に関するサービス（以下「旅行サービス」といいます）の提供を受けることができるように、弊社が手配し、旅程を管理することを引き受ける契約をいいます。")}</li>
                    <li>{t("Conditions.旅行契約の内容・条件は、商品ページ、予約確認書又は予約内容提示ページ、予約内容確認ページ、本旅行条件書、確定書面（5項(2)に定義する）および当社の旅行業約款募集型企画旅行契約の部（以下「当社約款」といいます。）によるものとします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 3.旅行のお申込みと契約の成立時期 */}
              <section id="sec_03" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.3.旅行のお申込みと契約の成立時期")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社と旅行契約を締結しようとするお客様は、当社が運営するインターネット上の旅行予約サイト（以下「当サイト」といいます）又は「受託販売欄」に記載された当社の受託営業所（以下「当社ら」といいます。）において、当社らが定める方法により予約の申し込み（以下「所定の申込」といいます）をするものとし、当サイトに記載した申込金を添えてお申込みいただきます。申込金は旅行代金をお支払いいただくときに、その一部として繰り入れます。また、旅行契約は、当社らが契約の締結を承諾し申込金を受領したときに成立するものといたします。")}</li>
                    <li>{t("Conditions.当社らは、団体・グループを構成する旅行者の代表としての契約責任者から旅行申し込みがあった場合、契約の締結及び解除等に関する一切の代理権を有しているものとみなします。")}</li>
                    <li>{t("Conditions.契約責任者は、申込の時点で構成者の名簿を当社らに通知しなければなりません。")}</li>
                    <li>{t("Conditions.当社らは、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。")}</li>
                    <li>{t("Conditions.当社らは、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 4.お申し込み条件 */}
              <section id="sec_04" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.4.お申し込み条件")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.18歳未満の方は親権者の同意書が必要です。15才未満の方は保護者の同行を条件とさせていただきます。")}</li>
                    <li>{t("Conditions.ご参加にあたって特別の条件を定めた旅行については、年齢、資格、技能その他の条件が当社の指定する条件に合致しない場合は、ご参加をお断りする場合があります。")}</li>
                    <li>{t("Conditions.お客様が暴力団員、暴力団関係者、その他反社会的勢力であると判明した場合は、ご参加をお断りする場合があります。")}</li>
                    <li>{t("Conditions.お客様が、当社らに対して暴力的又は不当な要求行為や取引に関して脅迫的な言動や暴力を用いる行為などを行った場合は、ご参加をお断りする場合があります。")}</li>
                    <li>{t("Conditions.お客様が、風説を流布したり、偽計や威力を用いて当社らの信用を毀損したり業務を妨害するなどの行為を行った場合は、ご参加をお断りする場合があります。")}</li>
                    <li>{t("Conditions.健康を害している方、車椅子などの器具をご利用になっている方や心身に障がいのある方、食物アレルギー・動物アレルギーのある方、妊娠中の方、妊娠の可能性がある方、身体障害者補助犬（盲導犬、聴導犬、介助犬）をお連れの方その他特別の配慮を必要とする方は、お申込みの際に、参加にあたり特別な配慮が必要となる旨をお申し出下さい（旅行契約成立後にこれらの状態になった場合も直ちにお申し出ください。）。あらためて当社からご案内申し上げますので、旅行中に必要となる措置の内容を具体的にお申し出ください。")}</li>
                    <li>{t("Conditions.前号のお申し出を受けた場合、当社は、可能かつ合理的な範囲内でこれに応じます。これに際して、お客様の状況及び必要とされる措置についてお伺いし、又は書面でそれらを申し出ていただくことがあります。")}</li>
                    <li>{t("Conditions.当社は、旅行の安全かつ円滑な実施のために介助者又は同伴者の同行、医師の診断書の提出、コースの一部について内容を変更すること等を条件とすることがあります。また、お客様からお申し出いただいた措置を手配することができない場合は旅行契約のお申込みをお断りし、又は旅行契約の解除をさせていただくことがあります。なお、お客様からのお申し出に基づき、当社がお客様のために講じた特別な措置に要する費用は原則としてお客様の負担とします。")}</li>
                    <li>{t("Conditions.当社は、本項(1)(2)(6)(7)(8)の場合で、当社よりお客様にご連絡が必要な場合は、(1)(2)はお申し込みの日から、(6)(7)(8)はお申し出の日から、原則として1週間以内にご連絡いたします。")}</li>
                    <li>{t("Conditions.お客様がご旅行中に疾病、傷害その他の事由により、医師の診断又は加療を必要とする状態になったと当社が判断する場合は、旅行の円滑な実施をはかるため必要な措置をとらせていただきます。これにかかる一切の費用はお客様のご負担になります。")}</li>
                    <li>{t("Conditions.お客様のご都合による別行動は原則としてできません。ただし、コースにより別途条件でお受けする場合があります。")}</li>
                    <li>{t("Conditions.お客様が他のお客様に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあると当社が判断する場含は、ご参加をお断りする場合があります。")}</li>
                    <li>{t("Conditions.その他当社の業務上の都合があるときには、お申し込みをお断りする場合があります。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 5.契約書面と確定書面のお渡し */}
              <section id="sec_05" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.5.契約書面と確定書面のお渡し")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社らは、旅行契約成立後速やかにお客様に、旅行日程、旅行サービスの内容その他の旅行条件及び当社らの責任に関する事項を記載した契約書面をお渡しします。契約書面は商品ページ、予約確認書、本旅行条件書等により構成されます。ただし、商品によっては、書面の交付に代えて予約内容提示ページおよび予約内容確認ページに記載された事項および本旅行条件書を当サイトに掲示し、お客様はこれをお申し込み時に必ず閲覧するものとします。お客様は、当社がこの方法により契約内容を通知することに同意するものとします。")}</li>
                    <li>{t("Conditions.本項(1)の契約書面を補完する書面として、当社らはお客様に、集合時刻・場所、宿泊機関等に関する確定情報を記載した最終旅行日程表を遅くとも旅行開始日の前日までに電子メールで通知します。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 6.旅行代金のお支払い */}
              <section id="sec_06" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.6.旅行代金のお支払い")}</h2>
                <div className="card large-side-medium">
                  <p>{t("Conditions.旅行代金は商品ページ、予約確認書等の契約書面に明示した期日までにお支払いいただきます。また、当社とお客様が第24項に規定する通信契約を締結しない場合であっても、お客様が提携カード会社のカード会員である場合で、お客様の承諾があるときは、提携会社のカードよりお客様の署名無くして旅行代金（申込金、追加代金として表示したものを含みます。）や第14項に規定する取消料・違約料、第10項に規定されている追加料金をお支払いいただくことがあります。また、この場合のカード利用日は、お客様からお申し出がない限り、お客様の承諾日といたします。")}</p>
                </div>
              </section>

              {/* section: 7.旅行代金について */}
              <section id="sec_07" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.7.旅行代金について")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.参加されるお客様のうち、特に注釈のない場合、満12歳以上の方はおとな代金、満6歳以上12歳未満の方は、こども代金となります。")}</li>
                    <li>{t("Conditions.旅行代金は、各コースごとに表示してございます。出発日とご利用人数でご確認ください。")}</li>
                    <li>{t("Conditions.「旅行代金」は、第14項(1)の「取消料」、第14項(3)の「違約料」、及び第23項の「変更補償金」の額の算出の際の基準となります。「旅行代金」の計算方法は、「旅行代金（又は基本代金）として表示した金額」プラス「追加代金として表示した金額」マイナス「割引代金として表示した金額」となります。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 8.旅行代金に含まれるもの */}
              <section id="sec_08" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.8.旅行代金に含まれるもの")}</h2>
                <div className="card large-side-medium">
                  <p className="mb-24">{t("Conditions.旅行代金に含まれるものは、次の各号のとおりとします。また、次の各号の費用は、お客様の都合により一部利用されなくても、原則としてお客様に払い戻しできないものとします。")}</p>
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.旅行日程に明示した宿泊の料金および消費税・サービス料金")}</li>
                    <li>{t("Conditions.旅行日程に明示した食事の料金および消費税・サービス料金")}</li>
                    <li>{t("Conditions.旅行日程に明示した観光に伴う入場料、体験料及びガイド料金")}</li>
                    <li>{t("Conditions.その他、予約内容提示ページに定めのあるもの")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 9.旅行代金に含まれないもの */}
              <section id="sec_09" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.9.旅行代金に含まれないもの")}</h2>
                <div className="card large-side-medium">
                  <p className="mb-24">{t("Conditions.前項各号に該当しないものは、旅行代金に含まれません。その一部を以下に例示します。")}</p>
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.クリーニング代、電報電話料、ホテルのボーイ・メイド等に対する心付けその他の追加飲食等個人的性質の諸費用及びそれに伴う税・サービス料。")}</li>
                    <li>{t("Conditions.ご希望者のみ参加されるオプショナル・ツアー（別途料金の小旅行）の料金。")}</li>
                    <li>{t("Conditions.自宅から発着地（空港・港・駅等その他集合地点・解散地点）までの交通費及び宿泊費。途中合流・途中離団の場合はその地点までの交通費及び宿泊費。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 10.追加代金 */}
              <section id="sec_10" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.10.追加代金")}</h2>
                <div className="card large-side-medium">
                  <p className="mb-24">{t("Conditions.第7項(3)でいう「追加代金」は、以下の代金をいいます（あらかじめ「旅行代金」の中に含めて表示した場合を除きます。）。")}</p>
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.商品ページ等で当社が「グレードアッププラン」等と称するホテル又は部屋タイプのグレードアップのための追加代金。")}</li>
                    <li>{t("Conditions.「食事なしプラン」等を基本とする「食事付プラン」等の差額代金。")}</li>
                    <li>{t("Conditions.その他商品ページ等で「××××追加代金」と称するもの。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 11.旅行契約内容の変更 */}
              <section id="sec_11" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.11.旅行契約内容の変更")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は、旅行契約締結後であっても、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供その他当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、お客様にあらかじめ速やかに当該事由が当社の関与し得ないものである理由および当該事由との因果関係を説明して、旅行日程、旅行サービスの内容を変更することがあります。ただし、緊急の場合においてやむを得ないときは変更後に説明します。")}</li>
                    <li>{t("Conditions.お客様のご都合により旅行契約内容の変更をご希望の場合、いったん契約解除の上、新規ご予約をお願いします。契約解除には所定の取消料がかかる場合がございますのでご注意ください。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 12.旅行代金の額の変更 */}
              <section id="sec_12" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.12.旅行代金の額の変更")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は、第11項（旅行契約内容の変更）(1)により旅行の実施に要する費用（当該契約内容の変更のためにその提供を受けなかった旅行サービスに対して取消料、違約料その他既に支払い、またはこれから支払わなければならない費用を含む）の減少または増加が生じる場合（費用の増加が、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の設備の不足が発生したことによる場合は除く）には、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することができるものとします。")}</li>
                    <li>{t("Conditions.運送・宿泊機関等の利用人数により旅行代金が異なる募集型企画旅行で、旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人数が変更となったときは、旅行代金の額を変更することができるものとします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 13.お客様の交替 */}
              <section id="sec_13" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.13.お客様の交替")}</h2>
                <div className="card large-side-medium">
                  <p>{t("Conditions.お客様の交替は受け付けておりません。また、お名前の訂正についても予約したツアーの取消の後、再度新規でのご予約となります。")}</p>
                </div>
              </section>

              {/* section: 14.取消料 */}
              <section id="sec_14" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.14.取消料")}</h2>
                <div className="card large-side-medium">
                  <p className="mb-16 mb-24-md">{t("Conditions.旅行契約の成立後、お客様のご都合で契約を解除する場合、旅行代金に対してお客様お一人様につき次に定める取消料をいただきます。")}</p>
                  <h3 className="heading-3 mb-0">{t("Conditions.本体ツアーの取消料")}</h3>
                  <p className="mb-16 fz-14">{t("Conditions.（お客様お1人様につき）")}</p>
                  <table className="mb-24 mb-32-md table-xsmall">
                    <tbody>
                    <tr>
                      <td className="table-bgc-light_navy w-50per fw-b ta-c">
                        {t("Conditions.契約の解除日")}
                      </td>
                      <td className="table-bgc-light_navy fw-b ta-c">{t("Conditions.取消料")}</td>
                    </tr>
                    <tr>
                      <th className="w-40per fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日の前日から起算して8日前まで")}</th>
                      <td>{t("Conditions.無料")}</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日の前日から起算して7日前～2日前まで")}</th>
                      <td>{t("Conditions.旅行代金の30％")}</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日の前日")}</th>
                      <td>
                        {t("Conditions.旅行代金の40％")}
                      </td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日の当日")}</th>
                      <td>
                        {t("Conditions.旅行代金の50％")}
                      </td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.旅行開始後の解除、無連絡不参加")}</th>
                      <td>
                        {t("Conditions.旅行代金の100％")}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                
                  {/* レンタカー取消料 */}
                  <h3 className="heading-3 mb-0">{t("Conditions.レンタカー取消料")}</h3>
                  <p className="mb-16 fz-14">
                    {t("Conditions.（ご予約内容をやむを得ない場合取消可能です。但し規定により下記の予約手数料を申し受けます。）")}
                  </p>

                  <table className="mb-16 table-xsmall">
                    <tbody>
                    <tr>
                      <th className="w-60per w-40per-md table-bgc-light_navy fw-b ta-c">
                        {t("Conditions.契約の解除日")}
                      </th>
                      <th className="table-bgc-light_navy fw-b ta-c">{t("Conditions.取消料")}</th>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.乗車日の7日前")}</th>
                      <td>{t("Conditions.無料")}</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.乗車日の6日前～3日前")}</th>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.乗車日の2日前〜前日")}</th>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.当日以降")}</th>
                      <td>50%</td>
                    </tr>
                    </tbody>
                  </table>
                  <ul className="list-note light-gray mb-24 mb-32-md">
                    <li>{t("Conditions.ご予約の乗車時間を1時間以上過ぎてもご連絡のない場合は、予約の取り消しとさせていただきます。")}</li>
                    <li>
                      {t("Conditions.契約時間内に返還できない事態が発生したときは、営業所に連絡し、許可を得てください。")}
                      <br />
                      {t("Conditions.無断で契約期間を延長した場合は、貸渡約款違反となり、延滞違約金をお支払いいただきますので、特にご注意ください。")}
                    </li>
                  </ul>
                  
                  {/* オプション取消料 */}
                  <h3 className="heading-3 mb-16">{t("Conditions.オプション取消料")}</h3>
                  <table className="mb-16 table-xsmall">
                    <tbody>
                    <tr>
                      <th className="table-bgc-light_navy fw-b ta-c w-55per w-40per-md">
                        {t("Conditions.契約の解除日")}
                      </th>
                      <th className="table-bgc-light_navy fw-b ta-c">{t("Conditions.取消料")}</th>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.利用開始日の4日前まで")}</th>
                      <td>{t("Conditions.無料")}</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.利用開始日の3日前～2日前まで")}</th>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.利用開始日前日")}</th>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.利用開始日当日")}</th>
                      <td>50%</td>
                    </tr>
                    <tr>
                      <th className="fz-13 fz-16-md fw-b">{t("Conditions.利用開始後又は無連絡不参加")}</th>
                      <td>100%</td>
                    </tr>
                    </tbody>
                  </table>
                  <ul className="list-note light-gray mb-24 mb-32-md">
                    <li>{t("Conditions.各コース・オプショナルプランに適用取消料が記載されている場合は、その取消料を適用いたします。")}</li>
                  </ul>
                
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.旅行契約の成立後、お客様の都合で旅行契約を解除する場合には、当社は旅行代金に対してお一人につき上記の料率で取消料をいただきます。")}</li>
                    <li>{t("Conditions.当社の責任とならないローンの取扱上の事由に基づき、お取り消しになる場合も所定の取消料をお支払いただきます。")}</li>
                    <li>{t("Conditions.旅行代金が期日までに支払われないときは、当社は当該期日の翌日においてお客様が旅行契約を解除したものとし、取消料と同額の違約料をいただきます。")}</li>
                    <li>{t("Conditions.お客様のご都合による出発日およびコースの変更、運送・宿泊機関等行程中の一部の変更については、ご旅行全体のお取り消しとみなし、所定の取消料を収受します。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 15.旅行開始前の解除 */}
              <section id="sec_15" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.15.旅行開始前の解除")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot gray">
                    <li>{t("Conditions.お客様の解除権")}
                      <ul className="list-number-box gray mt-8">
                        <li>{t("Conditions.商品ページ、予約確認書又は確定書面に記載した取消料をお支払いいただくことにより、いつでも旅行契約を解除することができます。ただし、解除のお申し出は、原則として予約確認画面からのお手続きのみお受けいたします。")}</li>
                        <li>{t("Conditions.お客様は次の項目に該当する場合は取消料なしで旅行契約を解除することができます。")}
                          <ol className="lst-lower-latin pl-20">
                            <li>{t("Conditions.旅行契約内容が変更されたとき。ただし、その変更が第23項（旅程保証）の表左欄に掲げるものその他の重要なものである場合に限ります。")}</li>
                            <li>{t("Conditions.第12項(1)に基づき、旅行代金が増額改定されたとき。")}</li>
                            <li>{t("Conditions.天災地変、戦乱、暴動、運送、宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。")}</li>
                            <li>{t("Conditions.当社らがお客様に対し、第5項（契約書面と確定書面のお渡し）の(2)に記載の確定書面を同項に規定する日までにお渡ししなかったとき。")}</li>
                            <li>{t("Conditions.当社らの責に帰すべき事由により、商品ページ、契約書面等に記載した旅行日程に従った旅行実施が不可能となったとき。")}</li>
                          </ol>
                        </li>
                        <li>{t("Conditions.当社らは本項(1)の【1】により旅行契約を解除したときは、既に収受している旅行代金から所定の取消料を差し引いて払い戻しいたします。また本項(1)の【2】により旅行契約を解除したときは、既に収受している旅行代金の全額を払い戻しいたします。")}</li>
                      </ul>
                    </li>

                    <li>{t("Conditions.当社らの解除権")}      
                      <ul className="list-number-box gray mt-8">
                        <li>{t("Conditions.次の項目に該当する場合は、当社らは旅行契約を解除することがあります。")}
                          <ol className="lst-lower-latin gray pl-20">
                            <li>{t("Conditions.お客様が当社らのあらかじめ明示した性別・年令・資格・技能その他旅行参加条件を満たしていないことが明らかになったとき。")}</li>
                            <li>{t("Conditions.お客様が第4項（お申込み条件）の(3)から(5)までのいずれかに該当することが判明したとき。")}</li>
                            <li>{t("Conditions.お客様が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められたとき。")}</li>
                            <li>{t("Conditions.お客様が他のお客様に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあると認められたとき。")}</li>
                            <li>{t("Conditions.お客様が契約内容に関し合理的な範囲を超える負担を求めたとき。")}</li>
                            <li>{t("Conditions.お客様の人数が商品ページ等に記載した最少催行人員に満たないとき。この場合は旅行開始日の前日から起算してさかのぼって13日目にあたる日より前に旅行中止のご通知をいたします。")}</li>
                            <li>{t("Conditions.スキーを目的とする旅行における降雪量の不足のように、当社らがあらかじめ明示した旅行実施条件が成就しないとき、あるいはそのおそれが極めて大きいとき。")}</li>
                            <li>{t("Conditions.天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービスの提供の中止、官公署の命令その他の当社らの関与し得ない事由が生じた場合において、商品ページ等に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。")}</li>
                          </ol>
                        </li>
                        <li>{t("Conditions.お客様が、当社らに事実と異なる申告（故意・過失を問わず）により、本項(2)の【1】aが生じ、当社が旅行契約を解除したときは、既に収受している旅行代金から違約料を差し引いて払い戻しいたします。また本項(2)の【1】のb～hにより旅行契約を解除したときは、既に収受している旅行代金の全額を払い戻しいたします。")}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
            
              {/* section: 16.旅行開始後の解除 */}
              <section id="sec_16" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.16.旅行開始後の解除")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot gray">
                    <li>{t("Conditions.お客様の解除権")}
                      <ul className="list-number-box gray mt-8">
                        <li>{t("Conditions.お客様のご都合により途中で離団された場合は、お客様の権利放棄とみなし、一切の払い戻しをいたしません。")}</li>
                        <li>{t("Conditions.お客様の責に帰さない事由により商品ページ、契約書面等に記載した旅行サービスの提供を受けられない場合には、お客様は、取消料を支払うことなく当該不可能になった旅行サービス提供に係る部分の契約を解除することができます。")}</li>
                        <li>{t("Conditions.本項(1)の【2】の場合において、当社は、旅行代金のうち旅行サービスの当該受領することができなくなった部分に係る金額を旅行者に払い戻します。ただし、当該事由が当社の責に帰すべき事由によらない場合においては、当該金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものをお客様に払い戻します。")}</li>
                      </ul>
                    </li>

                    <li>{t("Conditions.当社らの解除権")}
                      <ul className="list-number-box gray mt-8">
                        <li>{t("Conditions.当社は次に掲げる場合においてはお客様にあらかじめ理由を説明して旅行契約の一部を解除することがあります。")}
                          <ol className="lst-lower-latin pl-20">
                            <li>{t("Conditions.お客様が病気、必要な介助者の不在その他の事由により、旅行の継続に耐えられないと認められるとき。")}</li>
                            <li>{t("Conditions.お客様が第4項（お申込み条件）の(3)から(5)までのいずれかに該当することが判明したとき。")}</li>
                            <li>{t("Conditions.お客様が旅行を安全かつ円滑に実施するための添乗員等その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等により団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。")}</li>
                            <li>{t("Conditions.天災地変、戦乱、暴動、運送・宿泊期間等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、旅行の継続が不可能となったとき。")}</li>
                          </ol>
                        </li>
                        <li>
                          {t("Conditions.解除の効果及び払い戻し")}<br />
                          {t("Conditions.本項(2)の【1】に記載した事由で当社が旅行契約を解除したときは、契約を解除したためにその提供を受けられなかった旅行サービスの提供者に対して、取消料・違約料その他の名目で既に支払い、又は支払わなければならない費用があるときは、これをお客様の負担とします。この場合、当社は旅行代金のうち、お客様がいまだその提供を受けていない旅行サービスに係る部分の費用から当社が当該旅行サービス提供者に支払い又はこれから支払うべき取消料・違約料その他の名目による費用を差し引いて払い戻しいたします。")}
                        </li>
                        <li>{t("Conditions.本項(2)の【1】のａ、ｄにより当社が旅行契約を解除したときは、お客様のお求めに応じてお客様のご負担で出発地に戻るための必要な手配をいたします。")}</li>
                        <li>{t("Conditions.当社が本項(2)の【1】の規定に基づいて旅行契約を解除したときは、当社とお客様との間の契約関係は、将来に向かってのみ消滅します。すなわちお客様が既に提供を受けた旅行サービスに関する当社の債務については、有効な弁済がなされたものとします。")}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>
            
             {/* section: 17.旅行代金の払い戻し */}
             <section id="sec_17" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.17.旅行代金の払い戻し")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は、第12項(旅行代金の額の変更)の規定により旅行代金を減額した場合、又は第15項（旅行開始前の解除）、第16項（旅行開始後の解除）の規定によりお客様もしくは当社が旅行契約を解除した場合において、お客様に対し払い戻すべき金額が生じたときは、旅行開始前の解除による払い戻しにあっては解除の翌日から起算して7日以内に、旅行代金の減額又は旅行開始後の解除による払い戻しにあっては商品ページ、契約書面等に記載した旅行終了日の翌日から起算して30日以内に、お客様に対し当該金額を払い戻しいたします。")}</li>
                    <li>{t("Conditions.本項(1)の規定は、第19項（当社の責任）又は第21項（お客様の責任）で規定するところにより、お客様又は当社が損害賠償請求権を行使することを妨げるものではありません。")}</li>
                    <li>{t("Conditions.クーポン券類の引渡し後の払戻しについては、お渡ししたクーポン券類が必要となります。クーポン券類の提出がない場合には、旅行代金の払戻しができないことがあります。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 18.旅程管理業務 */}
              <section id="sec_18" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.18.旅程管理業務")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.本旅行条件書の対象となる旅行では、添乗員は同行しないものとし、現地における当社の連絡先を、確定書面で通知します。")}</li>
                    <li>{t("Conditions.当社は、お客様の安全かつ円滑な旅行の実施を確保することに努力し、お客様に対し次に掲げる業務を行います。ただし、当社がお客様とこれと異なる特約を結んだ場合には、この限りではありません。")}
                      <ul className="list-number-box last-mb-none mt-8">
                        <li>{t("Conditions.お客様が旅行中旅行サービスを受けることができないおそれがあると認められるときは、募集型企画旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講ずること")}</li>
                        <li>{t("Conditions.前号の措置を講じたにもかかわらず、契約内容を変更せざるを得ないときは、代替サービスの手配を行うこと。この際、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努めること、また、旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努めることなど、契約内容の変更を最小限にとどめるよう努力すること")}</li>
                        <li>{t("Conditions.前号の規定にかかわらず、当社が営業時間外で連絡が不可能な場合において、悪天候等によってサービス内容の変更を必要とする事由が生じた場合における代替サービスの手配及び必要な手続きは、お客様ご自身で行っていただきます。")}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </section>

              {/* section: 19.当社の責任 */}
              <section id="sec_19" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.19.当社の責任")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は募集型企画旅行契約の履行にあたって、当社又は当社が手配を代行させた者の故意又は過失により、お客様に損害を与えたときは、お客様が被られた損害を賠償いたします。ただし損害発生の翌日から起算して2年以内に当社に対して通知があった場合に限ります。")}</li>
                    <li>{t("Conditions.お客様が次に例示するような事由により、損害を被られた場合におきましては、当社は原則として本項(1)の責任を負いません。")}
                      <ul className="list-number-box">
                        <li>{t("Conditions.天災地変、戦乱、暴動又はこれらのために生じる旅行日程の変更もしくは旅行の中止")}</li>
                        <li>{t("Conditions.宿泊機関等の事故、火災により発生する損害")}</li>
                        <li>{t("Conditions.宿泊機関等のサービス提供の中止又はこれらのために生じる旅行日程の変更もしくは旅行の中止")}</li>
                        <li>{t("Conditions.官公署の命令、伝染病による隔離又はこれらによって生じる旅行日程の変更、旅行の中止")}</li>
                        <li>{t("Conditions.自由行動中の事故")}</li>
                        <li>{t("Conditions.食中毒")}</li>
                        <li>{t("Conditions.盗難")}</li>
                        <li>{t("Conditions.運送機関の遅延・不通・スケジュール変更・経路変更など")}</li>
                        <li>{t("Conditions.上記各号のいずれかによって生じる旅行日程の変更、旅行の中止または目的地滞在時間の短縮")}</li>
                        <li>{t("Conditions.その他、当社または当社の手配代行者の関与し得ない事由")}</li>
                      </ul>
                    </li>
                    <li>{t("Conditions.当社は、手荷物について生じた損害については、本項(1)の規定にかかわらず、損害が発生した翌日から起算して14日以内に当社に対して通知があった場合に限り、賠償します。ただし、損害額の如何にかかわらず、当社が行う賠償額はお客様１名あたり15万円（当社の故意または重大な過失がある場合を除きます）を上限とします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 20.特別補償 */}
              <section id="sec_20" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.20.特別補償")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は、前項（当社の責任）(1)に基づく当社の責任が生ずるか否かを問わず、当社約款別紙の特別補償規程（以下「特別補償規程」といいます）で定めるところにより、お客様が本旅行条件書の対象となる旅行に参加中に急激かつ偶然な外来の事故により、その身体、生命に被られた一定の損害、ならびに手荷物に対する損害について、次の各号のとおり支払います。なお、当社が前項(1)の責任を負うことになった場合は、下記の各号の補償金および見舞金は、当社が負うべき損害賠償金の一部または全部に充当されるものとします。")}
                      <ul className="list-number-box">
                        <li>{t("Conditions.死亡補償金として1,500万円")}</li>
                        <li>{t("Conditions.入院見舞金として入院日数により2万円～20万円")}</li>
                        <li>{t("Conditions.通院見舞金として通院日数により1万円～5万円")}</li>
                        <li>{t("Conditions.手荷物にかかる損害補償金として１企画旅行お客様１名あたりにつき最高15万円（ただし、手荷物１個または一対あたり10万円を上限とし、現金、クレジットカード、クーポン券、航空券、その他特別補償規程第18条第2項に定める品目については補償いたしません。）")}</li>
                      </ul>
                    </li>
                    <li>{t("Conditions.本項(1)にかかわらず、当社の手配による募集型企画旅行に含まれる旅行サービスの提供が一切行われない日については、その旨商品ページ、契約書面等に明示した場合に限り、当該募集型企画旅行参加中とはいたしません。")}</li>
                    <li>{t("Conditions.お客様が旅行参加中に被られた損害が、お客様の故意、酒酔い運転、故意の法令違反行為・法令に違反するサービス提供の受領、疾病等のほか、山岳登はん（ピッケル、アイゼン、ザイル、ハンマー等の登山用具を使用するもの）、リュージュ、ボブスレー、スカイダイビング、ハングライダー搭乗、超軽量動力機（モーターハングライダー、マイクロライト機、ウルトラライト機等）搭乗、ジャイロプレーン搭乗その他これらに類する危険な運動中の事故によるものである場合、その他特別補償規程に定める除外事由に該当するときは、当社は本項(1)の補償金および見舞金を支払いません。")}</li>
                    <li>{t("Conditions.当社は、現金、有価証券、クレジットカード、クーポン券、航空券、パスポート、免許証、査証、預金証書・貯金証書（通帳及び現金支払機用カードを含みます。）、各種データその他これらに準ずるもの、コンタクトレンズ等の当社約款に定められている補償対象除外品については、損害補償金を支払いません。")}</li>
                    <li>{t("Conditions.当社が本項(1)に基づく補償金支払い義務と前項により損害賠償義務を重ねて負う場合であっても、一方の義務が履行されたときはその金額の限度において補償金支払義務・損害賠償義務とも履行されたものといたします。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 21.お客様の責任 */}
              <section id="sec_21" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.21.お客様の責任")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社は、お客様の故意、過失、法令違反、公序良俗に反する行動により当社が損害を受けた場合には、お客様に対して被った全ての損害の賠償を請求することができるものとします。")}</li>
                    <li>{t("Conditions.お客様は、旅行契約を締結するに際し、当社から提供された情報を活用し、お客様の権利義務その他の旅行契約の内容について理解するように努めなければなりません。")}</li>
                    <li>{t("Conditions.お客様は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一、契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者または当該旅行サービス提供者に申し出なければならないものとします。")}</li>
                    <li>{t("Conditions.当社は、旅行中のお客様が、疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用はお客様の負担とし、お客様は当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 22.オプショナルツアー又は情報提供 */}
              <section id="sec_22" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.22.オプショナルツアー又は情報提供")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社の募集型企画旅行参加中のお客様を対象として、別途の参加料金を収受して当社が企画・実施する募集型企画旅行（以下「当社オプショナルツアー」といいます。）の第20項（特別補償）の適用については、当社は、主たる募集型企画旅行契約の内容の一部として取り扱います。当社オプショナルツアーは、商品ページ、契約書面等で「企画者：当社」と明示します。")}</li>
                    <li>{t("Conditions.オプショナルツアーの企画・実施が当社以外である旨を商品ページ、契約書面等で明示した場合には、当社は、当該オプショナルツアー参加中にお客様に発生した第20項（特別補償）で規定する損害に対しては、同項の規定に基づき補償金又は見舞金を支払います（但し、当該オプショナルツアーのご利用日が主たる募集型企画旅行の「無手配日」であり、かつ、その旨を商品ページ、契約書面等又は確定書面にて記載した場合を除きます。）。ただし当該オプショナルプランに係る運営管理会社の責任およびお客様の責任はすべて、当該オプショナルプランが催行される当該運営管理会社の定めによります。")}</li>
                    <li>{t("Conditions.当社は、商品ページ、契約書面等で「単なる情報提供」として可能なスポーツ等を記載した場合は、その旨を明示します。この場合、当該可能なスポーツ等に参加中にお客様に発生した損害に対しては、当社は第20項の特別補償規程は適用します（但し、当該スポーツ等のご利用日が主たる募集型企画旅行の「無手配日」であり、かつ、その旨商品ページ、契約書面等又は確定書面にて記載した場合を除きます。）が、それ以外の責任を負いません。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 23.旅程保証 */}
              <section id="sec_23" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.23.旅程保証")}</h2>
                <div className="card large-side-medium">
                  <ol className="list-number-dot last-mb-none gray large mb-16">
                    <li>{t("Conditions.当社は、次表左欄に掲げる契約内容の重要な変更が生じた場合（ただし次の【1】・【2】・【3】で規定する変更を除きます。）は、第7項で定める「旅行代金」に次表右欄に記載する率を乗じて得た額の変更補償金を旅行終了日の翌日から起算して30日以内にお客様に支払います。ただし、当該変更について当社に第19項(1)の規定に基づく責任が発生することが明らかな場合には、変更補償金としてではなく、損害賠償金の全部又は一部として支払います。")}
                      <ul className="list-number-box mt-8">
                        <li>
                          {t("Conditions.次に掲げる事由による変更の場合は、当社は変更補償金を支払いません（ただし、サービスの提供が行われているにもかかわらず運送・宿泊機関等の座席・部屋その他の諸設備の不足が発生したことによる変更の場合は変更補償金を支払います。）。")}<br />
                          {t("Conditions.ア.旅行日程に支障をもたらす悪天候、天災地変、イ.戦乱、ウ.暴動、エ.官公署の命令、オ.欠航、不通、休業等運送・宿泊機関等の旅行サービス提供の中止、カ.遅延、運送スケジュールの変更等当初の運行計画によらない運送サービスの提供、キ.旅行参加者の生命又は身体の安全確保のため必要な措置")}
                        </li>
                        <li>{t("Conditions.第15項及び第16項の規定に基づき旅行契約が解除されたときの当該解除された部分に係る変更の場合、当社は変更補償金を支払いません。")}</li>
                        <li>{t("Conditions.商品ページ、予約確認書に記載した旅行サービスの提供を受ける順序が変更になった場合でも、旅行中に当該旅行サービスの提供を受けることができた場合においては、当社は変更補償金を支払いません。")}</li>
                      </ul>
                    </li>
                    <li>{t("Conditions.本項(1)の規定にかかわらず、当社がひとつの旅行契約に基づき支払う変更補償金の額は、第7項で定める「旅行代金」に15％を乗じて得た額を上限とします。またひとつの旅行契約に基づき支払う変更補償金の額がお一人様につき1,000円未満であるときは、当社は変更補償金を支払いません。")}</li>
                    <li>{t("Conditions.当社はお客様の同意を得て金銭による変更補償金・損害賠償金の支払いに替え、これと相応の物品サービスの提供をもって補償を行なうことがあります。")}
                      <div className="mt-24">
                        <table className="table-offset-left">
                          <tbody>
                            <tr>
                              <th className="w-50per w-55per-md table-bgc-light_navy fz-13 fz-16-md fw-b ta-c" rowSpan={2}>{t("Conditions.当社が変更補償金を")}<br className="d-n-md" />{t("Conditions.支払う変更")}</th>
                              <th className="table-bgc-light_navy fz-13 fz-16-md fw-b ta-c" colSpan={2}>
                                {t("Conditions.変更補償金の額＝1件につき")}<br />
                                {t("Conditions.下記の率×旅行代金")}
                              </th>
                            </tr>
                            <tr>
                              <th className="table-bgc-light_navy fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日の前日までにお客様に通知した場合")}</th>
                              <th className="table-bgc-light_navy fz-13 fz-16-md fw-b">{t("Conditions.旅行開始日以降にお客様に通知した場合")}</th>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.①ホームページ、パンフレット又は確定書面に記載した旅行開始日又は旅行終了日の変更")}</th>
                              <td className="ta-c">1.5%</td>
                              <td className="ta-c">3.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.②ホームページ、パンフレット又は確定書面に記載した入場する観光地又は観光施設（レストランを含みます。）その他の旅行の目的地の変更")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.③ホームページ、パンフレット又は確定書面に記載した運送機関の等級又は設備のより低い料金のものへの変更（変更後の等級及び設備の料金の合計額がホームページ、パンフレット又は確定書面に記載した等級及び設備のそれを下回った場合に限ります。）")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.④ホームページ、パンフレット又は確定書面に記載した運送機関の種類又は会社名の変更")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.⑤ホームページ、パンフレット又は確定書面に記載した本邦内の旅行開始地たる空港又は旅行終了地たる空港の異なる便への変更")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.⑥ホームページ、パンフレット又は確定書面に記載した本邦内と本邦外との間における直行便の乗継便又は経由便への変更")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.⑦ホームページ、パンフレット又は確定書面に記載した宿泊機関の種類又は名称の変更（当社が宿泊機関の等級を定めている場合であって、変更後の宿泊機関の等級が契約書面に記載した宿泊機関の等級を上回った場合を除きます）")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.⑧ホームページ、パンフレット又は確定書面に記載した宿泊機関の客室の種類、設備又は景観その他の客室条件の変更")}</th>
                              <td className="ta-c">1.0%</td>
                              <td className="ta-c">2.0%</td>
                            </tr>
                            <tr>
                              <th className="fz-13 fz-16-md fw-b">{t("Conditions.⑨上記①～⑧に掲げる変更のうち募集ホームページ、パンフレット又は確定書面のツアー・タイトル中に記載があった事項の変更")}</th>
                              <td className="ta-c">2.5%</td>
                              <td className="ta-c">5.0%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </ol>
                  <p>
                    <span className="c-red">{t("Conditions.注1")}</span>{t("Conditions.：商品ページ、予約確認書の記載内容と確定書面の記載内容との間又は確定書面の記載内容と実際に提供された旅行サービスの内容との間に変更が生じたときは、それぞれの変更につき1件として取り扱います。")}
                    <br /><span className="c-red">{t("Conditions.注2")}</span>{t("Conditions.：⑨に掲げる変更については、①～⑧の料率を適用せず、⑨の料率を適用します。")}
                    <br /><span className="c-red">{t("Conditions.注3")}</span>{t("Conditions.：1件とは、運送機関の場合1乗車船毎に、宿泊機関の場合1泊毎に、その他の旅行サービスの場合1該当事項毎に1件とします。")}
                    <br /><span className="c-red">{t("Conditions.注4")}</span>{t("Conditions.：④⑦⑧に掲げる変更が1乗車船又は1泊の中で複数生じた場合であっても、1乗車船又は1泊につき1変更として取り扱います。")}
                    <br /><span className="c-red">{t("Conditions.注5")}</span>{t("Conditions.：③④に掲げる運送機関が宿泊設備の利用を伴う場合、1泊につき1件として取扱います。")}
                    <br /><span className="c-red">{t("Conditions.注6")}</span>{t("Conditions.：④運送機関の会社名の変更、⑦宿泊機関の名称の変更については、運送・宿泊機関そのものの変更に伴うものをいいます。")}
                    <br /><span className="c-red">{t("Conditions.注7")}</span>{t("Conditions.：④運送機関の会社名の変更については、等級又は設備のより高いものへの変更を伴う場合には適用しません。")}
                    <br /><span className="c-red">{t("Conditions.注8")}</span>{t("Conditions.：⑦宿泊機関の等級は、旅行契約締結の時点で契約書面に記載しているリスト、若しくは当社のウェブページで閲覧に供しているリストによります。")}
                  </p>
                </div>
              </section>

              {/* section: 24.通信契約による旅行条件 */}
              <section id="sec_24" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.24.通信契約による旅行条件")}</h2>
                <div className="card large-side-medium">
                  <p>{t("Conditions.当社らは、当社が発行するカード又は当社が提携するクレジットカード会社（以下「提携会社」といいます。）のカード会員（以下「会員」といいます。）より「会員の署名なくして旅行代金や取消料等の支払いを受ける」こと（以下「通信契約」といいます。）を条件に旅行のお申込みを受ける場合があります。通信契約の旅行条件は通常の旅行条件と、以下の点で異なります。 （受託旅行業者により当該取扱ができない場合があります。また取扱可能なカードの種類も受託旅行業者により異なります。）")}</p>
                  <ul className="list-number-dot last-mb-none mb-0 mt-8 gray large">
                    <li>{t("Conditions.本項でいう「カード利用日」とは、会員及び当社が旅行契約に基づく旅行代金等の支払い又は払戻し債務を履行すべき日をいいます。")}</li>
                    <li>{t("Conditions.申し込みに際し、「会員番号（クレジットカード番号）」、「カード有効期限」等を当社らに通知していただきます。")}</li>
                    <li>{t("Conditions.通信契約による旅行契約は、当社らが旅行契約の締結を承諾する旨を電話又は郵便で通知する場合には、当社らがその通知を発した時に成立し、当社らがe-mail等の電子承諾通知による方法により通知する場合は、その通知がお客様に到達した時に成立するものとします。")}</li>
                    <li>{t("Conditions.当社らは提携会社のカードにより所定の伝票への会員の署名なくして「商品ページ、契約書面等に記載する金額の旅行代金」又は「第14項に定める取消料」の支払いを受けます。この場合、旅行代金のカード利用日は「契約成立日」とします。")}</li>
                    <li>{t("Conditions.契約解除のお申し出があった場合、当社らは旅行代金から取消料を差し引いた額を解除の申し出のあった日の翌日から起算して7日以内（減額又は旅行開始後の解除の場合は、30日以内）をカード利用日として払い戻します。")}</li>
                    <li>{t("Conditions.与信等の理由により会員のお申し出のクレジットカードでのお支払いができない場合、当社らは通信契約を解除し、当社らが別途指定する期日までに現金にて旅行代金をお支払いいただきます。当該期日までに、お支払いいただけない場合は14項(1)の取消料と同額の違約料を申し受けます。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 25.個人情報の取扱い */}
              <section id="sec_25" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.25.個人情報の取扱い")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.当社らは、旅行申込みの際に所定の申込書に記載された個人情報について、お客様との間の連絡のために利用させていただくほか、お客様がお申込みいただいた旅行において宿泊機関等（主要宿泊機関等については商品ページ、契約書面等に記載の日程表及び確定書面に記載されています。）の提供するサービスの手配及びそれらのサービスの受領のための手続きに必要な範囲内、当社の旅行契約上の責任、事故時の費用等を担保する保険の手続き上必要な範囲内で、それら宿泊機関等、保険会社等に対し、お客様の氏名、性別、年齢、住所、電話番号、搭乗便名等を、あらかじめ電子的方法等で送付することによって提供いたします。また、食物アレルギー等で特別な対応を必要とされるお客様については、上記項目に加えお客様のお体の状況等を上記事業者のほか医療機関、専門家をはじめとする関係機関に対し、あらかじめ電子的方法等で送付することによって提供することがあります。お申込みいただく際には、これらの個人データの提供についてお客様に同意いただくものとします。このほか、当社らは、①当社ら及び当社らと提携する企業の商品やサービス、キャンペーンのご案内②旅行参加後のご意見やご感想の提供のお願い③アンケートのお願い④特典サービスの提供⑤統計資料の作成に、お客様の個人情報を利用させていただくことがあります。")}</li>
                    <li>{t("Conditions.当社らが本項（1）の個人情報の全部又は一部を提供いただけない場合であって、お客様との連絡、あるいは旅行サービスの手配及びそれらのサービスの受領のために必要な手続きがとれない場合、お客様のお申込み、ご依頼をお引受できないことがあります。")}</li>
                    <li>{t("Conditions.当社らは、旅行中に傷病があった場合等に備え、お客様の旅行中の国内連絡先の方の個人情報をお伺いしています。この個人情報は、お客様に傷病等があった場合で国内連絡先の方への連絡の必要があると当社が認めた場合に使用させていただきます。お客様は、国内連絡先の方の個人情報を当社に提供することについて国内連絡先の方の同意を得るものとします。")}</li>
                    <li>{t("Conditions.当社は、本項(1)の利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することがあります。")}</li>
                    <li hidden={languageCode !== LANGUAGES.JA}>当社は、当社が保有するお客様の個人データのうち、氏名、住所、電話番号又はメールアドレス等のお客様へのご連絡にあたり必要となる最小限の範囲のものについて、当社グループ企業との間で、共同して利用させていただくことがあります。当社グループ企業は、それぞれの企業の営業案内、催し物内容等のご案内、ご購入いただいた商品の発送のために、これを利用させていただくことがあります。なお、当社グループ企業の名称及びプライバシーポリシーについては、当社サイト<a href={ROUTES.Privacypolicy.replace(":lang", languageCode)} target="_blank" rel="noreferrer">https://lpkg.digitaldmoplatform.com/ja/Privacypolicy</a>をご参照ください。</li>
                    <li hidden={languageCode !== LANGUAGES.TW}>本公司可能與本公司集團企業之間共同使用本公司持有之顧客個人資料中姓名、住址、電話號碼或電子郵件地址等與顧客聯絡時所需之最小範圍內之資料。本公司集團企業為介紹各企業之營業、介紹活動內容等、寄送顧客購買之商品，可能使用此資料。本公司集團企業之名稱及隱私權政策請參閱本公司網站<a href={ROUTES.Privacypolicy.replace(":lang", languageCode)} target="_blank" rel="noreferrer">https://lpkg.digitaldmoplatform.com/tw/Privacypolicy</a></li>
                    <li>{t("Conditions.当社が保有するお客様の個人データの開示、その内容の訂正、追加若しくは削除、又はその利用の停止、消去若しくは第三者への提供の停止をご希望の場合は、お申し込みの販売窓口までお申し出ください。")}</li>
                  </ul>
                </div>
              </section>

              {/* section: 26.旅行条件・旅行代金の基準 */}
              <section id="sec_26" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.26.旅行条件・旅行代金の基準")}</h2>
                <div className="card large-side-medium">
                  <p>{t("Conditions.本旅行条件の基準日と旅行代金の基準日については、商品ページ、契約書面等に明示した日となります。")}</p>
                </div>
              </section>

              {/* section: 27.その他 */}
              <section id="sec_27" className="mt-48-md mt-24 pt-16">
                <h2 className="heading-2 mt-0 mb-16 mb-24-md">{t("Conditions.27.その他")}</h2>
                <div className="card large-side-medium">
                  <ul className="list-number-dot mb-0 gray large">
                    <li>{t("Conditions.お客様の怪我、疾病等の発生に伴う諸費用、お客様の不注意による荷物紛失・忘れ物回収に伴う諸費用、別行動手配に要した諸費用が生じたときには、それらの費用はお客様にご負担いただきます。")}</li>
                    <li>{t("Conditions.当社はいかなる場合も旅行の再実施はいたしません。")}</li>
                    <li>{t("Conditions.その他の事項については別途お渡しする旅行書面など当社旅行業約款によります。")}</li>
                  </ul>
                </div>
              </section>


              <p className="mt-48">更新日：2022年11月14日</p>

            </div>
          </div>
        </div>

           
    </MainTemplate>
  );
};

export default Conditions;
