import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { InputsForm5 } from "../../../types/InputsForm5";
import { InputCheckbox } from "../../input/InputCheckbox";
import { InputRadio } from "../../input/InputRadio";
import { ROUTES } from "../../../constants/routes";
import { useTranslation } from "react-i18next";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";


type Props = {
  control: any;
  errors: FieldErrors;
  register: UseFormRegister<InputsForm5>;
}

const SectionOther: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <section>
        {/* その他確認事項について */}
          <h2 className="heading-2">{t("SectionOtherInForm5.その他確認事項について")}</h2>
          {/* <h2 className="heading-2">有關於其他的確認事項</h2> */}
          <div className="card mb-20 mb-32-md">
            <h3 className="heading-3">
              {t("SectionOtherInForm5.特別な配慮を必要とする方")}
              {/* 是否有需要特別照顧的旅客 */}
              <span className="label-required ml-5">必須</span>
            </h3>
            <p className="mb-20">
              {t("SectionOtherInForm5.ご参加者の中に、健康を害している方、車椅子などの器具をご利用になっている方や心身に障がいのある方、食物アレルギー・動物アレルギーのある方、妊娠中の方、妊娠の可能性がある方、身体障害者補助犬（盲導犬、聴導犬、介助犬）をお連れの方その他特別の配慮を必要とする方がいる。")}
              {/* 參加者一行當中，有身體狀況不佳，有正使用輪椅子等工具的身心障礙人士，有食物過敏者，有動物過敏者，有正在懷孕，或有疑似懷孕的女士，有正利用身心障礙輔助犬（導盲犬/導聾犬/肢體障礙輔助犬）的人士，以其他需要特別照顧的人士。 */}
            </p>
            <div className="form-content-input">
              <div
                className={
                  "form-content-input-sexual" +
                  (props.errors.other?.need_consideration ? " error" : "")
                }
              >
                <InputRadio
                  name="other.need_consideration"
                  control={props.control}
                  radios={[
                    // { label: "有", value: "yes" },
                    // { label: "沒有", value: "no" },
                    { label: t("SectionOtherInForm5.いる"), value: "yes" },
                    { label: t("SectionOtherInForm5.いない"), value: "no" },
                  ]}
                  options={{ required: true }}
                  isRow={true}
                ></InputRadio>
                {props.errors.other?.need_consideration && (
                  <p className="form-error_text">
                    {t("SectionOtherInForm5.特別な配慮を必要されるかをご選択ください。")}
                    {/* 請選擇是否有需要特別照顧的旅客 */}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="card mb-20">
            <p className="mb-20">
              <a
                // href="/Conditions"
                href={ROUTES.Conditions.replace(":lang", i18n.language)}
                className="button-large-blue block"
                target="_blank"
                rel="noreferrer"
              >
                {t("SectionOtherInForm5.旅行条件書を表示する")}
                {/* 顯示並閱讀旅遊條件契約書 */}
                <i className="icon-blank-white ml-5"></i>
              </a>
            </p>
            <div className="form-input-checkbox">
              <InputCheckbox
                name="other.agree"
                register={props.register}
                checkboxes={[
                  {
                    id: "other.agree",
                    label: "",
                    value: "yes"
                  },
                ]}
                options={{ required: true }}
                className={"form-input-checkbox-icon"}
              ></InputCheckbox>
              <span className="form-input-checkbox-text">
                <label htmlFor="other.agree.0">
                  {t("SectionOtherInForm5.旅行条件書を表示し、表示された諸条件について同意する")}
                  {/* 顯示旅遊條件契約書，並同意其內容 */}
                </label>
              </span>
            </div>
            {props.errors.other?.agree && (
              <p className="form-error_text c-red ta-c mt-4">
                {t("SectionOtherInForm5.「同意する」にチェックをいれてください。")}
                {/* 請勾選『我同意』 */}
              </p>
            )}  
          </div>
      </section>
    </>
  );
};

export default SectionOther;