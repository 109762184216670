import React from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../../constants/languages";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

type Props = {
  // calcAge: (input :{year: number, month: number, day: number}) => number;
  toGenderLabel: (gender: string) => string;
  applicant: {
    name: {
      kanji: {
        first: string;
        last: string;
      };
      kana: {
        first: string;
        last: string;
      }
    };
    // age: number;
    gender: string;
    email: string;
  };
  // followers: {
  //   name: {
  //     kana: {
  //       last: string;
  //       first: string;
  //     },
  //   },
  //   gender: string;
  //   age: string;
  //   mileage_number: string;
  //   birthday_year: string;
  //   birthday_month: string;
  //   birthday_day: string;
  // }[];
}

const SectionParticipant: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();

  // 敬称
  const showSuffixByGender = (params: { languageCode: string; gender: string; }) => {

    return t("ReservationCancelDetailSectionDetail.様");
  }

  // 言語によって名前を出し分ける
  const showName = (params: { languageCode: string; name: { kanji: { first: string; last: string; }; kana: { first: string; last: string; }; }; gender: string; }) => {
    const name = i18n.language === LANGUAGES.JA ? `${params.name.kana.last} ${params.name.kana.first}` : `${params.name.kanji.last} ${params.name.kanji.first}`;

    const suffix = showSuffixByGender({ languageCode: params.languageCode, gender: params.gender });

    return `${name}${suffix}`;
  }

  return (
    <>
      <section>
        <h2 className="heading-2 mt-40-md">{t("SectionParticipant.代表者情報")}</h2>
        {/* <h2 className="heading-2 mt-40-md">代表者情報</h2> */}
        <div className="card">
          <table className="table-responsive">
            <tbody>
              <tr>
                <td className="table-bgc-light_navy ta-c-md fw-b">
                  {/* 参加者1 */}
                  {t("SectionParticipant.旅行の代表者")}
                  {/* 旅遊行程的代表者 */}
                </td>
                <td>
                  <p>
                    <span className="mr-10 fw-b">
                      {/* {`${props.applicant.name.kana.last} ${props.applicant.name.kana.first}`}様 */}
                      { showName({ languageCode: i18n.language, name: props.applicant.name, gender: props.applicant.gender }) }
                    </span>
                    {/* <span className="fz-14 mr-10">{props.applicant.age}歳</span> */}
                    <span className="fz-14">{props.toGenderLabel(props.applicant.gender)}</span>
                  </p>
                  {props.applicant.email && (
                    <p className="fz-14">
                      {t("SectionParticipant.Emailアドレス")}：{props.applicant.email}
                      {/* 郵件地址：{props.applicant.email} */}
                    </p>
                  )}
                </td>
              </tr>

              {/* {props.followers.map((follower, index) => (
                <tr key={index}>
                  <td className="table-bgc-light_navy ta-c-md fw-b">
                    参加者{index + 2}
                  </td>
                  <td>
                    <p>
                      <span className="fw-b mr-10">{`${follower.name.kana.last} ${follower.name.kana.first}`} 様</span>
                      <span className="fz-14 mr-10">
                        {props.calcAge({
                          year: parseInt(follower.birthday_year),
                          month: parseInt(follower.birthday_month),
                          day: parseInt(follower.birthday_day),
                        })}歳
                      </span>
                      <span className="fz-14">{props.toGenderLabel(follower.gender)}</span>
                    </p>
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default SectionParticipant;