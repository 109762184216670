/**
 * ホテル種類(日本語)
 */
export const hotelTypeCodeForJp: {[key:string]: string} = {
  "1": "リゾートホテル",
  "2": "シティホテル",
  "3": "ビジネスホテル",
  "4": "コンドミニアム",
  "5": "旅館",
  "6": "民宿",
  "7": "ペンション",
  "8": "ドミトリー・ゲストハウス",
  "9": "その他宿泊施設",
  "10": "一棟貸切",
}

/**
 * ホテル種類(繁体字)
 */
export const hotelTypeCodeForTw: {[key:string]: string} = {
  "1": "度假飯店",
  "2": "城市飯店",
  "3": "商務飯店",
  "4": "度假公寓",
  "5": "旅館",
  "6": "民宿",
  "7": "簡易旅館",
  "8": "簡易旅館・招待所",
  "9": "其他類型住宿設施",
  "10": "一棟包租",
}


/**
 * 部屋種類(日本語)
 */
export const roomTypeCodeForJp:  {[key:string]: string} = {
  "1": "シングル",
  "2": "セミダブル",
  "3": "ダブル",
  "4": "ツイン",
  "5": "トリプル",
  "6": "4ベット",
  "7": "和室",
  "8": "和洋室",
  "9": "一棟貸切",
  "99": "その他",
}

/**
 * 部屋種類(繁体字)
 */
export const roomTypeCodeForTw:  {[key:string]: string} = {
  "1": "單人床房",
  "2": "小型雙人床房",
  "3": "雙人床房",
  "4": "雙床房",
  "5": "三床房",
  "6": "四床房",
  "7": "日式客房",
  "8": "日西式客房",
  "9": "一棟包租",
  "99": "其他",
}


/*
 * ホテルのサービスタグ画像名管理（レンジ、コンロ以外 / 日本語）
 */
export const hotelTagImageForJp: {[key:string]: string} = {
  "フリーWiFi": "ja/01_tag_free-wifi.svg",
  "無料駐車場": "ja/02_tag_free-parking.svg",
  "有料駐車場": "ja/03_tag_paid-parking.svg",
  "屋内プール": "ja/04_tag_indoor-pool.svg",
  "屋外プール（夏季営業）": "ja/05_tag_outdoor-pool-in-summer.svg",
  "ビーチ": "ja/06_tag_beach.svg",
  "大浴場": "ja/07_tag_large-public-bath.svg",
  "温泉": "ja/08_tag_hot-spring.svg",
  "スパ": "ja/09_tag_spa.svg",
  "露天風呂": "ja/10_tag_outdoor-bath.svg",
  "全室禁煙": "ja/11_tag_non-smoking.svg",
  "売店・コンビニ": "ja/12_tag_convenience-store.svg",
  "レストラン・食堂": "ja/13_tag_restaurant.svg",
  "コネクティングルーム": "ja/14_tag_connecting-room.svg",
  "全室キッチン付": "ja/15_tag_with-kitchen.svg",
  "キッチン": "ja/16_tag_kitchen.svg",
  "フィットネスジム": "ja/17_tag_fitness-gym.svg",
  "各種マッサージサービス（有料）": "ja/18_tag_paid-massage-service.svg",
  "エステサロン（有料）": "ja/19_tag_paid-beauty-salon.svg",
  "自動販売機": "ja/20_tag_vending-machine.svg",
  "調理器具・食器類": "ja/21_tag_kitchenware-tableware.svg",
  "洗濯物": "ja/22_tag_washing-machine.svg",
  "衣類乾燥機": "ja/23_tag_clothes-dryer.svg",
  "コインランドリー（有料）": "ja/24_tag_paid-coin-laundry.svg",
  "送迎バス": "ja/25_tag_courtesy-bus.svg",
  "ラウンジ": "ja/26_tag_lounge.svg",
  "バーベキューガーデン": "ja/27_tag_bbq-garden.svg",
  "喫煙所": "ja/30_tag_smoking-area.svg",
  "ペット可": "ja/31_tag_pet-friendly.svg",
}

/*
 * ホテルのサービスタグ画像名管理（レンジ、コンロ以外 / 繁体字
 */
export const hotelTagImageForTw: {[key:string]: string} = {
  "免費 WiFi": "tw/01_tag_free-wifi.svg",
  "免費的停車場": "tw/02_tag_free-parking.svg",
  "收費停車場": "tw/03_tag_paid-parking.svg",
  "室內游泳池": "tw/04_tag_indoor-pool.svg",
  "室外游泳池（夏季營業）": "tw/05_tag_outdoor-pool-in-summer.svg",
  "海灘": "tw/06_tag_beach.svg",
  "大浴場": "tw/07_tag_large-public-bath.svg",
  "溫泉": "tw/08_tag_hot-spring.svg",
  "SPA": "tw/09_tag_spa.svg",
  "露天浴池": "tw/10_tag_outdoor-bath.svg",
  "所有客房禁煙": "tw/11_tag_non-smoking.svg",
  "小賣部、便利商店": "tw/12_tag_convenience-store.svg",
  "餐廳・食堂": "tw/13_tag_restaurant.svg",
  "相通客房": "tw/14_tag_connecting-room.svg",
  "所有客房附廚房": "tw/15_tag_with-kitchen.svg",
  "廚房": "tw/16_tag_kitchen.svg",
  "健身房": "tw/17_tag_fitness-gym.svg",
  "各種按摩服務(收費)": "tw/18_tag_paid-massage-service.svg",
  "美容院(收費)": "tw/19_tag_paid-beauty-salon.svg",
  "自動販賣機": "tw/20_tag_vending-machine.svg",
  "烹調器具、餐具類": "tw/21_tag_kitchenware-tableware.svg",
  "洗衣機": "tw/22_tag_washing-machine.svg",
  "衣物烘乾機": "tw/23_tag_clothes-dryer.svg",
  "投幣式洗衣機(收費)": "tw/24_tag_paid-coin-laundry.svg",
  "接送巴士": "tw/25_tag_courtesy-bus.svg",
  "休息室": "tw/26_tag_lounge.svg",
  "ＢＢＱ燒烤花園": "tw/27_tag_bbq-garden.svg",
  "吸煙處": "tw/30_tag_smoking-area.svg",
  "可攜帶寵物": "tw/31_tag_pet-friendly.svg",
}


// todo: 言語ごとの出し分けが必要？
/**
 * okinawa ホテルエリアコード
 */
export const okinawaAreaCodeLabel: {[key:string]: string} = {
  all : "すべて",
 naha : "那覇",
 onnason : "恩納村・読谷村",
 chatan : "北谷・沖縄市",
 motobu : "本部・今帰仁",
 nago : "名護",
 ginowan : "浦添・宜野湾・北中城",
 miyako : "宮古島",
 yaeyama : "石垣島（八重山諸島）",
 tomishiro : "糸満・豊見城・八重瀬",
 shimajiri : "南城・南風原・与那原",
 chubu : "うるま・金武・宜野座",
 iejima : "伊江島",
 kunigami : "大宜味村・国頭村",
 kerama : "慶良間諸島",
 kumejima : "久米島",
 yonagunijima : "与那国島",
 mimasakasantou : "美作三湯（湯郷・湯原・奥津）・西粟倉・東粟倉・蒜山",
 kurashiki2 : "倉敷・総社・井笠・高梁",
 okayama2 : "岡山・牛窓・備前・玉野",
 shoudoshima : "小豆島",
 kotohira : "琴平",
 marugame : "丸亀・坂出",
 takamatsu : "高松・東讃",
 uwajima : "宇和島・南予",
 imabari : "今治・しまなみ海道・東予",
 matuyama : "松山・道後温泉・中予",
}