import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "./useApi";

export const useSendTestMail = () => {
    const [isLoading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const isError = error !== null;

    const { t } = useTranslation();

    const sendTestMail = async (targetEmail: string, languageCode: string): Promise<boolean> => {
      if (!targetEmail) {
        alert(t("SectionApplicant.メールアドレスを正しくご入力ください。"));
        // alert("請輸入正確的電子郵件。");
        return false;
      }

      // メアドの簡易チェック
      const regex = /^\S+@\S+$/i;
      if (!regex.test(targetEmail)) {
        alert(t("SectionApplicant.メールアドレスを正しくご入力ください。"));
        // alert("請輸入正確的電子郵件。");
        return false;
      }

      const confirm = window.confirm(`${t("sendTestMail.このメールアドレスにテストメールを送信します。よろしいですか？")}`);
      // const confirm = window.confirm("是否同意發送測試用郵件至此電子信箱?");
      if (!confirm) {
        return true;
      }

      setLoading(true);

      try {
        const response = await useApi.post({
          url: "/testMail",
          data: {
            address: targetEmail,
            language_code: languageCode,
          }
        })

        if (response.status !== 200) {
          throw new Error("送信に失敗しました。");
        }

        alert(t("sendTestMail.テストメールを送信しました。メールが受信できているかご確認ください。"));
        // alert("已發送，請確認是否成功收到測試郵件。");

        setLoading(false);

        return true;

      } catch (error) {
        setError("送信に失敗しました。");
        alert(t("sendTestMail.テストメールの送信に失敗しました。しばらくしてから再度お試しください。"));
        // alert("發送測試郵件失敗，請過段時間再行測試。");
      }

      setLoading(false);

      return false;
    }

    return [ sendTestMail, { isLoading, isError, error } ] as const;
};