import React, {useEffect} from "react";

// for styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {getBackSearchURL} from "../../utils/search";

import { useTranslation } from "react-i18next";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  planSelectFormData: PlanSelectFormData|undefined,
  callback: () => void,
}

const DialogChange: React.FC<Props> = ({
  isShow,
  planSelectFormData,
  callback
}) => {

  const { t, i18n } = useTranslation();
  const [search, setSearch] = React.useState<PlanSelectFormData["searchParams"]|undefined>(planSelectFormData?.searchParams);

  useEffect(() => {
    if (planSelectFormData !== undefined) {
      setSearch(planSelectFormData.searchParams);
    }
  }, []);
  
  const back = () => {
    if (search !== undefined && planSelectFormData !== undefined) {
      window.location.href = getBackSearchURL(search, planSelectFormData.id, i18n.language);
    }
    callback();
  }

  return (
    <>
    {/* ダイアログ:検索変更 */}
    <div>
        <Dialog
          open={isShow}
          onClose={callback}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialog"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title">
          </DialogTitle>
          <DialogContent className="dialog-content">
            <DialogContentText id="alert-dialog-description" className="dialog-content-text">
              {/* 選択中の条件を変更するには、トップページへ戻る必要がございます。よろしければトップページへ戻り選択条件をご変更ください。 */}
              {/* 若欲變更您設定的條件，需返回首頁。請返回首頁後再行變更您所設定的條件。 */}
              {t("DialogChange.選択中の条件を変更するには、トップページへ戻る必要がございます。よろしければトップページへ戻り選択条件をご変更ください。")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action">
            {/* <Button className="button-dialog-border-small-blue w-110" onClick={callback}>キャンセル</Button> */}
            {/* <Button className="button-dialog-border-small-blue w-110" onClick={callback}>取消</Button> */}
            <Button className="button-dialog-border-small-blue w-110" onClick={callback}>{t("DialogChange.キャンセル")}</Button>
            <Button className="button-dialog-small-blue w-110" onClick={back} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DialogChange;
