import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  description?: string;
  // path?: string;
};

export const HeadBlock: React.VFC<Props> = (props) => {
  const { title, description, /*path*/ } = props;

  const { t } = useTranslation();

  const serviceName = t("Common.デジタルDMOプラットフォーム");
  
  
  return (
    <Helmet>
      <title>{title !== "" ? title : serviceName}</title>
      {/* <title>{title !== "" ? title : "數位DMO預約平台"}</title> */}
      {/* <meta name="description" content={description ?? "數位DMO預約平台"} /> */}
      {/* <link rel="canonical" href={`https:hoge.com/${path ?? ''}`} /> */}
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
    </Helmet>
  );
};