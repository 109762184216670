import React from "react";
import _ from "lodash";

// toggle button
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//モーダルインポート
import RoomDetailModal from "../modal/RoomDetailModal";

import StaySearchResult from "./StaySearchResult";
import HotelPlanSearchResult from "./HotelPlanSearchResult"

import {
    Hotel, 
    // hotelPlanDetail
} from "../../types/Hotel";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {hotelAreaCodeLabel} from "../../utils/hotel";
import { useTranslation } from "react-i18next";
import {getComprehensiveItems, getHotelSettings} from "../../utils/sellerProduct";
// import {devLog} from "../../utils/errors";

type Props = {
    data: Hotel | undefined,
    defaultHotels: Hotel | undefined,
    setHotels: any,
    planSelectFormData: PlanSelectFormData,
    selectPlanSelectFormData(planSelectFromData:PlanSelectFormData):void,
    isLoading: boolean,
    // callback: () => void,
}

const StaySearchbox: React.VFC<Props> = ({
    data,
    defaultHotels,
    setHotels,
    planSelectFormData,
    selectPlanSelectFormData,
    isLoading,
    // callback,
}) => {

    const { t } = useTranslation();

    //エリアセレクトボックス
    // const options = ["全部"];
    const options = [t("StaySearchbox.すべて")];

    const hotelSettings = getHotelSettings(planSelectFormData);
    const target = hotelSettings?.target;
    const area = hotelSettings?.hotelCode;
    if (area) {
        let selectAreaList: string[] = []; // エリア絞り込みの項目
        planSelectFormData?.hotelData?.forEach((item, index) => {
            selectAreaList.push(item.sub_area_code ?? ""); // エリアを追加
        })
        selectAreaList = _.uniq(selectAreaList); // 重複した項目をまとめる
        if (selectAreaList.length > 1) {
            selectAreaList.forEach((areaCode) => {
                if (areaCode !== '') {
                    options.push(areaCode);
                }
            })
        }
    }
    
    //宿泊の選択トグルボタン
    // const [RoomDetailToggleState, setRoomDetailToggleState] = React.useState(() => ['all']);
    // const handleRoomDetailToggle = (event: React.MouseEvent<HTMLElement>, newRoomDetailToggle: string[]) => {
    //     setRoomDetailToggleState(newRoomDetailToggle);
    // };

    const [roomTypeFilterState, setRoomTypeFilterState] = React.useState<string[]>([]);
    const handleRoomTypeFilter = (
        event: React.MouseEvent<HTMLElement>,
        newRoomDetailToggle: string[],
    ) => {
        setRoomTypeFilterState(newRoomDetailToggle);
    };

    const [mealFilterState, setMealFilterState] = React.useState<string[]>([]);
    const handleMealFilter = (
        event: React.MouseEvent<HTMLElement>,
        newRoomDetailToggle: string[],
    ) => {
        setMealFilterState(newRoomDetailToggle);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const StaySelectOpen = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // const toggleCreateData = (
    //     label: string,
    //     value: string,
    // ) => {
    //     return { label, value };
    // };
    
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        let newHotel = _.cloneDeep(data);
        if (newHotel?.hotelList) {
            const filterSubArea = event.currentTarget.textContent;
            const hotelAreaCode =  options[index]

            // @ts-ignore
            newHotel.hotelList = _.filter(defaultHotels.hotelList, (item) => {
                // if (filterSubArea === "すべて") {
                // if (filterSubArea === "全部") {
                if (filterSubArea === t("StaySearchbox.すべて")) {    
                    return true;
                }
                return item.sub_area_code === hotelAreaCode;
            });
            newHotel.total = newHotel.hotelList.length;
            setHotels({...newHotel});
            setSelectedHotelSort("recommend");
        }
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const StaySlectClose = () => {
        setAnchorEl(null);
    };

    // 絞り込み条件(ルームタイプ)
    const [roomTypeFilter, setRoomTypeFilter] = React.useState<{label: string, value: string}[]>([]);
    const [mealFilter, setMealFilter] = React.useState<{label: string, value: string}[]>([]);

    // //モーダル：プラン詳細詳細
    // const [PlanDetailModalState, setPlanDetailModalState] = React.useState(false);
    // const handlPlandetailModal = () => {
    //     setPlanDetailModalState(!PlanDetailModalState);
    // };

    // モーダル：客室詳細
    const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
    const handleRoomDetailModal = () => {
        setRoomDetailModalState(!roomDetailModalState);
    };

    // //アコーディオン：制御
    // const[StayAccordionState,SetStayAccordionState] = React.useState(false);
    // const handleStayAccordion = useMemo(() => {
    //     return () => {
    //         SetStayAccordionState(!StayAccordionState);
    //         devLog(StayAccordionState);
    //     };
    // }, [StayAccordionState]);

    //カウンター
    const[ StaySearchPageView, setStaySearchPageView ] = React.useState(1);

    // const showPlanView = () => {
    //     setStaySearchPageView(StaySearchPageView+1)
    // }

    const showHotelView = () => {
        setStaySearchPageView(StaySearchPageView-1)
    }

    const [currentHotelCode, setCurrentHotelCode] = React.useState("");

    const selectHotel = (hCode: string) => {
        setCurrentHotelCode(hCode);
    }


    // const callbackUpdateSelectHotel = (planData: hotelPlanDetail|undefined) => {
    //     // if (planSelectFormData !== undefined && hotelDetail !== undefined) {
    //     //     planSelectFormData.hotel[0].detail = hotelDetail;
    //     //     planSelectFormData.hotel[0].plan = planData;
    //     //     updateSelectHotel({...planSelectFormData});
    //     // }
    // }

    const [selectedHotelSort, setSelectedHotelSort] = React.useState("recommend");
    const [selectedRoomSort, setSelectedRoomSort] = React.useState("");

    /**
     * 宿泊施設の並び替え
     * @param sortKey 
     * @param sortType 
     */
    const sortHotels = (sortKey: string, sortType: string) => {
        if (data?.hotelList && data?.hotelList.length > 0) {
            setSelectedHotelSort(sortType);
            let newHotel = _.cloneDeep(data);
            if (newHotel?.hotelList) {
                // @ts-ignore
                newHotel.hotelList = _.sortBy(newHotel.hotelList, sortKey);
                if (sortType === "desc") {
                    newHotel.hotelList.reverse();
                }
                setHotels({...newHotel});
            }
        }
    }

    /**
     * 宿泊プランの並び替え
     * @param sortType 
     */
    const sortRoomPlan = (sortType: string) => {
        if (data?.hotelList && data.hotelList.length > 0) {
            setSelectedRoomSort(sortType);
            let newHotel = _.cloneDeep(data);
            const newHotelList = newHotel?.hotelList.map((hotel) => {
                if (hotel.hotel_code === currentHotelCode) {
                    hotel.roomData = _.sortBy(hotel.roomData, "first_plan.total_price");
                    if (sortType === "desc") {
                        hotel.roomData.reverse();
                    }
                }
                return hotel;
            })
            // @ts-ignore
            newHotel.hotelList = [...newHotelList];
            setHotels({...newHotel});
        }
        
    }

    return (
        <>
            {data && (
              <>
                  <div className="mt-20">
                      {/* // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK */}
                      {((target === "エリア指定" && options.length > 1) || StaySearchPageView === 2) && (
                        <>
                            <div className="card small">
                                {/* 宿泊施設の絞り込み条件 */}
                                {target === "エリア指定" && options.length > 1 && (
                                  <>
                                      <div className={(StaySearchPageView === 1? " animation-fade active":" animation-fade")}>
                                          {options && options.length > 0 ?
                                            <div className="serchbox-stay">
                                                {/* <p className="fz-16 mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">增加搜尋條件</p> */}
                                                <p className="fz-16 mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">{t("StaySearchbox.条件で絞り込む")}</p>
                                                <dl className="d-f-md fw-w bl-gray-md pl-40-md">
                                                    <div className="d-f ai-c mt-16 mt-0-md w-100per">
                                                        {/* <dt className="c-navy fz-13 fz-16-md fw-b mr-16 fx-sh">地區</dt> */}
                                                        <dt className="c-navy fz-13 fz-16-md fw-b mr-16 fx-sh">{t("StaySearchbox.エリア")}</dt>
                                                        <dd className="w-100per w-200-md">
                                                            <List component="div" className="form-select_list">
                                                                <ListItem
                                                                  button
                                                                  id="lock-button"
                                                                  aria-haspopup="listbox"
                                                                  aria-controls="lock-menu"
                                                                  // aria-label="when device is locked"
                                                                  aria-expanded={StaySelectOpen ? 'true' : undefined}
                                                                  className="form-select_list-inner"
                                                                  onClick={handleClickListItem}
                                                                >
                                                                    <ListItemText
                                                                      // primary="When device is locked"
                                                                      secondary={t("HotelArea."+options[selectedIndex])}
                                                                      className="form-select_list-selected_text text_medium"
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                            <Menu
                                                              id="lock-menu"
                                                              anchorEl={anchorEl}
                                                              open={StaySelectOpen}
                                                              className="form-select_list-options"
                                                              onClose={StaySlectClose}
                                                              MenuListProps={{
                                                                  'aria-labelledby': 'lock-button',
                                                                  role: 'listbox',
                                                              }}
                                                            >
                                                                {options.map((option, index) => (
                                                                  <MenuItem
                                                                    key={"area_"+index}
                                                                    // disabled={index === 0}
                                                                    className="form-select_list-options-item"
                                                                    selected={index === selectedIndex}
                                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                                  >
                                                                      {t("HotelArea."+option)}
                                                                  </MenuItem>
                                                                ))}
                                                            </Menu>
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                            : ""}
                                      </div>
                                  </>
                                )}

                                {/* 宿泊プランの絞り込み条件 */}
                                <div className={(StaySearchPageView === 2? " animation-fade active":" animation-fade")}>
                                    <div className="serchbox-stay">
                                        {/* <p className="fz-16 mb-16 mb-0-md fw-b ta-c pr-10-md w-150-md">增加搜尋條件</p> */}
                                        <p className="fz-16 mb-16 mb-0-md fw-b ta-c pr-10-md w-150-md">{t("StaySearchbox.条件で絞り込む")}</p>
                                        <dl className="d-f-md fw-w bl-gray-md pl-24-md">
                                            {roomTypeFilter && roomTypeFilter.length > 0 ?
                                              <div className="d-f ai-c mb-10 mb-0-md">
                                                  {/* <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">房型</dt> */}
                                                  <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">{t("SearchCounter.お部屋")}</dt>
                                                  <dd className="fx-1">
                                                      <ToggleButtonGroup
                                                        value={roomTypeFilterState}
                                                        onChange={handleRoomTypeFilter}
                                                        aria-label="text formatting"
                                                        className="box-toggle_button w-a"
                                                      >
                                                          {roomTypeFilter.map((row,index) => (
                                                            <ToggleButton key={"roomToggle_"+index} value={row.value} aria-label={row.value} className="box-toggle_button-item">
                                                                {row.label}
                                                            </ToggleButton>
                                                          ))}
                                                      </ToggleButtonGroup>
                                                  </dd>
                                              </div>
                                              : ""}

                                            {mealFilter && mealFilter.length > 0 ?
                                              <div className="d-f ai-c pl-40-md">
                                                  {/* <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">餐食</dt> */}
                                                  <dt className="c-navy fz-13 fz-14-md fw-b mr-16 fx-sh">{t("StaySearchbox.お食事")}</dt>
                                                  <dd className="fx-1">
                                                      <ToggleButtonGroup
                                                        value={mealFilterState}
                                                        onChange={handleMealFilter}
                                                        aria-label="text formatting"
                                                        className="box-toggle_button w-a"
                                                      >
                                                          {mealFilter.length > 0 && mealFilter.map((row,index) => (
                                                            <ToggleButton key={"eats_"+index} value={row.value} aria-label={row.value} className="box-toggle_button-item">
                                                                {row.label}
                                                            </ToggleButton>
                                                          ))}
                                                      </ToggleButtonGroup>
                                                  </dd>
                                              </div>
                                              : ""}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </>
                      )}

                      {/* 宿泊施設の検索結果 */}
                      <div className={(StaySearchPageView === 1? " animation-fade active":" animation-fade")}>
                          <div className="d-f jc-sb mt-24 mt-40-md mb-16">
                              <div className="fz-12 fz-14-md fw-b">
                                {/* 搜尋結果<span className="c-red fz-20 fz-22-md ml-5 mr-5">{data?.total}</span>件 */}
                                {t("StaySearchbox.検索結果")}<span className="c-red fz-20 fz-22-md ml-5 mr-5">{data?.total}</span>件
                              </div>
                              {data?.total && data.total > 0 ?
                                <div className="box-sort">
                                    <ul className="box-sort-list">
                                        {target === "ホテル指定" &&
                                            <li key={"result_recommend"} className="box-sort-list-item">
                                                <button
                                                    className={selectedHotelSort !== "recommend" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                                    onClick={() => sortHotels("sortIndex", "recommend")}
                                                >
                                                    {/* おすすめ順 */}
                                                    {t("StaySearchbox.おすすめ順")}
                                                </button>
                                            </li>
                                        }
                                        <li key={"result_cheapest"} className="box-sort-list-item">
                                            <button
                                              className={selectedHotelSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                              onClick={() => sortHotels("min_price", "asc")}
                                            >
                                                {/* 價格由 低至高 */}
                                                {t("StaySearchbox.価格が安い順")}
                                            </button>
                                        </li>
                                        <li key={"result_expensive"} className="box-sort-list-item">
                                            <button
                                              className={selectedHotelSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                              onClick={() => sortHotels("min_price", "desc")}
                                            >
                                                {/* 價格由 高至低 */}
                                                {t("StaySearchbox.価格が高い順")}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                : ""}
                          </div>
                      </div>

                      {/* 宿泊プランの検索結果 */}
                      <div className={(StaySearchPageView === 2? " animation-fade active":" animation-fade")}>
                          <div className="d-f jc-sb ai-c mt-24 mt-20-md mb-16">
                              {planSelectFormData && planSelectFormData?.hotelData && planSelectFormData?.hotelData?.length > 1 && (
                                <>
                                    <div className="fz-12 fz-14-md fw-b">
                                        <button className="button-clear-blue" onClick={showHotelView}>
                                            <i className="icon-left-arrow-light_blue mr-5"></i>
                                            {/* 宿泊施設の選択に戻る */}
                                            {/* 返回上一頁  選擇住宿設施 */}
                                            {t("StaySearchbox.宿泊施設の選択に戻る")}
                                        </button>
                                    </div>
                                </>
                              )}
                              <div className="box-sort">
                                  <ul className="box-sort-list">
                                      <li key={"plan_cheapest"} className="box-sort-list-item">
                                        <button
                                            className={selectedRoomSort !== "asc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                            onClick={() => sortRoomPlan("asc")}
                                        >
                                            {/* 價格由 低至高 */}
                                            {t("StaySearchbox.価格が安い順")}
                                        </button>
                                      </li>
                                      <li key={"plan_expensive"} className="box-sort-list-item">
                                        <button
                                            className={selectedRoomSort !== "desc" ? "box-sort-list-item-link button-clear" : "box-sort-list-item-link button-clear active"}
                                            onClick={() => sortRoomPlan("desc")}
                                        >
                                            {/* 價格由 高至低 */}
                                            {t("StaySearchbox.価格が高い順")}
                                        </button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </>
            )}

            {/* 宿泊施設の検索結果 */}
            <StaySearchResult 
                planSelectFormData={planSelectFormData} selectHotel={selectHotel} 
                isLoading={isLoading} 
                hotel={data} 
                staySearchPageView={StaySearchPageView} 
                setStaySearchPageView={setStaySearchPageView} 
                setRoomTypeFilterState={setRoomTypeFilterState} 
                setMealFilterState={setMealFilterState} 
                // setMealFilter={setMealFilter} 
                // setRoomTypeFilter={setRoomTypeFilter}
            />
            
            {/* 宿泊プランの検索結果 */}
            <HotelPlanSearchResult 
                updateSelectHotel={selectPlanSelectFormData} 
                planSelectFormData={planSelectFormData} 
                hotelCode={currentHotelCode} hotel={data} 
                // setHotels={setHotels} 
                // defaultHotels={defaultHotels} 
                staySearchPageView={StaySearchPageView} 
                setStaySearchPageView={setStaySearchPageView} 
                // callback={callback} 
                mealFilter={mealFilterState} 
                setMealFilter={setMealFilter} 
                roomTypeFilter={roomTypeFilterState} 
                setRoomTypeFilter={setRoomTypeFilter}
            />

            {/* モーダル：客室詳細 */}
            <RoomDetailModal 
                isShow={roomDetailModalState} 
                roomData={undefined} 
                callback={handleRoomDetailModal}
            />
        </>
    );
}
export default StaySearchbox;