import {PlanSelectFormData, PlanSelectFromFlightType} from "../types/planSelectForm";
import {
  planSelectScheduleCompleteList,
  planSelectScheduleCompleteSetData,
  planSelectScheduleList
} from "../types/planSelectConfirm";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import {dateFormat, rangeDate} from "./convert";
import _ from "lodash";
import {devLog} from "./errors";
dayjs.extend(isBetween);

export const getPlanSelectSchedule = (planSelectFormData: PlanSelectFormData, rangeDates: string[]) => {
  const schedule:planSelectScheduleList[] = [];
  if (rangeDates.length > 0) {
    rangeDates.forEach((date) => {

      schedule.push({
        date: date,
        hotel: reserveHotelDateFilter(date, planSelectFormData),
        activity: reserveActivityDateFilter(date, planSelectFormData),
        flight: reserveFlightDateFilter(date, planSelectFormData),
      });
    });
  }

  return schedule;
}

export const reserveHotelDateFilter = (date:string, planSelectFormDate: PlanSelectFormData) => {

  if (planSelectFormDate.hotel.length > 0) {
    const reserveHotel = planSelectFormDate.hotel.filter((hotel) => {
      return dayjs(date).isBetween(hotel.checkIn, hotel.checkOut, null, '[)');
    });

    if (reserveHotel.length > 0) {
      return reserveHotel;
    }
  }

  return undefined;
}

export const reserveActivityDateFilter = (date:string, planSelectFormDate: PlanSelectFormData) => {

  if (planSelectFormDate.activity.length > 0) {
    const reserveActivity = planSelectFormDate.activity.filter((activity) => {
      return activity.date === date && activity.active;
    });

    if (reserveActivity.length > 0) {
      return reserveActivity;
    }
  }

  return undefined;
}

export const reserveOptionsDateFilter = (date:string, planSelectFormDate: PlanSelectFormData) => {

  if (planSelectFormDate.options.length > 0) {
    const reserveActivity = planSelectFormDate.options.filter((activity) => {
      return activity.date === date && activity.active;
    });

    if (reserveActivity.length > 0) {
      return reserveActivity;
    }
  }

  return undefined;
}

export const reserveCarRentalDateFilter = (date:string, planSelectFormDate: PlanSelectFormData) => {

  if (planSelectFormDate.carRental.length > 0) {
    const reserveCarRental = planSelectFormDate.carRental.filter((carRental) => {
      return carRental.departure_date === date || carRental.return_date === date;
    });

    if (reserveCarRental.length > 0) {
      return reserveCarRental;
    }
  }

  return undefined;
}

export const reserveFlightDateFilter = (date:string, planSelectFormDate: PlanSelectFormData) => {

  if (planSelectFormDate.flight.length > 0) {
    const reserveFlight = planSelectFormDate.flight.filter((flight) => {
      return flight.useDate === dateFormat(date, 'YYYYMMDD');
    });

    if (reserveFlight.length > 0) {
      return reserveFlight;
    }
  }

  return undefined;
}

export const getCompleteScheduleData = (planSelectFormData: PlanSelectFormData) => {
  const schedule:planSelectScheduleCompleteList[] = [];
  const rangeDates = rangeDate(planSelectFormData.searchParams.start, planSelectFormData.searchParams.end);

  if (rangeDates.length > 0) {
    devLog("planSelectFormData", planSelectFormData);
    rangeDates.forEach((date) => {
      const planData: planSelectScheduleCompleteSetData = {
        hotel : reserveHotelDateFilter(date, planSelectFormData),
        activity : reserveActivityDateFilter(date, planSelectFormData),
        flight : reserveFlightDateFilter(date, planSelectFormData),
        options : reserveOptionsDateFilter(date, planSelectFormData),
        carRental : reserveCarRentalDateFilter(date, planSelectFormData),
      }
      const term = (rangeDates[0] === date) ? "first" : (rangeDates[rangeDates.length - 1] === date) ? "last" : "middle";
      const dataOrder = getScheduleDataOrder(term);
      devLog("planData", planData);
      const rowSpan = getCompleteScheduleRowSpan(planData);
      const first = getCompleteScheduleFirstData(planData, rowSpan, dataOrder);
      const rows = getCompleteScheduleRowsData(planData, first, dataOrder);

      schedule.push({
        date: date,
        first: first,
        rows: rows,
        isLast: term === "last",
      })
    });
  }

  return schedule;
}

export const getCompleteScheduleRowSpan = (planData:{[key:string]: any}) => {
  let rowSpan = 0;
  if (planData.flight !== undefined && planData.flight?.length > 0) {
    planData.flight.forEach((flightData: any) => {
      rowSpan += flightData.entity.length ?? 1;
    });
  }
  if (planData.hotel !== undefined && planData.hotel?.length > 0) {
    rowSpan += planData.hotel.length;
  }
  if (planData.activity !== undefined && planData.activity?.length > 0) {
    rowSpan += planData.activity.length;
  }
  if (planData.options !== undefined && planData.options?.length > 0) {
    rowSpan += planData.options.length;
  }
  if (planData.carRental !== undefined && planData.carRental?.length > 0) {
    rowSpan += planData.carRental.length;
  }

  return rowSpan;
}

export const getCompleteScheduleFirstData = (planData:planSelectScheduleCompleteSetData, rowSpan:number, dataOrder:Array<string>) => {
  const firstData:planSelectScheduleCompleteList["first"] = {
    type: "",
    rowSpan: rowSpan,
    data: undefined
  }

  for (let i = 0; i < dataOrder.length; i++) {
    if (firstData.data !== undefined) {
      break;
    }
    const type: keyof planSelectScheduleCompleteSetData = dataOrder[i] as keyof planSelectScheduleCompleteSetData;
    const data = planData[type] ?? [];
    if (data.length > 0) {
      firstData.type = type;
      firstData.data = data[0];
    }
  }
  return firstData;
}

export const getCompleteScheduleRowsData = (planDataList:planSelectScheduleCompleteSetData, first: planSelectScheduleCompleteList["first"], dataOrder: Array<string>) => {
  if (first === undefined) {
    return [];
  }
  const rows: planSelectScheduleCompleteList["rows"] = [];
  
  dataOrder.forEach(orderType => {
    const type: keyof planSelectScheduleCompleteSetData = orderType as keyof planSelectScheduleCompleteSetData;
    const planData = planDataList[type] ?? undefined;
    if (planData !== undefined && planData.length > 0) {
      planData.forEach((data, index) => {
        if (type === "flight") {
          // フライトの場合
          const flightList: PlanSelectFromFlightType = _.cloneDeep(data as PlanSelectFromFlightType);
          const isConnectingFlight = flightList.entity.length > 1; // 乗り継ぎ便か
          flightList.entity = flightList.entity.map((flightData: any, i: number) => {
            if (isConnectingFlight) {
              flightData.num = i + 1; // 乗り継ぎ便の場合に表示する番号
            }
            return (first.type === "flight" && index === 0 && i === 0) ? false : flightData;
          }).filter((result: any) => result);
          // データ追加
          rows.push({
            type: type,
            data: flightList
          });
        } else if (first.type !== type || index !== 0) {
          // フライト以外
          rows.push({
            type: type,
            data: data
          });
        }
      })
    }
  });
  return rows;
}

/**
 * 表示する項目の並び順を取得する
 * @param term 
 * @returns 
 */
const getScheduleDataOrder = (term: string) => {
  // 中間日と最終日は同じ並び
  let dataOrder = ["activity", "options", "carRental", "hotel", "flight"];
  if (term === "first") {
    dataOrder = ["flight", "activity", "options", "carRental", "hotel"];
  }
  return dataOrder;
}
