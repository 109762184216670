import React, {useEffect, useState} from "react";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//パーツをインポート
import  {
  PartsStep,
  PartsCancellationChargeText
} from "./parts"

import {
  SectionDetail,
  SectionCheck,
} from "./section/PlanSelectComplete"
import {PlanSelectFormData} from "../types/planSelectForm";
import {useLocation, useNavigate} from "react-router-dom";
import {getPriceRequestParams} from "../utils/price";
import {useTravelPrice} from "../utils/useTravelPrice";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SectionTravelPrice from "./section/SectionTravelPrice";
import {isPlanSelectComplete} from "../utils/errors";
import Page404 from "./Page404";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {getBackSearchURL} from "../utils/search";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";

// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const PlanSelectComplete: React.FC = () => {

  const classesForLoading = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const planData = location.state as { data: PlanSelectFormData } ?? {data: defaultPlanSelectForm};
  const [planSelectFormData] = useState<{ data: PlanSelectFormData }>(planData);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  /**
   * お客様情報入力ページに遷移
   */
  const handleMove = () => {
    if(planSelectFormData && planSelectFormData.data.hotel.length > 0 && planSelectFormData.data.hotel[0].plan !== undefined) {
      navigate(ROUTES.Form1.replace(":lang", i18n.language), { state: {
          data: planSelectFormData.data
        } })
    }
  }

  const priceParams = getPriceRequestParams(planSelectFormData.data,undefined, undefined, i18n.language);
  const [ priceData, { isLoading, isError } ] = useTravelPrice({data: priceParams});
  useEffect(() => {
    if(priceData?.status === 200 && !isError) {
      planSelectFormData.data.travelPrice = priceData.result.insurance.insuranceCancelAmount;
    } else if ((priceData && priceData?.status !== 200) || isError) {
      alert(t("PlanSelectComplete.料金処理でエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。"));
      // alert("金額處理過程發生了系統上的錯誤，請過段時間再行設定您的行程方案。");
      window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id, i18n.language);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceData, isError])


  if (!planSelectFormData || isPlanSelectComplete(planSelectFormData.data)) {
    return (<Page404></Page404>);
  }

  return (
    <MainTemplate title={t("Steps.旅程の最終確認")} logo={planSelectFormData.data.logo}  sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId} supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial} planSelectFormData={planSelectFormData.data}>
      {/* ローディングの表示(APIのレスポンス待ち) */}
      <Backdrop className={classesForLoading.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

        <div className="wrap-content-900-no_padding">
          <PartsStep activeStep={3}></PartsStep>
        </div>

        <div className="wrap-content-900">
          {/* <!--アラート--> */}
          <div className="box-border-blue small icon-alert mt-16-md">
            <p className="fz-15 fz-16-md c-blue fw-b">
              {t("PlanSelectComplete.選択した内容、旅行代金をご確認ください")}
              {/* 確認您的旅行商品的內容及費用 */}
            </p>
          </div>

          {/* /行程表 */}
          <SectionDetail planSelectFormData={planSelectFormData.data}></SectionDetail>

          {/* 料金内訳 */}
          <SectionTravelPrice priceData={priceData}></SectionTravelPrice>

          {/* キャンセル料について */}
          <PartsCancellationChargeText />

          {/* 確認事項 */}
          <SectionCheck />

          {/* <!--次へ進む--> */}
          <div className="mt-48 mt-64-md">
            <ul className="form-step_button mt-24">
              <li className="form-step_button-next">
                {/* Form1へ進む */}
                <button
                  type="button"
                  className="button-large-red"
                  onClick={handleMove}
                  style={{
                    display: "block",
                    border: "none",
                    width: "100%",
                  }}
                >
                  {t("PlanSelectComplete.お客様情報入力へ進む")}
                  {/* 前往下一頁  填寫旅客資訊 */}
                </button>
              </li>
              <li className="form-step_button-back">
                <button
                  className="button-small-border_gray_blue button-medium-md"
                  onClick={() => {
                    navigate(ROUTES.PlanSelectConfirm.replace(":lang", i18n.language), { state: {
                        data: planSelectFormData.data
                      } })
                  }}
                >
                  <i className="icon-left_arrow mr-5"></i>
                  {t("PlanSelectComplete.旅行日程の確認・変更へ戻る")}
                  {/* 返回上一頁  確認/變更 旅遊行程 */}
                </button>
              </li>
            </ul>
          </div>
        </div>
           
    </MainTemplate>
  );
};

export default PlanSelectComplete;
