import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { useReserveCancel } from "../utils/useReserveCancel";
import {clear as clearReservationCancel, save} from "../redux/reservationCancelSlice";

//モーダルをインポート
// import Modal from "@material-ui/core/Modal";
import { 
  CancelIndividualModal,
  CancelAllModal,
} from "./modal"

import MainTemplate from "./MainTemplate";

//css
import "../scss/common.scss";
import "../scss/component.scss";

import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from "@material-ui/core";
import {reserveCancelDetailResponce} from "../types/reserveCancel";
import {dateFormatWithLocale, toCircled, toDisplayTime} from "../utils/convert";
import {getCarRentalAnshinOptionItem, getCarRentalOfficeLabel} from "../utils/carRental";
import dayjs from "dayjs";
import {hasReservePartCancel} from "../utils/reserve";
import {useReserveInfo} from "../utils/useReserveInfo";
import {devLog} from "../utils/errors";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import { LANGUAGES } from "../constants/languages";


// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReservationCancelFee: React.FC = () => {

  const classesForLoading = useStyles();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // reduxに保存されているデータを取得
  const savedReservationCancelState = useSelector((state: RootState) => state.reservationCancel);

  // ログイン画面で取得した予約情報
  const reservation = savedReservationCancelState.reservation as reserveCancelDetailResponce;
  
  // 予約キャンセル
  const [ cancelReserve, cancelResult, { isCancelLoading } ] = useReserveCancel();
  const today = dayjs().format('MM月DD日');
  const [optionsCancel, setOptionsCancel] = React.useState<{id: string}[]>([]);
  const [carRentalCancel, setCarRentalCancel] = React.useState<{id: string}[]>([]);
  const [cancelPartTotalPrice, setCancelPartTotalPrice] = React.useState(0);

  // 予約情報
  const [ fetchReserveInfo, reserveInfo, { isLoading } ] = useReserveInfo();
  
  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  // //モーダル初期表示ページを設定
  // const [step, setStep] = React.useState(1);
  // const stepHandle = (step: number) => {
  //   setStep(step)
  // }
  
  // モーダルのステータスを追加する
  const [cancelIndividualModal, setCancelIndividualModal] = React.useState(false);
  const [cancelAllModal, setCancelAllModal] = React.useState(false);

  //ラジオボタンでの表示内容を制御
  const [RadioStatus, setRadioStatus] = React.useState("CancelIndividual");
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioStatus(event.target.value);
  };

  // 15分経過でログイン画面に戻す
  const [time, setTime] = useState(0);

  const timeoutMinutes = 15;

  const intervalSeconds = 5;

  useEffect(() => {
    const id = setInterval(() => {
      setTime(t => t + 1);
    }, intervalSeconds * 1000); // 5秒ごとに更新

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // 15分で終了
    if (time >= (timeoutMinutes * 60 / intervalSeconds)) {
      // redux内のデータをクリア
      dispatch(clearReservationCancel());
      navigate(ROUTES.ReservationCancelLogin.replace(":lang", i18n.language), { replace: true });
    }

    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ time, dispatch, navigate ])

  useEffect(() => {
    if (cancelResult.message == null) {
      return;
    }
    if (cancelResult.status !== 200) {
      alert(t("ReservationCancelFee.キャンセル処理に失敗しました。"));
      // alert("取消時發生系統錯誤。");
      return;
    }
    if (reservation && reservation.data && reservation.data.bookingData) {
      fetchReserveInfo({
        bookingId: reservation.data.bookingData.bookingId,
        bookingEmail: reservation.data.bookingData.applicant.email
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ cancelResult ]);

  useEffect(() => {

    if (!reserveInfo ||  (reserveInfo && reserveInfo.data.message == null)) {
      return;
    }

    if (reserveInfo.status !== 200) {
      alert(t("ReservationCancelLogin.ログインに失敗しました。"));
      // alert("登入失敗。");
      return;
    }
    devLog("reserveInfo", reserveInfo);
    dispatch(save({ reservation: reserveInfo }));

    navigate(ROUTES.ReservationCancelDetail.replace(":lang", i18n.language));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveInfo])

  // 予約情報が無ければ or キャンセル済みならログイン画面にリダイレクト
  if (!reservation.data || reservation.data.bookingData == null || reservation.data.bookingData.status !== "reserved") {
    const path = window.location.pathname;

    const languageCode = path.startsWith(`/${LANGUAGES.JA}/`) ? LANGUAGES.JA : LANGUAGES.TW;

    return (
      <Navigate
        to={ROUTES.ReservationCancelLogin.replace(":lang", languageCode)}
        replace
      />
    );
  }

  const status: string = reservation.data.bookingData.status ?? "reserved";


  // モーダルの閉じるボタンをクリックした
  const handleCloseModal = () => {
    setCancelIndividualModal(false);
    setCancelAllModal(false);
  }


  /**
   * 個別キャンセル実行
   */
  // 「キャンセル内容を確認する」のボタンをクリックした(=個別キャンセルのモーダルを開く)
  const handleOpenIndividualModal  = () => {
    setCancelIndividualModal(true);
  }

  // 個別キャンセルのモーダル内でキャンセルボタンをクリックした
  const handleCancelIndividual = () => {
    setCancelIndividualModal(false);

    const bookingId = reservation.data.bookingData.bookingId;

    const bookingEmail = reservation.data.bookingData.applicant.email;

    // todo: チェックされた追加オプションを取得する

    // キャンセルAPI呼び出し(結果はuseEffectで受け取る)
    if(carRentalCancel.length > 0 || optionsCancel.length > 0) {
      cancelReserve({
        cancelType: "part",
        bookingId: bookingId,
        bookingEmail: bookingEmail,
        carRental: carRentalCancel,
        option: optionsCancel,
      });
    } else {
      alert(t("ReservationCancelFee.キャンセルする追加オプションまたはレンタカーを選択してください。"));
      // alert("請選擇欲取消的追加項目，或是選擇租車。");
    }
  }

  /**
   * 追加オプションチェックボックス イベント
   * @param event
   */
  const onChangeOptions = (event:React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) {
      optionsCancel.push({
        id: event.target.value
      });
      setOptionsCancel([...optionsCancel])
    } else {
      if (optionsCancel.length > 0) {
        const removeIndex = optionsCancel.findIndex((option) => {
          return option.id === event.target.value;
        })
        
        if (removeIndex >= 0){
          optionsCancel.splice(removeIndex, 1);
          setOptionsCancel([...optionsCancel])
        }
      }
    }
    updateCancelPartTotalPrice(carRentalCancel, optionsCancel);
  }

  /**
   * レンタカーチェック インベント
   * @param event
   */
  const onChangeCarRental = (event:React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) {
      carRentalCancel.push({
        id: event.target.value
      });
      setCarRentalCancel([...carRentalCancel])
    } else {
      if (carRentalCancel.length > 0) {
        const removeIndex = carRentalCancel.findIndex((carRental) => {
          return carRental.id === event.target.value;
        })
        
        if (removeIndex >= 0){
          carRentalCancel.splice(removeIndex, 1);
          setCarRentalCancel([...carRentalCancel])
        }
      }
    }
    updateCancelPartTotalPrice(carRentalCancel, optionsCancel);
  }
  
  const updateCancelPartTotalPrice = (updateCarRentalCancel: {id: string}[], updateOptionCancel: {id: string}[]) => {
    let total = 0;
    if (updateCarRentalCancel.length > 0) {
      reservation.cancelData.carRental.forEach((carRental) => {
        if (updateCarRentalCancel.find((checkCarRental) => checkCarRental.id === carRental.id)) {
          total += carRental.cancelPrice;
        }
      })
    }
 
    if (updateOptionCancel.length > 0) {
      reservation.cancelData.options.forEach((option) => {
        if (updateOptionCancel.find((checkOption) => checkOption.id === option.id)) {
          total += option.cancelPrice;
        }
      })
    }

    setCancelPartTotalPrice(total);
  }


  /**
   * 全体キャンセル
   */
  // 「旅行全体をキャンセルする」のボタンをクリックした(=全体キャンセルのモーダルを開く)
  const handleOpenAllModal  = () => {
    setCancelAllModal(true);
  }

  // 全体キャンセルのモーダル内でキャンセルボタンをクリックした
  const handleCancelAll = () => {
    setCancelAllModal(!cancelAllModal)

    const bookingId = reservation.data.bookingData.bookingId;

    const bookingEmail = reservation.data.bookingData.applicant.email;

    // キャンセルAPI呼び出し(結果はuseEffectで受け取る)
    cancelReserve({
      cancelType: "all",
      bookingId: bookingId,
      bookingEmail: bookingEmail,
    });
  }

  // 戻るボタンをクリックした
  const handleGoBack = () => {
    navigate(ROUTES.ReservationCancelDetail.replace(":lang", i18n.language));
  }


  return (
    <MainTemplate
      title={t("ReservationCancelFee.キャンセル料金のご案内")}
      logo={undefined} 
      sellerId={undefined} 
      supportDial={undefined}
      planSelectFormData={undefined}
    >

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isCancelLoading || isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>


        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("ReservationCancelFee.キャンセル料金のご案内")}</h1>
            {/* <h1 className="l-page-content-upper_navi-heading">取消費用的詳細內容</h1> */}
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <div className="pt-24 pt-50-md">

              {/* <!--アラート--> */}
              <div className="box-alert-red-small icon-alert">
                <p className="fz-14 fz-16-md fw-b c-red">
                  {t("ReservationCancelFee.本日でのキャンセルには以下の料金が発生いたしますので内容をご確認ください。")}
                  {/* 今日取消會產生取消費用，詳細內容請從下方確認。 */}
                </p>
              </div>

              {/* キャンセル内容のご選択 */}
              <h2 className="heading-2">{t("ReservationCancelFee.キャンセル内容のご選択")}</h2>
              {/* <h2 className="heading-2">請選擇所希望的取消內容</h2> */}
              <div className="card">
                <p className="mb-16">{t("ReservationCancelFee.キャンセルを希望される方は、以下いずれかをご選択ください。")}</p>
                {/* <p className="mb-16">若欲取消，請從下方勾選所希望的選項。</p> */}
                <ul className="d-f-md">
                  <li key="CancelIndividual" className="mb-8 mb-0-md">
                    <input
                      type="radio"
                      name="AccordionRadioStatus01"
                      id="AccordionRadioStatus01_view"
                      value="CancelIndividual"
                      checked={RadioStatus === "CancelIndividual"}
                      onChange={handleChangeRadio}
                    />
                    <label htmlFor="AccordionRadioStatus01_view">{t("ReservationCancelFee.追加オプションの個別キャンセル")}</label>
                    {/* <label htmlFor="AccordionRadioStatus01_view">個別取消已追加的選購項目</label> */}
                  </li>
                  <li key="CancelAll" className="ml-20-md">
                    <input
                      type="radio"
                      name="AccordionRadioStatus01"
                      id="AccordionRadioStatus01_hidden"
                      value="CancelAll"
                      checked={RadioStatus === "CancelAll"}
                      onChange={handleChangeRadio}
                    />
                    <label htmlFor="AccordionRadioStatus01_hidden">{t("ReservationCancelFee.ご予約全体のキャンセル")}</label>
                    {/* <label htmlFor="AccordionRadioStatus01_hidden">取消所有的旅行內容</label> */}
                  </li>
                </ul>
              </div>

              {/* 追加オプション個別キャンセル */}
              <div className={"searchbox-content-item-group-block" + (RadioStatus === "CancelIndividual" ? "":" d-n")}>
                <h2 className="heading-2">{t("ReservationCancelFee.タイトル：追加オプションの個別キャンセル")}</h2>
                {/* <h2 className="heading-2">加購項目個別取消</h2> */}
                <p className="mb-24">{t("ReservationCancelFee.追加いただいたオプション、レンタカーが個別にキャンセル可能です。")}</p>
                {/* <p className="mb-24">您所加購的體驗活動及租車，可以進行個別的取消。</p> */}


                {hasReservePartCancel(reservation) ?
                <>
                  <div className="part-group">
                    {reservation.cancelData.carRental?.map((row, rId) => (
                      <div className="part-group-item" key={rId}>
                        <div className="card-small">
                          <div className="box-cancel_option">
                            <div className="box-cancel_option-content">
                              <div className="box-separate">
                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <input
                                      id={"cancel_retacar_" + rId}
                                      type="checkbox"
                                      value={row.id}
                                      className="form-input-checkbox-icon"
                                      onChange={(e) => onChangeCarRental(e)}
                                    />
                                    <span className="form-input-checkbox-text text-large">
                                  <label htmlFor={"cancel_retacar_" + rId}>
                                    {t("CarRental.レンタカー")}{toCircled(row.data.order)}
                                    {/* 租車{toCircled(row.data.order)} */}
                                  </label>
                                </span>
                                  </div>
                                </div>

                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("CarRental.貸出")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">取車</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {dateFormatWithLocale(row.data.departure_date, "YYYY年MM月DD日（ddd）", i18n.language)}／{getCarRentalOfficeLabel(row.data.detail[0].departure_office_code)}
                                      </dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("CarRental.返却")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">還車</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {dateFormatWithLocale(row.data.return_date, "YYYY年MM月DD日（ddd）", i18n.language)}／{getCarRentalOfficeLabel(row.data.detail[0].return_office_code)}
                                      </dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("CarRental.車種")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">車款等級</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {row.data.car_detail.map((carDetail, carDetailIndex) => (
                                          <Fragment key={carDetailIndex}>
                                            {carDetail.car_model}{carDetail.num > 1 && (<> × {carDetail.num.toString()}</>)}
                                          </Fragment>
                                        ))}
                                      </dd>
                                    </dl>

                                    {(row.data.count_option.baby_seat > 0 || row.data.count_option.child_seat > 0 || row.data.count_option.junior_seat > 0 || row.data.car_detail[0]?.count_option?.anshin?.used || row.data.car_detail[0]?.count_option?.anshin_premium?.used) && (
                                      <>
                                        <dl className="box-cancel_option-content-dl">
                                          <dt className="box-cancel_option-content-dl-dt">{t("CarRental.オプション")}</dt>
                                          {/* <dt className="box-cancel_option-content-dl-dt">追加項目</dt> */}
                                          <dd className="box-cancel_option-content-dl-dd">
                                            {row.data.car_detail[0]?.count_option?.anshin?.used && (
                                              <>
                                                {t('CarRental.' + getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin.option_name, 'label', i18n.language))}　
                                              </>
                                            )}
                                            {row.data.car_detail[0]?.count_option?.anshin_premium?.used && (
                                              <>
                                                {t('CarRental.' + getCarRentalAnshinOptionItem(row.data.car_detail[0].count_option.anshin_premium.option_name, 'label', i18n.language))}　
                                              </>
                                            )}
                                            
                                            {row.data.count_option.baby_seat > 0 && (
                                              <>
                                                {t("CarRental.乳幼児ベビーシート")} × {row.data.count_option.baby_seat}　
                                                {/* 嬰兒座椅 × {row.data.count_option.baby_seat}&nbsp; */}
                                              </>
                                            )}
                                            {row.data.count_option.child_seat > 0 && (
                                              <>
                                                {t("CarRental.幼児用チャイルドシート")} × {row.data.count_option.child_seat}　
                                                {/* 幼兒座椅 × {row.data.count_option.child_seat}&nbsp; */}
                                              </>
                                            )}
                                            {row.data.count_option.junior_seat > 0 && (
                                              <>
                                                {t("CarRental.学童用ジュニアシート")} × {row.data.count_option.junior_seat}&nbsp;
                                                {/* 兒童用座椅 × {row.data.count_option.junior_seat} */}
                                              </>
                                            )}
                                          </dd>
                                        </dl>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-cancel_option-status">
                              <p className="fz-12 fz-14-md ta-c-md mb-8-md">
                                {t("ReservationCancelFee.キャンセル料")}<br className="d-n d-b-md" />（{today}{t("ReservationCancelFee.時点")}）
                              </p>
                              {/* <p className="fz-12 fz-14-md ta-c-md mb-8-md">取消費用<br className="d-n d-b-md" />（{today}現在）</p> */}
                              <p className="fz-20 fw-b c-red">
                                {row.cancelPrice.toLocaleString()}
                                <span className="fz-12">{t("Common.円")}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {reservation.cancelData.options?.map((row, oId) => (
                      <div className="part-group-item" key={oId}>
                        <div className="card-small">
                          <div className="box-cancel_option">
                            <div className="box-cancel_option-content">
                              <div className="box-separate">
                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <input
                                      id={"cancel_option_" + oId}
                                      type="checkbox"
                                      value={row.id}
                                      className="form-input-checkbox-icon"
                                      onChange={(e) => onChangeOptions(e)}
                                    />
                                    <span className="form-input-checkbox-text text-large">
                                  <label htmlFor={"cancel_option_" + oId}>
                                    {t("SectionDetail.追加オプション")}{toCircled(row.data.order)}
                                    {/* 追加項目{toCircled(row.data.order)} */}
                                  </label>
                                </span>
                                  </div>
                                </div>

                                <div className="box-separate-item">
                                  <div className="box-separate-item-block">
                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("ScheduleDetailModal.ご利用日")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">利用日期</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {dateFormatWithLocale(row.data.use_date, "YYYY年MM月DD日（ddd）", i18n.language)} {toDisplayTime(row.data.use_time)}
                                      </dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("ReservationCancelDetailSectionDetail.プラン名")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">方案名稱</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">{row.data.planName}</dd>
                                    </dl>

                                    <dl className="box-cancel_option-content-dl">
                                      <dt className="box-cancel_option-content-dl-dt">{t("ReservationCancelFee.申込内容")}</dt>
                                      {/* <dt className="box-cancel_option-content-dl-dt">預約內容</dt> */}
                                      <dd className="box-cancel_option-content-dl-dd">
                                        {row.data.price_type_detail.map((price_type_detail, detailIndex) => (
                                          <Fragment key={detailIndex}>
                                            {price_type_detail.name} × {price_type_detail.num}　
                                          </Fragment>
                                        ))}
                                      </dd>
                                    </dl>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box-cancel_option-status">
                              <p className="fz-12 fz-14-md ta-c-md mb-8-md">
                                {t("ReservationCancelFee.キャンセル料")}<br className="d-n d-b-md" />（{today}{t("ReservationCancelFee.時点")}）
                              </p>
                              {/* <p className="fz-12 fz-14-md ta-c-md mb-8-md">取消費用<br className="d-n d-b-md" />（{today}現在）</p> */}
                              <p className="fz-20 fw-b c-red">
                                {row.cancelPrice.toLocaleString()}
                                <span className="fz-12">{t("Common.円")}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="box-total box mt-32 mt-50-md">
                    <span className="box-total-heading small">{t("ReservationCancelDetailSectionTravelPrice.キャンセル代金")} {t("ReservationCancelFee.合計")}</span>
                    {/* <span className="box-total-heading small">取消費用 合計</span> */}
                    <span className="box-total-pay small">
                      {cancelPartTotalPrice.toLocaleString()}
                      <span className="c-red fz-14 fz-16-md">{t("Common.円")}(税込)</span>
                    </span>
                  </div>

                  {/* <!--次へ進む--> */}
                  <div className="mt-48 mt-64-mt">
                    <ul className="form-step_button mt-24">

                      {/* ※追加オプションがない場合は確定ボタンはなし */}
                      <li key="cancel-button" className="form-step_button-next">
                        <button onClick={handleOpenIndividualModal} className="button-large-red w-100per">
                          {t("ReservationCancelFee.キャンセル内容を確定する")}
                          {/* 請確定取消內容 */}
                        </button>
                      </li>

                      <li key="cancel-section-reserve-back" className="form-step_button-back">
                        <button
                          className="button-small-border_gray_blue button-medium-md"
                          onClick={handleGoBack}
                        >
                          <i className="icon-left_arrow mr-5"></i>
                          {t("ReservationCancelFee.予約情報詳細に戻る")}
                          {/* 返回預約的詳細內容 */}
                        </button>
                      </li>
                    </ul>
                  </div>
                </>:
                  <>
                    {/* ※追加オプションがない場合のみ表示 */}
                    <div className="box-border-red mb-32">
                      <p className="ta-c c-red fz-16 fw-b">
                        {t("ReservationCancelFee.キャンセル可能な")}<br className="d-n-md" />{t("ReservationCancelFee.追加オプションはありません。")}
                      </p>
                      {/* <p className="ta-c c-red fz-16 fw-b">沒有可取消的<br className="d-n-md" />追加選購項目</p> */}
                    </div>
                  </>
                }
              </div>

               {/* 追加オプション全体キャンセル */}
               <div className={"searchbox-content-item-group-block" + (RadioStatus === "CancelAll" ? "":" d-n")}>

                 {status === "reserved" ?
                   <>
                     {/* ご予約全体のキャンセル */}
                     <h2 className="heading-2 mb-16 mb-24-md">{t("ReservationCancelFee.タイトル：ご予約全体のキャンセル")}</h2>
                     {/* <h2 className="heading-2 mb-16 mb-24-md">取消所有的旅行內容</h2> */}
                     <div className="box-total-short">
                       <div className="box-total-short-sub_total">
                         <div className="box-total-short-sub_total-item">
                           <div className="fz-14 fz-16-md">{t("SectionTravelPrice.基本旅行代金")}</div>
                           {/* <div className="fz-14 fz-16-md">行程基本費用</div> */}
                           <div className="fz-20 fz-22-md fw-b c-red">
                            {reservation.cancelData.basicTotal.toLocaleString()}<span className="fz-10 fz-14-md">{t("Common.円")}</span>
                          </div>
                         </div>

                         {reservation.cancelData.carRental.length > 0 && (
                           <>
                             <div className="box-total-short-sub_total-item">
                               <div className="fz-14 fz-16-md">{t("CarRental.レンタカー")}</div>
                               {/* <div className="fz-14 fz-16-md">租車</div> */}
                               <div className="fz-20 fz-22-md fw-b c-red">
                                {reservation.cancelData.carRentalTotal.toLocaleString()}<span className="fz-10 fz-14-md">{t("Common.円")}</span>
                              </div>
                             </div>
                           </>
                         )}

                         {reservation.cancelData.options.length > 0 && (
                           <>
                             <div className="box-total-short-sub_total-item">
                               <div className="fz-14 fz-16-md">{t("SectionDetail.追加オプション")}</div>
                               {/* <div className="fz-14 fz-16-md">追加項目</div> */}
                               <div className="fz-20 fz-22-md fw-b c-red">
                                {reservation.cancelData.optionTotal.toLocaleString()}<span className="fz-10 fz-14-md">{t("Common.円")}</span>
                              </div>
                             </div>
                           </>
                         )}
                       </div>
                       <div className="box-total-short-total">
                         <span className="box-total-short-total-heading">{t("ReservationCancelDetailSectionTravelPrice.キャンセル代金")} {t("ReservationCancelFee.合計")}</span>
                         {/* <span className="box-total-short-total-heading">取消費用 合計</span> */}
                         <span className="box-total-short-total-pay">
                        {Number(reservation.cancelData.cancelTotal).toLocaleString()}
                           <span className="c-red fz-16">{t("Common.円")}(税込)</span>
                    </span>
                       </div>
                     </div>

                     {/* <!--次へ進む--> */}
                     <div className="mt-48 mt-64-mt">
                       <ul className="form-step_button mt-24">

                         {/* ※追加オプションがない場合は確定ボタンはなし */}
                         <li key="travel-cancel-button" className="form-step_button-next">
                           <button onClick={handleOpenAllModal} className="button-large-red w-100per">
                            {t("ReservationCancelFee.旅行全体をキャンセルする")}
                             {/* 取消所有行程 */}
                           </button>
                         </li>

                         <li key="cancel-fee-reserve-back" className="form-step_button-back">
                           <button
                             className="button-small-border_gray_blue button-medium-md"
                             onClick={handleGoBack}
                           >
                             <i className="icon-left_arrow mr-5"></i>
                             {t("ReservationCancelFee.予約情報詳細に戻る")}
                             {/* 返回預約的詳細內容 */}
                           </button>
                         </li>
                       </ul>
                     </div>
                   </>
                   :
                   <>
                     {/* ※キャンセル済みの場合のみ表示 */}
                     <div className="box-border-red mb-32">
                       <p className="ta-c c-red fz-16 fw-b">{t("ReservationCancelFee.既に全体キャンセル済みです。")}</p>
                       {/* <p className="ta-c c-red fz-16 fw-b">已取消全部內容。</p> */}
                     </div>
                   </>
                 }

              </div>
            </div>
          </div>
        </div>


        {/* モーダル：個別キャンセル */}
        <CancelIndividualModal
          isShow={cancelIndividualModal}
          onClickCancel={handleCancelIndividual}
          onClickClose={handleCloseModal}
        />

        {/* モーダル：全体キャンセル */}
        <CancelAllModal 
          isShow={cancelAllModal} 
          onClickCancel={handleCancelAll} 
          onClickClose={handleCloseModal} 
        />

    </MainTemplate>
  );
};

export default ReservationCancelFee;
