import React, { Fragment } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {getOptionsInputFields, getPlanInputFields} from "../../../utils/form2";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {toCircled} from "../../../utils/convert";
import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

//input
import { InputText } from "./../../input/InputText";
import { InputRadio } from "./../../input/InputRadio";
import { InputsForm2 } from "../../../types/InputsForm2";
import {InputCheckbox} from "../../input/InputCheckbox";
import {InputTextarea} from "../../input/InputTextarea";
import {Select} from "../../input/Select";

// sass
import "./../../../scss/common.scss";
import "./../../../scss/component.scss";


type Props = {
  planSelectFormData: PlanSelectFormData,
  control: any;
  errors: FieldErrors;
  getValues: any;
  register: UseFormRegister<InputsForm2>;
  toCircledNumberLabel: (index: number) => string;
};

const SectionPlanCheck: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation();

  const planValues = getPlanInputFields(props.planSelectFormData, i18n.language);
  const optionValues = getOptionsInputFields(props.planSelectFormData, i18n.language);

  if (planValues.length === 0 && optionValues.length === 0) {
    return <Fragment />
  }

  // todo: コンポーネント作って使い回す

  return (
    <>
      {/* <!--プラン別確認事項の入力--> */}
      <section>
        {/* <h2 className="heading-2">請輸入各體驗活動的確認事項</h2> */}
        <h2 className="heading-2">{t("SectionPlanCheck.プラン別確認事項の入力")}</h2>
        <div className="pb-10">
          {planValues.length > 0 ?
            <>
              {planValues.map((planData, pIndex) => (
                <Accordion key={pIndex} className="accordion">
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id={"plan-" + pIndex}
                    className="accordion-heading"
                  >
                    {t("SectionPlanCheck.基本プラン")}{toCircled(pIndex + 1)}
                    {/* 基本方案{toCircled(pIndex + 1)} */}
                  </AccordionSummary>
                  <AccordionDetails className="accordion-content">
                    <div className="fx-1">
                      <p className="mb-16">
                        {t("ScheduleDetailModal.ご利用日")}：{planData.useDate}
                        {/* 利用日期：{planData.useDate} */}
                      </p>
                      <p className="mb-40 fw-b">
                        {planData.planName}
                      </p>
                      <div className="box-separator_bottom-large">
                        {Object.keys(planData.fields).length > 0 ?
                        <>
                          {
                            Object.keys(planData.fields).map((filedIndex, fIndex) => (
                              <Fragment key={fIndex}>
                                {planData.fields[filedIndex].field_type === "1" ? 
                                <>
                                  <div
                                    id={"plans."+planData.planCode+".fields."+filedIndex}
                                    className="box-separator_bottom-large-item"
                                  >
                                    <div className="form-content">
                                      <div className="form-content-heading">
                                        <h3 className="heading-3-small">
                                          {planData.fields[filedIndex].field_name}
                                          {planData.fields[filedIndex].required_type && (
                                            <span className="label-required ml-5">
                                              必須
                                            </span>
                                          )}
                                        </h3>
                                      </div>
                                      <div className="form-birthday">
                                        <div className="form-birthday-year">
                                          <div>
                                            <InputText
                                              name={"plans."+planData.planCode+".fields."+filedIndex}
                                              placeholder=""
                                              register={props.register}
                                              options={{ required: planData.fields[filedIndex].required_type }}
                                              error={props.errors.plans?.[planData.planCode]?.fields?.[filedIndex]}
                                            ></InputText>
                                            {props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] &&
                                              props.errors.plans?.[planData.planCode]?.fields?.[filedIndex].type ===
                                              "required" && (
                                                <p className="form-error_text">
                                                  {/* {planData.fields[filedIndex].field_name}をご入力ください。 */}
                                                  {/* 請填入{planData.fields[filedIndex].field_name}。 */}
                                                  {t("SectionPlanCheck.fieldをご入力ください。", { field: planData.fields[filedIndex].field_name })}
                                                </p>
                                              )}
                                          </div>
                                          <p className="mr-10 ml-10 color-dark_blue">
                                            {planData.fields[filedIndex].unit}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </> 
                                : planData.fields[filedIndex].field_type === "2"?
                                <>
                                  <div
                                    id={"plans."+planData.planCode+".fields."+filedIndex}
                                    className="box-separator_bottom-large-item"
                                  >
                                    <div className="form-content">
                                      <div className="form-content-heading">
                                        <h3 className="heading-3-small">
                                          {planData.fields[filedIndex].field_name}
                                          {planData.fields[filedIndex].required_type && (
                                            <span className="label-required ml-5">
                                            必須
                                          </span>
                                          )}
                                        </h3>
                                      </div>
                                      <div className="form-birthday">
                                        <div className="form-birthday-year">
                                          <div>
                                            <InputTextarea
                                              name={"plans."+planData.planCode+".fields."+filedIndex}
                                              placeholder=""
                                              register={props.register}
                                              options={{ required: planData.fields[filedIndex].required_type }}
                                              error={props.errors.plans?.[planData.planCode]?.fields?.[filedIndex]}
                                            ></InputTextarea>
                                            {props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] &&
                                              props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] === "maxLength" && (
                                                <p className="form-error_text">
                                                  {/* {planData.fields[filedIndex].field_name}は1000文字以内でご入力ください。 */}
                                                  {/* {planData.fields[filedIndex].field_name}請填入1000以內的文字。 */}
                                                  {t("SectionPlanCheck.fieldは1000文字以内でご入力ください。", { field: planData.fields[filedIndex].field_name })}
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                : planData.fields[filedIndex].field_type === "3" || planData.fields[filedIndex].field_type === "6" ?
                                <>
                                  <div
                                    id={"plans."+planData.planCode+".fields."+filedIndex}
                                    className="box-separator_bottom-large-item"
                                  >
                                    <div className="form-content">
                                      <div className="form-content-heading">
                                        <h3 className="heading-3-small">
                                          {planData.fields[filedIndex].field_name}
                                          {planData.fields[filedIndex].required_type && (
                                            <span className="label-required ml-5">
                                              必須
                                            </span>
                                          )}
                                        </h3>
                                      </div>
                                      <div className="form-content-input">
                                        <div
                                          className={
                                            "plans."+ planData.planCode+".fields."+filedIndex +
                                            (props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] ? " error" : "")
                                          }
                                        >
                                          <Select
                                            name={"plans."+planData.planCode+".fields."+filedIndex}
                                            register={props.register}
                                            options={{ required: planData.fields[filedIndex].required_type }}
                                            selectOptions={planData.fields[filedIndex].option_list}
                                          ></Select>
                                          {props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] && (
                                            <p className="form-error_text">
                                              {/* {planData.fields[filedIndex].field_name}をご選択ください。 */}
                                              {/* 請選擇{planData.fields[filedIndex].field_name}。 */}
                                              {t("SectionPlanCheck.fieldをご選択ください。", { field: planData.fields[filedIndex].field_name })}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                : planData.fields[filedIndex].field_type === "4" ?
                                <>
                                  <div
                                    id={"plans."+planData.planCode+".fields."+filedIndex}
                                    className="box-separator_bottom-large-item"
                                  >
                                    <div className="form-content">
                                      <div className="form-content-heading">
                                        <h3 className="heading-3-small">
                                          {planData.fields[filedIndex].field_name}
                                          {planData.fields[filedIndex].required_type && (
                                            <span className="label-required ml-5">
                                            必須
                                          </span>
                                          )}
                                        </h3>
                                      </div>
                                      <div className="form-content-input">
                                        <div
                                          className={
                                            "form-content-input-sexual" +
                                            (props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] ? " error" : "")
                                          }
                                          >
                                          <InputRadio
                                            name={"plans."+planData.planCode+".fields."+filedIndex}
                                            control={props.control}
                                            radios={planData.fields[filedIndex].option_list}
                                            options={{ required: planData.fields[filedIndex].required_type }}
                                            isRow={true}
                                          ></InputRadio>
                                          {props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] && (
                                            <p className="form-error_text">
                                              {/* {planData.fields[filedIndex].field_name}をご選択ください。 */}
                                              {/* 請選擇{planData.fields[filedIndex].field_name}。 */}
                                              {t("SectionPlanCheck.fieldをご選択ください。", { field: planData.fields[filedIndex].field_name })}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                : planData.fields[filedIndex].field_type === "5" ?
                                <>
                                  <div
                                    id={"plans."+planData.planCode+".fields."+filedIndex}
                                    className="box-separator_bottom-large-item"
                                  >
                                    <div className="form-content">
                                      <div className="form-content-heading">
                                        <h3 className="heading-3-small">
                                          {planData.fields[filedIndex].field_name}
                                          {planData.fields[filedIndex].required_type && (
                                            <span className="label-required ml-5">
                                              必須
                                          </span>
                                          )}
                                        </h3>
                                      </div>
                                      <div className="form-content-input">
                                        <div>
                                          <InputCheckbox
                                            name={"plans."+planData.planCode+".fields."+filedIndex}
                                            register={props.register}
                                            checkboxes={planData.fields[filedIndex].option_list}
                                            options={{ required: planData.fields[filedIndex].required_type }}
                                            className={"form-input-checkbox-icon pr-16"}
                                            showLabel={true}
                                            useCheckBoxIdToName={true}
                                          ></InputCheckbox>
                                          {props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] &&
                                            props.errors.plans?.[planData.planCode]?.fields?.[filedIndex].type ===
                                            "required" && (
                                              <>
                                                <div className="form-content-input error">
                                                  <p className="form-error_text">
                                                    {/* {planData.fields[filedIndex].field_name}を設定してください。 */}
                                                    {/* 請設定{planData.fields[filedIndex].field_name}。 */}
                                                    {t("SectionPlanCheck.fieldを設定してください。", { field: planData.fields[filedIndex].field_name })}
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>:<></>
                                
                                }
                              </Fragment>
                            ))
                          }
                          
                        </>:<></>
                        }

                        {Object.keys(planData.multipleFields).length > 0 ?
                        <>
                          {Object.keys(planData.multipleFields).map((multipleKey, mIndex) => (
                            <Fragment key={mIndex}>
                              <div
                                id={"multiple-filed-" + multipleKey}
                                className="box-separator_bottom-large-item"
                              >
                                <div className="form-accompany">
                                  {planData.multipleFields[multipleKey]?.fields?.map((multipleField:any, mIndex:number) => (
                                    <div className="form-accompany-item" key={mIndex}>
                                      <div className="form-accompany-item-heading">
                                        {planData.multipleFields[multipleKey].priceName} {mIndex + 1}
                                      </div>
                                      <div className="form-accompany-item-content">
                                        <div className="box-separator_bottom-large">
                                          <Fragment key={mIndex}>
                                            {Object.keys(multipleField).map((filedCode, fIndex) => (
                                              <Fragment key={fIndex}>
                                                {multipleField[filedCode].field_type === "1" ?
                                                  <>
                                                    <div
                                                      id={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                      className="box-separator_bottom-large-item"
                                                    >
                                                      <div className="form-content">
                                                        <div className="form-content-heading">
                                                          <h3 className="heading-3-small">
                                                            {multipleField[filedCode].field_name}
                                                            {multipleField[filedCode].required_type && (
                                                              <span className="label-required ml-5">
                                                              必須
                                                            </span>
                                                            )}
                                                          </h3>
                                                        </div>
                                                        <div className="form-birthday">
                                                          <div className="form-birthday-year">
                                                            <div>
                                                                <InputText
                                                                  name={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  placeholder=""
                                                                  register={props.register}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  error={props.errors.plans?.[planData.planCode]?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode]}
                                                                ></InputText>
                                                                {props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] &&
                                                                  props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode].type ===
                                                                  "required" && (
                                                                    <p className="form-error_text">
                                                                      {/* {multipleField[filedCode].field_name}をご入力ください。 */}
                                                                      {/* 請填入{multipleField[filedCode].field_name}。 */}
                                                                      {t("SectionPlanCheck.fieldをご入力ください。", { field: multipleField[filedCode].field_name })}
                                                                    </p>
                                                                  )}
                                                            </div>
                                                            <p className="mr-10 ml-10 color-dark_blue">
                                                              {multipleField[filedCode].unit}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                : multipleField[filedCode].field_type === "2" ?
                                                  <>
                                                    <div
                                                      id={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                      className="box-separator_bottom-large-item"
                                                    >
                                                      <div className="form-content">
                                                        <div className="form-content-heading">
                                                          <h3 className="heading-3-small">
                                                            {multipleField[filedCode].field_name}
                                                            {multipleField[filedCode].required_type && (
                                                              <span className="label-required ml-5">
                                                              必須
                                                            </span>
                                                            )}
                                                          </h3>
                                                        </div>
                                                        <div className="form-birthday">
                                                          <div className="form-birthday-year">
                                                            <div>
                                                              <InputTextarea
                                                                name={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                placeholder=""
                                                                register={props.register}
                                                                options={{ required: multipleField[filedCode].required_type }}
                                                                error={props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode]}
                                                              ></InputTextarea>
                                                              {props.errors.plans?.[planData.planCode]?.[planData.multipleFields[multipleKey].priceCode]?.multiple?.[mIndex]?.[filedCode] &&
                                                                props.errors.plans?.[planData.planCode]?.[planData.multipleFields[multipleKey].priceCode]?.multiple?.[mIndex]?.[filedCode] === "maxLength" && (
                                                                  <p className="form-error_text">
                                                                    {/* {multipleField[filedCode].field_name}は1000文字以内でご入力ください。 */}
                                                                    {/* {multipleField[filedCode].field_name}請填入1000以內的文字。 */}
                                                                    {t("SectionPlanCheck.fieldは1000文字以内でご入力ください。", { field: multipleField[filedCode].field_name })}
                                                                  </p>
                                                                )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                : multipleField[filedCode].field_type === "3" || multipleField[filedCode].field_type === "6" ?
                                                  <>
                                                    <div
                                                      id={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                      className="box-separator_bottom-large-item"
                                                    >
                                                      <div className="form-content">
                                                        <div className="form-content-heading">
                                                          <h3 className="heading-3-small">
                                                            {multipleField[filedCode].field_name}
                                                            {multipleField[filedCode].required_type && (
                                                              <span className="label-required ml-5">
                                                              必須
                                                            </span>
                                                            )}
                                                          </h3>
                                                        </div>
                                                        <div className="form-content-input">
                                                          <div
                                                            className={
                                                              "plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode +
                                                              (props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] ? " error" : "")
                                                            }
                                                          >
                                                            <Select
                                                              name={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                              register={props.register}
                                                              options={{ required: multipleField[filedCode].required_type }}
                                                              selectOptions={multipleField[filedCode].option_list}
                                                            ></Select>
                                                            {props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] && (
                                                              <p className="form-error_text">
                                                                {/* {multipleField[filedCode].field_name}をご選択ください。 */}
                                                                {/* 請選擇{multipleField[filedCode].field_name}。 */}
                                                                {t("SectionPlanCheck.fieldをご選択ください。", { field: multipleField[filedCode].field_name })}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                : multipleField[filedCode].field_type === "4" ?
                                                  <>
                                                    <div
                                                      id={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                      className="box-separator_bottom-large-item"
                                                    >
                                                      <div className="form-content">
                                                        <div className="form-content-heading">
                                                          <h3 className="heading-3-small">
                                                            {multipleField[filedCode].field_name}
                                                            {multipleField[filedCode].required_type && (
                                                              <span className="label-required ml-5">
                                                              必須
                                                            </span>
                                                            )}
                                                          </h3>
                                                        </div>
                                                        <div className="form-content-input">
                                                          <div
                                                            className={
                                                              "plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode +
                                                              (props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] ? " error" : "")
                                                            }
                                                          >
                                                            <InputRadio
                                                              name={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                              control={props.control}
                                                              radios={multipleField[filedCode].option_list}
                                                              options={{ required: multipleField[filedCode].required_type }}
                                                              isRow={true}
                                                            ></InputRadio>
                                                            {props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] && (
                                                              <p className="form-error_text">
                                                                {/* {multipleField[filedCode].field_name}をご選択ください。 */}
                                                                {/* 請選擇{multipleField[filedCode].field_name}。 */}
                                                                {t("SectionPlanCheck.fieldをご選択ください。", { field: multipleField[filedCode].field_name })}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
    
                                                  </>
                                                : multipleField[filedCode].field_type === "5" ?
                                                  <>
                                                    <div
                                                      id={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                      className="box-separator_bottom-large-item"
                                                    >
                                                      <div className="form-content">
                                                        <div className="form-content-heading">
                                                          <h3 className="heading-3-small">
                                                            {multipleField[filedCode].field_name}
                                                            {multipleField[filedCode].required_type && (
                                                              <span className="label-required ml-5">
                                                              必須
                                                            </span>
                                                            )}
                                                          </h3>
                                                        </div>
                                                        <div className="form-content-input">
                                                          <div>
                                                            <InputCheckbox
                                                              name={"plans."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                              register={props.register}
                                                              checkboxes={multipleField[filedCode].option_list}
                                                              options={{ required: multipleField[filedCode].required_type }}
                                                              className={"form-input-checkbox-icon pr-16"}
                                                              showLabel={true}
                                                              useCheckBoxIdToName={true}
                                                            ></InputCheckbox>
                                                            {props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] &&
                                                              props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode].type ===
                                                              "required" && (
                                                                <>
                                                                <div className="form-content-input error">
                                                                  <p className="form-error_text">
                                                                    {/* {multipleField[filedCode].field_name}を設定してください。 */}
                                                                    {/* 請設定{multipleField[filedCode].field_name}。 */}
                                                                    {t("SectionPlanCheck.fieldを設定してください。", { field: multipleField[filedCode].field_name })}
                                                                  </p>
                                                                </div>
                                                                </>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>:<></>}
                                              </Fragment>
                                            ))}
                                          </Fragment>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Fragment>
                          ))}
                        </>:<></>}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>:<></>
          }
        </div>
        <div>
          {optionValues.length > 0 ?
            <>
              {optionValues.map((planData, pIndex) => (
                <Accordion key={pIndex} className="accordion">
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id={"plan-" + pIndex}
                    className="accordion-heading"
                  >
                    {t("SectionPlanCheck.追加プラン")}{toCircled(pIndex + 1)}
                    {/* 加購方案{toCircled(pIndex + 1)} */}
                  </AccordionSummary>
                  <AccordionDetails className="accordion-content">
                    <div className="fx-1">
                      <p className="mb-16">
                        {t("ScheduleDetailModal.ご利用日")}：{planData.useDate}
                        {/* 利用日期：{planData.useDate} */}
                      </p>
                      <p className="mb-40 fw-b">
                        {planData.planName}
                      </p>
                      <div className="box-separator_bottom-large">
                        {Object.keys(planData.fields).length > 0 ?
                          <>
                            {
                              Object.keys(planData.fields).map((filedIndex, fIndex) => (
                                <Fragment key={fIndex}>
                                  {planData.fields[filedIndex].field_type === "1" ?
                                    <>
                                      <div
                                        id={"options."+planData.planCode+".fields."+filedIndex}
                                        className="box-separator_bottom-large-item"
                                      >
                                        <div className="form-content">
                                          <div className="form-content-heading">
                                            <h3 className="heading-3-small">
                                              {planData.fields[filedIndex].field_name}
                                              {planData.fields[filedIndex].required_type && (
                                                <span className="label-required ml-5">
                                              必須
                                            </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="form-birthday">
                                            <div className="form-birthday-year">
                                              <div>
                                                <InputText
                                                  name={"options."+planData.planCode+".fields."+filedIndex}
                                                  placeholder=""
                                                  register={props.register}
                                                  options={{ required: planData.fields[filedIndex].required_type }}
                                                  error={props.errors.options?.[planData.planCode]?.fields?.[filedIndex]}
                                                ></InputText>
                                                {props.errors.options?.[planData.planCode]?.fields?.[filedIndex] &&
                                                  props.errors.options?.[planData.planCode]?.fields?.[filedIndex].type ===
                                                  "required" && (
                                                    <p className="form-error_text">
                                                      {/* {planData.fields[filedIndex].field_name}をご入力ください。 */}
                                                      {/* 請填入{planData.fields[filedIndex].field_name}。 */}
                                                      {t("SectionPlanCheck.fieldをご入力ください。", { field: planData.fields[filedIndex].field_name })}
                                                    </p>
                                                  )}
                                              </div>
                                              <p className="mr-10 ml-10 color-dark_blue">
                                                {planData.fields[filedIndex].unit}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  : planData.fields[filedIndex].field_type === "2"?
                                    <>
                                      <div
                                        id={"options."+planData.planCode+".fields."+filedIndex}
                                        className="box-separator_bottom-large-item"
                                      >
                                        <div className="form-content">
                                          <div className="form-content-heading">
                                            <h3 className="heading-3-small">
                                              {planData.fields[filedIndex].field_name}
                                              {planData.fields[filedIndex].required_type && (
                                                <span className="label-required ml-5">
                                                  必須
                                                </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="form-birthday">
                                            <div className="form-birthday-year">
                                              <div>
                                                <InputTextarea
                                                  name={"options."+planData.planCode+".fields."+filedIndex}
                                                  placeholder=""
                                                  register={props.register}
                                                  options={{ required: planData.fields[filedIndex].required_type }}
                                                  error={props.errors.options?.[planData.planCode]?.fields?.[filedIndex]}
                                                ></InputTextarea>
                                                {props.errors.options?.[planData.planCode]?.fields?.[filedIndex] &&
                                                  props.errors.options?.[planData.planCode]?.fields?.[filedIndex] === "maxLength" && (
                                                    <p className="form-error_text">
                                                      {/* {planData.fields[filedIndex].field_name}は1000文字以内でご入力ください。 */}
                                                      {/* {planData.fields[filedIndex].field_name}請填入1000以內的文字。 */}
                                                      {t("SectionPlanCheck.fieldは1000文字以内でご入力ください。", { field: planData.fields[filedIndex].field_name })}
                                                    </p>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  : planData.fields[filedIndex].field_type === "3" || planData.fields[filedIndex].field_type === "6" ?
                                    <>
                                      <div
                                        id={"options."+planData.planCode+".fields."+filedIndex}
                                        className="box-separator_bottom-large-item"
                                      >
                                        <div className="form-content">
                                          <div className="form-content-heading">
                                            <h3 className="heading-3-small">
                                              {planData.fields[filedIndex].field_name}
                                              {planData.fields[filedIndex].required_type && (
                                                <span className="label-required ml-5">
                                                  必須
                                                </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="form-content-input">
                                            <div
                                              className={
                                                "options."+ planData.planCode+".fields."+filedIndex +
                                                (props.errors.plans?.[planData.planCode]?.fields?.[filedIndex] ? " error" : "")
                                              }
                                            >
                                              <Select
                                                name={"options."+planData.planCode+".fields."+filedIndex}
                                                register={props.register}
                                                options={{ required: planData.fields[filedIndex].required_type }}
                                                selectOptions={planData.fields[filedIndex].option_list}
                                              ></Select>
                                              {props.errors.options?.[planData.planCode]?.fields?.[filedIndex] && (
                                                <p className="form-error_text">
                                                  {/* {planData.fields[filedIndex].field_name}をご選択ください。 */}
                                                  {/* 請選擇{planData.fields[filedIndex].field_name}。 */}
                                                  {t("SectionPlanCheck.fieldをご選択ください。", { field: planData.fields[filedIndex].field_name })}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  : planData.fields[filedIndex].field_type === "4" ?
                                    <>
                                      <div
                                        id={"options."+planData.planCode+".fields."+filedIndex}
                                        className="box-separator_bottom-large-item"
                                      >
                                        <div className="form-content">
                                          <div className="form-content-heading">
                                            <h3 className="heading-3-small">
                                              {planData.fields[filedIndex].field_name}
                                              {planData.fields[filedIndex].required_type && (
                                                <span className="label-required ml-5">
                                                  必須
                                                </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="form-content-input">
                                            <div
                                              className={
                                                "form-content-input-sexual" +
                                                (props.errors.options?.[planData.planCode]?.fields?.[filedIndex] ? " error" : "")
                                              }
                                            >
                                              <InputRadio
                                                name={"options."+planData.planCode+".fields."+filedIndex}
                                                control={props.control}
                                                radios={planData.fields[filedIndex].option_list}
                                                options={{ required: planData.fields[filedIndex].required_type }}
                                                isRow={true}
                                              ></InputRadio>
                                              {props.errors.options?.[planData.planCode]?.fields?.[filedIndex] && (
                                                <p className="form-error_text">
                                                  {/* {planData.fields[filedIndex].field_name}をご選択ください。 */}
                                                  {/* 請選擇{planData.fields[filedIndex].field_name}。 */}
                                                  {t("SectionPlanCheck.fieldをご選択ください。", { field: planData.fields[filedIndex].field_name })}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  : planData.fields[filedIndex].field_type === "5" ?
                                    <>
                                      <div
                                        id={"options."+planData.planCode+".fields."+filedIndex}
                                        className="box-separator_bottom-large-item"
                                      >
                                        <div className="form-content">
                                          <div className="form-content-heading">
                                            <h3 className="heading-3-small">
                                              {planData.fields[filedIndex].field_name}
                                              {planData.fields[filedIndex].required_type && (
                                                <span className="label-required ml-5">
                                                    必須
                                                </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="form-content-input">
                                            <div>
                                              <InputCheckbox
                                                name={"options."+planData.planCode+".fields."+filedIndex}
                                                register={props.register}
                                                checkboxes={planData.fields[filedIndex].option_list}
                                                options={{ required: planData.fields[filedIndex].required_type }}
                                                className={"form-input-checkbox-icon pr-16"}
                                                showLabel={true}
                                                useCheckBoxIdToName={true}
                                              ></InputCheckbox>
                                              {props.errors.options?.[planData.planCode]?.fields?.[filedIndex] &&
                                                props.errors.options?.[planData.planCode]?.fields?.[filedIndex].type ===
                                                "required" && (
                                                  <>
                                                    <div className="form-content-input error">
                                                      <p className="form-error_text">
                                                        {/* {planData.fields[filedIndex].field_name}を設定してください。 */}
                                                        {/* 請設定{planData.fields[filedIndex].field_name}。 */}
                                                        {t("SectionPlanCheck.fieldを設定してください。", { field: planData.fields[filedIndex].field_name })}
                                                      </p>
                                                    </div>
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>:<></>

                                  }
                                </Fragment>
                              ))
                            }

                          </>:<></>
                        }

                        {Object.keys(planData.multipleFields).length > 0 ?
                          <>
                            {Object.keys(planData.multipleFields).map((multipleKey, mIndex) => (
                              <Fragment key={mIndex}>
                                <div
                                  id={"multiple-filed-" + multipleKey}
                                  className="box-separator_bottom-large-item"
                                >
                                  <div className="form-accompany">
                                    {planData.multipleFields[multipleKey]?.fields?.map((multipleField:any, mIndex:number) => (
                                      <div className="form-accompany-item" key={mIndex}>
                                        <div className="form-accompany-item-heading">
                                          {planData.multipleFields[multipleKey].priceName} {mIndex + 1}
                                        </div>
                                        <div className="form-accompany-item-content">
                                          <div className="box-separator_bottom-large">
                                              <Fragment key={mIndex}>
                                                {Object.keys(multipleField).map((filedCode, fIndex) => (
                                                  <Fragment key={fIndex}>
                                                    {multipleField[filedCode].field_type === "1" ?
                                                    <>
                                                      <div
                                                        id={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                        className="box-separator_bottom-large-item"
                                                      >
                                                        <div className="form-content">
                                                          <div className="form-content-heading">
                                                            <h3 className="heading-3-small">
                                                              {multipleField[filedCode].field_name}
                                                              {multipleField[filedCode].required_type && (
                                                                <span className="label-required ml-5">
                                                                  必須
                                                                </span>
                                                              )}
                                                            </h3>
                                                          </div>
                                                          <div className="form-birthday">
                                                            <div className="form-birthday-year">
                                                              <div>
                                                                <InputText
                                                                  name={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  placeholder=""
                                                                  register={props.register}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  error={props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode]}
                                                                ></InputText>
                                                                {props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] &&
                                                                  props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode].type ===
                                                                  "required" && (
                                                                    <p className="form-error_text">
                                                                      {/* {multipleField[filedCode].field_name}をご入力ください。 */}
                                                                      {/* 請填入{multipleField[filedCode].field_name}。 */}
                                                                      {t("SectionPlanCheck.fieldをご入力ください。", { field: multipleField[filedCode].field_name })}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                              <p className="mr-10 ml-10 color-dark_blue">
                                                                {multipleField[filedCode].unit}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                    : multipleField[filedCode].field_type === "2" ?
                                                    <>
                                                      <div
                                                        id={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                        className="box-separator_bottom-large-item"
                                                      >
                                                        <div className="form-content">
                                                          <div className="form-content-heading">
                                                            <h3 className="heading-3-small">
                                                              {multipleField[filedCode].field_name}
                                                              {multipleField[filedCode].required_type && (
                                                                <span className="label-required ml-5">
                                                                必須
                                                              </span>
                                                              )}
                                                            </h3>
                                                          </div>
                                                          <div className="form-birthday">
                                                            <div className="form-birthday-year">
                                                              <div>
                                                                <InputTextarea
                                                                  name={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  placeholder=""
                                                                  register={props.register}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  error={props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode]}
                                                                ></InputTextarea>
                                                                {props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] &&
                                                                  props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] === "maxLength" && (
                                                                    <p className="form-error_text">
                                                                      {/* {multipleField[filedCode].field_name}は1000文字以内でご入力ください。 */}
                                                                      {/* {multipleField[filedCode].field_name}請填入1000以內的文字。 */}
                                                                      {t("SectionPlanCheck.fieldは1000文字以内でご入力ください。", { field: multipleField[filedCode].field_name })}
                                                                    </p>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                  </>
                                                    : multipleField[filedCode].field_type === "3" || multipleField[filedCode].field_type === "6" ?
                                                      <>
                                                        <div
                                                          id={"options."+planData.planCode+".multiple."+multipleField[filedCode].index+"."+mIndex+"."+filedCode}
                                                          className="box-separator_bottom-large-item"
                                                        >
                                                          <div className="form-content">
                                                            <div className="form-content-heading">
                                                              <h3 className="heading-3-small">
                                                                {multipleField[filedCode].field_name}
                                                                {multipleField[filedCode].required_type && (
                                                                  <span className="label-required ml-5">
                                                                  必須
                                                                </span>
                                                                )}
                                                              </h3>
                                                            </div>
                                                            <div className="form-content-input">
                                                              <div
                                                                className={
                                                                  "options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode +
                                                                  (props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] ? " error" : "")
                                                                }
                                                              >
                                                                <Select
                                                                  name={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  register={props.register}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  selectOptions={multipleField[filedCode].option_list}
                                                                ></Select>
                                                                {props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] && (
                                                                  <p className="form-error_text">
                                                                    {/* {multipleField[filedCode].field_name}をご選択ください。 */}
                                                                    {/* 請選擇{multipleField[filedCode].field_name}。 */}
                                                                    {t("SectionPlanCheck.fieldをご選択ください。", { field: multipleField[filedCode].field_name })}
                                                                  </p>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    : multipleField[filedCode].field_type === "4" ?
                                                      <>
                                                        <div
                                                          id={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                          className="box-separator_bottom-large-item"
                                                        >
                                                          <div className="form-content">
                                                            <div className="form-content-heading">
                                                              <h3 className="heading-3-small">
                                                                {multipleField[filedCode].field_name}
                                                                {multipleField[filedCode].required_type && (
                                                                  <span className="label-required ml-5">
                                                                    必須
                                                                  </span>
                                                                )}
                                                              </h3>
                                                            </div>
                                                            <div className="form-content-input">
                                                              <div
                                                                className={
                                                                  "options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode +
                                                                  (props.errors.plans?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] ? " error" : "")
                                                                }
                                                              >
                                                                <InputRadio
                                                                  name={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  control={props.control}
                                                                  radios={multipleField[filedCode].option_list}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  isRow={true}
                                                                ></InputRadio>
                                                                {props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] && (
                                                                  <p className="form-error_text">
                                                                    {/* {multipleField[filedCode].field_name}をご選択ください。 */}
                                                                    {/* 請選擇{multipleField[filedCode].field_name}。 */}
                                                                    {t("SectionPlanCheck.fieldをご選択ください。", { field: multipleField[filedCode].field_name })}
                                                                  </p>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
  
                                                      </>
                                                    : multipleField[filedCode].field_type === "5" ?
                                                      <>
                                                        <div
                                                          id={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                          className="box-separator_bottom-large-item"
                                                        >
                                                          <div className="form-content">
                                                            <div className="form-content-heading">
                                                              <h3 className="heading-3-small">
                                                                {multipleField[filedCode].field_name}
                                                                {multipleField[filedCode].required_type && (
                                                                  <span className="label-required ml-5">
                                                                    必須
                                                                  </span>
                                                                )}
                                                              </h3>
                                                            </div>
                                                            <div className="form-content-input">
                                                              <div>
                                                                <InputCheckbox
                                                                  name={"options."+planData.planCode+".multiple."+planData.multipleFields[multipleKey].priceCode+"."+mIndex+"."+filedCode}
                                                                  register={props.register}
                                                                  checkboxes={multipleField[filedCode].option_list}
                                                                  options={{ required: multipleField[filedCode].required_type }}
                                                                  className={"form-input-checkbox-icon pr-16"}
                                                                  showLabel={true}
                                                                  useCheckBoxIdToName={true}
                                                                ></InputCheckbox>
                                                                {props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode] &&
                                                                  props.errors.options?.[planData.planCode]?.multiple?.[planData.multipleFields[multipleKey].priceCode]?.[mIndex]?.[filedCode].type ===
                                                                  "required" && (
                                                                    <>
                                                                      <div className="form-content-input error">
                                                                        <p className="form-error_text">
                                                                          {/* {multipleField[filedCode].field_name}を設定してください。 */}
                                                                          {/* 請設定{multipleField[filedCode].field_name}。 */}
                                                                          {t("SectionPlanCheck.fieldを設定してください。", { field: multipleField[filedCode].field_name })}
                                                                        </p>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>:<></>}
                                                  </Fragment>
                                                ))}
                                              </Fragment>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                          </>:<></>}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>:<></>
          }
        </div>
      </section>
    </>
  );
};

export default SectionPlanCheck;
