import React, { useEffect, Fragment } from "react";
// import { Element, Link as Scroll } from 'react-scroll';


// for google map
import Map from "../google/Maps";

// for styles
import {
  Button,
  Box,
  Backdrop
} from "@material-ui/core/"

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

import Modal from "@material-ui/core/Modal";
import {
  // Hotel, 
  hotelList,
  // hotelData, 
  // hotelPlanDetail, 
  // roomData
} from "../../types/Hotel";
import {
  diffHotelPrice,
  getHotelDetailSearchParms,
  // getHotelSearchParms, 
  getHotelTypeLabel,
  // getHotelSearchParms, 
  getHotelAreaCode,
} from "../../utils/hotel";
// import {PlanSearch} from "../../types/Search";
import {apiClient} from "../../utils/apiClient";
import {
  PlanSelectFormData, 
  // PlanSelectFromHotelType
} from "../../types/planSelectForm";
import LoadingOverlay from "react-loading-overlay-ts";
// import MyComponent from "../google/Maps";
import PartsHotelTagList from "../parts/PartsHotelTagList";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/languages";
import { PartsModalHeader } from "../parts";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  isUpdate: boolean,
  updateIndex: number | undefined,
  hotelList: hotelList,
  selectHotel(hotelCode:string):void,
  planSelectFormData: PlanSelectFormData | undefined,
  callback: () => void,
  // languageCode: string;
}

export const useScroll = (): [React.RefObject<HTMLDivElement>, () => void] => {
  const ref = React.useRef<HTMLDivElement>(null);
  const moveTo = React.useCallback(() => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, []);
  return [ref, moveTo];
};

export const useScroll2 = (): [React.RefObject<HTMLDivElement>, () => void] => {
  const ref2 = React.useRef<HTMLDivElement>(null);
  const moveTo2 = React.useCallback(() => {
    ref2?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);
  return [ref2, moveTo2];
};

/***
 * ホテル詳細モーダル
 * @param isShow
 * @param planSelectFormData
 * @param callback
 * @param hotelList
 * @constructor
 */
const HotelHandModal: React.FC<Props> = ({
  isShow,
  isUpdate,
  updateIndex,
  planSelectFormData,
  selectHotel,
  callback,
  hotelList,
  // languageCode
}) => {

  // // 座標を渡す(GoogleMap用)
  // const position = {
  //   defaultZoom: 1,
  //   latitude: 1,
  //   longitude: 1,
  //   isMarkerShown: true
  // }
  
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [hotelDetail, setHotelDetail] = React.useState<hotelList>();
  const checkIn = planSelectFormData?.searchParams.checkin ?? '';
  const checkOut = planSelectFormData?.searchParams.checkout ?? '';
  
  useEffect(() => {
    (async () => {
      if(hotelList !== undefined && hotelList.hotelData === undefined && planSelectFormData !== undefined) {
        setHotelDetail({...hotelList});
        setLoading(true)
        const hotelAreaCode = getHotelAreaCode(planSelectFormData);
        const hotelSearchParams = getHotelDetailSearchParms(hotelAreaCode, hotelList.hotel_code, planSelectFormData.searchParams, i18n.language);
        await apiClient.post("/hotel/hotel-detail", JSON.stringify(hotelSearchParams))
          .then(function (response) {
            setLoading(false);
            setHotelDetail({...hotelList, hotelData:response.data.response});
          })
          .catch(function (error) {
            setLoading(false);
            // TODO: handle error
            console.log('Error');
            console.log(error);
          });
      } else if (hotelList !== undefined) {
        setHotelDetail({...hotelList});
        setLoading(false);
      }
      // setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList]);

  //ページ内リンク用関数を呼び出し
  const [ref, moveTo] = useScroll();
  const [ref2, moveTo2] = useScroll2();

  const [scrollStatus, setScrollStatus] = React.useState(false);
  const handleScrollStatus = () => {
      setScrollStatus(scrollStatus);
  }

  const [scrollStatus2, setScrollStatus2] = React.useState(false);
  const handleScrollStatus2 = () => {
      setScrollStatus2(scrollStatus2);
  }

  // const [isScroll, setIsScroll] = useState(false);
  // const toggleVisibility = () => {
  //   window.scrollY > 1 && window.scrollY > -1
  //     ? setIsScroll(true)
  //     : setIsScroll(false)
  //   }
  
  // useEffect(() => {
  //   window.addEventListener('scroll', toggleVisibility)
  //   return () => window.removeEventListener('scroll', toggleVisibility)
  // }, [])


  return (
    <>
    <Modal
      open={isShow}
      onClose={callback}
      className="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <div className="modal-fixed">
        <LoadingOverlay
          active={loading}
          spinner
          className="loading-hotel_hand"
        >
        {/* <div className="modal-fixed-heading no-shadow"> */}
          {/* <p className="modal-fixed-heading-text">酒店介紹</p> */}
          {/* <p className="modal-fixed-heading-text">{t("HotelHandModal.ホテル詳細")}</p>
          <p className="modal-fixed-close">
            <Button
              onClick={callback}
              className="modal-fixed-close-button"
            >
              <i className="modal-fixed-close-button-icon"></i>
              {t("Common.閉じる")}
            </Button>
          </p>
        </div> */}
        <PartsModalHeader
          headingText={t("HotelHandModal.ホテル詳細")}
          appendClass={"no-shadow"}
          close={callback}
        />

        <div className="modal-fixed-content">
          <div className="step-tab">
            <Box className="step-tab-option">
              <div className="modal-navi">
                <Button
                  // onClick={moveTo}
                  onClick={() => {
                    moveTo();
                    handleScrollStatus2();
                  }}
                  className="modal-navi-item"
                >
                  {/* ホテルの特徴 */}
                  {/* 酒店特色 */}
                  {t("HotelHandModal.ホテルの特徴")}
                  <span className="modal-navi-item-arrow">
                    <img
                      src={require("../../img/share/icon/icon_arrow_down.svg").default}
                      alt=""
                    />
                  </span>
                </Button>
                <Button
                  onClick={() => {
                    moveTo2();
                    handleScrollStatus();
                  }}
                  className="modal-navi-item"
                >
                  {/* ホテルの基本情報 */}
                  {/* 飯店基本資訊 */}
                  {t("HotelHandModal.ホテルの基本情報")}
                  <span className="modal-navi-item-arrow">
                    <img
                      src={require("../../img/share/icon/icon_arrow_down.svg").default}
                      alt=""
                    />
                  </span>
                </Button>
              </div>
              <div className="wrap-content-900-large">
                <div className="d-f ai-c fd-c fd-r-md mt-40 mb-24">
                  <div className="ta-c fz-36 w-100per w-50per-md mb-24 mb-0-md">
                    <img
                      src={hotelDetail?.image_m_url}
                      alt=""
                    />
                  </div>
                  <div className="box-separator_bottom-large-item_no_bdr w-100per ml-24-md">
                    <div>
                      <ul className="d-f ai-c mb-8">
                        {hotelDetail?.sub_area_name !== undefined?(
                          <li className="fz-12 fz-14-md mr-10">{hotelDetail?.sub_area_name}</li>
                        ):(<></>)}

                        {hotelDetail?.hotel_type_list?.map((list, index) => (
                          <li key={"hote_list"+index} className="label-gray-small mr-5">
                            {getHotelTypeLabel(list.hotel_type_code, i18n.language)}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <h2 className="fz-18 fz-22-md fw-b mb-16">
                      {hotelDetail?.hotel_name}
                    </h2>
                    {/*APIから取得できないため一旦非表示*/}
                    <div>
                      <PartsHotelTagList
                        hotel_facility_list={hotelDetail?.hotel_facility_list}
                        ulClass="parts-hotel_tag-list d-f fw-w"
                        languageCode={i18n.language}
                      />
                    </div>
                  </div>
                </div>
                <div className="box-separator_bottom-large">
                  <div className="box-separator_bottom-large-item_no_bdr parts-inner-scroll-offset">
                    <div className="box-light-gray small mb-24-md">
                      <p className="fz-16 fz-20-md c-navy mb-16">
                        {hotelDetail?.hotelData?.hotel_info?.pr_main_title}
                      </p>
                      <p>
                        {hotelDetail?.pr_main_description}
                      </p>
                    </div>
                  </div>
                  <div
                    ref={ref}
                    // className={(!scrollStatus ? " pb-100" : "") + (isScroll? " pb-0":"")}
                  >
                  </div>
                  <div
                    id="link_feature"
                    className="box-separator_bottom-large-item_no_bdr parts-inner-scroll-inset"
                  >
                    <h3 className="fz-20 fz-26-md c-navy mb-16">
                      {/* ホテルの特徴 */}
                      {/* 酒店特色 */}
                      {t("HotelHandModal.ホテルの特徴")}
                    </h3>
                    {hotelDetail?.hotelData?.hotel_info?.pr_list?.map((pr, index) => (
                      <dl key={index} className="d-f fd-c fd-r-md mb-32">
                        <dt className="ta-c w-100per w-40per-md mb-16 mb-0-md mr-20-md">
                          <img
                            src={pr.pr_image_url}
                            alt=""
                          />
                        </dt>
                        <dd className="w-100per w-60per-md">
                          <h3 className="fz-14 fz-16-md c-navy mb-10 mb-16-md">
                            {pr.pr_title}
                          </h3>
                          <p className="fz-13 fz-14-md">
                            {pr.pr_description}
                          </p>
                        </dd>
                      </dl>
                    ))}
                  </div>
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <h3 className="fz-20 fz-26-md c-dark_gray mb-16">
                      {/* ホテルアクセスマップ */}
                      {/* 飯店交通地圖 */}
                      {t("HotelHandModal.ホテルアクセスマップ")}
                    </h3>
                    <div className="parts-inner-scroll-offset">
                      <Map address={hotelDetail?.address} lat={hotelDetail?.hotelData?.hotel_info.map_latitude} lng={hotelDetail?.hotelData?.hotel_info.map_longitude} label={hotelDetail?.hotelData?.hotel_info.hotel_name??""} />
                    </div>
                  </div>
                  <div className="box-separator_bottom-large-item_no_bdr">
                    <div
                      ref={ref2}
                      // className={(!scrollStatus2 ? " pb-100" : "")}
                    >
                    </div>
                    <h3
                      id="link_info"
                      className="fz-20 fz-26-md c-dark_gray mb-16 parts-inner-scroll-inset"
                    >
                      {/* ホテルの基本情報 */}
                      {/* 飯店基本資訊 */}
                      {t("HotelHandModal.ホテルの基本情報")}
                    </h3>
                    <div className="mb-24">
                      <h4 className="fz-16 fz-18-md mb-16">
                        {/* 基本情報 */}
                        {/* 基本資訊 */}
                        {t("HotelHandModal.基本情報")}
                      </h4>
                      <table>
                        <tbody>
                        <tr>
                          <th className="fz-12 fz-16-md w-30per">
                            {/* ホテル名 */}
                            {/* 飯店名稱 */}
                            {t("HotelHandModal.ホテル名")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotel_name}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">{t("HotelHandModal.住所")}</th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.address}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* アクセス */}
                            {/* 交通資訊 */}
                            {t("HotelHandModal.アクセス")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.access}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* TEL/FAX */}
                            TEL/FAX
                          </th>
                          <td className="fz-12 fz-16-md">
                            TEL：{hotelDetail?.hotelData?.hotel_info?.tel}
                            <br />
                            FAX：{hotelDetail?.hotelData?.hotel_info?.fax}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* 駐車場 */}
                            {/* 停車場 */}
                            {t("HotelHandModal.駐車場")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.parking}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* 総客室数 */}
                            {/* 客房總數 */}
                            {t("HotelHandModal.総客室数")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.total_room_num}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* チェックイン */}
                            {/* 入住日期 */}
                            {t("HotelHandModal.チェックイン")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.check_in_start} ～ {hotelDetail?.hotelData?.hotel_info?.check_in_end}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* チェックアウト */}
                            {/* 退房日期 */}
                            {t("HotelHandModal.チェックアウト")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.check_out}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mb-24">
                      <h4 className="fz-16 fz-18-md mb-16">
                        {/* 全室共通の設備・アメニティ */}
                        {/* 客房設備・盥洗用品 */}
                        {t("HotelHandModal.全室共通の設備・アメニティ")}
                      </h4>
                      <table>
                        <tbody>
                        {hotelDetail && hotelDetail.hotel_facility_list.length > 0 && (
                          <>
                            <tr>
                              <th className="fz-12 fz-16-md w-30per">
                                {/* 設備・サービス */}
                                {/* 設備・服務 */}
                                {t("HotelHandModal.設備・サービス")}
                              </th>
                              <td className="fz-12 fz-16-md">
                                {hotelDetail.hotel_facility_list.map((facility, index) => (
                                  <Fragment key={index}>
                                    {facility.name}
                                  </Fragment>
                                ))}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* 全室共通の設備 */}
                            {/* 全客房共通的設備 */}
                            {t("HotelHandModal.全室共通の設備")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.room_equipment_list?.map((list, index) => (
                              <Fragment key={index}>{list.name}</Fragment>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="fz-12 fz-16-md">
                            {/* 全室共通のアメニティ */}
                            {/* 全客房共通的盥洗用品 */}
                            {t("HotelHandModal.全室共通のアメニティ")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.room_amenity_list?.map((list, index) => (
                              <Fragment key={index}>{list.name}</Fragment>
                            ))}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mb-24">
                      {hotelDetail?.hotelData?.hotel_info?.other_notes? (
                      <h4 className="fz-16 fz-18-md mb-16">
                        {/* 注意事項など */}
                        {/* 注意事項等 */}
                        {t("HotelHandModal.注意事項など")}
                      </h4>):(<></>)}
                      {hotelDetail?.hotelData?.hotel_info?.other_notes? (
                        <table>
                          <tbody>
                          {/*<tr>*/}
                          {/*  <th className="fz-12 fz-16-md w-30per">*/}
                          {/*    バリアフリー対応*/}
                          {/*  </th>*/}
                          {/*  <td className="fz-12 fz-16-md">*/}
                          {/*    ・バリアフリー用トイレ・車椅子可・貸出用車椅子・客室内バリアフリー・アレルギーに配慮した料理への対応可能(予約必要）*/}
                          {/*    <ul className="list-note">*/}
                          {/*      <li>*/}
                          {/*        宿泊施設によって、バリアフリー設備内容や、対応できるサービス範囲が異なります。ご不便なく安心してご利用いただくために、予め宿泊施設へ直接ご確認ください。*/}
                          {/*      </li>*/}
                          {/*    </ul>*/}
                          {/*  </td>*/}
                          {/*</tr>*/}
                        <tr>
                          <th className="fz-12 fz-16-md w-30per">
                            {/* その他注意事項 */}
                            {/* 其他注意事項 */}
                            {t("HotelHandModal.その他注意事項")}
                          </th>
                          <td className="fz-12 fz-16-md">
                            {hotelDetail?.hotelData?.hotel_info?.other_notes}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      ): (<></>)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-fixed-bottom modal-fixed-bottom-2column-wrap">
                <div className="modal-fixed-bottom-2column jc-sb">
                  <dl className="d-f fd-c fd-r-md ai-s ai-e-md">
                    <dt className="fz-13 fz-14-md mr-20">
                      {/* 需追加費用 */}
                      {t("HotelHandModal.追加料金")}
                      <br />
                      <span className="fz-10 fz-12-md">
                        {/* (１人あたり) */}
                        ({t("HotelPlanSearchResult.１人あたり")})
                      </span>
                    </dt>
                    <dd className="c-red fz-20 fz-24-md fw-b">
                      {diffHotelPrice(planSelectFormData, {
                        isUpdate: isUpdate,
                        step: 'hotel',
                        updateHotelIndex: updateIndex,
                        updatePlan: undefined,
                        updateHotel: hotelDetail,
                        checkIn: checkIn,
                        checkOut: checkOut
                      })}
                      <span className="fz-12 fz-14-md fw-n">
                        {/* 円〜 */}
                        {t("Common.円")}〜
                      </span>
                    </dd>
                  </dl>
                  <button
                    onClick={() => selectHotel(hotelDetail?.hotel_code?? "")}
                    className="button-medium-light_blue padding-large"
                  >
                    {/* このホテルを
                    <br className="d-b d-n-md" />
                    選択する */}
                    {/* 選擇此酒店 */}
                    {
                      i18n.language === LANGUAGES.JA ? (
                        <>このホテルを<br className="d-b d-n-md" />選択する</>
                      ) : (
                        <>選擇此酒店</>
                      )
                    }
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
        </LoadingOverlay>
      </div>
    </Modal>
    </>
  );
};

export default HotelHandModal;
