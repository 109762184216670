import React from "react";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";
import {useTranslation} from "react-i18next";

type Props = {
  isCoupon: boolean;
  couponName: string;
  couponPrice: number;
};

const SectionCouponsDiscountPrice: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      { (props.isCoupon && props?.couponPrice > 0) && (
        <div className="box-separate-item">
          <div className="box-separate-item-block large">
            <h3 className="c-red fz-16 fw-b mb-16">{t("Coupon.クーポン割引")}</h3>
            <div className="box-separate-item-block-subtotal_list">
              <div className="box-separate-item-block-subtotal_list-item">
                <p className="box-separate-item-block-subtotal_list-item-label">
                  {props.couponName}
                </p>
                <p className="box-separate-item-block-subtotal_list-item-subtotal"></p>
                <p className="box-separate-item-block-subtotal_list-total-total">
              <span className="fz-16 fz-18-md fz-20-md c-red">
                -{props.couponPrice.toLocaleString()}
              </span>
                  <span className="fz-14 c-red">{t("Common.円")}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};
export default SectionCouponsDiscountPrice;
