import React, { useEffect, useState } from "react";

// smooth-scroll
import { animateScroll as scroll } from 'react-scroll';

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants/routes";
// import {Hotel} from "../../types/Hotel";
// import {PlanSelectFormData} from "../../types/planSelectForm";
import {getSellerId} from "../../utils/footer";
import { LANGUAGES } from "../../constants/languages";


type Props = {
  sellerId: number | undefined;
  supportDial: string | undefined;
}

const Footer: React.VFC<Props> = ({
  sellerId,
  supportDial,
}) => {

  const [ languageCode, setLanguageCode ] = useState<string>("tw");

  const simpleNavi = useLocation().pathname;

  const { t, i18n } = useTranslation();


  // go to top of page
  const scrollToTop = () => {
    scroll.scrollToTop(); 
  };

  const createData = (
    title: string,
    href: string,
    target: string,
  ) => {
    return { title, href, target };
  };
  
  // 初期化
  const rowsTW = [
    createData(t("Footer.旅行業約款"), ROUTES.Agreement.replace(":lang", languageCode),""),
    createData(t("Footer.旅行条件書"), ROUTES.Conditions.replace(":lang", languageCode),""),
    createData(t("Footer.プライバシーポリシー"), ROUTES.Privacypolicy.replace(":lang", languageCode),""),
    createData(t("Footer.キャンセルポリシー"), `${ROUTES.Conditions.replace(":lang", languageCode)}/#sec_14`,""),
    createData(t("Footer.会社案内"), "https://otspremium.com/en/aboutus/outline/","_blank"),
  ];

  const rowsJA = [
    createData(t("Footer.旅行業約款"), "https://digitaldmoplatform.com/Agreement/","_blank"),
    createData(t("Footer.旅行条件書"), ROUTES.Conditions.replace(":lang", languageCode),""),
    createData(t("Footer.プライバシーポリシー"), ROUTES.Privacypolicy.replace(":lang", languageCode),""),
    createData(t("Footer.キャンセルポリシー"), `${ROUTES.Conditions.replace(":lang", languageCode)}/#sec_14`,""),
    createData(t("Footer.会社案内"), "https://otspremium.com/aboutus/outline/","_blank"),
  ];

  useEffect(() => {
    setLanguageCode(i18n.language);
  }, [i18n.language]);

  return (
    <footer className="l-site-footer">
        <>
          {simpleNavi === ROUTES.Page404.replace(":lang", languageCode) || simpleNavi === ROUTES.Conditions.replace(":lang", languageCode) ? (<></>):(
            <>
              {(sellerId && supportDial) && (
                <>
                  <div className="l-site-footer-contact">
                    <div className="wrap-content-900 p-r">
                      <p className="l-site-footer-contact-heading">{t("Footer.お問い合わせ")}</p>
                      <p className="l-site-footer-contact-company" hidden={languageCode !== LANGUAGES.JA}>
                        デジタルDMOカスタマーサポート
                      </p>
                      {/* <p className="l-site-footer-contact-call">
                        <a
                          href="tel:098-917-2593"
                          className="l-site-footer-contact-call-link"
                        >
                          <i className="icon-call-blue mr-5 fz-18"></i>{supportDial}
                        </a>
                      </p> */}
                      <dl className="l-site-footer-contact-inqury d-f mb-5">
                        <dt>mail：</dt>
                        <dd>
                          <a href="mailto:digitaldmo_cs@otsinternational.jp">digitaldmo_cs@otsinternational.jp</a>
                        </dd>
                      </dl>
                      {/* <dl className="l-site-footer-contact-inqury d-f mb-5">
                        <dt>営業時間：</dt>
                        <dd>
                          平日10：00～18：00<br/>
                          土曜10：00～14：00　日祝休み
                        </dd>
                      </dl> */}
                      <p className="l-site-footer-contact-inqury d-f mb-5">
                        {t("Footer.平日10：00～18：00　土日祝休み")}
                      </p>
                      {simpleNavi === ROUTES.ReservationCancelLogin.replace(":lang", languageCode) ? (<></>):(
                      <>
                        <p className="l-site-footer-contact-inqury">
                          {t("Footer.お問い合わせコード")}：{sellerId && (
                            <>{getSellerId((sellerId.toString().padStart(6, '0')))}</>
                          )}
                        </p>
                      </>
                      )}

                      <div className="page-to-top">
                        <span onClick={scrollToTop} className="page-to-top-link">
                          <p className="page-to-top-link-icon"><i className="icon-top-arrow-blue fz-24"></i></p>
                          <p className="page-to-top-link-text">{t("LinkListModal.ページトップ")}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>

        <div className="l-site-footer-navi">
          <div className="wrap-content-900">
            <div className="l-site-footer-navi-content">
              <div className="l-site-footer-navi-content-menu">
                {/* 繁体語 */}
                <ul className="l-site-footer-navi-content-menu-list" hidden={languageCode !== LANGUAGES.TW}>
                  {rowsTW.map((row, index) => (
                    <li key={index} className="l-site-footer-navi-content-menu-list-item" hidden={languageCode !== LANGUAGES.TW}>
                      <a
                        href={row.href}
                        className="l-site-footer-navi-content-menu-list-item-link"
                        target={row.target}
                      >
                        {row.title}
                      </a>
                  </li>
                  ))}
                </ul>

                {/* 日本語 */}
                <ul className="l-site-footer-navi-content-menu-list" hidden={languageCode !== LANGUAGES.JA}>
                  {rowsJA.map((row, index) => (
                    <li key={index} className="l-site-footer-navi-content-menu-list-item" hidden={languageCode !== LANGUAGES.JA}>
                      <a
                        href={row.href}
                        className="l-site-footer-navi-content-menu-list-item-link"
                        target={row.target}
                      >
                        {row.title}
                      </a>
                  </li>
                  ))}
                </ul>
              </div>

              {/* コピーライト */}
              <div className="l-site-footer-navi-content-copyright">
                Copyright © OKINAWA TOURIST SERVICE All Rights Reserved.
              </div>
              
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;