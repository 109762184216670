import axios from "axios";

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_FUNCTIONS_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
});


export const signalApiClient = (controller?: AbortController) => axios.create({
  baseURL: process.env.REACT_APP_FUNCTIONS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  signal: controller?.signal
});