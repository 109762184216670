import { FC } from "react";


type Props = {
  message: string;
}

const ValidationError: FC<Props> = (props) => {
 
  return (
    <p className="form-error_text">
      {props.message}
    </p>
  );
};

export default ValidationError;
