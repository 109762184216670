import React from "react";
import { useTranslation } from "react-i18next";

//css
import "../../../scss/common.scss";
import "../../../scss/component.scss";


const SectionCheck: React.FC = () => {

  const { t } = useTranslation();

  return (
    <>
      <section>
        {/* 確認事項 */}
        <h2 className="heading-2 mb-24-md">{t("SectionCheck.確認事項")}</h2>
        <div className="card large-side">
          <div className="box-separate">
            <div className="box-separate-item">
              <div className="box-separate-item-block">
                <ul className="list-box list-mb-large last-mb-small large fz-14">
                  <li>
                    {/* <span className="fz-16 fz-18-md fw-b">最少成團人數：1位</span> */}
                    <span className="fz-16 fz-18-md fw-b">{t("SectionCheck.最少催行人数：1名")}</span>
                    <p className="mt-5">
                      {/* （有可能會因您所選擇的住宿酒店的不同，會有即使1名也無法成團的情形出現） */}
                      （{t("SectionCheck.ただし、選択した宿泊施設により、1名でご参加いただけない場合もございます。")}）
                    </p>
                  </li>
                  <li>
                    {/* <span className="fz-16 fz-18-md fw-b">有無領隊同行：無領隊同行</span> */}
                    <span className="fz-16 fz-18-md fw-b">{t("SectionCheck.添乗員の有無：同行いたしません")}</span>
                  </li>
                  <li>
                    {/* <span className="fz-16 fz-18-md fw-b">費用裡所含的項目【重要】</span> */}
                    <span className="fz-16 fz-18-md fw-b">{t("SectionCheck.旅行代金に含まれるもの【重要】")}</span>
                    <ul className="list-number-circle mb-0 mt-5">
                      {/* <li>旅客於旅程中的往返機票</li> */}
                      {/* <li>{t("SectionCheck.個人包括旅行運賃適用の往復航空運賃")}</li> */}
                      {/* <li>日本國內線旅客的設施使用費</li> */}
                      {/* <li>{t("SectionCheck.国内線旅客施設使用料")}</li> */}
                      <li>
                        {/* 行程表中所明載的住宿費，餐費。 */}
                        {t("SectionCheck.行程表に明示した宿泊代、食事代")}
                        <ul className="list-note light-gray">
                          <li>
                            {/* 有加購體驗活動時則含有體驗活動費（其它等餐食費或是體驗方案等，會因商品不同而變動） */}
                            {t("SectionCheck.オプション付プランの場合はオプション代金（食事代又は体験プランなど、商品によってことなります）")}
                          </li>
                        </ul>
                      </li>
                      {/* <li>日本消費稅等各項稅費・服務費。</li> */}
                      <li>{t("SectionCheck.消費税等の諸税・サービス料金")}</li>
                    </ul>
                  </li>
                </ul>
                <p className="fz-14">
                  {/* 上述以外的其它項目皆不含在旅遊費用裡。 */}
                  {t("SectionCheck.上記以外は旅行代金に含まれません。")}
                  <br />
                  {/* (例)從自家至出發/抵達地點的交通費用・住宿費用等。 */}
                  {t("SectionCheck.(例)ご自宅から発着地までの交通費・宿泊費")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionCheck;
