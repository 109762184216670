import React from "react";
import { useTranslation } from "react-i18next";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";


type Props = {
  activeStep: number;
}

const PartsStep: React.FC<Props> = ({
  activeStep,
}) => {

  const { t } = useTranslation();

  return (
    <>
      <div className="step-option">
        <ul className="step-option-list">
          <li className={"step-option-list-item" + (activeStep === 1? " active":"")}>
            {/* 宿泊を選択 */}
            {/* 請選擇住宿酒店 */}
            {t("Steps.宿泊を選択")}
          </li>
          <li className={"step-option-list-item" + (activeStep === 2? " active":"")}>
            {/* 旅程の
            <br />
            確認・変更・追加 */}
            {/* 行程 */}
            {t("Steps.旅程の")}
            <br />
            {/* 確認・變更・追加 */}
            {t("Steps.確認・変更・追加")}
          </li>
          <li className={"step-option-list-item" + (activeStep === 3? " active":"")}>
            {/* 旅程の最終確認 */}
            {/* 行程的最終確認 */}
            {t("Steps.旅程の最終確認")}
          </li>
        </ul>
      </div>        
    </>
  );
};

export default PartsStep;
