import DatePicker from "react-datepicker";
import {dateFormat} from "../../../utils/convert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CounterNumberOfUnitsCarRental from "../../counter/carRentalCounterNumberOfUnits";
import {getCarRentalCountNum, getCarRentalSheetCountNum} from "../../../utils/carRental";
import {SearchNotFound} from "../../index";
import React from "react";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {carRentalPlanData, carRentalSaveData, carRentalSearchResponsePlanList} from "../../../types/carRental";
import {DATEPICKER_LOCALES, LANGUAGES} from "../../../constants/languages";
import {useTranslation} from "react-i18next";

type Props = {
  step: number
  departureDate: string
  changeDepartureDate: (newValue: Date | null) => void
  planSelectFormData: PlanSelectFormData
  isLoading: boolean
  borrowingTimeOpen: boolean
  handleBorrowingTimeClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  borrowingTimeOptions: string[]
  selectedBorrowingTimeIndex: number
  borrowingTimeAnchorEl: null | HTMLElement
  borrowingTimeHandleClose: () => void
  handleBorrowingTimeClick: (event: React.MouseEvent<HTMLElement>, index: number,) => void
  borrowingPlaceOpen: boolean
  handleBorrowingPlaceClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  selectedBorrowingPlaceIndex: number
  borrowingPlaceAnchorEl: null | HTMLElement
  borrowingPlaceHandleClose: () => void
  handleBorrowingPlaceClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  returnDate: string
  changeReturnDate: (newValue: Date | null) => void
  returnTimeOpen: boolean
  handleReturnTimeClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  returnTimeOptions: string[]
  selectedReturnTimeIndex: number
  returnTimeAnchorEl: null | HTMLElement
  returnTimeHandleClose: () => void
  handleReturnTimeClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  returnPlaceOpen: boolean
  handleReturnPlaceClickListItem: (event: React.MouseEvent<HTMLElement>) => void
  selectedReturnPlaceIndex: number
  returnPlaceAnchorEl: null | HTMLElement
  returnPlaceHandleClose: () => void
  handleReturnPlaceClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  searchCarRental: () => void
  rentalRows: carRentalSearchResponsePlanList[]
  carRentalPlanParams: carRentalSaveData
  addSaveDataByStep1: (count:number, plan_code:string, type: string) => void
  callBackModal: () => void
  flights: {label: string, code: string}[]
  borrowingPlaceOptions: {label: string, code: string}[]
  returnPlaceOptions: {label: string, code: string}[]
  accessTypes: {label: string, code: string}[]
  accessTypeOpen: boolean
  handleAccessTypes: (event: React.MouseEvent<HTMLElement>) => void
  accessTypeAnchorEl: null | HTMLElement
  selectAccessTypeIndex: number
  handleAccessTypesClose: () => void
  handleAccessTypesClick: (event: React.MouseEvent<HTMLElement>, index: number,) => void
  departureFlightsOpen: boolean
  handleDepartureFlights: (event: React.MouseEvent<HTMLElement>) => void
  selectDepartureFlightsIndex: number
  departureFlightsAnchorEl: null | HTMLElement
  handleDepartureFlightsClose: () => void
  handleDepartureFlightsClick: (event: React.MouseEvent<HTMLElement>, index: number) => void
  departureFlightNumber: string | undefined
  handleDepartureFlightNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
  returnFlightsOpen: boolean
  handleReturnFlights: (event: React.MouseEvent<HTMLElement>) => void
  selectReturnFlightsIndex: number
  returnFlightsAnchorEl: null | HTMLElement
  handleReturnFlightsClose: () => void
  handleReturnFlightsClick: (event: React.MouseEvent<HTMLElement>, index:number) => void
  returnFlightNumber: string | undefined
  handleReturnFlightNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RentalModalStep1: React.FC<Props> = ({ ...props }) => {

  const { t, i18n } = useTranslation();
  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));
  return (
    <>
      {props.step === 1 && (
        <>
          <div className="bgc-white mb-48-md mb-40">

            <div className="bgc-white">
              <div className="wrap-content-900-large wrap-padding-md">
                {/* <p className="heading-2 fz-26-md mt-24 mb-20 mt-50-md">若有需要加購租車的客人，請從下方進行加購</p> */}
                <p
                  className="heading-2 fz-26-md mt-24 mb-20 mt-50-md">{t("RentalModal.レンタカーを追加される方は下記よりお選びください")}</p>
                <div className="d-f ai-c">
                  <div className="w-100 w-130-md fx-sh">
                    <img src={require("../../../img/share/rentacar/logo_ots_rentacar.svg").default} alt="OTS RENT-A-CAR"/>
                  </div>
                  <p className="fz-12 fz-16-md lh-1_5 lh-1_75 ml-8 ml-24-md">
                    {/* 沖繩及北海道的租車請您放心交給OTS租車！我們除了準備多樣式的車種，還有各種早鳥優惠方案，還有機場，酒店或港口等免費接送服務！ */}
                    {t("RentalModal.レンタカーなら、OTSレンタカーにお任せ！多彩なラインナップのお車をご用意しております。空港から営業所までの無料送迎サービスもございます。")}
                  </p>
                </div>
                <div className="box-light-gray medium mt-24 mt-16-md">
                  {/* <div className="fz-12 fz-13-md fw-b c-navy mb-8">【注意事項】</div>
                    <ul className="list-dot-blue_gray">
                      <li key="1">標示的金額已含有免責賠償費用。</li>
                      <li key="2">請於營業所的營業時間內<span className="c-red">（8:00～19:00／會因季節及店舖而有所變動）</span>完成交車及還車手續。</li>
                      <li key="3">若出發/抵達的班機時間為早班/深夜時，有可能會有無法接受預約的情況出現，請您見諒並配合。</li>
                      <li key="4">有關於車款，我們會以「需求」的方式來接受您的預約，但若當天因內在或外在因素無法配車時，我們會為您安排其它同等級的車款，敬請您的事先諒解與合作。</li>
                      <li key="5">此網站照片僅為參考，敬請見諒。</li>
                    </ul> */}
                  <div className="fz-12 fz-13-md fw-b c-navy mb-8">【注意事項】</div>
                  <ul className="list-dot-blue_gray">
                    <li>{t("RentalModal.表示されている料金は、免責補償料込となります。")}</li>
                    <li>
                      {t("RentalModal.配車・返車は、営業所の営業時間内")}
                      <span className="c-red">
                          {
                            // NOTE: 普通に翻訳すると「(8:」が消えるので分けて翻訳する
                            i18n.language === LANGUAGES.JA ? "（8:00～19:00／時期・店舗により変動あり）" : "（8:00～19:00／會因季節及店舖而有所變動）"
                          }
                        </span>
                      {t("RentalModal.となります。")}
                    </li>
                    <li>{t("RentalModal.早朝・深夜発着の飛行機をご選択いただいた場合、手配をお断りさせていただく場合がございますので予めご了承ください。")}</li>
                    <li>{t("RentalModal.車種につきましては、「ご希望」として承ることが可能ですが、ご希望に沿いかねる場合や当日同等クラスの他の車種を代わりにご用意させて頂く場合がございますので、予めご了承ください。")}</li>
                    <li>{t("RentalModal.掲載されている写真はイメージです。実際にご利用いただける車種と異なる場合がございます。")}</li>
                  </ul>
                </div>

                <div className="box-separator mt-24 mt-32-md pb-20 pb-40-md">
                  {/* 貸出 */}
                  <div className="box-separator-item pc-large">
                    <div className="d-f-md ai-c">
                      <p
                        className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-100-md">{t("CarRental.貸出")}</p>
                      <div className="fx-1 d-f-md">
                        <div className="w-50per-md d-f pr-6-md">
                          <div className="w-50per pr-6">
                            {/* <label htmlFor="borrowing_date" className="d-b c-blue_gray fz-12 fz-14-md mb-5">取車日期</label> */}
                            <label htmlFor="borrowing_date"
                                   className="d-b c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.貸出日")}</label>
                            <div className="form-date-seal">
                              <div className="form-input-date-icon-wrap">
                                <DatePicker
                                  dateFormat="yyyy/MM/dd"
                                  locale={i18n.language === LANGUAGES.JA ? DATEPICKER_LOCALES.JA : DATEPICKER_LOCALES.TW}
                                  selected={new Date(props.departureDate)}
                                  value={dateFormat(props.departureDate, 'YYYY/MM/DD')}
                                  className="form-input-date"
                                  onChange={props.changeDepartureDate}
                                  minDate={new Date(props.planSelectFormData.searchParams.start)}
                                  maxDate={new Date(props.planSelectFormData.searchParams.end)}
                                  customInput={<ForceReadOnlyInput/>}
                                  disabled={props.isLoading}
                                  calendarStartDay={0}
                                />
                                <p className="form-date-seal-label"> {dateFormat(props.departureDate, "YYYY/MM/DD")}</p>
                              </div>
                            </div>
                          </div>
                          <div className="w-50per pl-6">
                            <p className="c-blue_gray fz-12 fz-14-md mb-5">時間</p>
                            <div className="form-select_list-wrap">
                              <List
                                component="div"
                                className="form-select_list"
                              >
                                <ListItem
                                  button
                                  id="borrowing_time"
                                  aria-haspopup="listbox"
                                  aria-controls="borrowing_menu"
                                  // aria-label="when device is locked"
                                  aria-expanded={props.borrowingTimeOpen ? 'true' : undefined}
                                  className="form-select_list-inner"
                                  onClick={props.handleBorrowingTimeClickListItem}
                                >
                                  <ListItemText
                                    // primary="When device is locked"
                                    secondary={props.borrowingTimeOptions[props.selectedBorrowingTimeIndex]}
                                    className="form-select_list-selected_text text_medium"
                                  />
                                </ListItem>
                              </List>
                              <Menu
                                id="borrowing_menu"
                                anchorEl={props.borrowingTimeAnchorEl}
                                open={props.borrowingTimeOpen}
                                className="form-select_list-options"
                                onClose={props.borrowingTimeHandleClose}
                                MenuListProps={{
                                  'aria-labelledby': 'borrowing_time',
                                  role: 'listbox',
                                }}
                              >
                                {props.borrowingTimeOptions.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    // disabled={index === 0}
                                    className="form-select_list-options-item"
                                    selected={index === props.selectedBorrowingTimeIndex}
                                    onClick={(event) => props.handleBorrowingTimeClick(event, index)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </div>
                          </div>
                        </div>

                        <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                          {/* <p className="c-blue_gray fz-12 fz-14-md mb-5">取車營業所</p> */}
                          <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.貸出店舗")}</p>
                          <div className="form-select_list-wrap">
                            <List
                              component="div"
                              className="form-select_list"
                            >
                              <ListItem
                                button
                                id="borrowing_place"
                                aria-haspopup="listbox"
                                aria-controls="borrowing_place_menu"
                                // aria-label="when device is locked"
                                aria-expanded={props.borrowingPlaceOpen ? 'true' : undefined}
                                className="form-select_list-inner"
                                onClick={props.handleBorrowingPlaceClickListItem}
                              >
                                <ListItemText
                                  // primary="When device is locked"
                                  secondary={t("CarRental." + props.borrowingPlaceOptions[props.selectedBorrowingPlaceIndex]["label"])}
                                  className="form-select_list-selected_text text_medium"
                                />
                              </ListItem>
                            </List>
                            <Menu
                              id="borrowing_place_menu"
                              anchorEl={props.borrowingPlaceAnchorEl}
                              open={props.borrowingPlaceOpen}
                              className="form-select_list-options"
                              onClose={props.borrowingPlaceHandleClose}
                              MenuListProps={{
                                'aria-labelledby': 'borrowing_place',
                                role: 'listbox',
                              }}
                            >
                              {props.borrowingPlaceOptions.map((option, index) => (
                                <MenuItem
                                  key={option["code"]}
                                  // disabled={index === 0}
                                  className="form-select_list-options-item"
                                  selected={index === props.selectedBorrowingPlaceIndex}
                                  onClick={(event) => props.handleBorrowingPlaceClick(event, index)}
                                >
                                  {t("CarRental." + option["label"])}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 返却 */}
                  <div className="box-separator-item pc-large">
                    <div className="d-f-md ai-c">
                      <p
                        className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-100-md">{t("CarRental.返却")}</p>
                      <div className="fx-1 d-f-md">
                        <div className="w-50per-md d-f pr-6-md">
                          <div className="w-50per pr-6">
                            {/* <label htmlFor="return_date" className="d-b c-blue_gray fz-12 fz-14-md mb-5">還車日期</label> */}
                            <label htmlFor="return_date"
                                   className="d-b c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.返却日")}</label>
                            <div className="form-date-seal">
                              <div className="form-input-date-icon-wrap">
                                <DatePicker
                                  dateFormat="yyyy/MM/dd"
                                  locale={i18n.language === LANGUAGES.JA ? DATEPICKER_LOCALES.JA : DATEPICKER_LOCALES.TW}
                                  selected={new Date(props.returnDate)}
                                  value={dateFormat(props.returnDate, 'YYYY/MM/DD')}
                                  className="form-input-date"
                                  onChange={props.changeReturnDate}
                                  minDate={new Date(props.planSelectFormData.searchParams.start)}
                                  maxDate={new Date(props.planSelectFormData.searchParams.end)}
                                  customInput={<ForceReadOnlyInput/>}
                                  disabled={props.isLoading}
                                  calendarStartDay={0}
                                />
                                <p className="form-date-seal-label"> {dateFormat(props.returnDate, "YYYY/MM/DD")}</p>
                              </div>
                            </div>
                          </div>
                          <div className="w-50per pl-6">
                            <p className="c-blue_gray fz-12 fz-14-md mb-5">時間</p>
                            <div className="form-select_list-wrap">
                              <List
                                component="div"
                                className="form-select_list"
                              >
                                <ListItem
                                  button
                                  id="borrowing_time"
                                  aria-haspopup="listbox"
                                  aria-controls="borrowing_menu"
                                  // aria-label="when device is locked"
                                  aria-expanded={props.returnTimeOpen ? 'true' : undefined}
                                  className="form-select_list-inner"
                                  onClick={props.handleReturnTimeClickListItem}
                                >
                                  <ListItemText
                                    // primary="When device is locked"
                                    secondary={props.returnTimeOptions[props.selectedReturnTimeIndex]}
                                    className="form-select_list-selected_text text_medium"
                                  />
                                </ListItem>
                              </List>
                              <Menu
                                id="borrowing_menu"
                                anchorEl={props.returnTimeAnchorEl}
                                open={props.returnTimeOpen}
                                className="form-select_list-options"
                                onClose={props.returnTimeHandleClose}
                                MenuListProps={{
                                  'aria-labelledby': 'borrowing_time',
                                  role: 'listbox',
                                }}
                              >
                                {props.returnTimeOptions.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    // disabled={index === 0}
                                    className="form-select_list-options-item"
                                    selected={index === props.selectedReturnTimeIndex}
                                    onClick={(event) => props.handleReturnTimeClick(event, index)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </div>
                          </div>
                        </div>

                        <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                          {/* <p className="c-blue_gray fz-12 fz-14-md mb-5">還車營業所</p> */}
                          <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.返却店舗")}</p>
                          <div className="form-select_list-wrap">
                            <List
                              component="div"
                              className="form-select_list"
                            >
                              <ListItem
                                button
                                id="borrowing_place"
                                aria-haspopup="listbox"
                                aria-controls="borrowing_place_menu"
                                // aria-label="when device is locked"
                                aria-expanded={props.returnPlaceOpen ? 'true' : undefined}
                                className="form-select_list-inner"
                                onClick={props.handleReturnPlaceClickListItem}
                              >
                                <ListItemText
                                  // primary="When device is locked"
                                  secondary={t("CarRental." + props.returnPlaceOptions[props.selectedReturnPlaceIndex]["label"])}
                                  className="form-select_list-selected_text text_medium"
                                />
                              </ListItem>
                            </List>
                            <Menu
                              id="borrowing_place_menu"
                              anchorEl={props.returnPlaceAnchorEl}
                              open={props.returnPlaceOpen}
                              className="form-select_list-options"
                              onClose={props.returnPlaceHandleClose}
                              MenuListProps={{
                                'aria-labelledby': 'borrowing_place',
                                role: 'listbox',
                              }}
                            >
                              {props.returnPlaceOptions.map((option, index) => (
                                <MenuItem
                                  key={option["code"]}
                                  // disabled={index === 0}
                                  className="form-select_list-options-item"
                                  selected={index === props.selectedReturnPlaceIndex}
                                  onClick={(event) => props.handleReturnPlaceClick(event, index)}
                                >
                                  {t("CarRental." + option["label"])}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="pb-20 pb-40-md">
                  <button
                    className="button-large-red w-100per"
                    onClick={props.searchCarRental}
                  >
                    {t("RentalModal.上記の条件で検索する")}
                  </button>
                </div>
              </div>
            </div>


            <div className="wrap-content-900-large wrap-padding-md ">

              <h2 className="heading-2 fz-26-md mt-40 mb-16 mt-48-md mb-20-md">{t("RentalModal.来店手段")}</h2>

              <div className="box-separator_bottom box bgc-white p-24 ">
                {/* 営業所までの来店手段 */}
                <div className="box-separator_bottom-item pc-large">
                  <div className="d-f-md ai-c">
                    <p
                      className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-200-md">{t("RentalModal.営業所までの来店手段")}<span
                      className="label-required ml-5">{t('Common.必須')}</span></p>
                    <div className="fx-1 d-f-md">

                      <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                        <div className="form-select_list-wrap">
                          <List
                            component="div"
                            className="form-select_list"
                          >
                            <ListItem
                              button
                              id="borrowing_place"
                              aria-haspopup="listbox"
                              aria-controls="borrowing_place_menu"
                              // aria-label="when device is locked"
                              aria-expanded={props.accessTypeOpen ? 'true' : undefined}
                              className="form-select_list-inner"
                              onClick={props.handleAccessTypes}
                            >
                              <ListItemText
                                // primary="When device is locked"
                                secondary={t("CarRental." + props.accessTypes[props.selectAccessTypeIndex]["label"])}
                                className="form-select_list-selected_text text_medium"
                              />
                            </ListItem>
                          </List>
                          <Menu
                            id="borrowing_place_menu"
                            anchorEl={props.accessTypeAnchorEl}
                            open={props.accessTypeOpen}
                            className="form-select_list-options"
                            onClose={props.handleAccessTypesClose}
                            MenuListProps={{
                              'aria-labelledby': 'borrowing_place',
                              role: 'listbox',
                            }}
                          >
                            {props.accessTypes.map((option, index) => (
                              <MenuItem
                                key={option["code"]}
                                // disabled={index === 0}
                                className="form-select_list-options-item"
                                selected={index === props.selectAccessTypeIndex}
                                onClick={(event) => props.handleAccessTypesClick(event, index)}
                              >
                                {t("CarRental." + option["label"])}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {props.carRentalPlanParams.access_type === "airport" && (
                  <>
                    {/* 到着便 */}
                    <div className="box-separator_bottom-item pc-large">
                      <div className="d-f-md ai-c">
                        <p
                          className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-200-md">{t("RentalModal.到着便")}<span
                          className="label-required ml-5">{t('Common.必須')}</span></p>
                        <div className="fx-1 d-f-md">
                          <div className="w-50per-md d-f pr-6-md">
                            <div className="w-100per pl-6">
                              <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.航空会社")}</p>
                              <div className="form-select_list-wrap">
                                <List
                                  component="div"
                                  className="form-select_list"
                                >
                                  <ListItem
                                    button
                                    id="borrowing_time"
                                    aria-haspopup="listbox"
                                    aria-controls="borrowing_menu"
                                    // aria-label="when device is locked"
                                    aria-expanded={props.departureFlightsOpen ? 'true' : undefined}
                                    className="form-select_list-inner"
                                    onClick={props.handleDepartureFlights}
                                  >
                                    <ListItemText
                                      // primary="When device is locked"
                                      secondary={t("CarRental." + props.flights[props.selectDepartureFlightsIndex]['label'])}
                                      className="form-select_list-selected_text text_medium"
                                    />
                                  </ListItem>
                                </List>
                                <Menu
                                  id="borrowing_menu"
                                  anchorEl={props.departureFlightsAnchorEl}
                                  open={props.departureFlightsOpen}
                                  className="form-select_list-options"
                                  onClose={props.handleDepartureFlightsClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'borrowing_time',
                                    role: 'listbox',
                                  }}
                                >
                                  {props.flights.map((option, index) => (
                                    <MenuItem
                                      key={option["code"]}
                                      // disabled={index === 0}
                                      className="form-select_list-options-item"
                                      selected={index === props.selectDepartureFlightsIndex}
                                      onClick={(event) => props.handleDepartureFlightsClick(event, index)}
                                    >
                                      {t("CarRental." + option["label"])}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                            </div>
                          </div>

                          <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                            {/* <p className="c-blue_gray fz-12 fz-14-md mb-5">還車營業所</p> */}
                            <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.便名")}</p>
                            <div className="form-select_list-wrap">
                              <input
                                name="departureFlightNumber"
                                type="text"
                                className="form-input-text"
                                value={props.departureFlightNumber}
                                onChange={(event) => {
                                  props.handleDepartureFlightNumber(event)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 出発便 */}
                    <div className="box-separator_bottom-item pc-large">
                      <div className="d-f-md ai-c">
                        <p
                          className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md ml-16-md fx-sh w-200-md">{t("RentalModal.出発便")}<span
                          className="label-required ml-5">{t('Common.必須')}</span></p>
                        <div className="fx-1 d-f-md">
                          <div className="w-50per-md d-f pr-6-md">
                            <div className="w-100per pl-6">
                              <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.航空会社")}</p>
                              <div className="form-select_list-wrap">
                                <List
                                  component="div"
                                  className="form-select_list"
                                >
                                  <ListItem
                                    button
                                    id="borrowing_time"
                                    aria-haspopup="listbox"
                                    aria-controls="borrowing_menu"
                                    // aria-label="when device is locked"
                                    aria-expanded={props.returnFlightsOpen ? 'true' : undefined}
                                    className="form-select_list-inner"
                                    onClick={props.handleReturnFlights}
                                  >
                                    <ListItemText
                                      // primary="When device is locked"
                                      secondary={t("CarRental." + props.flights[props.selectReturnFlightsIndex]['label'])}
                                      className="form-select_list-selected_text text_medium"
                                    />
                                  </ListItem>
                                </List>
                                <Menu
                                  id="borrowing_menu"
                                  anchorEl={props.returnFlightsAnchorEl}
                                  open={props.returnFlightsOpen}
                                  className="form-select_list-options"
                                  onClose={props.handleReturnFlightsClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'borrowing_time',
                                    role: 'listbox',
                                  }}
                                >
                                  {props.flights.map((option, index) => (
                                    <MenuItem
                                      key={option["code"]}
                                      // disabled={index === 0}
                                      className="form-select_list-options-item"
                                      selected={index === props.selectReturnFlightsIndex}
                                      onClick={(event) => props.handleReturnFlightsClick(event, index)}
                                    >
                                      {t("CarRental." + option["label"])}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </div>
                            </div>
                          </div>

                          <div className="w-50per-md mt-16 mt-0-md pl-6-md">
                            {/* <p className="c-blue_gray fz-12 fz-14-md mb-5">還車營業所</p> */}
                            <p className="c-blue_gray fz-12 fz-14-md mb-5">{t("RentalModal.便名")}</p>
                            <div className="form-select_list-wrap">
                              <input
                                name="returnFlightNumber"
                                type="text"
                                className="form-input-text"
                                value={props.returnFlightNumber}
                                onChange={(event) => props.handleReturnFlightNumber(event)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

            </div>

            <div className="modal-fixed-bottom">
              <button
                onClick={props.callBackModal}
                className="button-medium-light_blue padding-large w-100per"
              >
                {/* 依上述內容來加購此項目 */}
                {t("RentalModal.上記内容で追加する")}
              </button>
            </div>
          </div>

          <div className="wrap-content-900-large wrap-padding-md ">
            <p className="heading-2 fz-26-md mt-24 mb-20 mt-50-md">{t("CarRental.レンタカー")}</p>
            <div className="box-card-rentacar">
              {props.rentalRows.length > 0 ?
                <>
                  {props.rentalRows?.map((row, index) => (
                    <div key={index} className={(() => {
                      if (index !== 0) {
                        return ("box-card-rentacar-item mt-8")
                      } else {
                        return ("box-card-rentacar-item")
                      }
                    })()}>
                      <div className="box-card-rentacar-detail">
                        <div className="box-card-rentacar-detail-left">
                          {row.image_url ? (
                            <div><img src={row.image_url} alt={row.car_type_code} className="d-b w-100per"/></div>
                          ) : (
                            <div><img src={require("../../..//img/share/rentacar/car_no_image.png").default} alt={row.car_type_code} className="d-b w-100per"/></div>
                          )}
                          {/*<span className="label-gray-sausage mt-8">*/}
                          {/*        空車：<i className="icon-possible"></i>*/}
                          {/*</span>*/}
                        </div>
                        <div className="box-card-rentacar-detail-right">
                          <p className="fz-14 fz-16-sm fw-b c-navy">{row.plan_name}</p>
                          <dl className="box-card-rentacar-detail-right-table mt-8">
                            <div className="box-card-rentacar-detail-right-table-item">
                              <dt className="box-card-rentacar-detail-right-table-item-heading">{t("CarRental.車種")}</dt>
                              <dd className="box-card-rentacar-detail-right-table-item-text">{row.class_model}</dd>
                            </div>
                            {row.max_member &&
                              <div className="box-card-rentacar-detail-right-table-item">
                                <dt className="box-card-rentacar-detail-right-table-item-heading">{t("CarRental.定員")}</dt>
                                <dd
                                  className="box-card-rentacar-detail-right-table-item-text">{row.max_member.toString()}人
                                </dd>
                              </div>
                            }
                          </dl>
                          <ul className="d-f fxw-w mt-4">
                            {row.default_option_list.map((tagRow, tIndex) => (
                              <li key={tIndex}><span className="label-gray-small mr-4 mt-4">{t("CarRental." + tagRow.option_name)}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="box-card-rentacar-price">
                        <p className="mr-8 mr-0-md ta-r-sm">
                        <span
                          className="d-ib d-b-sm fz-12 fz-13-sm fw-b mr-8 mr-0-md c-navy">{t("RentalModal.追加代金 (一台あたり)")}</span>
                          <span className="d-b-sm fz-20 fz-24-sm fw-b lh-1-md c-red mt-5-md">{Number(row.price).toLocaleString()}<span
                            className="fz-12 fz-12-sm fw-b c-red">{t('Common.円')}</span></span>
                        </p>

                        {/* カウンター */}
                        <div className="pl-32-md pr-0-md mt-24-sm">
                          <CounterNumberOfUnitsCarRental
                            type="car"
                            defaultCount={getCarRentalCountNum(props.carRentalPlanParams, row.plan_code)}
                            plan_code={row.plan_code}
                            updateCounter={props.addSaveDataByStep1}></CounterNumberOfUnitsCarRental>
                        </div>

                      </div>
                    </div>
                  ))}
                </>
                :
                <>
                  <SearchNotFound></SearchNotFound>
                </>
              }
            </div>
          </div>

          <div className="modal-fixed-bottom">
            <button onClick={props.callBackModal} className="button-medium-light_blue padding-large w-100per">
              {t("CarRental.オプションを選択する")}
            </button>
          </div>
        </>
      )}
    </>
  );

}

export default RentalModalStep1;