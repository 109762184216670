import React from "react";

// for styles
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import {dateFormat} from "../../utils/convert";
import { useTranslation } from "react-i18next";


// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  date:string,
  checkIn:string,
  hotelName:string,
  callbackHotelCancel(date:string, checkIn:string):void
  callback: () => void,
}

// ダイアログ:ホテルの日程のキャンセル
const DialogHotelCancel: React.FC<Props> = ({
  isShow,
  date,
  checkIn,
  hotelName,
  callbackHotelCancel,
  callback
}) => {

  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={isShow}
        onClose={callback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog"
      >
        <DialogTitle id="alert-dialog-title" className="dialog-title">
          {t("DialogHotelCancel.ホテルの日程のキャンセル")}
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText id="alert-dialog-description" className="dialog-content-text">
            {/* {hotelName}の{dateFormat(date, "YYYY年MM月DD日")}以降の日程をキャンセルしてもよろしいですか？ */}
            {t("DialogHotelCancel.hotelのdate以降の日程をキャンセルしてもよろしいですか？", { hotel: hotelName, date: dateFormat(date, "YYYY年MM月DD日") })}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button className="button-dialog-border-small-blue w-110" onClick={callback}>{t("DialogCarRentalCancel.いいえ")}</Button>
          <Button className="button-dialog-small-blue w-110" onClick={() => callbackHotelCancel(date, checkIn)} autoFocus>
            {t("DialogCarRentalCancel.はい")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogHotelCancel;
