// import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
// import {PlanSelectFormData} from "../../types/planSelectForm";
import React from "react";
import { useTranslation } from "react-i18next";

const mapKey = process.env.REACT_APP_MAP_KEY;

type Props = {
  lat: string | undefined,
  lng: string | undefined,
  address: string | undefined,
  label: string
}

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

const Map: React.FC<Props> = ({
  lat,
  lng,
  address,
  label
}) => {
  // const center = {
  //   lat: Number(lat)?? 0,
  //   lng: Number(lng)?? 0,
  // }
  const { i18n } = useTranslation();
  let lang = 'ja';
  if (i18n.language === 'tw') {
    lang = 'zh-tw';
  }
  
  return (
    <iframe
      title={label}
      src={"https://www.google.com/maps/embed/v1/place?key="+mapKey+"&q="+address+"&language="+lang}
      width="100%" height="450" style={{ border: 0 }}  loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade"></iframe>
  );
};

export default Map;