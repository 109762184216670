import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputsForm3 } from "../types/InputsForm3";

export interface Form3State {
  form3: InputsForm3;
}

const initialState: Form3State = {
  form3: {
    disability_insurance: {
      subscription_period: {
        start: "",
        end: "",
      },
      agree: "",
      we_do_join: "",
    },
    cancellation_insurance: {
      agree: "",
      we_do_join: "",
    },
  },
};

export const form3Slice = createSlice({
  name: "form3",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Form3State>) => {
      state.form3 = { ...state.form3, ...action.payload.form3 };
    },
    clear: (state) => {
      state.form3 = initialState.form3;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save, clear } = form3Slice.actions;

export default form3Slice.reducer;
